/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntPopover from '../../Vendor/Ant/Popover';

/**
 * Styled components
 */
export default styled(VendorAntPopover)`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`;
