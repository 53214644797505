/**
 * Node modules
 */
import _ from 'lodash';

export default (fields) => {
  const values = {};
  for (let i = 0; i < fields.length; i += 1) {
    const {
      initial,
      path: fieldPath,
    } = fields[i];
    if (_.isString(fieldPath)) {
      if (_.isArray(initial) && _.every(initial, element => _.isPlainObject(element))) {
        for (let j = 0; j < initial.length; j += 1) {
          const value = initial[j];
          const keys = Object.keys(value);
          for (let k = 0; k < keys.length; k += 1) {
            const key = keys[k];
            const path = `${fieldPath}[${j}].${key}`;
            _.set(values, path, value[key]);
          }
        }
      } else {
        _.set(values, fieldPath, initial);
      }
    }
  }
  return values;
};
