/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Betlytical modules
 */
import { permissionIdentities } from '@betlytical/api-schema';

/**
 * Advanced containers
 */
import AdvancedForm from '../../../Advanced/Form';

/**
 * Locales
 */
import { useTranslate } from '../../../../../locales';
import * as Styled from '../../../../components/Styled';

/**
 * Functional components
 */
const ActionCancelDealById = (props) => {
  const {
    objectId,
    compositeKey,
    isIcon = true,
  } = props;
  const translate = useTranslate();
  const context = {
    actionType: 'CANCEL_DEAL_BY_ID',
    by: objectId,
    permissionIdentity: permissionIdentities.CANCEL_DEAL,
  };
  const fields = [{
    initial: objectId,
    path: 'params.objectId',

  },
    compositeKey &&{
    initial: compositeKey,
    path: 'params.compositeKey'
  }].filter(Boolean);
  const as = isIcon ? (<Styled.Icon.IonicClose />) : translate('label.cancel');
  return (
    <AdvancedForm
      as={as}
      context={context}
      fields={fields}
      isDirty
    />
  );
};
ActionCancelDealById.displayName = 'ActionCancelDealById';
ActionCancelDealById.propTypes = { objectId: PropTypes.string.isRequired };
export default React.memo(ActionCancelDealById);
