/**
 * Node modules
 */
import React from 'react';
import {
  Redirect,
  useLocation,
  useNavigate,
} from '@reach/router';
import _ from 'lodash';
import { useSelector } from 'react-redux';

/**
 * Components
 */
import Toaster from '../../application/components/Vendor/Ant/Toaster';

/**
 * Configurations
 */
import { app } from '../../config';

/**
 * Helpers
 */
import Contexts from '../contexts';
import constants from '../constants';
import utilities from '../utilities';

/**
 * Locales
 */
import { useTranslate } from '../../locales';

/**
 * Navigations
 */
import navigations from '../../navigations';

/**
 * Private modules
 */
import {
  useBaseBundle,
  useDeepEffect,
} from './.private';

/**
 * Redux
 */
import { selectors } from '../../redux';

/**
 * Socket
 */
import socketClient from '../../socket-client';

/**
 * Function hooks
 */
const useAccessibleBundle = (props) => {
  const {
    context,
    options = {},
    pathForMarkers = {},
    redirectTo,
  } = props;
  const currentAccountIdentityForPermission = useSelector(state => selectors.currentAccountIdentityForPermissionSelector(state));
  const flattenOptions = Object.keys(options).map(key => options[key]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const accessibleMenus = React.useMemo(() => utilities.generatePermittedMenus(currentAccountIdentityForPermission, navigations[context], options), [currentAccountIdentityForPermission, ...flattenOptions]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const accessibleRoutes = React.useMemo(() => utilities.generatePermittedRoutes(currentAccountIdentityForPermission, navigations[context], options), [currentAccountIdentityForPermission, ...flattenOptions]);
  const routeComponents = React.useMemo(() => {
    const components = [];
    for (let j = 0; j < accessibleRoutes.length; j += 1) {
      const {
        LazyComponent,
        path: accessibleRoutePath,
      } = accessibleRoutes[j];
      const markers = pathForMarkers[accessibleRoutePath];
      components.push(React.createElement(LazyComponent, {
        key: accessibleRoutePath,
        markers,
        path: accessibleRoutePath || '/',
      }));
    }
    components.push(React.createElement(Redirect, {
      default: true,
      from: '/',
      key: 'redirect',
      noThrow: true,
      to: redirectTo,
    }));
    return components;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleRoutes, pathForMarkers, redirectTo]);
  return {
    accessibleMenus,
    routeComponents,
  };
};
const useActionBundle = (props) => {
  const bundle = useBaseBundle(props);
  const [isDialogShown, setIsDialogShown] = React.useState(false);
  bundle.handleHideDialog = React.useCallback(() => setIsDialogShown(false), [setIsDialogShown]);
  bundle.handleShowDialog = React.useCallback(() => setIsDialogShown(true), [setIsDialogShown]);
  bundle.isDialogShown = isDialogShown;
  return bundle;
};
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};
const useDocumentBundle = (props) => {
  const bundle = useBaseBundle(props);
  bundle.isFirstBoot = bundle.request.count === 0;
  return bundle;
};
const useFeedback = (props) => {
  const {
    sharePayloadFormat,
    exportExcel,
    setHasReconfirmed = _.noop,
    handleHideDialog = _.noop,
    isIgnoringSuccess = false,
    resetForm = _.noop,
    response,
    setSubmitting = _.noop,
  } = props;
  const metadataSet = React.useContext(Contexts.MetadataSet);
  const translate = useTranslate();
  const { metadata, payload } = response;
  const isInitialMount = React.useRef(true);
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (metadata && !metadataSet.has(metadata)) {
      metadataSet.add(metadata);
      if (metadata.status === constants.statusSuccess && !isIgnoringSuccess) {
        if (sharePayloadFormat) {
          utilities.webShare({ ...payload, format: sharePayloadFormat }).then((hasError) => {
            if (hasError) {
              setHasReconfirmed(true);
            } else {
              Toaster.success(translate(metadata.message));
            }
          });
        } else if (exportExcel) {
          utilities.exportExcel({ ...payload }).then(() => {});
        } else {
          Toaster.success(translate(metadata.message));
        }
      } else if (metadata && metadata.status === constants.statusError) {
        metadataSet.add(metadata);
        Toaster.error(translate(metadata.message));
      }
    }
  }, [isIgnoringSuccess, metadata, metadataSet, payload, setHasReconfirmed, sharePayloadFormat, translate]);
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (metadata && metadata.status === constants.statusSuccess && !isIgnoringSuccess) {
      setSubmitting(false);
      handleHideDialog();
      resetForm();
    } else if (metadata && metadata.status === constants.statusError) {
      setSubmitting(false);
    }
  }, [handleHideDialog, isIgnoringSuccess, metadata, resetForm, setSubmitting]);
};
const useIsKeyPressed = (targetKeys = []) => {
  const [isKeyPressed, setIsKeyPressed] = React.useState(false);
  const handleOnKeyDown = React.useCallback((e) => {
    if (e.key === 'F1') {
      e.preventDefault();
    }
    const isFound = targetKeys.some(targetKey => targetKey === e.key);
    if (isFound) {
      setIsKeyPressed(true);
    }
  }, [targetKeys]);
  const handleOnKeyUp = React.useCallback((e) => {
    if (e.key === 'F1') {
      e.preventDefault();
    }
    const isFound = targetKeys.some(targetKey => targetKey === e.key);
    if (isFound) {
      setIsKeyPressed(false);
    }
  }, [targetKeys]);
  React.useEffect(() => {
    window.addEventListener('keydown', handleOnKeyDown);
    window.addEventListener('keyup', handleOnKeyUp);
    return () => {
      window.removeEventListener('keydown', handleOnKeyDown);
      window.removeEventListener('keyup', handleOnKeyUp);
    };
  }, [handleOnKeyDown, handleOnKeyUp]);
  return isKeyPressed;
};
const usePrevious = (value) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
const useProcessedSections = (props) => {
  const { accessibleMenus } = props;
  return React.useMemo(() => {
    const processedSections = [];
    const sortedAccessibleMenus = _.sortBy(accessibleMenus, 'section');
    let previousSection = '0.0.0';
    for (let i = 0; i < sortedAccessibleMenus.length; i += 1) {
      const sortedAccessibleMenu = sortedAccessibleMenus[i];
      const {
        group,
        name,
        path,
        section,
        type,
      } = sortedAccessibleMenu;
      const [major, minor] = section.split('.');
      const [previousMajor, previousMinor] = previousSection.split('.');
      if (previousMajor !== major) {
        processedSections[processedSections.length] = {
          header: group,
          key: _.kebabCase(group) || 'default',
          links: [],
        };
      }
      if (type === 'collapse') {
        const lastProcessedSection = _.last(processedSections);
        if (lastProcessedSection.links.length === 0 || _.last(lastProcessedSection.links).type !== 'collapse') {
          lastProcessedSection.links.push({
            key: 'collapse',
            panels: [],
            type,
          });
        }
        if (previousMinor !== minor || previousSection === '0.0.0') {
          _.last(lastProcessedSection.links).panels.push({
            key: _.kebabCase(group) || 'default',
            links: [],
            name: group,
          });
        }
        _.last(_.last(lastProcessedSection.links).panels).links.push({
          key: _.kebabCase(name) || 'default',
          name,
          path,
        });
      } else {
        _.last(processedSections).links.push({
          key: _.kebabCase(name) || 'default',
          name,
          path,
        });
      }
      previousSection = section;
    }
    return processedSections;
  }, [accessibleMenus]);
};
const useDesktopViewport = (minimumWidth) => {
  const applyViewport = React.useCallback(() => {
    if (utilities.isMobile()) {
      const element = document.querySelector('meta[name="viewport"]');
      const screenWidth = Math.min(window.outerWidth, window.screen.width);
      const ratio = screenWidth / minimumWidth;
      if (screenWidth < minimumWidth) {
        element.setAttribute('content', `initial-scale=${ratio}, maximum-scale=2.0, minimum-scale=${ratio}, user-scalable=yes, width=${minimumWidth}`);
      } else {
        element.setAttribute('content', `initial-scale=1.0, maximum-scale=2.0, minimum-scale=1.0, user-scalable=yes, width=${screenWidth}`);
      }
    }
  }, [minimumWidth]);
  React.useEffect(() => {
    applyViewport();
    window.addEventListener('resize', applyViewport);
    return () => window.removeEventListener('resize', applyViewport);
  }, [applyViewport]);
};
const useQueriedBundle = (props) => {
  const {
    actionType,
    body,
    by,
    overrideRoom,
    condition,
    hasCleanUp = false,
    hasToken = true,
    isLive = true,
    params,
    permissionIdentity,
    query,
    additionalDrawCount=0,
  } = props;
  const documentBundle = useDocumentBundle({
    actionType,
    by,
    permissionIdentity,
  });
  const namespace = `/${_.kebabCase(actionType)}`;
  const [drawCount, setDrawCount] = React.useState(0);
  const [socketId, setSocketId] = React.useState(null);
  const handleSetDrawCount = React.useCallback(() => setDrawCount(previousDrawCount => previousDrawCount + 1), []);
  const debouncedSetDrawCount = React.useMemo(() => _.debounce(handleSetDrawCount, 500, {
    leading: true,
    maxWait: 500,
  }), [handleSetDrawCount]);
  const token = React.useContext(Contexts.Token);
  const compositeIds = [];
  if (params) {
    const keys = Object.keys(params).filter(key => key.endsWith('Id'));
    for (let i = 0; i < keys.length; i += 1) {
      compositeIds.push(params[keys[i]]);
    }
  }
  const room = compositeIds.length > 0 ? compositeIds.sort().join('#') : overrideRoom;
  const namespacedSocket = React.useMemo(() => {
    const input = {
      namespace,
      room,
    };
    if (hasToken && token) {
      input.token = token;
    }
    const socket = socketClient(input);
    socket.on('connect', () => {
      setSocketId(socket.id);
    });
    socket.on('refresh', () => {
      if (isLive) {
        debouncedSetDrawCount();
      }
    });
    socket.on('logout', () => {
      localStorage.removeItem(`${app.SITE_ID}#token`);
      window.location.reload();
    });

    return socket;
  }, [debouncedSetDrawCount, hasToken, isLive, namespace, room, token]);
  React.useEffect(() => () => {
    if (namespacedSocket) {
      namespacedSocket.disconnect();
    }
  }, [namespacedSocket]);
  const {
    cleanUp,
    dispatch,
    isPermitted,
    response,
  } = documentBundle;
  const isQueryReady = [
    !body || Object.values(body).every(Boolean),
    !condition || condition(documentBundle.response.payload),
    !params || Object.values(params).every(Boolean),
    !permissionIdentity || (permissionIdentity && isPermitted),
    !query || Object.values(query).every(Boolean),
    socketId,
  ].every(Boolean);
  const resetInput = {
    by,
    params: {
      ...params,
    },
  };
  useDeepEffect(() => {
    if (hasCleanUp) {
      return () => cleanUp(resetInput);
    }
    return undefined;
  }, [hasCleanUp, resetInput]);
  useDeepEffect(() => {
    const input = {};
    if (body) {
      input.body = { ...body };
    }
    if (params) {
      input.params = { ...params };
    }
    if (query) {
      input.query = {
        ...query,
        socketId,
      };
    } else {
      input.query = { socketId };
    }
    if (isQueryReady) {
      dispatch(input);
    }
    return undefined;
  }, [body, dispatch, drawCount, isQueryReady, params, query, socketId, additionalDrawCount]);
  useFeedback({
    isIgnoringSuccess: true,
    response,
  });
  documentBundle.setDrawCount= setDrawCount;
  documentBundle.socket = namespacedSocket;
  return documentBundle;
};
const useRedirectToBefore = (props) => {
  const { status } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const fromPathname = location.state && (location.state.from || location.pathname);
  React.useEffect(() => {
    if (status === constants.statusSuccess && fromPathname) {
      navigate(fromPathname, { replace: true });
    }
  }, [fromPathname, navigate, status]);
};
const useRedirectToLogin = (props) => {
  const { token } = props;
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!token && location.pathname !== '/login') {
      navigate('/login', {
        replace: true,
        state: {
          from: location.pathname,
        },
      });
    }
  }, [location.pathname, navigate, token]);
};
const useSectionBundle = (props) => {
  const {
    list,
    type,
  } = props;
  return React.useMemo(() => {
    const keyForElements = {};
    const keys = [];
    if (type === 'category' || type === 'category-volume' || type === 'overall-volume' || type.startsWith('report') || type === 'request-volume') {
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        const resolvedNumberManipulator = utilities.getResolvedValue(element, 'numberManipulator');
        const resolvedNumberOfDigits = utilities.getResolvedValue(element, 'numberOfDigits');
        if (resolvedNumberManipulator) {
          if (!keyForElements['-']) {
            keyForElements['-'] = [];
          }
          keyForElements['-'].push({
            index: i,
            ...element,
          });
        } else {
          if (!keyForElements[resolvedNumberOfDigits]) {
            keyForElements[resolvedNumberOfDigits] = [];
          }
          keyForElements[resolvedNumberOfDigits].push({
            index: i,
            ...element,
          });
        }
      }
      keys.push(...Object.keys(keyForElements).sort().reverse());
    } else if (type === 'prize' || type === 'result' || type === 'prize-volume') {
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        const resolvedAlias = utilities.getResolvedValue(element, 'alias');
        const head = _.head(resolvedAlias.split(' '));
        if (!keyForElements[head]) {
          keyForElements[head] = [];
        }
        keyForElements[head].push({
          index: i,
          ...element,
        });
      }
      keys.push(...Object.keys(keyForElements));
    } else if (type === 'payout') {
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        const resolvedGrouping = utilities.getResolvedValue(element, 'grouping');
        if (!keyForElements[resolvedGrouping]) {
          keyForElements[resolvedGrouping] = [];
        }
        keyForElements[resolvedGrouping].push({
          index: i,
          ...element,
        });
      }
      keys.push(...Object.keys(keyForElements));
    }
    return {
      keyForElements,
      keys,
    };
  }, [list, type]);
};
const useSystemTime = () => {
  const timeOffset = Number(localStorage.getItem(`${app.SITE_ID}#time-offset`));
  const [systemTime, setSystemTime] = React.useState(new Date().valueOf());
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timeOffset !== undefined) {
        setSystemTime(new Date().valueOf() + timeOffset);
      }
    }, 250);
    return () => clearInterval(interval);
  }, [timeOffset]);
  return systemTime;
};
export default {
  useAccessibleBundle,
  useActionBundle,
  useDebounce,
  useDocumentBundle,
  useFeedback,
  useIsKeyPressed,
  usePrevious,
  useProcessedSections,
  useQueriedBundle,
  useRedirectToBefore,
  useRedirectToLogin,
  useDesktopViewport,
  useSectionBundle,
  useSystemTime,
};
