/**
 * Internals
 */
import actions from './actions';

/**
 * Private modules
 */
import constants from '../../constants';

/**
 * Constant variables
 */
const handlers = {
  [actions.types.CREATE_ACCOUNT_SESSION_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    return {
      ...state,
      request: {
        ...state.request,
        createAccountSession: {
          ...state.request.createAccountSession,
          status: 'FAIL',
        },
      },
      response: {
        ...state.response,
        createAccountSession: {
          ...state.response.createAccountSession,
          metadata,
        },
      },
    };
  },
  [actions.types.CREATE_ACCOUNT_SESSION_FULFILL]: state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createAccountSession: false,
    },
    request: {
      ...state.request,
      createAccountSession: {
        ...state.request.createAccountSession,
        count: state.request.createAccountSession.count + 1,
        status: 'READY',
      },
    },
  }),
  [actions.types.CREATE_ACCOUNT_SESSION_REQUEST]: state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      createAccountSession: true,
    },
    request: {
      ...state.request,
      createAccountSession: {
        ...state.request.createAccountSession,
        status: 'REQUEST',
      },
    },
  }),
  [actions.types.CREATE_ACCOUNT_SESSION_RESET]: state => ({
    ...state,
    request: {
      ...state.request,
      createAccountSession: {
        ...state.request.createAccountSession,
        count: 0,
        status: 'READY',
      },
    },
    response: {
      ...state.response,
      createAccountSession: {
        ...state.response.createAccountSession,
        headers: {},
        metadata: {},
        payload: {},
      },
    },
  }),
  [actions.types.CREATE_ACCOUNT_SESSION_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    return {
      ...state,
      request: {
        ...state.request,
        createAccountSession: {
          ...state.request.createAccountSession,
          status: 'SUCCESS',
        },
      },
      response: {
        ...state.response,
        createAccountSession: {
          ...state.response.createAccountSession,
          headers,
          metadata,
          payload,
        },
      },
    };
  },
};
const initialState = {
  isLoading: {
    createAccountSession: false,
  },
  request: {
    createAccountSession: {
      count: 0,
      status: 'READY',
    },
  },
  response: {
    createAccountSession: {
      metadata: {},
      payload: {
        token: localStorage.getItem(constants.localStorageTokenKey),
      },
    },
  },
};
export default {
  handlers,
  initialState,
};
