/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  border-bottom: ${scale.minor(0.25)}px solid rgba(240, 240, 240, 1);
  cursor: pointer;
  display: flex;
  height: ${scale.major(5)}px;
  justify-content: flex-start;
  width: 100%;
`;
