/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 0;
  margin-right: ${scale.major(2)}px;
  width: ${scale.major(35)}px;
  @media screen and (max-width: ${scale.major(124)}px) {
    margin-bottom: ${scale.major(2)}px;
    margin-right: 0;
    width: 100%;
  }
  > section:nth-child(n + 3) {
    margin-top: ${scale.major(2)}px;
  }
`;
