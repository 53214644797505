/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.img`
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: ImageLogoTransition;
  animation-timing-function: ease;
  height: ${scale.major(5)}px;
  width: ${scale.major(5)}px;
  @keyframes ImageLogoTransition {
    from {
      transform: rotate(0deg) scale(0);
    }
    to {
      transform: rotate(360deg) scale(1);
    }
  }
`;
