/**
 * Internals
 */
import Android from './logo/android.png';
import App from './vector/app.svg';
import Addon from './vector/addon.svg';
import Busy from './vector/busy.svg';
import MobileLanding from './vector/mobile-landing.svg';
import Analytics from './vector/analytics.svg';
import AtomLoader from './vector/atom-loader.svg';
import BallLoader from './vector/ball-loader.svg';
import BoardLoader from './vector/board-loader.svg';
import Calendar from './vector/calendar.svg';
import CalendarLanding from './vector/calendar-landing.svg';
import Canceled from './vector/canceled.svg';
import CardLoader from './vector/card-loader.svg';
import Chrome from './logo/chrome.png';
import Coin from './vector/coin.svg';
import Damacai from './logo/damacai.png';
import GameController from './vector/game-controller.svg';
import Dice from './vector/dice.svg';
import Baht from './vector/baht.svg';
import DiceLoader from './vector/dice-loader.svg';
import BillLoader from './vector/bill-loader.svg';
import DrawResults from './vector/draw-results.svg';
import Edge from './logo/edge.png';
import Empty from './vector/empty.svg';
import Firefox from './logo/firefox.png';
import GovernmentLotteryOffice from './logo/government-lottery-office.png';
import GovernmentSavingsBank from './logo/government-savings-bank.png';
import GrandDragon from './logo/grand-dragon.png';
import IE from './logo/ie.png';
import IOS from './logo/ios.png';
import MacOS from './logo/mac-os.png';
import Magnum from './logo/magnum.png';
import OfficeLanding from './vector/office-landing.svg';
import Opera from './logo/opera.png';
import PageLoader from './vector/page-loader.svg';
import Play from './vector/play.svg';
import Receipts from './vector/receipts.svg';
import RedNumbers from './vector/red-numbers.svg';
import Reports from './vector/reports.svg';
import Sabah from './logo/sabah.png';
import Safari from './logo/safari.png';
import SamsungBrowser from './logo/samsung-browser.png';
import SandakanTurfClub from './logo/sandakan-turf-club.png';
import SarawakCashSweep from './logo/sarawak-cash-sweep.png';
import Settings from './vector/settings.svg';
import Singapore from './logo/singapore.png';
import SportToto from './logo/sport-toto.png';
import Submit from './vector/submit.svg';
import Switch from './vector/switch.svg';
import Windows from './logo/windows.png';
import Hook from './vector/hook.svg';

import Hanoi from './logo/hanoi.png';
import LaoLotteryOfficial from './logo/lao-lottery-official.png';
export default {
  Hook,
  Android,
  LaoLotteryOfficial,
  Hanoi,
  App,
  AtomLoader,
  BallLoader,
  BoardLoader,
  Calendar,
  CalendarLanding,
  Canceled,
  CardLoader,
  Chrome,
  Coin,
  Damacai,
  Dice,
  DiceLoader,
  DrawResults,
  Edge,
  Analytics,
  Empty,
  Firefox,
  GovernmentLotteryOffice,
  GovernmentSavingsBank,
  GrandDragon,
  IE,
  BillLoader,
  IOS,
  MacOS,
  Magnum,
  OfficeLanding,
  Opera,
  PageLoader,
  Play,
  Receipts,
  GameController,
  RedNumbers,
  Reports,
  Sabah,
  Safari,
  Busy,
  SamsungBrowser,
  MobileLanding,
  SandakanTurfClub,
  SarawakCashSweep,
  Settings,
  Singapore,
  SportToto,
  Submit,
  Addon,
  Baht,
  Switch,
  Windows,
};
