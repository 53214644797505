/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'canceled': {
      styles.push('color: rgba(189, 195, 199, 0.5);');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.td`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  height: 100%;
  > div {
    border-radius: 8px;
    margin-right: 0;
    > button {
      :not(:first-child) {
        margin-left: 8px;
      }
    }
  }
  border: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 0.5);
  padding: 4px 8px;
`;
