/**
 * Node modules
 */
import 'antd/dist/antd.css';
import 'nprogress/nprogress.css';
import { createGlobalStyle } from 'styled-components';

/**
 * Internals
 */
import './index.css';

/**
 * Private modules
 */
import { scale } from '../.private';

export default createGlobalStyle`
.specialCardd {
  .ant-card-body {
  //height: 850px;
  }
}
  html {
    min-height: 100%;
  }
  body {
    font-family: 'SFProText', 'Arial', sans-serif;
    --ion-font-family: 'SFProText', 'Arial', sans-serif;
    min-height: inherit;
  }
  div#root {
    height: 0;
    min-height: inherit;
  }
  div#bootstrap-authorized-entry, div#bootstrap-desktop-entry {
    width: 100%;
    height: 0;
    min-height: inherit;
  }
  div#desktop-router {
    width: 100%;
  }
  div.ant-table-content {
    /* stylelint-disable */
    overflow: auto hidden !important;
    /* stylelint-enable */
  }
  section#bootstrap-public-entry {
    width: 100%;
    height: 0;
    min-height: inherit;
  }
  tr > th.ant-table-cell {
    padding: 0;
    > div {
      align-items: center;
      display: flex;
    }
  }
  table > tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td > div > div > div > div > div > div > table {
    > tbody > tr > td.ant-table-cell {
      padding: 8px 16px;
    }
    > thead > tr > th.ant-table-cell {
      padding: 8px 0;
    }
  }
  td.ant-table-cell {
    > div.ant-table-wrapper {
      padding: 0;
      > div > div > div.ant-table.ant-table-small.ant-table-bordered.ant-table-scroll-horizontal {
        margin: 0;
      }
      thead {
        > tr > th {
          background-color: rgba(243, 156, 18, 0.2);
          > div {
            min-height: 0;
          }
        }
      }
    }
  }
  tr.ant-table-expanded-row {
    div.ant-card-body {
      padding: ${scale.major(3)}px;
    }
  }
  ul.ant-menu-inline > li.ant-menu-item {
    align-items: center;
    display: flex;
    height: ${scale.major(7)}px;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .ant-btn-icon-only {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
    > a {
      color: rgba(24, 144, 255, 1);
    }
  }
  .ant-menu-dark .ant-menu-sub {
    background-color: white;
    > li.ant-menu-item {
      margin-bottom: 0;
      :hover {
        margin: 0;
        vertical-align: middle;
        color: rgba(53, 58, 64, 1);
        border: none;
        background-color: whitesmoke;
      }
      > svg {
        :hover {
          color: rgba(53, 58, 64, 1);
        }
        color: rgba(53, 58, 64, 1);
      }
      > a {
        :hover {
          color: rgba(53, 58, 64, 1);
        }
        color: rgba(53, 58, 64, 1);
      }
    }
    > li.ant-menu-item.ant-menu-item-selected {
      background-color: rgba(230, 247, 255, 1);
      > a {
        color: rgba(24, 144, 255, 1);
      }
    }
  }
  .ant-menu.ant-menu-dark {
    background: rgba(53, 58, 64, 1);
  }
  .ant-menu-dark.ant-menu-submenu-popup {
    > ul {
      border-radius: ${scale.minor(0.5)}px;
      padding: ${scale.minor(1)}px 0;
    }
  }
  .ant-tag {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > small {
      margin-left: 8px;
    }
  }
  .ant-row > .ant-tag {
    margin-bottom: ${scale.major(1)}px;
    :not(:first-child) {
      margin-left: ${scale.major(1)}px;
    }
  }
  .ion-game-4d {
    font-weight: bold;
    font-size: ${scale.major(2)}px;
  }
  .ion-game-th {
    font-weight: bold;
    font-size: ${scale.major(2)}px;
  }
  .ion-tab {
    height: ${scale.minor(12)}px;
  }
 .ion-toolbar {
    --min-height: 0;
    display: flex;
    height: ${scale.minor(12)}px;
  }
  .ion-menu-item {
    border-bottom: ${scale.minor(0.25)}px solid rgba(225, 228, 232, 1);
    height: ${scale.major(15)}px;
    display: flex;
    align-items: center;
    .ion-menu-item-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: ${scale.major(15)}px;
    }
    .ion-menu-item-avatar {
      height: ${scale.major(6)}px;
      width: ${scale.major(6)}px;
    }
  }
  .ion-menu-button-4d::part(native) {
    background-color: rgba(252, 244, 225, 0.7);
    .item-inner {
      padding: 0;
    }
  }
  .ion-menu-button-th::part(native) {
    background-color: rgba(228, 228, 246, 0.7);
    .item-inner {
      padding-right: 0;
    }
  }
  .ion-menu-button-4d, .ion-menu-button-th, .ion-menu-button-la, .ion-menu-button-nvn {
    align-items: center;
    border-bottom: ${scale.minor(0.25)}px solid rgba(225, 228, 232, 1);
    display: flex;
    height: ${scale.major(10)}px;
    margin-top: -8px;
    .ion-menu-button-label {
      align-items: center;
      display: flex;
      height: ${scale.major(10)}px;
      justify-content: center;
      margin: 0;
      padding: 0;
      > span {
        margin-left: ${scale.major(2)}px;
      }
    }
    .ion-menu-button-avatar {
      height: ${scale.major(6)}px;
      width: ${scale.major(6)}px;
    }
  }
  @media only screen and (max-width: 633px) {
    .ant-steps-navigation > .ant-steps-item::after {
      display: none;
    }
    .ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
      display: none;
    }
  }
  .ant-steps-horizontal.ant-steps-navigation:not(.ant-steps-label-vertical) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: -${scale.major(2)}px;
    padding-top: ${scale.minor(3)}px;
    > * {
      margin-top: ${scale.major(2)}px;
    }
    > .ant-steps-item {
      align-items: center;
      display: flex;
      max-width: ${scale.major(32)}px;
      min-width: ${scale.major(32)}px;
      :first-child {
        padding-left: ${scale.major(2)}px;
      }
      > div {
        margin-left: 0;
        padding-left: ${scale.major(2)}px;
        padding-right: ${scale.major(2)}px;
      }
    }
  }
  .PrivateDatePickerToolbar-penIcon {
    display: none;
  }
`;
