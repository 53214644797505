/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntMenu from '../../Vendor/Ant/Menu';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { type } = props;
  const styles = [];
  switch (type) {
    case 'header': {
      styles.push('display: flex;');
      styles.push('flex-direction: row;');
      styles.push(`height: ${scale.major(8)}px;`);
      break;
    }
    case 'sidebar': {
      styles.push('display: flex;');
      styles.push('flex-direction: column;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled(VendorAntMenu)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  background-color: rgba(53, 58, 64, 1);
  border: none;
  color: rgba(236, 240, 241, 1);
  flex-basis: 100%;
  line-height: ${scale.major(8)}px;
  min-width: 0;
  width: 100%;
  .ant-menu-inline .ant-menu-item {
    margin-bottom: 0;
  }
`;
