/**
 * Betlytical modules
 */
import { routes } from '@betlytical/api-schema';

export default [
  ...routes.customerDealSchedules,
  ...routes.customerDealerEatLimits,
  ...routes.customerDealerFights,
  ...routes.customerDealerProperties,
  ...routes.customerDealerTankLimits,
  ...routes.customerDownlines,
  ...routes.customerPartners,
  ...routes.customerProperties,
  ...routes.customerRequestLimits,
  ...routes.customerRewardLimits,
  ...routes.customerRunnerContractDiscounts,
  ...routes.customerRunnerContractPayouts,
  ...routes.customerRunnerContracts,
  ...routes.customerRunnerProperties,
  ...routes.customerRunnerTicketAddonEntries,
  ...routes.customerRunnerTicketAddons,
  ...routes.customerRunnerTicketConversions,
  ...routes.customerRunnerTicketHookEntries,
  ...routes.customerRunnerTicketHooks,
  ...routes.customerRunnerTicketSchemeEntries,
  ...routes.customerRunnerTicketSchemes,
  ...routes.customerSystemGameTypes,
  ...routes.customerSystemTicketNumberPlugins,
  ...routes.customers,
];
