/**
 * Node modules
 */
import styled from 'styled-components';

const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'column': {
      styles.push(`
        && {
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          > span {
            width: auto;
          }
        }
      `);
      styles.push(`
        > div:not(:first-child) {
          margin-top: 4px;
          justify-content: flex-start;
          > img {
            margin-left: 0;
            border-radius: 50%;
            :not(:first-child) {
              margin-left: 8px;
            }
          }
        }
      `);
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  width: 100%;
  > span > .ant-tag {
    margin-right: 8px;
  }
  > .ant-tag:not(:first-child) {
    margin-left: 8px;
  }
  > img {
    margin-left: 8px;
    border-radius: 50%;
  }
`;
