/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    majorScaledHeight,
    majorScaledWidth,
    minorScaledHeight,
    minorScaledWidth,
    mode,
  } = props;
  const styles = [];
  if (majorScaledHeight !== undefined) {
    styles.push(`height: ${scale.major(majorScaledHeight)}px;`);
  } else if (minorScaledHeight !== undefined) {
    styles.push(`height: ${scale.minor(minorScaledHeight)}px;`);
  }
  if (majorScaledWidth !== undefined) {
    styles.push(`width: ${scale.major(majorScaledWidth)}px;`);
  } else if (minorScaledWidth !== undefined) {
    styles.push(`width: ${scale.minor(minorScaledWidth)}px;`);
  }
  if (mode === 'faded') {
    styles.push('opacity: 0.1;');
  }
  return styles.join('');
};
export default styled.img`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
