/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'share': {
      styles.push(`height: ${scale.major(7)}px;`);
      break;
    }
    default: {
      styles.push(`height: ${scale.major(12)}px;`);
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-left: ${scale.major(2)}px;
  width: 100%;
  :hover {
    background-color: rgba(242, 242, 242, 1);
  }
`;
