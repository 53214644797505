/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitPacket = (props) => {
  const {
    mode,
    content,
    isInput,
    onClick: handleOnClick,
    recommendedContent,
    referenceContent,
    state,
    secondaryContent,
    title,
    windowContent,
  } = props;
  return (
    <Styled.DivisionPacket>
      <Styled.DivisionPacketTitle
        mode={isInput ? 'input' : mode}
        onClick={isInput ? handleOnClick : _.noop}
        state={state}
      >
        {title}
      </Styled.DivisionPacketTitle>
      {recommendedContent && (
        <Styled.DivisionPacketContent mode="recommended">
          {recommendedContent}
        </Styled.DivisionPacketContent>
      )}
      {referenceContent && (
        <Styled.DivisionPacketContent mode="reference">
          {referenceContent}
        </Styled.DivisionPacketContent>
      )}
      {content && (
        <Styled.DivisionPacketContent mode={isInput ? 'input' : 'default'}>
          {content}
        </Styled.DivisionPacketContent>
      )}
      {secondaryContent && (
        <Styled.DivisionPacketContent mode={isInput ? 'input' : 'default'}>
          {secondaryContent}
        </Styled.DivisionPacketContent>
      )}
      {windowContent && (
        <Styled.DivisionPacketWindowContent>
          {windowContent}
        </Styled.DivisionPacketWindowContent>
      )}
    </Styled.DivisionPacket>
  );
};
BitPacket.defaultProps = {
  content: null,
  isInput: false,
  onClick: _.noop,
  recommendedContent: null,
  referenceContent: null,
  state: 'default',
  mode: 'default',
  windowContent: null,
};
BitPacket.displayName = 'BitPacket';
BitPacket.propTypes = {
  content: PropTypes.node,
  isInput: PropTypes.bool,
  onClick: PropTypes.func,
  recommendedContent: PropTypes.node,
  referenceContent: PropTypes.node,
  state: PropTypes.string,
  title: PropTypes.string.isRequired,
  windowContent: PropTypes.node,
};
export default React.memo(BitPacket);
