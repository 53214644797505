/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import { VariableSizeGrid } from 'react-window';
import _ from 'lodash';
import numeral from 'numeral';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitBoard from '../Board';
import BitMask from '../Mask';
import BitPacket from '../Packet';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitPacketBoards = (props) => {
  const {
    entries,
    handleShowRecipes,
    systemGameCategoryAlias,
    systemGameCategoryCode,
    systemGameCategoryId,
    systemGamePrizeNumberOfDigits,
    totalValue,
    totalAmount,
    type,
  } = props;
  const searchInputRef = React.useRef(null);
  const [currentSearchValue, setCurrentSearchValue] = React.useState('');
  const handleSearchChange = React.useCallback((text) => {
    if (!text) {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
      setCurrentSearchValue('');
    } else {
      setCurrentSearchValue(text);
    }
  }, []);
  const debouncedSearch = _.debounce(handleSearchChange, 500);
  const debouncedHandleSearchChange = React.useCallback(e => debouncedSearch(e.target.value), [debouncedSearch]);
  const handleSearchClear = React.useCallback(() => handleSearchChange(''), [handleSearchChange]);
  const filteredEntries = React.useMemo(() => {
    const list = [];
    if (currentSearchValue) {
      for (let i = 0; i < entries.length; i += 1) {
        if (entries[i].number.replace(/\D/g, '').includes(currentSearchValue)) {
          list.push({
            rank: i + 1,
            ...entries[i],
          });
        }
      }
      return list;
    }
    return entries;
  }, [currentSearchValue, entries]);
  const translate = useTranslate();
  return React.useMemo(() => {
    const windowContent = (
      <Styled.DivisionWindow>
        <Styled.DivisionWindowHeader>
          <Styled.DivisionWindowHeaderLabel mode="rank">
            {translate('title.rank')}
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="number">
            {translate('title.number')}
          </Styled.DivisionWindowHeaderLabel>
          {type.includes('value') && (
            <Styled.DivisionWindowHeaderLabel mode="value">
              <Styled.DivisionAmount>
                <Styled.Span>
                  {translate('title.value')}
                </Styled.Span>
                <Styled.Image
                  minorScaledHeight={5}
                  minorScaledWidth={5}
                  src={images.Coin}
                />
              </Styled.DivisionAmount>
            </Styled.DivisionWindowHeaderLabel>
          )}
          {type.includes('amount') && (
            <Styled.DivisionWindowHeaderLabel mode="amount">
              <Styled.DivisionAmount>
                <Styled.Span>
                  {translate('title.amount')}
                </Styled.Span>
                <Styled.Image
                  minorScaledHeight={5}
                  minorScaledWidth={5}
                  src={images.Baht}
                />
              </Styled.DivisionAmount>
            </Styled.DivisionWindowHeaderLabel>
          )}
        </Styled.DivisionWindowHeader>
        <VariableSizeGrid
          columnCount={3}
          columnWidth={columnIndex => [80, 100, 132][columnIndex]}
          height={400}
          rowCount={filteredEntries.length}
          rowHeight={() => 40}
          width={335}
        >
          {(window) => {
            const {
              columnIndex,
              rowIndex,
              style,
            } = window;
            const {
              amount,
              number,
              rank,
              value,
            } = filteredEntries[rowIndex];
            const bundle = {
              ...filteredEntries[rowIndex],
              systemGameCategoryAlias,
              systemGameCategoryCode,
              systemGameCategoryId,
              systemGamePrizeNumberOfDigits,
            };
            return (
              <Styled.DivisionWindowRow
                type={type}
                mode={(rowIndex + 1) % 2 === 0 ? 'even' : 'odd'}
                style={style}
              >
                {columnIndex === 0 && (
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {rank || rowIndex + 1}
                    </Styled.Span>
                  </Styled.DivisionColumn>
                )}
                {columnIndex === 1 && (
                  <Styled.DivisionTicketNumber
                    mode="link"
                    onClick={() => handleShowRecipes(bundle)}
                  >
                    {number.replace(/\D/g, '')}
                  </Styled.DivisionTicketNumber>
                )}
                {columnIndex === 2 && type.includes('amount') && (
                  <Styled.Division>
                    <Styled.SpanRightAlign>
                      {utilities.toLocaleString(amount, 2)}
                    </Styled.SpanRightAlign>
                  </Styled.Division>
                )}
                {columnIndex === 2 && type.includes('value') && (
                  <Styled.Division>
                    <Styled.SpanRightAlign>
                      {utilities.toLocaleString(value, 2)}
                    </Styled.SpanRightAlign>
                  </Styled.Division>
                )}
              </Styled.DivisionWindowRow>
            );
          }}
        </VariableSizeGrid>
        <Styled.DivisionWindowFooter mode="tanked">
          <Styled.Span>
            {`${translate('label.entries')}: ${entries.length.toLocaleString()}`}
          </Styled.Span>
          {type.includes('value') && (
            <Styled.Span>
              {utilities.toLocaleString(totalValue, 2)}
            </Styled.Span>
          )}
          {type.includes('amount') && (
            <Styled.Span>
              {utilities.toLocaleString(totalAmount, 2)}
            </Styled.Span>
          )}
        </Styled.DivisionWindowFooter>
      </Styled.DivisionWindow>
    );
    const searchClear = currentSearchValue !== undefined && (
      <Styled.DivisionSearchClear onClick={handleSearchClear}>
        <Styled.Icon.IonicClose />
      </Styled.DivisionSearchClear>
    );
    const title = (
      <Styled.DivisionWindowTitle>
        <Styled.Division>
          {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
        </Styled.Division>
        <Styled.DivisionSearch>
          <Styled.DivisionTableHandlerIcon>
            <Styled.Icon.IonicSearch />
          </Styled.DivisionTableHandlerIcon>
          <Styled.InputSearch
            onChange={debouncedHandleSearchChange}
            placeholder={`${translate('label.placeholder.search')}...`}
            ref={searchInputRef}
          />
          {searchClear}
        </Styled.DivisionSearch>
      </Styled.DivisionWindowTitle>
    );
    return (
      <Styled.DivisionChain>
        <BitPacket
          mode="window"
          title={title}
          windowContent={windowContent}
        />
      </Styled.DivisionChain>
    );
  }, [currentSearchValue, debouncedHandleSearchChange, entries.length, filteredEntries, handleSearchClear, handleShowRecipes, systemGameCategoryAlias, systemGameCategoryCode, systemGameCategoryId, systemGamePrizeNumberOfDigits, translate, type]);
};
BitPacketBoards.displayName = 'BitPacketBoards';
BitPacketBoards.propTypes = {
  contentKey: PropTypes.string,
  dataType: PropTypes.string,
  handleShowRecipes: PropTypes.func,
  isInput: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  maskOptions: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  path: PropTypes.string,
  possibleLinkKeySet: PropTypes.instanceOf(Set),
  recommendedContentKey: PropTypes.string,
  referenceContentKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  symbol: PropTypes.node,
  type: PropTypes.string,
  unit: PropTypes.node,
};
export default React.memo(BitPacketBoards);
