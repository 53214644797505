/**
 * Node modules
 */
import {
  all,
  fork,
} from 'redux-saga/effects';

/**
 * Private modules
 */
import { saga } from '../.private';

export default function* rootSaga() {
  yield all([
    fork(saga),
  ]);
}
