/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  border-bottom: white;
  color: white;
  display: flex;
  font-size: ${scale.major(2)}px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: ${scale.minor(1)}px;
  padding-bottom: ${scale.major(2)}px;
  text-transform: uppercase;
  width: 100%;
`;
