/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: ${scale.major(2)}px;
`;
