/**
 * Node modules
 */
import { Link } from '@reach/router';
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-right: ${scale.major(2)}px;
`;
