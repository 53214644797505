/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: ${scale.minor(3.5)}px;
  font-weight: bold;
  margin-bottom: ${scale.major(1)}px;
`;
