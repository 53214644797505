/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  padding: ${scale.major(1)}px ${scale.major(2)}px;
  width: 100%;
  > span:first-child {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: center;
    margin-bottom: ${scale.major(1)}px;
    width: 100%;
  }
  > div {
    :not(:nth-child(2)) {
      margin-top: ${scale.major(3)}px;
    }
    > div {
      align-items: center;
      display: flex;
      width: 100%;
      > div {
        justify-content: flex-end;
      }
    }
  }
`;
