/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Bit from '../../Bit';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { hooks } from '../../../../helpers';

/**
 * Functional components
 */
const TemplateEmbedded = (props) => {
  const {
    accessibleMenus,
    children,
    customerRelationshipsAncestorBundle,
    markers,
    path,
    profile,
    selectedKey,
  } = props;
  const processedSections = hooks.useProcessedSections({ accessibleMenus });
  return (
    <React.Fragment>
      <Bit.LayoutTitle
        customerRelationshipsAncestorBundle={customerRelationshipsAncestorBundle}
        markers={markers}
        path={path}
      />
      <Styled.DivisionLayout>
        <Styled.DivisionSideMenu>
          {profile && (
            <Styled.SectionSideMenu>
              {profile}
            </Styled.SectionSideMenu>
          )}
          <Bit.SideMenu
            processedSections={processedSections}
            selectedKey={selectedKey}
          />
        </Styled.DivisionSideMenu>
        <Styled.DivisionRightBody>
          {children}
        </Styled.DivisionRightBody>
      </Styled.DivisionLayout>
    </React.Fragment>
  );
};
TemplateEmbedded.defaultProps = {
  customerRelationshipsAncestorBundle: undefined,
  profile: null,
};
TemplateEmbedded.displayName = 'TemplateEmbedded';
TemplateEmbedded.propTypes = {
  accessibleMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
  customerRelationshipsAncestorBundle: PropTypes.objectOf(PropTypes.any),
  markers: PropTypes.arrayOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
  profile: PropTypes.node,
  selectedKey: PropTypes.string.isRequired,
};
export default React.memo(TemplateEmbedded);
