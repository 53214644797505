/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.span`
  color: white;
  font-size: 50%;
  font-weight: bold;
  position: relative;
  top: -0.5em;
`;
