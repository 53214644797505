/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
