/**
 * Node modules
 */
import { Provider } from 'react-redux';
import React from 'react';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';

/**
 * Internals
 */
import EntryBootstrap from './containers/Bootstrap/Entry';
import * as Template from './components/Template';

/**
 * Redux
 */
import { store } from '../redux';
import * as Styled from './components/Styled';
import { images } from '../assets';
import styled from 'styled-components';
import { scale } from './components/Styled/.private';

/**
 * Functional components
 */

const Temp = styled.div`
  align-items: center;
  background-color: rgba(44, 44, 44, 1);
  display: flex;

  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-left: ${scale.minor(5)}px;
  padding-right: ${scale.minor(5)}px;

  > div {
    width: 100%;
    > span {
      position: absolute;
      color: white;
      font-weight: bold;
      top: 50%;
      left: 50%;
      font-size: 30px;
      transform: translate(-50%, -50%);
    }

    position: relative;
  }

  > img {
    z-index: 1;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      setTimeout(() => {
         window.location.reload(false);
      }, 2000);
      return (
        <Temp>
          <div>
            <span>
              เซิฟเวอร์กำลังยุ่ง โปรดรอสักครู่
            </span>
          </div>
        </Temp>
      );
    }

    return this.props.children;
  }
}

const Application = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ErrorBoundary>
          <Template.Root>
            <EntryBootstrap />
          </Template.Root>
        </ErrorBoundary>
      </StyledEngineProvider>
    </Provider>
  )
};
Application.displayName = 'Application';
export default React.memo(Application);
