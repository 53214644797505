/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'recommended': {
      styles.push(`padding: ${scale.major(2)}px;`);
      styles.push('background-color: rgba(46, 204, 113, 0.1);');
      break;
    }
    case 'reference': {
      styles.push(`padding: ${scale.major(2)}px;`);
      styles.push('background-color: rgba(52, 152, 219, 0.1);');
      break;
    }
    case 'input': {
      styles.push(`padding: ${scale.major(2)}px;`);
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  border-bottom: ${scale.minor(0.25)}px solid rgba(240, 240, 240, 1);
  border-left: ${scale.minor(0.25)}px solid rgba(240, 240, 240, 1);
  border-right: ${scale.minor(0.25)}px solid rgba(240, 240, 240, 1);
  display: flex;
  height: ${scale.major(5)}px;
  justify-content: center;
  width: 100%;
  && > img {
    margin-right: 0;
  }
`;
