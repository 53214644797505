/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.span`
  width: 100%;
  text-align: right;
  padding-right: 8px;
`;
