/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  height: ${scale.major(8)}px;
  width: 100%;
  button {
    align-items: center;
    border-radius: 0;
    border-top: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 1);
    border-bottom: none;
    display: flex;
    flex: auto;
    height: 100%;
    justify-content: center;
    :not(:first-child) {
      border-left: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 1);
    }
  }
`;
