/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  ::after {
    content: ':';
    margin-right: ${scale.minor(1)}px;
  }
`;
