/**
 * Node modules
 */
import Input from 'antd/lib/input';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Internals
 */
import TextArea from './TextArea';

/**
 * Functional components
 */
const VendorAntInput = (props) => {
  const {
    hasClear,
    isDisabled,
    ...rest
  } = props;
  return (
    <Input
      allowClear={hasClear}
      disabled={isDisabled}
      {...rest}
    />
  );
};
VendorAntInput.TextArea = TextArea;
VendorAntInput.defaultProps = {
  hasClear: false,
  isDisabled: false,
};
VendorAntInput.displayName = 'VendorAntInput';
VendorAntInput.propTypes = {
  hasClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
export default VendorAntInput;
