/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  //span {
  //  color: #34495e;
  //}
  position: fixed;
  width: 512px;
  z-index: -1000;
`;
