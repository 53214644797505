/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntMenu from '../../Vendor/Ant/Menu';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { type } = props;
  const styles = [];
  switch (type) {
    case 'header': {
      styles.push(`
        &&.ant-menu-item:hover {
          color: white;
          vertical-align: bottom;
        }
      `);
      break;
    }
    default: {
      styles.push(`
        &&.ant-menu-item {
          border-left: ${scale.minor(0.5)}px solid rgba(53, 58, 64, 1);
          :hover {
            color: white;
            border-left: ${scale.minor(0.5)}px solid rgba(24, 144, 255, 1);
          }
        }
        &&.ant-menu-item.ant-menu-item-selected {
          border-left: ${scale.minor(0.5)}px solid rgba(16, 112, 202, 1);
        }
      `);
      break;
    }
  }
  return styles.join('');
};
export default styled(VendorAntMenu.Item)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  && {
    align-items: center;
    border-bottom: none;
    display: flex;
    margin-top: 0;
    top: 0;
    vertical-align: middle;
  }
  && a {
    color: white;
  }
  &&.ant-menu-item-selected {
    background-color: rgba(16, 112, 202, 1);
    border-bottom: none;
    color: white;
    a:hover {
      color: white;
    }
    :hover {
      border-bottom: none;
      margin-top: 0;
      vertical-align: middle;
    }
  }
  > svg  {
    margin-right: ${scale.major(1)}px;
  }
`;
