/**
 * Node modules
 */
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

/**
 * Clients
 */
import clients from '../../../../clients';

/**
 * Internals
 */
import actions from './actions';

/**
 * Functions
 */
const requestGetDealReceipt = function* (action) {
  const { data } = action;
  const by = data.params.dealId;
  try {
    yield put(actions.creators.getDealReceiptRequest({ key: by }));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-get-deal-receipt'], data);
    yield put(actions.creators.getDealReceiptSuccess({
      key: by,
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.getDealReceiptFail({
      key: by,
      metadata: error.metadata,
    }));
  } finally {
    yield put(actions.creators.getDealReceiptFulfill({ key: by }));
  }
};
const requestPrintDealReceipt = function* (action) {
  const { data } = action;
  const by = data.params.dealId;
  try {
    yield put(actions.creators.printDealReceiptRequest({ key: by }));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-print-deal-receipt'], data);
    yield put(actions.creators.printDealReceiptSuccess({
      key: by,
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.printDealReceiptFail({
      key: by,
      metadata: error.metadata,
    }));
  } finally {
    yield put(actions.creators.printDealReceiptFulfill({ key: by }));
  }
};
const requestShareDealReceipt = function* (action) {
  const { data } = action;
  const by = data.params.dealId;
  try {
    yield put(actions.creators.shareDealReceiptRequest({ key: by }));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-share-deal-receipt'], data);
    yield put(actions.creators.shareDealReceiptSuccess({
      key: by,
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.shareDealReceiptFail({
      key: by,
      metadata: error.metadata,
    }));
  } finally {
    yield put(actions.creators.shareDealReceiptFulfill({ key: by }));
  }
};
const watchRequestGetDealReceipt = function* () {
  yield takeEvery(actions.types.GET_DEAL_RECEIPT_TRIGGER, requestGetDealReceipt);
};
const watchRequestPrintDealReceipt = function* () {
  yield takeEvery(actions.types.PRINT_DEAL_RECEIPT_TRIGGER, requestPrintDealReceipt);
};
const watchRequestShareDealReceipt = function* () {
  yield takeEvery(actions.types.SHARE_DEAL_RECEIPT_TRIGGER, requestShareDealReceipt);
};
export default {
  watchRequestGetDealReceipt,
  watchRequestPrintDealReceipt,
  watchRequestShareDealReceipt,
};
