/**
 * Node modules
 */
import Collapse from 'antd/lib/collapse';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntCollapsePanel = (props) => {
  const {
    hasArrow,
    ...rest
  } = props;
  return (
    <Collapse.Panel
      showArrow={hasArrow}
      {...rest}
    />
  );
};
VendorAntCollapsePanel.defaultProps = {
  hasArrow: true,
  isDisabled: false,
};
VendorAntCollapsePanel.displayName = 'VendorAntCollapsePanel';
VendorAntCollapsePanel.propTypes = {
  hasArrow: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
export default VendorAntCollapsePanel;
