/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */

export default styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(56, 67, 81, 1.0);
  :nth-child(1) {
    border-bottom: 1px solid rgba(34, 47, 62,1.0);
  }
  > button > div:not(:first-child) {
    margin-top: 0;
  }
  > button {
    :active {
      background-color: rgba(56, 67, 81, 1.0);
    }
    :disabled img {
      opacity: 1;
    }
    align-items: center;
    background-color: rgba(56, 67, 81, 1.0);
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0;
    padding: 0;
    img {
      height: 28px;
      margin: 0;
      width: 28px;
    }
    > div {
      height: 40px;
      margin: 0;
      :nth-child(1) {
        background-color: rgba(34, 47, 62,1.0);
        color: white;
        flex-shrink: 0;
        width: 64px;
      }
      :nth-child(2) {
        > img {
          border-radius: 4px;
        }
        > img:not(:first-child) {
          margin-left: 16px;
        }
        span {
          margin-left: 16px;
        }
        .ant-spin {
          margin-left: -32px;
        }
        align-items: center;
        color: white;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        letter-spacing: 16px;
        width: 100%;
      }
   }
  }
`;
