/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: ${scale.minor(5)}px;
  justify-content: center;
  padding-bottom: ${scale.major(1)}px;
  padding-top: ${scale.major(1)}px;
  > span:nth-child(2) {
    margin-top: ${scale.minor(1)}px;
  }
`;
