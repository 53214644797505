/**
 * Node modules
 */
import _ from 'lodash';
import { createSelector } from 'reselect';

/**
 * Functions
 */
const getDealReceiptResponseSelector = createSelector(
  state => state.reducers.response.getDealReceipt,
  object => _.memoize((by) => {
    const response = {
      headers: {},
      metadata: {},
      payload: {},
    };
    if (by !== undefined && object[by]) {
      response.headers = object[by].headers || {};
      response.metadata = object[by].metadata || {};
      response.payload = object[by].payload || {};
    }
    return response;
  }),
);
const getDealReceiptRequestSelector = createSelector(
  state => state.reducers.request.getDealReceipt,
  object => _.memoize((by) => {
    const request = {
      count: 0,
      status: 'READY',
    };
    if (by !== undefined && object[by]) {
      request.count = object[by].count || 0;
      request.status = object[by].status || 'READY';
    }
    return request;
  }),
);
const isGetDealReceiptLoadingSelector = createSelector(
  state => state.reducers.isLoading.getDealReceipt,
  object => _.memoize(by => (by !== undefined && object[by]) || false),
);
const isPrintDealReceiptLoadingSelector = createSelector(
  state => state.reducers.isLoading.printDealReceipt,
  object => _.memoize(by => (by !== undefined && object[by]) || false),
);
const isShareDealReceiptLoadingSelector = createSelector(
  state => state.reducers.isLoading.shareDealReceipt,
  object => _.memoize(by => (by !== undefined && object[by]) || false),
);
const printDealReceiptResponseSelector = createSelector(
  state => state.reducers.response.printDealReceipt,
  object => _.memoize((by) => {
    const response = {
      headers: {},
      metadata: {},
      payload: {},
    };
    if (by !== undefined && object[by]) {
      response.headers = object[by].headers || {};
      response.metadata = object[by].metadata || {};
      response.payload = object[by].payload || {};
    }
    return response;
  }),
);
const printDealReceiptRequestSelector = createSelector(
  state => state.reducers.request.printDealReceipt,
  object => _.memoize((by) => {
    const request = {
      count: 0,
      status: 'READY',
    };
    if (by !== undefined && object[by]) {
      request.count = object[by].count || 0;
      request.status = object[by].status || 'READY';
    }
    return request;
  }),
);
const shareDealReceiptResponseSelector = createSelector(
  state => state.reducers.response.shareDealReceipt,
  object => _.memoize((by) => {
    const response = {
      headers: {},
      metadata: {},
      payload: {},
    };
    if (by !== undefined && object[by]) {
      response.headers = object[by].headers || {};
      response.metadata = object[by].metadata || {};
      response.payload = object[by].payload || {};
    }
    return response;
  }),
);
const shareDealReceiptRequestSelector = createSelector(
  state => state.reducers.request.shareDealReceipt,
  object => _.memoize((by) => {
    const request = {
      count: 0,
      status: 'READY',
    };
    if (by !== undefined && object[by]) {
      request.count = object[by].count || 0;
      request.status = object[by].status || 'READY';
    }
    return request;
  }),
);
export default {
  getDealReceiptRequestSelector,
  getDealReceiptResponseSelector,
  isGetDealReceiptLoadingSelector,
  isPrintDealReceiptLoadingSelector,
  isShareDealReceiptLoadingSelector,
  printDealReceiptRequestSelector,
  printDealReceiptResponseSelector,
  shareDealReceiptRequestSelector,
  shareDealReceiptResponseSelector,
};
