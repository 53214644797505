/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.thead`
  font-weight: 600;
`;
