/**
 * Node modules
 */
import React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

/**
 * Redux
 */
import {
  actions,
  selectors,
} from '../../../../redux';

/**
 * Constant variables
 */
const { currentAccountIdentityForPermissionSelector } = selectors;
export default (props) => {
  const {
    actionType,
    by,
    permissionIdentity,
  } = props;
  const dispatch = useDispatch();
  const resetAction = _.camelCase(`${actionType}_RESET`);
  const triggerAction = _.camelCase(`${actionType}_TRIGGER`);
  const dispatchTriggerAction = React.useCallback(data => dispatch(actions.creators[triggerAction](data)), [dispatch, triggerAction]);
  const dispatchResetAction = React.useCallback(data => dispatch(actions.creators[resetAction](data)), [dispatch, resetAction]);
  const isLoadingSelectorKey = _.camelCase(`IS_${actionType}_LOADING_SELECTOR`);
  const requestSelectorKey = _.camelCase(`${actionType}_REQUEST_SELECTOR`);
  const responseSelectorKey = _.camelCase(`${actionType}_RESPONSE_SELECTOR`);
  const isLoading = useSelector(state => (by ? selectors[isLoadingSelectorKey](state)(by) : selectors[isLoadingSelectorKey](state)));
  const request = useSelector(state => (by ? selectors[requestSelectorKey](state)(by) : selectors[requestSelectorKey](state)));
  const response = useSelector(state => (by ? selectors[responseSelectorKey](state)(by) : selectors[responseSelectorKey](state)));
  const bundle = React.useMemo(() => ({
    cleanUp: dispatchResetAction,
    dispatch: dispatchTriggerAction,
    isDocumentLoading: isLoading,
    isPermitted: true,
    request,
    response,
  }), [dispatchResetAction, dispatchTriggerAction, isLoading, request, response]);
  const currentAccountIdentityForPermission = useSelector(state => currentAccountIdentityForPermissionSelector(state));
  if (permissionIdentity) {
    bundle.isPermitted = currentAccountIdentityForPermission[permissionIdentity] !== undefined;
  }
  return bundle;
};
