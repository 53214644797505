/**
 * Node modules
 */
import _ from 'lodash';

/**
 * Configurations
 */
import { app } from '../../config';

/**
 * Private modules
 */
import { request } from '../.private';

/**
 * Routes
 */
import * as routes from '../../routes';

/**
 * Constant variables
 */
const keyForClient = {};

/**
 * Functions
 */
const entries = Object.entries(routes);
for (let i = 0; i < entries.length; i += 1) {
  const [name, flattenedRoutes] = entries[i];
  for (let j = 0; j < flattenedRoutes.length; j += 1) {
    const {
      action,
      method,
      path,
    } = flattenedRoutes[j];
    const key = `request-${_.kebabCase(action)}`;
    keyForClient[key] = input => request({
      ...input,
      method,
      url: `${app.MAIN_API_PROTOCOL}://${app.MAIN_API_HOST}:${app.MAIN_API_PORT}/api/${name}`.concat(path),
    });
  }
}
export default keyForClient;
