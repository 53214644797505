/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  min-height: ${scale.major(7)}px;
  padding-left: ${scale.major(2)}px;
  width: 100%;
`;
