/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';


export default styled.div`
  > span {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 ${scale.major(2)}px;
    :nth-child(1) {
      width: 100px;
    }
    :nth-child(2) {
      width: 132px;
    }
  }
  justify-content: center;
  align-items: center;
  border-top: solid ${scale.minor(0.25)}px rgba(242, 242, 242, 1);
  display: flex;
  font-weight: bold;
  height: ${scale.minor(9)}px;
  width: 100%;
  
`;
