/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const TemplateRoot = (props) => {
  const { children } = props;
  return (
    <React.Fragment>
      <Styled.Global />
      {children}
    </React.Fragment>
  );
};
TemplateRoot.displayName = 'TemplateRoot';
TemplateRoot.propTypes = { children: PropTypes.node.isRequired };
export default React.memo(TemplateRoot);
