/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'icon': {
      break;
    }
    case 'banner': {
      styles.push('background-color: white;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(52, 73, 94,1.0);`);
      break;
    }
    default: {
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(240, 240, 240, 1);`);
      styles.push(`border-radius: ${scale.major(1)}px;`);
      styles.push(`padding: ${scale.major(1)}px;`);
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: ${scale.major(2)}px;
  img {
    border-radius: ${scale.major(1)}px;
    margin: ${scale.major(1)}px;
  }
  > small {
    height: 16px;
    :nth-child(3){
      margin-bottom: ${scale.minor(1)}px;
    }
    justify-content: center;
    padding: 0 ${scale.major(1)}px;
  }
`;
