/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  svg {
    height: ${scale.minor(5)}px;
    margin-right: ${scale.minor(1)}px;
    width: ${scale.minor(5)}px;
  }
  > span {
    :last-child {
      margin-left: auto;
    }
    :nth-child(3)::before {
      content: '|';
      margin-left: ${scale.minor(1)}px;
      margin-right: ${scale.minor(1)}px;
    }
  }
`;
