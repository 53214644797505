/**
 * Internals
 */
import actions from './actions';

/**
 * Constant variables
 */
const handlers = {
  [actions.types.PUSH_NOTIFICATION]: (state, action) => {
    const { data } = action;
    state.notificationSet.add(data);
    return {
      ...state,
    };
  },
  [actions.types.REMOVE_NOTIFICATION]: (state, action) => {
    const { data } = action;
    state.notificationSet.delete(data);
    return {
      ...state,
    };
  },
};
const initialState = {
  notificationSet: new Set(),
};
export default {
  handlers,
  initialState,
};
