/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  border-radius: ${scale.major(1)}px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 ${scale.minor(1)}px ${scale.major(2)}px;
  margin-bottom: ${scale.major(3)}px;
  margin-left: ${scale.major(2)}px;
  margin-right: ${scale.major(2)}px;
`;
