/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntTag from '../../Vendor/Ant/Tag';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    color,
    mode,
  } = props;
  const styles = [];
  switch (mode) {
    case 'active': {
      if (color === 'green') {
        styles.push(`box-shadow: 0 0 0 ${scale.minor(0.75)}px #b7eb8f;`);
      }
      if (color === 'orange') {
        styles.push(`box-shadow: 0 0 0 ${scale.minor(0.75)}px #ffd591;`);
      }
      break;
    }
    case 'disabled': {
      styles.push('opacity: 0.85;');
      styles.push('background-color: #ecf0f1;');
      styles.push('border-color: white;');
      styles.push('color: #34495e;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled(VendorAntTag)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
