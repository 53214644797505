/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.input`
  background-color: inherit;
  border: none;
  height: 100%;
  width: 100%;
  :focus {
    border: none;
    outline: none;
  }
`;
