/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  border-radius: ${scale.major(1)}px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 ${scale.minor(1)}px ${scale.major(2)}px;
  flex-direction: column;
  font-size: ${scale.minor(4.25)}px;
  margin: ${scale.major(3)}px ${scale.major(2)}px 0 ${scale.major(2)}px;
`;
