/**
 * Functions
 */
const createActionCreator = type => data => ({
  data,
  type,
});
const handleActions = (handlers, initialState) => (state = initialState, action) => (handlers[action.type] ? handlers[action.type](state, { data: action.data }) : state);
export default {
  createActionCreator,
  handleActions,
};
