/**
 * Node modules
 */
import _ from 'lodash';

/**
 * Internals
 */
import accountActions from './account/actions';
import accountPermissionActions from './account-permission/actions';
import accountPermissionReducers from './account-permission/reducers';
import accountPermissionSagaWatchers from './account-permission/saga';
import accountPermissionSelectors from './account-permission/selectors';
import accountReducers from './account/reducers';
import accountSagaWatchers from './account/saga';
import accountSelectors from './account/selectors';
import accountSessionActions from './account-session/actions';
import accountSessionReducers from './account-session/reducers';
import accountSessionSagaWatchers from './account-session/saga';
import accountSessionSelectors from './account-session/selectors';
import appActions from './app/actions';
import appReducers from './app/reducers';
import appSelectors from './app/selectors';
import dealActions from './deal/actions';
import dealReducers from './deal/reducers';
import dealSelectors from './deal/selectors';
import dealSagaWatchers from './deal/saga';
import drawSystemGameTypeActions from './draw-system-game-types/actions';
import drawSystemGameTypeReducers from './draw-system-game-types/reducers';
import drawSystemGameTypeSelectors from './draw-system-game-types/selectors';
import drawSystemGameTypeSagaWatchers from './draw-system-game-types/saga';

/**
 * Constant variables
 */
const creators = {
  ...accountActions.creators,
  ...accountPermissionActions.creators,
  ...accountSessionActions.creators,
  ...appActions.creators,
  ...dealActions.creators,
  ...drawSystemGameTypeActions.creators,
};
const handlers = {
  ...accountPermissionReducers.handlers,
  ...accountReducers.handlers,
  ...accountSessionReducers.handlers,
  ...appReducers.handlers,
  ...dealReducers.handlers,
  ...drawSystemGameTypeReducers.handlers,
};
const sagaWatchers = {
  ...accountPermissionSagaWatchers,
  ...accountSagaWatchers,
  ...accountSessionSagaWatchers,
  ...dealSagaWatchers,
  ...drawSystemGameTypeSagaWatchers,
};
const selectors = {
  ...accountPermissionSelectors,
  ...accountSelectors,
  ...accountSessionSelectors,
  ...appSelectors,
  ...dealSelectors,
  ...drawSystemGameTypeSelectors,
};
const types = {
  ...accountActions.types,
  ...accountPermissionActions.types,
  ...accountSessionActions.types,
  ...appActions.types,
  ...dealActions.types,
  ...drawSystemGameTypeActions.types,
};

/**
 * Functions
 */
const initialState = _.merge(
  {},
  { ...accountPermissionReducers.initialState },
  { ...accountReducers.initialState },
  { ...accountSessionReducers.initialState },
  { ...appReducers.initialState },
  { ...dealReducers.initialState },
  { ...drawSystemGameTypeReducers.initialState },
);
export default {
  actions: {
    creators,
    types,
  },
  reducers: {
    handlers,
    initialState,
  },
  sagaWatchers,
  selectors,
};
