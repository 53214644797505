/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  font-size: 80%;
  font-weight: bold;
  position: relative;
  top: -0.3em;
`;
