/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'antd/lib/tooltip';

/**
 * Functional components
 */
const VendorAntTooltip = (props) => {
  const {
    hasOverflowAdjustment,
    isArrowCentered,
    isVisibleByDefault,
    ...rest
  } = props;
  return (
    <Tooltip
      arrowPointAtCenter={isArrowCentered}
      autoAdjustOverflow={hasOverflowAdjustment}
      defaultVisible={isVisibleByDefault}
      {...rest}
    />
  );
};
VendorAntTooltip.defaultProps = {
  hasOverflowAdjustment: true,
  isArrowCentered: false,
  isVisibleByDefault: false,
};
VendorAntTooltip.displayName = 'VendorAntTooltip';
VendorAntTooltip.propTypes = {
  hasOverflowAdjustment: PropTypes.bool,
  isArrowCentered: PropTypes.bool,
  isVisibleByDefault: PropTypes.bool,
};
export default VendorAntTooltip;
