/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  margin-top: 8px;
  > img {
    border-radius: 25%;
  }
  > img:not(:first-child) {
    margin-left: 8px;
  }
`;
