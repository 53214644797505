/**
 * Betlytical modules
 */
import { routes } from '@betlytical/api-schema';

export default [
  ...routes.accountActivities,
  ...routes.accountPermissions,
  ...routes.accountPrinters,
  ...routes.accountSessions,
  ...routes.accountWorkers,
  ...routes.accounts,
];
