/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
`;
