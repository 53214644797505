/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { filled } = props;
  const styles = [];
  if (filled > 0) {
    styles.push(`
      :after {
        content: '';
        position: absolute;
        background: rgba(255, 118, 117, 0.2);
        top: 0;
        bottom: 0;
        left: 0; 
        width: ${Math.min(filled, 1) * 100}%;
      }
    `);
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  font-style: italic;
  height: 100%;
  white-space: pre;
  width: 80%;
  justify-content: center;
  font-size: 10px;
  position: relative;
  border: 1px solid #dfe6e9;
  margin-top: -2px;
  border-radius: 4px;
`;
