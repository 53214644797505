/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  div {
    flex-wrap: nowrap;
  }
  > div > div > div:first-child {
    display: none;
  }
`;
