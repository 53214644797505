/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import BitHapticButton from '../HapticButton';
import * as Styled from '../../Styled';

/**
 * Private functions
 */
const isTextValid = (playCode) => {
  const {
    index,
    isNoteFocused,
    lines,
  } = playCode;
  const { text } = lines[index];
  const [number, ...amounts] = text.split('#');
  if (text.endsWith('**')) {
    return true;
  }
  if (index > 0) {
    const previousLine = lines[index - 1];
    const { text: previousText } = previousLine;
    const [previousNumber] = previousText.split('#');
    if (number.length === previousNumber.length && lines[index].schemeId === previousLine.schemeId && amounts.length === 1 && amounts[0] === '') {
      return true;
    }
  }
  // eslint-disable-next-line prefer-named-capture-group
  return text.match(/^(?:(?:\*[0-9]+)(?:#[0-9]+)+)$|^(?:(?:[0-9]+)(?:#(:?[0-9]+)?\*?[0-9]+)+)$/);
};
const shouldAsteriskDisable = (text, focusedSelectedCustomerRunnerTicketScheme) => {
  const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
  const [number, ...amounts] = text.split('#');
  const isWholeSequence = text.endsWith('**');
  const isNumberBoxed = number.startsWith('*');
  const numberOfDigits = number.replace(/\*/g, '').length;
  let targetedEntry = null;
  for (let i = 0; i < entries.length && !targetedEntry; i += 1) {
    const {
      systemGameCategoryNumberManipulator,
      systemGameCategoryNumberOfDigits,
    } = entries[i];
    if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && (numberOfDigits >= systemGameCategoryNumberOfDigits)) {
      targetedEntry = entries[i];
    }
  }
  if (amounts.length === 0) {
    if (number || isNumberBoxed) {
      return true;
    }
  }
  if (isWholeSequence) {
    return true;
  }
  if (!isNumberBoxed) {
    if (targetedEntry) {
      const { systemGameCategoryNumberManipulator } = targetedEntry;
      if (text.endsWith('#') && systemGameCategoryNumberManipulator !== null) {
        return true;
      }
      if (amounts.length > 1 && systemGameCategoryNumberManipulator !== null) {
        return true;
      }
    }
    if (amounts.length > 0 && amounts[amounts.length - 1].startsWith('*')) {
      return true;
    }
    if (amounts.length === 1 && amounts[amounts.length - 1].includes('*') && !amounts[amounts.length - 1].endsWith('*')) {
      return true;
    }
    if (amounts.length > 1 && amounts[amounts.length - 1].includes('*')) {
      return true;
    }
  }
  if (isNumberBoxed) {
    if (text.endsWith('#')) {
      return true;
    }
    if (amounts.length > 1 && amounts[amounts.length - 1]) {
      return true;
    }
  }
  return false;
};
const shouldHashtagDisable = (text, focusedSelectedCustomerRunnerTicketScheme) => {
  const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
  const [number, ...amounts] = text.split('#');
  const numberOfDigits = number.replace(/\*/g, '').length;
  const isWholeSequence = text.endsWith('**');
  const isNumberBoxed = number.startsWith('*');
  let validCount = 0;
  for (let i = 0; i < entries.length; i += 1) {
    const {
      systemGameCategoryNumberOfDigits,
      systemGameCategoryNumberManipulator,
    } = entries[i];
    if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits) {
      validCount += 1;
    }
  }
  if (!number || isWholeSequence) {
    return true;
  }
  if (validCount === 0) {
    return true;
  }
  if (validCount === amounts.length) {
    return true;
  }
  return text.endsWith('*') && !isWholeSequence;
};
const shouldNumberDisable = (text, focusedSelectedCustomerRunnerTicketScheme) => {
  const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
  const [number, ...amounts] = text.split('#');
  const numberOfDigits = number.replace(/\*/g, '').length;
  const isNumberBoxed = number.startsWith('*');
  let targetedEntry = null;
  for (let i = 0; i < entries.length && !targetedEntry; i += 1) {
    const {
      systemGameCategoryNumberManipulator,
      systemGameCategoryNumberOfDigits,
    } = entries[i];
    if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && (numberOfDigits >= systemGameCategoryNumberOfDigits)) {
      targetedEntry = entries[i];
    }
  }
  if (!number) {
    return false;
  }
  if (number) {
    if (number.replace(/\*/g, '').length >= 5 && !text.includes('#')) {
      return true;
    }
    if (number.startsWith('*') && text.endsWith('*') && number !== text) {
      return true;
    }
    if (text.endsWith('**')) {
      return true;
    }
    if (amounts.length === 1 && targetedEntry && targetedEntry.systemGameCategoryNumberManipulator !== null && text.endsWith('*')) {
      return true;
    }
  }
  return false;
};

/**
 * Functional components
 */
const BitKeypad = (props) => {
  const {
    focusedSelectedCustomerRunnerTicketScheme,
    playCode,
    setPlayCode,
    ss,
    systemGame,
    keypadHeight,
    isNoteFocused,
    buttonSpacing,
  } = props;
  const {
    index,
    lines,
  } = playCode;
  const { text } = lines[index];
  return (
    <Styled.DivisionKeypad code={systemGame.code}
                           mode={isNoteFocused ? 'hidden' : 'default'}
                           keypadHeight={keypadHeight}
                           buttonSpacing={buttonSpacing}
    >
      <Styled.DivisionKeypadRow>
        <BitHapticButton
          inputKeys={['ArrowUp']}
          isDisabled={index === 0 || _.isEmpty(focusedSelectedCustomerRunnerTicketScheme)}
          mode="up"
          setPlayCode={setPlayCode}
        />
        <BitHapticButton
          mode="fn"
        >
          {/*<Styled.Small>*/}
          {/*  FN*/}
          {/*</Styled.Small>*/}
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['Backspace']}
          isDisabled={!text || _.isEmpty(focusedSelectedCustomerRunnerTicketScheme)}
          mode="backspace"
          setPlayCode={setPlayCode}
        />
        <BitHapticButton
          focusedSelectedCustomerRunnerTicketScheme={focusedSelectedCustomerRunnerTicketScheme}
          inputKeys={['ArrowDown', 'Enter']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || !isTextValid(playCode)}
          mode="down"
          setPlayCode={setPlayCode}
        />
      </Styled.DivisionKeypadRow>
      <Styled.DivisionKeypadRow>
        <BitHapticButton
          inputKeys={['1']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          1
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['2']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          2
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['3']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          3
        </BitHapticButton>
      </Styled.DivisionKeypadRow>
      <Styled.DivisionKeypadRow>
        <BitHapticButton
          inputKeys={['4']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          4
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['5']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          5
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['6']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          6
        </BitHapticButton>
      </Styled.DivisionKeypadRow>
      <Styled.DivisionKeypadRow>
        <BitHapticButton
          inputKeys={['7']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          7
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['8']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          8
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['9']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          9
        </BitHapticButton>
      </Styled.DivisionKeypadRow>
      <Styled.DivisionKeypadRow>
        <BitHapticButton
          inputKeys={['*']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldAsteriskDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          *
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['0']}
          isDisabled={_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) || shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          0
        </BitHapticButton>
        <BitHapticButton
          inputKeys={['#', '-']}
          isDisabled={shouldHashtagDisable(text, focusedSelectedCustomerRunnerTicketScheme)}
          mode="input"
          setPlayCode={setPlayCode}
        >
          #
        </BitHapticButton>
      </Styled.DivisionKeypadRow>
    </Styled.DivisionKeypad>
  );
};
BitKeypad.defaultProps = {
  focusedSelectedCustomerRunnerTicketScheme: {},
};
BitKeypad.displayName = 'BitKeypad';
BitKeypad.propTypes = {
  focusedSelectedCustomerRunnerTicketScheme: PropTypes.objectOf(PropTypes.any),
  playCode: PropTypes.objectOf(PropTypes.any).isRequired,
  setPlayCode: PropTypes.func.isRequired,
  systemGame: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default React.memo(BitKeypad);
