/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'negative': {
      styles.push('color: rgba(231, 76, 60, 1);');
      styles.push(`
        ::before {
          content: '-';
          margin-right: ${scale.minor(1)}px;
        }
      `);
      break;
    }
    case 'positive': {
      styles.push('color: rgba(39, 174, 96, 1);');
      styles.push(`
        ::before {
          content: '+';
          margin-right: ${scale.minor(1)}px;
        }
      `);
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
