/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  > div:first-child {
    margin-top: ${scale.major(2)}px;
  }
`;
