/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitOverlayLoader = (props) => {
  const {
    isLoading,
    message,
  } = props;
  if (!isLoading) {
    return null;
  }
  return (
    <Styled.DivisionBackdrop>
      <Styled.Division name="division">
        <Styled.Image
          minorScaledHeight={7}
          minorScaledWidth={7}
          src={images.BallLoader}
        />
        <Styled.Span>
          {message}
        </Styled.Span>
      </Styled.Division>
    </Styled.DivisionBackdrop>
  );
};
BitOverlayLoader.displayName = 'BitOverlayLoader';
BitOverlayLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};
export default React.memo(BitOverlayLoader);
