/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.small`
  font-style: italic;
  margin-bottom: -0.5em;
`;
