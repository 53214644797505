/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  div#router {
    width: 100%;
    > div:not(:last-child) {
      margin-bottom: ${scale.major(4)}px;
      width: 100%;
    }
    .ant-tabs-tabpane {
      > div:not(:last-child) {
        margin-bottom: ${scale.major(4)}px;
        width: 100%;
      }
    }
  }
`;
