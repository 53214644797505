/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
  padding: 24px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
