/**
 * Node modules
 */
import Menu from 'antd/lib/menu';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntMenuSub = (props) => {
  const {
    isDisabled,
    ...rest
  } = props;
  return (
    <Menu.SubMenu
      disabled={isDisabled}
      {...rest}
    />
  );
};
VendorAntMenuSub.defaultProps = { isDisabled: false };
VendorAntMenuSub.displayName = 'VendorAntMenuSub';
VendorAntMenuSub.propTypes = { isDisabled: PropTypes.bool };
export default VendorAntMenuSub;
