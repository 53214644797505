/**
 * Node modules
 */
import { IonActionSheet } from '@ionic/react';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitSelectDrawSystemGameType = (props) => {
  const {
    drawSystemGameTypes,
    selectedDrawSystemGameType,
    setSelectedDrawSystemGameType,
  } = props;
  const translate = useTranslate();
  const [isActionSheetOpen, setIsActionSheetOpen] = React.useState(false);
  const handleDismiss = React.useCallback(() => setIsActionSheetOpen(false), [setIsActionSheetOpen]);
  const handleClick = React.useCallback(() => setIsActionSheetOpen(true), [setIsActionSheetOpen]);
  const buttons = React.useMemo(() => {
    const components = [];
    for (let i = 0; i < drawSystemGameTypes.length; i += 1) {
      const {
        alias,
        code,
        objectId,
        systemGameTypeId,
      } = drawSystemGameTypes[i];
      components.push({
        handler: () => setSelectedDrawSystemGameType({
          alias,
          code,
          objectId,
          systemGameTypeId,
        }),
        text: translate(`display.alias.${alias}`),
      });
    }
    return components;
  }, [drawSystemGameTypes, setSelectedDrawSystemGameType, translate]);
  React.useEffect(() => {
    if (drawSystemGameTypes.length === 1) {
      const {
        alias,
        code,
        objectId,
        systemGameTypeId,
      } = drawSystemGameTypes[0];
      setSelectedDrawSystemGameType({
        alias,
        code,
        objectId,
        systemGameTypeId,
      });
    }
    if (drawSystemGameTypes.length > 1) {
      setIsActionSheetOpen(true);
    }
  }, [drawSystemGameTypes, setSelectedDrawSystemGameType]);
  return (
    <React.Fragment>
      <IonActionSheet
        backdropDismiss={false}
        buttons={buttons}
        isOpen={isActionSheetOpen}
        onDidDismiss={handleDismiss}
      />
      <Styled.ButtonSelection
        disabled={drawSystemGameTypes.length === 0}
        onClick={drawSystemGameTypes.length > 1 ? handleClick : _.noop}
      >
        <Styled.Division name="division">
          <Styled.Span>
            {_.isEmpty(selectedDrawSystemGameType) && (
              <Styled.Image
                minorScaledHeight={7}
                minorScaledWidth={7}
                src={images.Dice}
              />
            )}
            {!_.isEmpty(selectedDrawSystemGameType) && (
              <Styled.Image
                minorScaledHeight={7}
                minorScaledWidth={7}
                src={images[selectedDrawSystemGameType.alias.replace(/\s/g, '')]}
              />
            )}
          </Styled.Span>
        </Styled.Division>
      </Styled.ButtonSelection>
    </React.Fragment>
  );
};
BitSelectDrawSystemGameType.displayName = 'BitSelectDrawSystemGameType';
BitSelectDrawSystemGameType.propTypes = {
  drawSystemGameTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedDrawSystemGameType: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedDrawSystemGameType: PropTypes.func.isRequired,
};
export default React.memo(BitSelectDrawSystemGameType);
