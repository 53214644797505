/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntTable from '../../Vendor/Ant/Table';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntTable)`
  padding: ${scale.minor(3)}px 0;
`;
