/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'text': {
      styles.push(`
        > button {
          border-radius: 0;
          :not(:first-child) {
            margin-left: ${scale.major(1)}px;
          }
        }
      `);
      break;
    }
    default: {
      styles.push(`
        > button {
          border-radius: 50%;
          width: ${scale.major(5)}px;
        }
      `);
      break;
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  > button {
    :active {
      box-shadow: none;
      outline: 0;
    }
    :focus, :hover {
      border: ${scale.minor(0.25)}px solid rgba(64, 169, 255, 1);
    }
    align-items: center;
    background-color: white;
    border: ${scale.minor(0.25)}px solid rgba(217, 217, 217, 1);
    box-shadow: none;
    cursor: pointer;
    display: flex;
    height: ${scale.major(5)}px;
    justify-content: center;
    outline: 0;
  }
  .ant-dropdown-menu-item {
    [ant-click-animating-without-extra-node]::after {
      animation: 0s;
    }
    > button {
      box-shadow: unset;
      background: 0;
      border: 0;
      text-align: left;
      width: 100%;
      :focus {
        outline: none;
      }
      :hover {
        color: inherit;
        background: 0;
      }
    }
  }
  .ant-spin-spinning {
    display: flex;
  }
`;
