/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  color: white;
  font-size: ${scale.major(2)}px;
  font-weight: bold;
  letter-spacing: ${scale.minor(1)}px;
  text-transform: uppercase;
`;
