/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntMenu from '../../Vendor/Ant/Menu';

/**
 * Styled components
 */
export default styled(VendorAntMenu.Sub)`
  &&.ant-menu-submenu-active {
    color: white;
  }
  &&.ant-menu-submenu-selected {
    background-color: rgba(24, 144, 255, 1);
  }
  .ant-menu-submenu-title:hover {
    color: white;
  }
`;
