/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${scale.major(2)}px;
  padding: 0 ${scale.minor(5)}px;
  > section:nth-child(2) {
    border-top: 1px solid #bdc3c7;
  }
`;
