/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${scale.major(1)}px;
  min-width: ${scale.major(10)}px;
  :not(:first-child) {
    margin-left: -${scale.minor(0.25)}px;
  }
`;
