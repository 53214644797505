/**
 * Private modules
 */
import helpers from '../../helpers';

/**
 * Constant variables
 */
const types = {
  GET_CURRENT_ACCOUNT_PERMISSIONS_FAIL: 'GET_CURRENT_ACCOUNT_PERMISSIONS_FAIL',
  GET_CURRENT_ACCOUNT_PERMISSIONS_FULFILL: 'GET_CURRENT_ACCOUNT_PERMISSIONS_FULFILL',
  GET_CURRENT_ACCOUNT_PERMISSIONS_REQUEST: 'GET_CURRENT_ACCOUNT_PERMISSIONS_REQUEST',
  GET_CURRENT_ACCOUNT_PERMISSIONS_RESET: 'GET_CURRENT_ACCOUNT_PERMISSIONS_RESET',
  GET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS: 'GET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS',
  GET_CURRENT_ACCOUNT_PERMISSIONS_TRIGGER: 'GET_CURRENT_ACCOUNT_PERMISSIONS_TRIGGER',
};
const creators = {
  getCurrentAccountPermissionsFail: helpers.createActionCreator(types.GET_CURRENT_ACCOUNT_PERMISSIONS_FAIL),
  getCurrentAccountPermissionsFulfill: helpers.createActionCreator(types.GET_CURRENT_ACCOUNT_PERMISSIONS_FULFILL),
  getCurrentAccountPermissionsRequest: helpers.createActionCreator(types.GET_CURRENT_ACCOUNT_PERMISSIONS_REQUEST),
  getCurrentAccountPermissionsReset: helpers.createActionCreator(types.GET_CURRENT_ACCOUNT_PERMISSIONS_RESET),
  getCurrentAccountPermissionsSuccess: helpers.createActionCreator(types.GET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS),
  getCurrentAccountPermissionsTrigger: helpers.createActionCreator(types.GET_CURRENT_ACCOUNT_PERMISSIONS_TRIGGER),
};
export default {
  creators,
  types,
};
