/**
 * Node modules
 */
import React from 'react';
import _ from 'lodash';

/**
 * Function hooks
 */
const useDeepCompareMemoize = (value) => {
  const ref = React.useRef();
  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};
// eslint-disable-next-line react-hooks/exhaustive-deps
export default (callback, dependencies) => React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
