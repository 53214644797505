/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import IconIonicCloudDownloadOutline from '../../Icon/Ionic/CloudDownloadOutline';
import IconIonicCloseCircle from '../../Icon/Ionic/CloseCircle';
import IconIonicAnalytics from '../../Icon/Ionic/Analytics';
import IconIonicAdd from '../../Icon/Ionic/Add';
import IconIonicStar from '../../Icon/Ionic/Star';
import IconIonicAlertCircle from '../../Icon/Ionic/AlertCircle';
import IconIonicArchive from '../../Icon/Ionic/Archive';
import IconIonicArrowDown from '../../Icon/Ionic/ArrowDown';
import IconIonicArrowForward from '../../Icon/Ionic/ArrowForward';
import IconIonicArrowUndo from '../../Icon/Ionic/ArrowUndo';
import IconIonicArrowUp from '../../Icon/Ionic/ArrowUp';
import IconIonicBackspace from '../../Icon/Ionic/Backspace';
import IconIonicCalendar from '../../Icon/Ionic/Calendar';
import IconIonicCaretBack from '../../Icon/Ionic/CaretBack';
import IconIonicCaretDown from '../../Icon/Ionic/CaretDown';
import IconIonicCaretForward from '../../Icon/Ionic/CaretForward';
import IconIonicCaretUp from '../../Icon/Ionic/CaretUp';
import IconIonicCheckmarkCircle from '../../Icon/Ionic/CheckmarkCircle';
import IconIonicClose from '../../Icon/Ionic/Close';
import IconIonicCloseCircleOutline from '../../Icon/Ionic/CloseCircleOutline';
import IconIonicCog from '../../Icon/Ionic/Cog';
import IconIonicColorWand from '../../Icon/Ionic/ColorWand';
import IconIonicCopy from '../../Icon/Ionic/Copy';
import IconIonicDice from '../../Icon/Ionic/Dice';
import IconIonicEarth from '../../Icon/Ionic/Earth';
import IconIonicExpand from '../../Icon/Ionic/Expand';
import IconIonicEye from '../../Icon/Ionic/Eye';
import IconIonicFunnel from '../../Icon/Ionic/Funnel';
import IconIonicGameController from '../../Icon/Ionic/GameController';
import IconIonicHappy from '../../Icon/Ionic/Happy';
import IconIonicHome from '../../Icon/Ionic/Home';
import IconIonicLink from '../../Icon/Ionic/Link';
import IconIonicLockClosed from '../../Icon/Ionic/LockClosed';
import IconIonicLogOut from '../../Icon/Ionic/LogOut';
import IconIonicMenu from '../../Icon/Ionic/Menu';
import IconIonicPencil from '../../Icon/Ionic/Pencil';
import IconIonicPeople from '../../Icon/Ionic/People';
import IconIonicPhonePortrait from '../../Icon/Ionic/PhonePortrait';
import IconIonicPrintOutline from '../../Icon/Ionic/PrintOutline';
import IconIonicReaderOutline from '../../Icon/Ionic/ReaderOutline';
import IconIonicReturnDownForward from '../../Icon/Ionic/ReturnDownForward';
import IconIonicSad from '../../Icon/Ionic/Sad';
import IconIonicSave from '../../Icon/Ionic/Save';
import IconIonicSearch from '../../Icon/Ionic/Search';
import IconIonicServer from '../../Icon/Ionic/Server';
import IconIonicShareSocialOutline from '../../Icon/Ionic/ShareSocialOutline';
import IconIonicSquare from '../../Icon/Ionic/Square';
import IconIonicSwapHorizontal from '../../Icon/Ionic/SwapHorizontal';
import IconIonicSwapVertical from '../../Icon/Ionic/SwapVertical';
import IconIonicTrash from '../../Icon/Ionic/Trash';
import IconIonicEyeOff from '../../Icon/Ionic/EyeOff';

/**
 * Private modules
 */
import { scale } from '../.private';
import CloudDownloadOutline from '../../Icon/Ionic/CloudDownloadOutline';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    mode,
    placeholder,
    size,
  } = props;
  const styles = [];
  if (size !== undefined) {
    styles.push(`height: ${scale.minor(size)}px;`);
    styles.push(`width: ${scale.major(size)}px;`);
  } else {
    styles.push(`height: ${scale.major(2)}px;`);
    styles.push(`width: ${scale.major(2)}px;`);
  }
  if (placeholder) {
    switch (placeholder) {
      case '1': {
        styles.push('color: rgba(231, 76, 60, 1);');
        break;
      }
      case '2': {
        styles.push('color: rgba(243, 156, 18, 1);');
        break;
      }
      case '3': {
        styles.push('color: rgba(39, 174, 96, 1);');
        break;
      }
      case '4': {
        styles.push('color: rgba(41, 128, 185, 1);');
        break;
      }
      case '5': {
        styles.push('color: rgba(142, 68, 173, 1);');
        break;
      }
      case '6': {
        styles.push('color: rgba(52, 73, 94, 1);');
        break;
      }
      default: {
        styles.push('color: inherit;');
        break;
      }
    }
  }
  if (mode) {
    switch (mode) {
      case 'selected': {
        styles.push('color: rgba(24, 144, 255, 1);');
        break;
      }
      default: {
        styles.push('color: rgba(191, 191, 191, 1);');
        break;
      }
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
const IonicAdd = styled(IconIonicAdd)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicAlertCircle = styled(IconIonicAlertCircle)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicArchive = styled(IconIonicArchive)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicEyeOff = styled(IconIonicEyeOff)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;

const IonicArrowDown = styled(IconIonicArrowDown)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicArrowForward = styled(IconIonicArrowForward)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicArrowUndo = styled(IconIonicArrowUndo)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicArrowUp = styled(IconIonicArrowUp)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicBackspace = styled(IconIonicBackspace)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCalendar = styled(IconIonicCalendar)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCaretBack = styled(IconIonicCaretBack)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCaretDown = styled(IconIonicCaretDown)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCaretForward = styled(IconIonicCaretForward)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCaretUp = styled(IconIonicCaretUp)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCheckmarkCircle = styled(IconIonicCheckmarkCircle)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicClose = styled(IconIonicClose)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCloseCircleOutline = styled(IconIonicCloseCircleOutline)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCog = styled(IconIonicCog)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicColorWand = styled(IconIonicColorWand)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCopy = styled(IconIonicCopy)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicDice = styled(IconIonicDice)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicEarth = styled(IconIonicEarth)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicExpand = styled(IconIonicExpand)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicEye = styled(IconIonicEye)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicFunnel = styled(IconIonicFunnel)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicGameController = styled(IconIonicGameController)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicHappy = styled(IconIonicHappy)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicHome = styled(IconIonicHome)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicLink = styled(IconIonicLink)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  margin-left: ${scale.minor(1)}px;
  margin-right: ${scale.minor(1)}px;
`;
const IonicLockClosed = styled(IconIonicLockClosed)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicLogOut = styled(IconIonicLogOut)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicMenu = styled(IconIonicMenu)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicPencil = styled(IconIonicPencil)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicPeople = styled(IconIonicPeople)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicPhonePortrait = styled(IconIonicPhonePortrait)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicPrintOutline = styled(IconIonicPrintOutline)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicReaderOutline = styled(IconIonicReaderOutline)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicReturnDownForward = styled(IconIonicReturnDownForward)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicSad = styled(IconIonicSad)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicSave = styled(IconIonicSave)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicSearch = styled(IconIonicSearch)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicServer = styled(IconIonicServer)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicShareSocialOutline = styled(IconIonicShareSocialOutline)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicSquare = styled(IconIonicSquare)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicSwapHorizontal = styled(IconIonicSwapHorizontal)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicSwapVertical = styled(IconIonicSwapVertical)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicTrash = styled(IconIonicTrash)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicAnalytics = styled(IconIonicAnalytics)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicStar = styled(IconIonicStar)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCloseCircle = styled(IconIonicCloseCircle)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
const IonicCloudDownloadOutline = styled(IconIonicCloudDownloadOutline)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
export default {
  IonicStar,
  IonicCloudDownloadOutline,
  IonicAdd,
  IonicAnalytics,
  IonicAlertCircle,
  IonicArchive,
  IonicArrowDown,
  IonicArrowForward,
  IonicCloseCircle,
  IonicArrowUndo,
  IonicArrowUp,
  IonicBackspace,
  IonicCalendar,
  IonicCaretBack,
  IonicCaretDown,
  IonicCaretForward,
  IonicCaretUp,
  IonicCheckmarkCircle,
  IonicClose,
  IonicCloseCircleOutline,
  IonicCog,
  IonicColorWand,
  IonicCopy,
  IonicDice,
  IonicEarth,
  IonicExpand,
  IonicEye,
  IonicFunnel,
  IonicGameController,
  IonicHappy,
  IonicHome,
  IonicLink,
  IonicLockClosed,
  IonicLogOut,
  IonicMenu,
  IonicPencil,
  IonicEyeOff,
  IonicPeople,
  IonicPhonePortrait,
  IonicPrintOutline,
  IonicReaderOutline,
  IonicReturnDownForward,
  IonicSad,
  IonicSave,
  IonicSearch,
  IonicServer,
  IonicShareSocialOutline,
  IonicSquare,
  IonicSwapHorizontal,
  IonicSwapVertical,
  IonicTrash,
};
