/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  font-size: ${scale.major(2)}px;
  height: ${scale.major(8)}px;
  justify-content: center;
  padding: 0 ${scale.minor(3)}px;
  white-space: nowrap;
  > span {
    margin-left: ${scale.major(1)}px;
  }
`;
