/**
 * Node modules
 */
import React from 'react';

/**
 * Contexts
 */
const AccountBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const CurrentAccountPermissionsBundle = React.createContext({});
const CustomerBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const CustomerPropertyBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const CustomerRelationshipsAncestorBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const CustomerSystemGameTypesInputBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const DealBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const DeauthorizeAccountActionBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const Device = React.createContext('desktop');
const DrawBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const DrawSystemGameTypesBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const DrawsOpenBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const Handler = React.createContext({});
const History = React.createContext({});
const Locale = React.createContext('th-TH');
const TerminalFontSize = React.createContext('Medium');
const ShowDeepDownlines = React.createContext('No');
const KeypadHeight = React.createContext('Medium');
const LoginBundle = React.createContext({});
const MetadataSet = React.createContext(new Set());
const SelectedCustomer = React.createContext({});
const SetLocale = React.createContext(() => undefined);
const SetTerminalFontSize = React.createContext(() => 'undefined');
const SetShowDeepDownlines = React.createContext(() => 'undefined');
const SetKeypadHeight = React.createContext(() => 'undefined');
const SystemBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const SystemGame = React.createContext({});
const SystemGameCategoriesInputBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const SystemGamePrizesInputBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const SystemGameTypeBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: {},
  },
});
const SystemGameTypesBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const SystemGamesBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const SystemTranslationsGlobalBundle = React.createContext({
  isDocumentLoading: false,
  isFirstBoot: true,
  response: {
    payload: [],
  },
});
const SetIsUsingKeypad = React.createContext(() => undefined);
const IsUsingKeypad = React.createContext('No');
const SetCanTakeNote = React.createContext(() => undefined);
const CanTakeNote = React.createContext('No');
const SetButtonSpacing = React.createContext(() => undefined);
const ButtonSpacing = React.createContext('Small');
const SetInvoiceFormat = React.createContext(() => undefined);
const InvoiceFormat = React.createContext('Identifier');
const SetShareReceiptFormat = React.createContext(() => undefined);
const ShareReceiptFormat = React.createContext('Image');
const Table = React.createContext({});
const Token = React.createContext(null);
export default {
  SetIsUsingKeypad,
  IsUsingKeypad,
  SetCanTakeNote,
  CanTakeNote,
  ButtonSpacing,
  ShowDeepDownlines,
  InvoiceFormat,
  SetInvoiceFormat,
  SetButtonSpacing,
  AccountBundle,
  CurrentAccountPermissionsBundle,
  SetShowDeepDownlines,
  CustomerBundle,
  CustomerPropertyBundle,
  CustomerRelationshipsAncestorBundle,
  CustomerSystemGameTypesInputBundle,
  DealBundle,
  DeauthorizeAccountActionBundle,
  Device,
  DrawBundle,
  DrawSystemGameTypesBundle,
  DrawsOpenBundle,
  Handler,
  History,
  KeypadHeight,
  Locale,
  LoginBundle,
  MetadataSet,
  SelectedCustomer,
  SetKeypadHeight,
  SetLocale,
  SetShareReceiptFormat,
  SetTerminalFontSize,
  ShareReceiptFormat,
  SystemBundle,
  SystemGame,
  SystemGameCategoriesInputBundle,
  SystemGameTypesBundle,
  SystemGamePrizesInputBundle,
  SystemGameTypeBundle,
  SystemGamesBundle,
  SystemTranslationsGlobalBundle,
  Table,
  TerminalFontSize,
  Token,
};
