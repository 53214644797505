/**
 * Node modules
 */
import React from 'react';

export default React.memo(props => (
  <svg
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>
      Swap Vertical
    </title>
    <polyline
      fill="none"
      points="464 208 352 96 240 208"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="352"
      x2="352"
      y1="113.13"
      y2="416"
    />
    <polyline
      fill="none"
      points="48 304 160 416 272 304"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="160"
      x2="160"
      y1="398"
      y2="96"
    />
  </svg>
));
