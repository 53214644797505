/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  background-color: rgba(250, 250, 250, 1);
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  font-size: ${scale.major(2)}px;
  font-weight: bold;
  padding: ${scale.major(2)}px ${scale.major(3)}px;
`;
