/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  font-size: ${scale.major(2)}px;
  font-weight: 800;
  padding: ${scale.major(2)}px ${scale.major(3)}px;
  width: 100%;
  small {
    padding: ${scale.minor(1)}px;
    font-weight: normal;
  }
  > div:nth-child(1) {
    > img {
      margin-left: 0;
    }
    > div {
      justify-content: flex-start;
    }
  }
  > div:nth-child(2) {
    width: auto;
    justify-content: flex-end;
  }
`;
