/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.table`
  :not(:first-child) {
    margin-top: 12px;
  }
  border-collapse: collapse;
  width: 100%;
  > thead > tr {
    > th:nth-child(1) {
      //min-width: 168px;
    }
    > th:nth-child(2) {
      //min-width: 100px;
    }
  }
`;
