/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */

export default styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  
  letter-spacing: ${scale.major(1)}px;
  text-indent: 8px;
`;
