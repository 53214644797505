/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

const getStyles = (props) => {
  const {
    buttonSpacing,
    keypadHeight,
    mode,
  } = props;
  const styles = [];
  if (mode === 'hidden') {
    styles.push('position: absolute;');
    styles.push('top: -10000px;');
  }
  switch (keypadHeight) {
    case 'Extra Large': {
      styles.push('height: 55%;');
      break;
    }
    case 'Large': {
      styles.push('height: 50%;');
      break;
    }
    case 'Small': {
      styles.push('height: 40%;');
      break;
    }
    default: {
      styles.push('height: 45%;');
      break;
    }
  }
  switch (buttonSpacing) {
    case 'Extra Large': {
      styles.push(`padding-left: ${scale.minor(6.0)}px;`);
      styles.push(`padding-right: ${scale.minor(6.0)}px;`);
      styles.push(`
        > div {
          margin-bottom: ${scale.minor(6.0)}px;
          > :not(:first-child) {
            margin-left: ${scale.minor(6.0)}px;
          }
          > button {
            padding: 0;
            border-radius: 16px;
          } 
        }
      `);
      break;
    }
    case 'Large': {
      styles.push(`padding-left: ${scale.minor(4.0)}px;`);
      styles.push(`padding-right: ${scale.minor(4.0)}px;`);
      styles.push(`
        > div {
          margin-bottom: ${scale.minor(4.0)}px;
          > :not(:first-child) {
            margin-left: ${scale.minor(4.0)}px;
          }
          > button {
            padding: 0;
            border-radius: 16px;
          } 
        }
      `);
      break;
    }
    case 'Medium': {
      styles.push(`padding-left: ${scale.minor(2.0)}px;`);
      styles.push(`padding-right: ${scale.minor(2.0)}px;`);
      styles.push(`
        > div {
          margin-bottom: ${scale.minor(2.0)}px;
          > :not(:first-child) {
            margin-left: ${scale.minor(2.0)}px;
          }
          > button {
            padding: 0;
            border-radius: 16px;
          } 
        }
      `);
      break;
    }
    default: {
      styles.push(`
        > div {
          :not(:last-child) {
            margin-bottom: ${scale.minor(0.25)}px;
          }
          > :not(:first-child) {
            margin-left: ${scale.minor(0.25)}px;
          }
        }
      `);
      break;
    }
  }
  return styles.join('');
};

export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: ${scale.minor(5)}px;
  margin-top: auto;
  svg {
    height: ${scale.minor(5)}px;
    width: ${scale.minor(5)}px;
  }
`;
