/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import BitClock from '../Clock';
import * as Styled from '../../Styled';
import VendorAntMenu from '../../Vendor/Ant/Menu';

/**
 * Configurations
 */
import { app } from '../../../../config';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import utilities from '../../../../helpers/utilities';

/**
 * Functional components
 */


const BitLayoutHeader = (props) => {
  const {
    brand,
    deauthorizeAccountActionBundle,
    locale: selectedLocale,
    loginBundle,
    menu,
    mode,
    navigate,
    setLocale,
    systemGame,
    systemGamesBundle,
    token,
  } = props;
  const translate = useTranslate();
  const handleGameChange = React.useCallback((item) => {
    navigate(`/${item.key}`, 200);
  }, [navigate]);
  const handleSelectLanguage = React.useCallback((language) => {
    setLocale(language.key);
    localStorage.setItem(`${app.SITE_ID}#locale`, language.key);
  }, [setLocale]);
  const {
    avatar,
    isAuthorized,
    username,
  } = loginBundle.response.payload;
  const { dispatch: logout } = deauthorizeAccountActionBundle;
  const handleMenuClick = React.useCallback((e) => {
    const { key } = e;
    if (key === 'mobile') {
      if (_.isEmpty(systemGame)) {
        window.open('/mobile');
      } else {
        window.open(`/mobile/${_.kebabCase(systemGame.alias)}`);
      }
    }
    if (key === 'setting') {
      navigate(`/${_.kebabCase(systemGame.alias)}/accounts/${username.toLowerCase()}/overview`);
    }
    if (key === 'logout') {
      const input = {
        body: {
          bearerToken: `Bearer ${token}`,
        },
      };
      logout(input);
    }
  }, [logout, navigate, systemGame, token, username]);
  const locales = [
    {
      icon: '🇺🇸',
      key: 'en-US',
      label: 'English',
    },
    {
      icon: '🇹🇭',
      key: 'th-TH',
      label: 'ไทย',
    },
  ];
  const isLoginLayout = mode === 'login';
  const accountMenuOverlay = !isLoginLayout && (
    <VendorAntMenu onClick={handleMenuClick}>
      {!utilities.isMobile() && (
        <React.Fragment>
          <Styled.VendorAntMenuItemOverlay key="mobile">
            <Styled.Icon.IonicPhonePortrait />
            <Styled.SpanLabel>
              {translate('title.mobile')}
            </Styled.SpanLabel>
          </Styled.VendorAntMenuItemOverlay>
          <VendorAntMenu.Divider />
        </React.Fragment>
      )}
      {!_.isEmpty(systemGame) && (
        <React.Fragment>
          <Styled.VendorAntMenuItemOverlay key="setting">
            <Styled.Icon.IonicCog />
            <Styled.SpanLabel>
              {translate('title.setting')}
            </Styled.SpanLabel>
          </Styled.VendorAntMenuItemOverlay>
          <VendorAntMenu.Divider />
        </React.Fragment>
      )}
      <Styled.VendorAntMenuItemOverlay key="logout">
        <Styled.Icon.IonicLogOut />
        <Styled.SpanLabel>
          {translate('title.logout')}
        </Styled.SpanLabel>
      </Styled.VendorAntMenuItemOverlay>
    </VendorAntMenu>
  );
  const gameMenuOverlay = (
    <VendorAntMenu selectedKeys={_.isEmpty(systemGame) ? [] : [_.kebabCase(systemGame.alias)]}>
      {systemGamesBundle.response.payload.map(selectableSystemGame => (
        <Styled.VendorAntMenuItemOverlay
          key={_.kebabCase(selectableSystemGame.alias)}
          onClick={handleGameChange}
        >
          {selectableSystemGame.code}
        </Styled.VendorAntMenuItemOverlay>
      ))}
    </VendorAntMenu>
  );
  const languageMenuOverlay = (
    <VendorAntMenu
      onClick={handleSelectLanguage}
      selectedKeys={[selectedLocale]}
    >
      {locales.map(locale => (
        <Styled.VendorAntMenuItemOverlay key={locale.key}>
          <Styled.SpanLabel>
            {locale.icon}
          </Styled.SpanLabel>
          <Styled.SpanLabel>
            {locale.label}
          </Styled.SpanLabel>
        </Styled.VendorAntMenuItemOverlay>
      ))}
    </VendorAntMenu>
  );
  return (
    <Styled.DivisionLayoutHeader mode={mode}>
      {brand}
      {menu}
      <Styled.DivisionLayoutHeaderRightContent mode={mode}>
        {!isLoginLayout && (!isAuthorized || loginBundle.isFirstBoot || loginBundle.isLoading) && (<Styled.VendorAntSpinHeader />)}
        {!isLoginLayout && isAuthorized && (
          <Styled.VendorAntDropdownHeader
            menuOverlay={accountMenuOverlay}
            mode={mode}
            placement="bottomRight"
          >
            <Styled.DivisionDropdownHeaderMenu>
              <Styled.Image
                minorScaledHeight={7}
                minorScaledWidth={7}
                src={`data:image/svg+xml;base64,${avatar}`}
              />
              <Styled.SpanLabel>
                {username}
              </Styled.SpanLabel>
            </Styled.DivisionDropdownHeaderMenu>
          </Styled.VendorAntDropdownHeader>
        )}
        {!isLoginLayout && (
          <Styled.VendorAntDropdownHeader
            menuOverlay={gameMenuOverlay}
            mode={mode}
            placement="bottomRight"
          >
            <Styled.DivisionDropdownHeaderMenu>
              <Styled.Icon.IonicGameController />
            </Styled.DivisionDropdownHeaderMenu>
          </Styled.VendorAntDropdownHeader>
        )}
        <Styled.VendorAntDropdownHeader
          menuOverlay={languageMenuOverlay}
          mode={mode}
          placement="bottomRight"
        >
          <Styled.DivisionDropdownHeaderMenu>
            <Styled.Icon.IonicEarth />
          </Styled.DivisionDropdownHeaderMenu>
        </Styled.VendorAntDropdownHeader>
        <BitClock />
      </Styled.DivisionLayoutHeaderRightContent>
    </Styled.DivisionLayoutHeader>
  );
};
BitLayoutHeader.defaultProps = {
  systemGame: {},
};
BitLayoutHeader.displayName = 'BitLayoutHeader';
BitLayoutHeader.propTypes = {
  brand: PropTypes.node.isRequired,
  deauthorizeAccountActionBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  loginBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  menu: PropTypes.node.isRequired,
  mode: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
  systemGame: PropTypes.objectOf(PropTypes.any),
  systemGamesBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  token: PropTypes.string.isRequired,
};
export default React.memo(BitLayoutHeader);
