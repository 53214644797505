/**
 * Node modules
 */
import Input from 'antd/lib/input';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntInputTextArea = (props) => {
  const {
    hasClear,
    isAutoResized,
    isDisabled,
    ...rest
  } = props;
  return (
    <Input.TextArea
      allowClear={hasClear}
      autoSize={isAutoResized}
      disabled={isDisabled}
      {...rest}
    />
  );
};
VendorAntInputTextArea.defaultProps = {
  hasClear: false,
  isAutoResized: false,
  isDisabled: false,
};
VendorAntInputTextArea.displayName = 'VendorAntInputTextArea';
VendorAntInputTextArea.propTypes = {
  hasClear: PropTypes.bool,
  isAutoResized: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
export default VendorAntInputTextArea;
