/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitCard = (props) => {
  const {
    actions,
    children,
    isFirstBoot,
    isLoading,
    logo,
    state,
    title,
    ...rest
  } = props;
  const titles = [];
  titles.push((
    <Styled.SpanCardTitle key="title">
      {title}
    </Styled.SpanCardTitle>
  ));
  titles.push(!_.isEmpty(actions) && !isLoading && (
    <Styled.DivisionCardTitleControlPanel key="actions">
      {actions}
    </Styled.DivisionCardTitleControlPanel>
  ));
  titles.push(isLoading && (
    <Styled.DivisionLoading key="loading">
      <Styled.Image
        minorScaledHeight={7}
        minorScaledWidth={7}
        src={images.CardLoader}
      />
    </Styled.DivisionLoading>
  ));
  const cardTitle = (
    <Styled.DivisionCardTitle>
      {logo && (<Styled.ImageLogo src={images[logo]} />)}
      {titles}
    </Styled.DivisionCardTitle>
  );
  return (
    <Styled.VendorAntCard
      isLoading={isFirstBoot}
      state={state}
      title={title && cardTitle}
      {...rest}
    >
      {children}
    </Styled.VendorAntCard>
  );
};
BitCard.defaultProps = {
  actions: null,
  isFirstBoot: false,
  isLoading: false,
  logo: undefined,
  state: 'default',
};
BitCard.displayName = 'BitCard';
BitCard.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
  isFirstBoot: PropTypes.bool,
  isLoading: PropTypes.bool,
  logo: PropTypes.string,
  state: PropTypes.string,
  title: PropTypes.node.isRequired,
};
export default React.memo(BitCard);
