/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';


const getStyles = (props) => {
  const { color, } = props;
  const styles = [];
  if (color) {
    styles.push(`> input {
      background-color: ${color};
    }`);
  }
  return styles.join('');
};
export default styled.span`
  /* stylelint-disable */
  ${getStyles};
  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */

    text-align: center;
    color: rgba(52, 73, 94,0.5);
    opacity: 1; /* Firefox */
  }
  /* stylelint-enable */
`;
