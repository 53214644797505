/**
 * Helpers
 */
import constants from '../constants';
import _ from 'lodash';

/**
 * Functional components
 */
const generatePathForMarkers = (context) => {
  const pathForMarkers = {};
  const stack = [];
  stack.push({
    markers: [],
    node: context,
    paths: [context.path],
  });
  while (stack.length > 0) {
    const {
      markers,
      node,
      paths,
    } = stack.pop();
    const resolvedPath = paths.join('/');
    const marker = {
      alias: node.alias || null,
      isClickable: node.Component !== undefined || node.LazyComponent !== undefined,
      isValid: true,
      link: resolvedPath,
      names: [node.name],
    };
    markers.push(marker);
    if (node.Component || node.LazyComponent) {
      pathForMarkers[resolvedPath] = markers;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i += 1) {
        const child = node.children[i];
        const clonedPaths = [...paths];
        const pathList = clonedPaths.length === 1 && clonedPaths[0] === '/' ? [] : clonedPaths;
        if (child.path) {
          pathList.push(child.path);
        }
        stack.push({
          markers: [...markers],
          node: child,
          paths: pathList,
        });
      }
    }
  }
  const entries = Object.entries(pathForMarkers);
  for (let i = 0; i < entries.length; i += 1) {
    const [path, markers] = entries[i];
    const clonedMarkers = [];
    for (let j = 0; j < markers.length; j += 1) {
      clonedMarkers.push({ ...markers[j] });
    }
    for (let j = 0; j < clonedMarkers.length; j += 1) {
      const current = clonedMarkers[j];
      const previous = j > 0 && clonedMarkers[j - 1];
      if (previous && previous.link === current.link) {
        current.names = [...previous.names, ...current.names];
        previous.isValid = false;
      }
      if (j === clonedMarkers.length - 1) {
        current.isClickable = false;
      }
    }
    const processedMarkers = [];
    for (let j = 0; j < clonedMarkers.length; j += 1) {
      const clonedMarker = clonedMarkers[j];
      const {
        alias,
        isClickable,
        isValid,
        link,
        names,
      } = clonedMarker;
      if (isValid) {
        processedMarkers.push({
          alias,
          isClickable,
          isValid,
          link,
          names,
          relativeCount: 0,
        });
      }
    }
    for (let j = processedMarkers.length - 2; j >= 0; j -= 1) {
      const nextMarker = processedMarkers[j + 1];
      const currentMarker = processedMarkers[j];
      currentMarker.relativeCount += nextMarker.relativeCount + nextMarker.link.replace('/*', '').split('/').filter(Boolean).length - currentMarker.link.replace('/*', '').split('/').filter(Boolean).length;
    }
    pathForMarkers[path] = processedMarkers;
  }
  return pathForMarkers;
};
const generatePermittedMenus = (identityForPermission, context, options = {}) => {
  const {
    account,
    customerProperty,
    login,
    systemGame,
  } = options;

  const accessibleMenus = [];
  const rootChildren = context.children || [];
  for (let i = 0; i < rootChildren.length; i += 1) {
    const rootChild = rootChildren[i];
    const paths = [rootChild.path];
    const hasAccountType = !account || !rootChild.accountTypeSet || rootChild.accountTypeSet.size === 0 || rootChild.accountTypeSet.has(account.type);
    const hasCustomerPropertyType = _.isEmpty(customerProperty) ||  !customerProperty || !rootChild.customerPropertyTypeSet || rootChild.customerPropertyTypeSet.has(customerProperty.type);

    const hasPermission = !rootChild.permissionIdentity || identityForPermission[rootChild.permissionIdentity];
    const hasSystemGame = !systemGame || !rootChild.systemGameSet || rootChild.systemGameSet.has(systemGame.code);
    let isShownToDelegation = true;
    if (rootChild.isHiddenFromDelegation && account && login) {
      if (login.type === constants.delegatedAdmin || login.type === constants.user) {
        const { employerId } = login;
        isShownToDelegation = employerId !== account.objectId;
      }
    }
    if (hasAccountType && hasCustomerPropertyType && hasPermission && hasSystemGame && isShownToDelegation) {
      const menu = {
        alias: rootChild.alias,
        children: [],
        group: rootChild.group,
        icon: rootChild.icon,
        name: rootChild.name,
        path: paths.join(''),
        permissionIdentity: rootChild.permissionIdentity,
        section: rootChild.section,
        type: rootChild.type,
      };
      const children = rootChild.children || [];
      for (let j = 0; j < children.length; j += 1) {
        const child = children[j];
        const resolvedPath = [...paths];
        if (child.path) {
          resolvedPath.push(child.path);
        }
        if (child.isHidden !== true && (!child.permissionIdentity || identityForPermission[child.permissionIdentity])) {
          menu.children.push({
            name: child.name,
            path: resolvedPath.join('/'),
          });
        }
      }
      if (rootChild.isHidden !== true) {
        accessibleMenus.push(menu);
      }
    }
  }
  return accessibleMenus;
};
const generatePermittedRoutes = (identityForPermission, context, options = {}) => {
  const {
    account,
    customerProperty,
    login,
    systemGame,
  } = options;
  const accessibleRoutes = [];
  const stack = [];
  stack.push({
    Component: context.Component,
    LazyComponent: context.LazyComponent,
    accountTypeSet: context.accountTypeSet,
    customerPropertyTypeSet: context.customerPropertyTypeSet,
    node: context,
    paths: [context.path],
    permissionIdentity: context.permissionIdentity,
    systemGameSet: context.systemGameSet,
  });
  while (stack.length > 0) {
    const {
      Component,
      LazyComponent,
      accountTypeSet,
      customerPropertyTypeSet,
      isHiddenFromDelegation,
      node,
      paths,
      permissionIdentity,
      systemGameSet,
    } = stack.pop();
    const hasAccountType = !account || !accountTypeSet || accountTypeSet.has(account.type);
    const hasCustomerPropertyType = !customerProperty || !customerPropertyTypeSet || customerPropertyTypeSet.has(customerProperty.type);
    const hasPermission = !permissionIdentity || identityForPermission[permissionIdentity];
    const hasSystemGame = !systemGame || !systemGameSet || systemGameSet.has(systemGame.code);
    let isShownToDelegation = true;
    if (isHiddenFromDelegation && account && login) {
      if (login.type === constants.delegatedAdmin || login.type === constants.user) {
        const { employerId } = login;
        isShownToDelegation = employerId !== account.objectId;
      }
    }
    if ((Component || LazyComponent) && hasAccountType && hasCustomerPropertyType && hasPermission && hasSystemGame && isShownToDelegation) {
      const resolvedPath = paths.join('/');
      accessibleRoutes.push({
        Component,
        LazyComponent,
        path: resolvedPath,
        permissionIdentity,
      });
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i += 1) {
        const child = node.children[i];
        const clonedPaths = [...paths];
        const pathList = clonedPaths.length === 1 && clonedPaths[0] === '/' ? [] : clonedPaths;
        if (child.path) {
          pathList.push(child.path);
        }
        stack.push({
          Component: child.Component,
          LazyComponent: child.LazyComponent,
          accountTypeSet: child.accountTypeSet,
          customerPropertyTypeSet: child.customerPropertyTypeSet,
          isHiddenFromDelegation: child.isHiddenFromDelegation,
          node: child,
          paths: pathList,
          permissionIdentity: child.permissionIdentity,
        });
      }
    }
  }
  return accessibleRoutes;
};

/**
 * Functions
 */
const getResolvedValue = (object, name) => {
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i += 1) {
    if (keys[i].toLowerCase().endsWith(name.toLowerCase())) {
      return object[keys[i]];
    }
  }
  return null;
};
const webShare = async (input) => {
  const {
    image,
    name,
    text,
    format,
  } = input;
  const url = `data:image/png;base64,${image}`;
  const data = await fetch(url);
  const blob = await data.blob();
  const file = new File([blob], `${name}.png`, { type: blob.type });
  try {
    if (format !== 'Text' && navigator.canShare && navigator.canShare({ files: [file] })) {
      await navigator.share({
        files: [file],
        text: name,
      });
    } else if (navigator.share) {
      await navigator.share({
        text: text || '...',
      });
    } else {
      const link = document.createElement('a');
      if (typeof link.download === 'string') {
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  } catch (e) {
    if (e.name === 'NotAllowedError') {
      return true;
    }
  }
  return false;
};

const exportExcel = async (input) => {
  const {
    file,
    name,
  } = input;
  var bytes = new Uint8Array(file.data); // pass your byte response to this constructor
  var blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });// change resultByte to bytes
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.xlsx`;
  link.click();
};
const precom = {
  '1#1#1#1#1-1': 5,
  '1#1#1#1#1-2': 20,
  '1#1#1#1#1-3': 60,
  '1#1#1#1#1-4': 120,
  '1#1#1#1#1-5': 120,
  '1#1#1#1-1': 4,
  '1#1#1#1-2': 12,
  '1#1#1#1-3': 24,
  '1#1#1#1-4': 24,
  '1#1#1#2-1': 4,
  '1#1#1#2-2': 13,
  '1#1#1#2-3': 33,
  '1#1#1#2-4': 60,
  '1#1#1#2-5': 60,
  '1#1#1-1': 3,
  '1#1#1-2': 6,
  '1#1#1-3': 6,
  '1#1#2-1': 3,
  '1#1#2-2': 7,
  '1#1#2-3': 12,
  '1#1#2-4': 12,
  '1#1#3-1': 3,
  '1#1#3-2': 7,
  '1#1#3-3': 13,
  '1#1#3-4': 20,
  '1#1#3-5': 20,
  '1#1-1': 2,
  '1#1-2': 2,
  '1#2#2-1': 3,
  '1#2#2-2': 8,
  '1#2#2-3': 18,
  '1#2#2-4': 30,
  '1#2#2-5': 30,
  '1#2-1': 2,
  '1#2-2': 3,
  '1#2-3': 3,
  '1#3-1': 2,
  '1#3-2': 3,
  '1#3-3': 4,
  '1#3-4': 4,
  '1#4-1': 2,
  '1#4-2': 3,
  '1#4-3': 4,
  '1#4-4': 5,
  '1#4-5': 5,
  '1-1': 1,
  '2#2-1': 2,
  '2#2-2': 4,
  '2#2-3': 6,
  '2#2-4': 6,
  '2#3-1': 2,
  '2#3-2': 4,
  '2#3-3': 7,
  '2#3-4': 10,
  '2#3-5': 10,
  '2-1': 1,
  '2-2': 1,
  '3-1': 1,
  '3-2': 1,
  '3-3': 1,
  '4-1': 1,
  '4-2': 1,
  '4-3': 1,
  '4-4': 1,
  '5-1': 1,
  '5-2': 1,
  '5-3': 1,
  '5-4': 1,
  '5-5': 1,
};
const calculateAmount = (playCode, idForCustomerRunnerTicketScheme, codes) => {
  let totalAmount = 0;
  const { lines } = playCode;
  const boxes = [];
  for (let i = 0; i < lines.length; i += 1) {
    const { schemeId } = lines[i];
    let { text } = lines[i];
    if (text && schemeId) {
      if (text.endsWith('**')) {
        const { entries = [] } = idForCustomerRunnerTicketScheme[schemeId];
        const [number, ...amounts] = text.split('#');
        const numberOfDigits = number.replace(/\*/g, '').length;
        let validCount = 0;
        const isNumberBoxed = number.startsWith('*');
        for (let j = 0; j < entries.length; j += 1) {
          const {
            systemGameCategoryNumberManipulator,
            systemGameCategoryNumberOfDigits,
          } = entries[j];
          if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits) {
            validCount += 1;
          }
        }
        const amount = amounts[0].replace('**', '');
        text = [number, ...[...new Array(validCount)].map(() => amount)].join('#');
      }
      const { entries } = idForCustomerRunnerTicketScheme[schemeId];
      const [number, ...amounts] = text.split('#');
      const isNumberBoxed = number.startsWith('*');
      const sanitizedNumber = number.replace(/\*/g, '');
      const numberOfDigits = sanitizedNumber.length;
      let tracker = 0;
      for (let j = 0; j < entries.length; j += 1) {
        const {
          systemGameCategoryNumberOfDigits,
          systemGameCategoryNumberManipulator,
        } = entries[j];
        if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits && (tracker < amounts.length)) {
          const amount = amounts[tracker];
          const isAmountBoxed = amount.includes('*');
          if (isAmountBoxed) {
            const [straightAmount, boxedAmount] = amount.split('*');
            if (straightAmount) {
              totalAmount += Number(straightAmount);
            }
            if (boxedAmount) {
              boxes.push({
                amount: boxedAmount,
                number: sanitizedNumber,
                numberOfDigits: systemGameCategoryNumberOfDigits,
              });
            }
          } else if (isNumberBoxed) {
            boxes.push({
              amount,
              number: sanitizedNumber,
              numberOfDigits: systemGameCategoryNumberOfDigits,
            });
          } else {
            totalAmount += Number(amount);
          }
          tracker += 1;
        }
      }
    }
  }
  _.each(boxes, (box) => {
    const {
      amount,
      number,
      numberOfDigits,
    } = box;
    const frequencyMap = {};
    _.each(number.split(''), n => {
      if (n !== '*') {
        if (!frequencyMap[n]) {
          frequencyMap[n] = 0;
        }
        frequencyMap[n] += 1;
      }
    });
    const key = Object.values(frequencyMap).sort().join('#');
    const finalKey = `${key}-${numberOfDigits}`;
    if (precom[finalKey]) {
      totalAmount += amount * precom[finalKey];
    }
  });
  return codes.length * totalAmount;
};
const digestEntries = (input) => {
  const {
    data,
    setCurrentEntries,
    setCurrentTotalAmount,
    setCurrentTotalQuantity,
  } = input;
  setCurrentEntries((previousCurrentEntries) => {
    const numberForDatum = {};
    _.each(data, (datum) => {
      const {
        number,
        nonce,
      } = datum;
      if (!numberForDatum[number]) {
        numberForDatum[number] = datum;
      } else if (nonce > numberForDatum[number].nonce) {
        numberForDatum[number] = datum;
      }
    });
    let totalAmount = 0;
    let totalQuantity = 0;
    const processEntries = [];
    let hasChanged = false;
    _.each(previousCurrentEntries, (previousCurrentEntry) => {
      const {
        number,
        nonce: previousNonce,
      } = previousCurrentEntry;
      const datum = numberForDatum[number] || {};
      const {
        nonce,
        amount,
        quantity,
      } = datum;
      if (nonce > previousNonce) {
        hasChanged = true;
        if (amount !== 0 || quantity !== 0) {
          processEntries.push(datum);
        }
        totalAmount += amount;
        totalQuantity += quantity;
        delete numberForDatum[number];
      } else {
        const {
          amount,
          quantity,
        } = previousCurrentEntry;
        totalAmount += amount;
        totalQuantity += quantity;
        processEntries.push(previousCurrentEntry);
      }
    });
    const values = Object.values(numberForDatum);
    if (values.length > 0) {
      _.each(values, (value) => {
        const {
          amount,
          quantity,
        } = value;
        if (amount !== 0 || quantity !== 0) {
          hasChanged = true;
          totalAmount += amount;
          totalQuantity += quantity;
          processEntries.push(value);
        }
      });
    }
    if (setCurrentTotalAmount) {
      setCurrentTotalAmount(totalAmount);
    }
    if (setCurrentTotalQuantity) {
      setCurrentTotalQuantity(totalQuantity);
    }
    return _.orderBy(processEntries, ['quantity', 'number'], ['desc', 'asc']);
  });
};
const toLocaleString = (number, decimalPlaces = 0, currency) => {
  const option = {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  };
  if (currency === 'THB') {
    return `฿ ${Number(number).toLocaleString('en-US', option)}`;
  }
  return Number(number).toLocaleString('en-US', option);
};
const isMobile = () => {
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
    return true;
  }
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
export default {
  digestEntries,
  toLocaleString,
  isMobile,
  generatePathForMarkers,
  calculateAmount,
  exportExcel,
  generatePermittedMenus,
  generatePermittedRoutes,
  getResolvedValue,
  webShare,
};
