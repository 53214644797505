/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  justify-content: center;
  min-width: 0;
  width: 100%;
  > div {
    width: 100%;
  }
`;
