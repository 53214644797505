/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorCollapse from '../../Vendor/Ant/Collapse';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorCollapse.Panel)`
  && .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    > a {
      :first-child {
        border-top: ${scale.minor(0.25)}px solid rgba(225, 228, 232, 1);
      }
      :last-child {
        border-bottom: none;
      }
    }
  }
`;
