/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { useLocation } from '@reach/router';

/**
 * Components
 */
import * as Bit from '../../Bit';
import * as Styled from '../../Styled';

/**
 * Configurations
 */
import { app } from '../../../../config';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Constant variables
 */
const deviceForMenuType = {
  desktop: 'header',
  mobile: 'sidebar',
};

/**
 * Functional components
 */
const TemplateDesktop = (props) => {
  const {
    accessibleMenus,
    children,
    deauthorizeAccountActionBundle,
    drawsOpenBundle,
    locale,
    loginBundle,
    navigate,
    setLocale,
    systemBundle,
    systemGame,
    systemGamesBundle,
    token,
    type,
  } = props;
  const translate = useTranslate();
  const [breakpointDraw, setBreakpointDraw] = React.useState(0);
  const [device, setDevice] = React.useState('mobile');
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const handleBreakpoint = React.useCallback((hasPassedBreakpoint) => {
    setIsCollapsed(!hasPassedBreakpoint);
    setBreakpointDraw(previousBreakpointDraw => previousBreakpointDraw + 1);
    setDevice(hasPassedBreakpoint ? 'mobile' : 'desktop');
  }, [setBreakpointDraw, setIsCollapsed, setDevice]);
  const handleMenuBarClick = React.useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed, setIsCollapsed]);
  const location = useLocation();
  const menuType = deviceForMenuType[device];
  const isLoginLayout = type === 'login';
  const isSidebarLayout = type !== 'login' && menuType === 'sidebar';
  const menu = !_.isEmpty(systemGame) && (
    <Bit.LayoutMenu
      accessibleMenus={accessibleMenus}
      loginBundle={loginBundle}
      pathname={location.pathname}
      type={menuType}
    />
  );
  const brand = (isCollapsed || isLoginLayout || isSidebarLayout) && (
    <Bit.LayoutBrand
      hasAlias={isLoginLayout}
      hasLogo={!isLoginLayout}
      isLoginLayout={isLoginLayout}
      isSidebarLayout={isSidebarLayout}
      onMenuBarClick={isSidebarLayout ? handleMenuBarClick : undefined}
      systemBundle={systemBundle}
      systemGame={systemGame}
    />
  );
  const {
    alias,
    code,
  } = systemGame;
  const title = alias ? translate(`display.alias.${alias}`) : null;
  return (
    <Styled.VendorAntLayout>
      {!_.isEmpty(systemGame) && (
        <Bit.LayoutSidebar
          breakpointDraw={breakpointDraw}
          isCollapsed={isLoginLayout || isCollapsed}
          menu={isSidebarLayout && menu}
          onBreakpoint={handleBreakpoint}
          setIsCollapsed={setIsCollapsed}
          systemBundle={systemBundle}
          systemGame={systemGame}
        />
      )}
      <Bit.LayoutHeader
        brand={brand}
        deauthorizeAccountActionBundle={deauthorizeAccountActionBundle}
        locale={locale}
        loginBundle={loginBundle}
        menu={!isSidebarLayout && menu}
        mode={isLoginLayout ? 'login' : menuType}
        navigate={navigate}
        setLocale={setLocale}
        systemBundle={systemBundle}
        systemGame={systemGame}
        systemGamesBundle={systemGamesBundle}
        token={token}
      />
      <Styled.DivisionDesktopBody>
        {!isLoginLayout && title && (
          <Bit.LayoutPanel
            code={code}
            drawsOpenBundle={drawsOpenBundle}
            title={title}
          />
        )}
        {!isLoginLayout && !title && (<Bit.EmptySpace type="calendar" />)}
        {children}
      </Styled.DivisionDesktopBody>
      <Styled.VendorAntLayoutFooter>
        &copy; 2019 Elizabeth C., All rights reserved Test.
        <Styled.SpanVersion>
          {`(${app.VERSION})`}
        </Styled.SpanVersion>
      </Styled.VendorAntLayoutFooter>
    </Styled.VendorAntLayout>
  );
};
TemplateDesktop.defaultProps = {
  accessibleMenus: [],
  deauthorizeAccountActionBundle: {
    isDocumentLoading: false,
    isFirstBoot: true,
    response: {
      payload: {},
    },
  },
  drawsOpenBundle: {
    isDocumentLoading: false,
    isFirstBoot: true,
    response: {
      payload: {},
    },
  },
  loginBundle: {
    isDocumentLoading: false,
    isFirstBoot: true,
    response: {
      payload: {},
    },
  },
  navigate: _.noop,
  systemGame: {},
  systemGamesBundle: {
    isDocumentLoading: false,
    isFirstBoot: true,
    response: {
      payload: [],
    },
  },
  token: '',
  type: 'default',
};
TemplateDesktop.displayName = 'TemplateDesktop';
TemplateDesktop.propTypes = {
  accessibleMenus: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.node.isRequired,
  deauthorizeAccountActionBundle: PropTypes.objectOf(PropTypes.any),
  drawsOpenBundle: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  loginBundle: PropTypes.objectOf(PropTypes.any),
  navigate: PropTypes.func,
  setLocale: PropTypes.func.isRequired,
  systemBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  systemGame: PropTypes.objectOf(PropTypes.any),
  systemGamesBundle: PropTypes.objectOf(PropTypes.any),
  token: PropTypes.string,
  type: PropTypes.string,
};
export default React.memo(TemplateDesktop);
