/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

import _ from 'lodash';
/**
 * Components
 */
import BitMask from '../Mask';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { masks } from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import { images } from '../../../../assets';
import { format } from 'date-fns';

/**
 * Functional components
 */
const BitResult = (props) => {
  const {
    date,
    actions,
    systemGameTypeAlias,
    keyForElements,
  } = props;
  const translate = useTranslate();
  return (
    <Styled.DivisionResultCard name="division-result-card">
      <Styled.DivisionResult name="division-result">
        <Styled.DivisionRow name="division-row">
          <Styled.Division name="division">
            <Styled.Image src={images[systemGameTypeAlias.replace(/\s/g, '')]} />
            <Styled.Span name="span">
              {translate(`display.alias.${systemGameTypeAlias}`)}
            </Styled.Span>
          </Styled.Division>
          <Styled.Span name="span">
            {format(new Date(date), 'dd-MM-yy')}
          </Styled.Span>
        </Styled.DivisionRow>
        {Object.keys(keyForElements).map(key => {
          const chunks = _.chunk(keyForElements[key], 2);
          return (
            <Styled.DivisionColumn  name="division-column" key={key}>
              <Styled.DivisionRow name="division-row">
                <Styled.Span  name="span">
                  {translate(`display.alias.${key}`)}
                </Styled.Span>
              </Styled.DivisionRow>
              {chunks.map((chunk, index) => (
                <Styled.DivisionRow  name="division-row" key={index}>
                  {chunk.map((element) => (
                    <Styled.DivisionTicketNumber  name="division-ticket-number" key={element.objectId}>
                      {element.number || ''.padStart(element.systemGamePrizeNumberOfDigits, '-')}
                    </Styled.DivisionTicketNumber>
                  ))}
                </Styled.DivisionRow>
              ))}
            </Styled.DivisionColumn>
          );
        })}
      </Styled.DivisionResult>
      <Styled.DivisionToolbar>
        {actions}
      </Styled.DivisionToolbar>
    </Styled.DivisionResultCard>
  );
};
BitResult.displayName = 'BitResult';
BitResult.defaultProps = { downlineUsername: '' };
BitResult.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  downlineUsername: PropTypes.string,
  receivedCommissionAmount: PropTypes.number.isRequired,
  receivedRewardAmount: PropTypes.number.isRequired,
  receivedValue: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  requestedBalanceAmount: PropTypes.number.isRequired,
  requestedValue: PropTypes.number.isRequired,
  respondedAmount: PropTypes.number.isRequired,
  respondedBalanceAmount: PropTypes.number.isRequired,
  respondedCommissionAmount: PropTypes.number.isRequired,
  respondedRewardAmount: PropTypes.number.isRequired,
};
export default React.memo(BitResult);
