/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'amount': {
      styles.push(`width: ${scale.minor(33)}px;`);
      break;
    }
    case 'category': {
      styles.push(`width: ${scale.minor(25)}px;`);
      break;
    }
    case 'number': {
      styles.push(`width: ${scale.minor(25)}px;`);
      break;
    }
    case 'rank': {
      styles.push(`width: ${scale.major(10)}px;`);
      break;
    }
    case 'risk': {
      styles.push(`width: ${scale.minor(38)}px;`);
      break;
    }
    case 'value': {
      styles.push(`width: ${scale.minor(33)}px;`);
      break;
    }
    case 'tags': {
      styles.push(`width: 350px;`);
      break;
    }
    case 'quota': {
      styles.push(`width: 100px;`);
      break;
    }

    case 'final': {
      styles.push(`width: 200px;`);
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :not(:last-child) {
    border-right: solid ${scale.minor(0.25)}px rgba(240, 240, 240, 1);
  }
  > img {
    margin-right: 0;
  }
`;
