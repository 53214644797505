/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitDialog = (props) => {
  const {
    children,
    isDialogOpen,
    setIsDialogOpen,
  } = props;
  const handleClick = React.useCallback(() => setIsDialogOpen(false), [setIsDialogOpen]);
  return (
    <Styled.SectionDialog
      mode={isDialogOpen ? 'open' : 'close'}
      onClick={handleClick}
    >
      <Styled.DivisionContainer onClick={e => e.stopPropagation()}>
        {children}
      </Styled.DivisionContainer>
    </Styled.SectionDialog>
  );
};
BitDialog.displayName = 'BitDialog';
BitDialog.propTypes = {
  children: PropTypes.node.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
};
export default React.memo(BitDialog);
