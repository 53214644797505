/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: 24px;
  justify-content: center;
  width: 100%;
  :not(:first-child){
    margin-top: 4px;
  }
`;
