/**
 * Node modules
 */
import React from 'react';

/**
 * Lazy components
 */
const LazyPageDesktopAccounts = React.lazy(() => import('../../application/containers/Page/Desktop/Accounts'));
const LazyPageDesktopDashboard = React.lazy(() => import('../../application/containers/Page/Desktop/Dashboard'));
const LazyPageDesktopDraws = React.lazy(() => import('../../application/containers/Page/Desktop/Draws'));
const LazyRouterAccount = React.lazy(() => import('../../application/containers/Router/Account'));
const LazyRouterAnalytics = React.lazy(() => import('../../application/containers/Router/Analytics'));
const LazyRouterDraw = React.lazy(() => import('../../application/containers/Router/Draw'));
const LazyRouterPlay = React.lazy(() => import('../../application/containers/Router/Play'));
const LazyRouterSystem = React.lazy(() => import('../../application/containers/Router/System'));
export default {
  LazyComponent: LazyPageDesktopDashboard,
  children: [
    {
      LazyComponent: LazyPageDesktopAccounts,
      alias: 'IonicPeople',
      children: [
        {
          LazyComponent: LazyRouterAccount,
          isHidden: true,
          name: 'account',
          path: ':username/*',
        },
      ],
      name: 'accounts',
      path: 'accounts',
    },
    {
      LazyComponent: LazyPageDesktopDraws,
      alias: 'IonicCalendar',
      children: [
        {
          LazyComponent: LazyRouterDraw,
          isHidden: true,
          name: 'draw',
          path: ':objectId/*',
        },
      ],
      name: 'draws',
      path: 'draws',
    },
    {
      LazyComponent: LazyRouterPlay,
      alias: 'IonicDice',
      name: 'play',
      path: 'play/*',
    },
    {
      LazyComponent: LazyRouterAnalytics,
      alias: 'IonicAnalytics',
      name: 'analytics',
      path: 'analytics/*',
    },
    {
      LazyComponent: LazyRouterSystem,
      alias: 'IonicServer',
      name: 'system',
      path: 'system/*',
    },
  ],
  name: 'dashboard',
  path: '/',
};
