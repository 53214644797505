/**
 * Node modules
 */
import React from 'react';

export default React.memo(props => (
  <svg
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>
      Print Outline
    </title>
    <path
      d="M384,368h24a40.12,40.12,0,0,0,40-40V168a40.12,40.12,0,0,0-40-40H104a40.12,40.12,0,0,0-40,40V328a40.12,40.12,0,0,0,40,40h24"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <rect
      fill="none"
      height="208"
      rx="24.32"
      ry="24.32"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="32"
      width="256"
      x="128"
      y="240"
    />
    <path
      d="M384,128V104a40.12,40.12,0,0,0-40-40H168a40.12,40.12,0,0,0-40,40v24"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="392"
      cy="184"
      r="24"
    />
  </svg>
));
