/**
 * Betlytical modules
 */
import { routes } from '@betlytical/api-schema';

export default [
  ...routes.systemGameCategories,
  ...routes.systemGamePrizes,
  ...routes.systemGameTypes,
  ...routes.systemGames,
  ...routes.systemPermissions,
  ...routes.systemTicketNumberPlugins,
  ...routes.systemTranslations,
  ...routes.systems,
];
