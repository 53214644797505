/**
 * Node modules
 */
import Menu from 'antd/lib/menu';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Internals
 */
import Divider from './Divider';
import Item from './Item';
import Sub from './Sub';

/**
 * Functional components
 */
const VendorAntMenu = (props) => {
  const {
    isSelectable,
    layout,
    onOpen: handleOpenChange,
    ...rest
  } = props;
  return (
    <Menu
      mode={layout}
      multiple={false}
      onOpenChange={handleOpenChange}
      selectable={isSelectable}
      {...rest}
    />
  );
};
VendorAntMenu.Divider = Divider;
VendorAntMenu.Item = Item;
VendorAntMenu.Sub = Sub;
VendorAntMenu.defaultProps = {
  isSelectable: false,
  layout: 'vertical',
  onOpen: _.noop,
};
VendorAntMenu.displayName = 'VendorAntMenu';
VendorAntMenu.propTypes = {
  isSelectable: PropTypes.bool,
  layout: PropTypes.string,
  onOpen: PropTypes.func,
};
export default VendorAntMenu;
