/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */

const getStyles = (props) => {
  const {
    mode,
  } = props;
  const styles = [];
  switch (mode) {
    case 'note': {
      styles.push(`
        > div {
          margin-top: 0;
          margin-bottom: 8px;
          > div {
            width: auto;
          }
        }
      `);
      break;
    }
    default: {
      styles.push('justify-content: space-between;');
      styles.push(`margin-bottom: ${scale.minor(1)}px;`);
      break;
    }
  }
  return styles.join('');
};

export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  width: 100%;
  > small {
    width: auto;
  }
`;
