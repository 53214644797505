/**
 * Node modules
 */
import React from 'react';

/**
 * Betlytical modules
 */
import { permissionIdentities } from '@betlytical/api-schema';

/**
 * Lazy components
 */
const LazyPageDrawSystemGameTypeAteVolume = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameTypeAteVolume'));
const LazyPageDrawSystemGameTypeOverallVolume = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameTypeOverallVolume'));
const LazyPageDrawSystemGameTypeResults = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameTypeResults'));
const LazyPageDrawSystemGameTypeTankedVolume = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameTypeTankedVolume'));
const LazyPageDrawSystemGameTypeTrackSale = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameTypeTrackSale'));
const LazyPageSystemGameTypeManageLimit = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameTypeManageLimit'));
const LazyPageDrawSystemGameOverview = React.lazy(() => import('../../application/containers/Page/Draw/SystemGameOverview'));
export default {
  children: [
    {
      LazyComponent: LazyPageDrawSystemGameTypeResults,
      name: 'results',
      path: 'results',
      permissionIdentity: permissionIdentities.GET_DRAW_SYSTEM_GAME_TYPE_RESULT,
      section: '1.0.0',
    },
    {
      LazyComponent: LazyPageDrawSystemGameTypeOverallVolume,
      group: 'volume',
      name: 'overall-volume',
      path: 'overall-volume',
      permissionIdentity: permissionIdentities.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME,
      section: '1.1.0',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageDrawSystemGameTypeAteVolume,
      group: 'volume',
      name: 'ate-volume',
      path: 'ate-volume',
      permissionIdentity: permissionIdentities.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME,
      section: '1.1.1',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageDrawSystemGameTypeTankedVolume,
      group: 'volume',
      name: 'tanked-volume',
      path: 'tanked-volume',
      permissionIdentity: permissionIdentities.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME,
      section: '1.1.2',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageDrawSystemGameOverview,
      name: 'draw-overview',
      path: 'overview',
      permissionIdentity: permissionIdentities.GET_ACCOUNT,
      section: '2.0.0',
    }, {
      LazyComponent: LazyPageDrawSystemGameTypeTrackSale,
      name: 'track-sales',
      path: 'tracksales',
      permissionIdentity: permissionIdentities.GET_ACCOUNT,
      section: '3.0.0',
    },
    {
      LazyComponent: LazyPageSystemGameTypeManageLimit,
      name: 'manage-limits',
      path: 'managelimits',
      permissionIdentity: permissionIdentities.GET_ACCOUNT,
      section: '4.0.0',
    },
  ],
};
