/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'type': {
      styles.push('background-color: rgba(52, 152, 219, 0.5);');
      break;
    }
    case 'open': {
      styles.push('background-color: rgba(46, 204, 113, 0.5);');
      break;
    }
    case 'close': {
      styles.push('background-color: rgba(231, 76, 60, 0.5);');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.small`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  font-style: italic;
  height: 100%;
  white-space: pre;
  width: 100%;
`;
