/**
 * Private modules
 */
import helpers from '../../helpers';

/**
 * Constant variables
 */
const types = {
  CREATE_ACCOUNT_SESSION_FAIL: 'CREATE_ACCOUNT_SESSION_FAIL',
  CREATE_ACCOUNT_SESSION_FULFILL: 'CREATE_ACCOUNT_SESSION_FULFILL',
  CREATE_ACCOUNT_SESSION_REQUEST: 'CREATE_ACCOUNT_SESSION_REQUEST',
  CREATE_ACCOUNT_SESSION_RESET: 'CREATE_ACCOUNT_SESSION_RESET',
  CREATE_ACCOUNT_SESSION_SUCCESS: 'CREATE_ACCOUNT_SESSION_SUCCESS',
  CREATE_ACCOUNT_SESSION_TRIGGER: 'CREATE_ACCOUNT_SESSION_TRIGGER',
};
const creators = {
  createAccountSessionFail: helpers.createActionCreator(types.CREATE_ACCOUNT_SESSION_FAIL),
  createAccountSessionFulfill: helpers.createActionCreator(types.CREATE_ACCOUNT_SESSION_FULFILL),
  createAccountSessionRequest: helpers.createActionCreator(types.CREATE_ACCOUNT_SESSION_REQUEST),
  createAccountSessionReset: helpers.createActionCreator(types.CREATE_ACCOUNT_SESSION_RESET),
  createAccountSessionSuccess: helpers.createActionCreator(types.CREATE_ACCOUNT_SESSION_SUCCESS),
  createAccountSessionTrigger: helpers.createActionCreator(types.CREATE_ACCOUNT_SESSION_TRIGGER),
};
export default {
  creators,
  types,
};
