/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntSelect from '../../Vendor/Ant/Select';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntSelect)`
  margin-left: ${scale.major(1)}px;
  width: ${scale.major(10)}px;
  .ant-select-selection-item {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
