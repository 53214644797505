/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  > div:nth-child(1) {
    border-radius: 8px;
    padding: 0 4px;
    align-items: center;
    background-color: rgb(52, 73, 94);
    color: white;
    height: 100%;
    justify-content: center;
    min-width: 80px;
    max-width: 80px;
  ;
  }
  > div:nth-child(2) {
    width: 100%;
    > div {
      height: 24px;
      border-radius: 8px;
      margin-left: 4px;
      margin-right: 0;
      :not(:first-child){
        margin-top: 4px;
      }
    }
  }
`;
