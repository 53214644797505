/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'tanked': {
      styles.push(`
        > span {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          padding: 0 ${scale.major(2)}px;
          width: 100%;
          :not(:first-child) {
            border-left: solid ${scale.minor(0.25)}px rgba(240, 240, 240, 1);
          }
        }
      `);
      break;
    }
    default: {
      styles.push(`
        > span {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          padding: 0 ${scale.major(2)}px;
          :first-child {
            width: ${scale.minor(45)}px;
            margin-left: -${scale.minor(6)}px;
          }
          :not(:last-child) {
            margin-right: 0;
          }
          :not(:first-child) {
            width: ${scale.minor(33)}px;
            border-left: solid ${scale.minor(0.25)}px rgba(240, 240, 240, 1);
          }
        }
      `);
      break;
    }
  }
  return styles.join('');
};

export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  justify-content: center;
  align-items: center;
  border-top: solid ${scale.minor(0.25)}px rgba(242, 242, 242, 1);
  display: flex;
  font-weight: bold;
  height: ${scale.minor(9)}px;
  width: 100%;
  
`;
