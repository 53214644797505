/**
 * Node modules
 */
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Internals
 */
import Application from './application';

ReactDOM.render((<Application />), document.getElementById('root'));
if (module.hot) {
  module.hot.accept();
}
