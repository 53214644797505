/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  utilities,
} from '../../../../helpers';

/**
 * Functional components
 */
const BitHapticButton = (props) => {
  const {
    children,
    focusedSelectedCustomerRunnerTicketScheme,
    inputKeys,
    isDisabled,
    mode,
    onClick: handleClick,
    setPlayCode,
    ...rest
  } = props;
  const isKeyPressed = hooks.useIsKeyPressed(inputKeys);
  const handlePress = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(50);
    }
    setPlayCode((state) => {
      const {
        index,
        lines,
      } = state;
      const clonedLines = [...lines];
      const { text } = clonedLines[index];
      if (mode === 'backspace') {
        clonedLines[index] = {
          ...clonedLines[index],
          text: text.slice(0, -1),
        };
        return {
          ...state,
          lines: clonedLines,
        };
      }
      if (mode === 'input') {
        if (children === '#' && text.endsWith('#')) {
          clonedLines[index] = {
            ...clonedLines[index],
            text: `${text}0${children}`,
          };
        } else {
          clonedLines[index] = {
            ...clonedLines[index],
            text: `${text}${children}`,
          };
        }
        return {
          ...state,
          lines: clonedLines,
        };
      }
      if (mode === 'down') {
        const isLastLine = index === lines.length - 1;
        if (text.endsWith('**')) {
          const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
          const [number, ...amounts] = text.split('#');
          const numberOfDigits = number.replace(/\*/g, '').length;
          let validCount = 0;
          const isNumberBoxed = number.startsWith('*');
          for (let i = 0; i < entries.length; i += 1) {
            const {
              systemGameCategoryNumberManipulator,
              systemGameCategoryNumberOfDigits,
            } = entries[i];
            if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits) {
              validCount += 1;
            }
          }
          const amount = amounts[0].replace('**', '');
          const expandedText = [number, ...[...new Array(validCount)].map(() => amount)].join('#');
          clonedLines[index] = {
            ...clonedLines[index],
            text: expandedText,
          };
        } else if (text.endsWith('#') && index > 0) {
          const [number] = text.split('#');
          const [previousNumber, ...previousAmounts] = clonedLines[index - 1].text.split('#');
          if (number.length === previousNumber.length) {
            clonedLines[index] = {
              ...clonedLines[index],
              text: [number, ...previousAmounts].join('#'),
            };
          }
        }
        if (isLastLine) {
          if (text !== '') {
            clonedLines[index + 1] = {
              ...clonedLines[index],
              key: _.uniqueId(),
              text: '',
            };
            return {
              ...state,
              index: index + 1,
              lines: clonedLines,
            };
          }
          return {
            ...state,
            lines: clonedLines,
          };
        }
        return {
          ...state,
          index: index + 1,
          lines: clonedLines,
        };
      }
      if (mode === 'up') {
        return {
          ...state,
          index: Math.max(0, index - 1),
        };
      }
      return state;
    });
  }, [children, mode, setPlayCode, focusedSelectedCustomerRunnerTicketScheme]);
  React.useEffect(() => {
    if (isKeyPressed && !isDisabled && !utilities.isMobile()) {
      handlePress({
        preventDefault: _.noop,
        stopPropagation: _.noop,
      });
    }
  }, [handlePress, isDisabled, isKeyPressed]);
  const buttonProps = handleClick ? { onClick: handleClick } : {
    // onMouseUp: handlePress,
    onTouchEnd: handlePress,
  };
  if (isDisabled) {
    delete buttonProps.onTouchEnd;
  }
  return (
    <Styled.ButtonKey
      disabled={isDisabled}
      {...buttonProps}
      {...rest}
    >
      {mode === 'backspace' && (<Styled.Icon.IonicBackspace />)}
      {mode === 'default' && children}
      {mode === 'down' && (<Styled.Icon.IonicCaretDown />)}
      {mode === 'fn' && children}
      {mode === 'input' && children}
      {mode === 'up' && (<Styled.Icon.IonicCaretUp />)}
    </Styled.ButtonKey>
  );
};
BitHapticButton.displayName = 'BitHapticButton';
BitHapticButton.defaultProps = {
  children: null,
  focusedSelectedCustomerRunnerTicketScheme: {},
  inputKeys: [],
  isDisabled: false,
  mode: 'default',
  onClick: null,
  setPlayCode: _.noop,
};
BitHapticButton.propTypes = {
  children: PropTypes.node,
  focusedSelectedCustomerRunnerTicketScheme: PropTypes.objectOf(PropTypes.any),
  inputKeys: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  setPlayCode: PropTypes.func,
};
export default React.memo(BitHapticButton);
