/**
 * Node modules
 */
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

/**
 * Redux
 */
import reducers from '../reducers';
import saga from '../saga';

/**
 * Functions
 */
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  combineReducers({ reducers }),
  compose(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(saga);
export default store;
