/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.button`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  outline: none;
  padding: ${scale.major(1)}px;
  width: 100%;
  img {
    box-sizing: content-box;
  }
  > div:not(:first-child) {
    margin-top: ${scale.minor(1)}px;
  }
`;
