/**
 * Node modules
 */
import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Bit from '../../Bit';

/**
 * Functional components
 */
const TemplateMobile = (props) => {
  const {
    children,
    start,
    title,
  } = props;
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar className="ion-toolbar">
          {start && (
            <IonButtons slot="start">
              {start}
            </IonButtons>
          )}
          <IonTitle>
            {title}
          </IonTitle>
          <IonButtons slot="end">
            <Bit.Clock />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonPage>
  );
};
TemplateMobile.defaultProps = {
  children: null,
  start: null,
  title: null,
};
TemplateMobile.displayName = 'TemplateMobile';
TemplateMobile.propTypes = {
  children: PropTypes.node,
  start: PropTypes.node,
  title: PropTypes.node,
};
export default React.memo(TemplateMobile);
