/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import BitLayoutBrand from '../LayoutBrand';
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitLayoutSidebar = (props) => {
  const {
    breakpointDraw,
    isCollapsed,
    menu,
    onBreakpoint: handleBreakpoint,
    setIsCollapsed,
    systemBundle,
    systemGame,
  } = props;
  const systemGameKey = _.kebabCase(systemGame.alias);
  const handleDrawerClose = React.useCallback(() => setIsCollapsed(true), [setIsCollapsed]);
  const title = (
    <BitLayoutBrand
      systemBundle={systemBundle}
      systemGame={systemGame}
    />
  );
  return (
    <Styled.VendorAntDrawer
      hasClose={false}
      isShown={(breakpointDraw === 0 || !isCollapsed)}
      onClose={handleDrawerClose}
      placement="left"
      title={title}
    >
      <Styled.VendorAntLayoutSidebar
        breakpoint="lg"
        isCollapsed={isCollapsed}
        onBreakpoint={handleBreakpoint}
        width={256}
      >
        <Styled.RouterLink to={systemGameKey ? `/${systemGameKey}` : '/'}>
          <Styled.DivisionMenuBrand>
            {systemBundle.response.payload.alias}
          </Styled.DivisionMenuBrand>
        </Styled.RouterLink>
        {menu}
      </Styled.VendorAntLayoutSidebar>
    </Styled.VendorAntDrawer>
  );
};
BitLayoutSidebar.displayName = 'BitLayoutSidebar';
BitLayoutSidebar.propTypes = {
  breakpointDraw: PropTypes.number.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  menu: PropTypes.node.isRequired,
  onBreakpoint: PropTypes.func.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  systemBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  systemGame: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default React.memo(BitLayoutSidebar);
