import helpers from '../../helpers';

/**
 * Constant variables
 */
const types = {
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};
const creators = {
  pushNotification: helpers.createActionCreator(types.PUSH_NOTIFICATION),
  removeNotification: helpers.createActionCreator(types.REMOVE_NOTIFICATION),
};
export default {
  creators,
  types,
};
