/**
 * Node modules
 */
import React from 'react';

export default React.memo(props => (
  <svg
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>
      Arrow Down
    </title>
    <polyline
      fill="none"
      points="112 268 256 412 400 268"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      x1="256"
      x2="256"
      y1="392"
      y2="100"
    />
  </svg>
));
