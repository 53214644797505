/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.tbody`
  > tr {
    td:first-child {
      text-align: center;
    }
    td:nth-child(2) {
      text-align: center;
    }
  }
`;
