/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'open': {
      styles.push('background-color: rgba(0, 0, 0, 0.5);');
      styles.push('opacity: 1;');
      styles.push('visibility: visible;');
      break;
    }
    case 'close': {
      styles.push('opacity: 0;');
      styles.push('visibility: hidden;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.section`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1000;
  > div {
    button {
      background-color: inherit;
    }
    .MuiPickersDay-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.1);
    }
    .MuiPickersToolbar-root {
      > span {
        color: rgba(255, 255, 255, 0.54);
      }
      > div > h4 {
        color: white;
      }
      > div > button {
        display: none;
      }
      background-color: #3f51b5;
    }
    .MuiPickersDateRangePickerToolbarProps-dateTextContainer {
      > * {
      color: white;
      }
      > button > span > span{
        color: white
      }
    }
    .MuiPickersCalendar-root {
      min-height: 232px;
    }
    .MuiPickersSlideTransition-root .MuiPickersDay-root {
      font-size: 14px;
    }
    //button.MuiPickersDay-daySelected {
    //  background-color: rgba(63, 81, 181, 1);
    //}
    //.MuiPickersCalendar-transitionContainer {
    //  margin-bottom: 12px;
    //}
  }
`;
