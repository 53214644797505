/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  white-space: nowrap;
  > span:not(:last-child) {
    margin-right: ${scale.major(1)}px;
  }
`;
