/**
 * Node modules
 */
import React from 'react';

/**
 * Helpers
 */
import Contexts from '../helpers/contexts';

/**
 * Internals
 */
import provider from './provider';

/**
 * Function hooks
 */
const useTranslate = () => {
  const locale = React.useContext(Contexts.Locale);
  const systemTranslationsGlobalBundle = React.useContext(Contexts.SystemTranslationsGlobalBundle);
  const systemTranslationsGlobal = systemTranslationsGlobalBundle.response.payload;
  const mergedProvider = React.useMemo(() => {
    const clonedProvider = {
      'en-US': {
        translation: {
          ...provider['en-US'].translation,
        },
      },
      'th-TH': {
        translation: {
          ...provider['th-TH'].translation,
        },
      },
    };
    for (let i = 0; i < systemTranslationsGlobal.length; i += 1) {
      const {
        enUs,
        identifier,
        thTh,
      } = systemTranslationsGlobal[i];
      clonedProvider['en-US'].translation[identifier] = enUs;
      clonedProvider['th-TH'].translation[identifier] = thTh;
    }
    return clonedProvider;
  }, [systemTranslationsGlobal]);
  return React.useCallback((key) => {
    if (!mergedProvider[locale]) {
      return key;
    }
    if (!mergedProvider[locale].translation[key]) {
      return key;
    }
    return mergedProvider[locale].translation[key];
  }, [locale, mergedProvider]);
};
export { useTranslate };
