/**
 * Node modules
 */
import Layout from 'antd/lib/layout';
import React from 'react';

/**
 * Functional components
 */
const VendorAntLayoutHeader = props => (<Layout.Header {...props} />);
VendorAntLayoutHeader.displayName = 'VendorAntLayoutHeader';
export default VendorAntLayoutHeader;
