/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

const getStyles = (props) => {
  const { type } = props;
  const styles = [];
  switch (type) {
    case 'Activated': {
      styles.push('color: #2ecc71;');
      break;
    }
    case 'Deactivated': {
      styles.push('color: #e74c3c;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};

export default styled.span`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
