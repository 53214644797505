module.exports = [
  {
    action: 'getCustomerUsedCreditByCompositeKey',
    message: 'message.success.customer-credit',
    method: 'GET',
    path: '/customer-credit/:compositeKey',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        params: {
          properties: {
            compositeKey: { type: 'string' },
          },
          required: [
            'compositeKey',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'getCheckCacheByCompositeKey',
    message: 'message.success.check-cache',
    method: 'GET',
    path: '/check-cache/:compositeKey',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        params: {
          properties: {
            compositeKey: { type: 'string' },
          },
          required: [
            'compositeKey',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'getTagCustomerRequestLimitsBySystemGameId',
    message: 'message.success.tag-customer-request-limit',
    method: 'GET',
    path: '/tag-customer-request-limits/:customerId/:systemGameId',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        params: {
          properties: {
            customerId: { type: 'string' },
            systemGameId: { type: ['string', 'number'] },
          },
          required: [
            'customerId',
            'systemGameId',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'saveEasyLimit',
    message: 'message.success.save-easy-limit',
    method: 'PATCH',
    path: '/save-easy-limits/:customerId/:systemGameTypeId',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        body: {
          properties: {
            date: { type: 'string' },
            topPositionStart: { type: ['number'] },
            topPositionEnd: { type: ['number'] },
            top3BMult: { type: ['number'] },
            top3TMult: { type: ['number'] },
            botPositionStart: { type: ['number'] },
            botPositionEnd: { type: ['number'] },
            bot2BMult: { type: ['number'] },
            bot2LMult: { type: ['number'] },
          },
        },
        params: {
          properties: {
            customerId: { type: 'string' },
            systemGameTypeId: { type: ['string', 'number'] },
          },
          required: [
            'customerId',
            'systemGameTypeId',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'getForwardsData',
    message: 'message.success.get-forward-data',
    method: 'GET',
    path: '/get-forwards-data/:compositeKey',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'collection',
    requestSchema: {
      properties: {
        params: {
          properties: {
            compositeKey: { type: 'string' },
          },
          required: [
            'compositeKey',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'getTrackSalesData',
    message: 'message.success.get-track-sales-data',
    method: 'GET',
    path: '/get-track-sales-data/:compositeKey',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        params: {
          properties: {
            compositeKey: { type: 'string' },
          },
          required: [
            'compositeKey',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },


  {
    action: 'saveLimitTable',
    message: 'message.success.save-limit-table',
    method: 'PATCH',
    path: '/save-limit-table/:customerId/:drawSystemGameTypeId',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        body: {
          properties: {
            ALimit: { type: 'number' },
            AData: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  APercentLimit: { type: 'number' },
                  A_3B_Factor: { type: ['number',null] },
                  A_3T_Factor_NON_REPEAT: { type: ['number',null] },
                  A_3T_Factor_ONCE_REPEAT: { type: ['number',null]},
                  A_3T_Factor_TWICE_REPEAT: { type: ['number',null]},
                },
              },
            },
            DLimit: { type: 'number' },
            DData: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  DPercentLimit: { type: ['number',null] },
                  D_2B_Factor: { type: ['number',null] },
                  D_2L_Factor: { type: ['number',null] },
                },
              },
            },
          },
        },
        params: {
          properties: {
            customerId: { type: 'string' },
            drawSystemGameTypeId: { type: ['string', 'number'] },
          },
          required: [
            'customerId',
            'drawSystemGameTypeId',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'getLimitTable',
    message: 'message.success.get-limit-table',
    method: 'GET',
    path: '/get-limit-table/:customerId/:drawSystemGameTypeId',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        params: {
          properties: {
            customerId: { type: 'string' },
            drawSystemGameTypeId: { type: ['string', 'number'] },
          },
          required: [
            'customerId',
            'drawSystemGameTypeId',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },

  {
    action: 'saveLimitMode',
    message: 'message.success.save-limit-mode',
    method: 'PATCH',
    path: '/save-limit-mode/:customerId/:systemGameTypeId',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        body: {
          properties: {
            mode: { type: 'string' },
          },
        },
        params: {
          properties: {
            customerId: { type: 'string' },
            systemGameTypeId: { type: ['string', 'number'] },
          },
          required: [
            'customerId',
            'systemGameTypeId',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },
  {
    action: 'getLimitMode',
    message: 'message.success.get-limit-mode',
    method: 'GET',
    path: '/get-limit-mode/:customerId/:systemGameTypeId',
    permissionIdentity: 'GET_ACCOUNT',
    payloadType: 'resource',
    requestSchema: {
      properties: {
        params: {
          properties: {
            customerId: { type: 'string' },
            systemGameTypeId: { type: ['string', 'number'] },
          },
          required: [
            'customerId',
            'systemGameTypeId',
          ],
          type: 'object',
        },
      },
      required: ['params'],
      type: 'object',
    },
  },


];
