/**
 * Node modules
 */
import React from 'react';

export default React.memo(props => (
  <svg
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>
      Swap Horizontal
    </title>
    <polyline
      fill="none"
      points="304 48 416 160 304 272"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="398.87"
      x2="96"
      y1="160"
      y2="160"
    />
    <polyline
      fill="none"
      points="208 464 96 352 208 240"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="114"
      x2="416"
      y1="352"
      y2="352"
    />
  </svg>
));
