/**
 * Node modules
 */
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

/**
 * Clients
 */
import clients from '../../../../clients';

/**
 * Internals
 */
import actions from './actions';

/**
 * Functions
 */
const requestGetDrawSystemGameTypeAteVolumeByCustomerId = function* (action) {
  const { data } = action;
  const by = [data.params.customerId, data.params.drawId, data.params.systemGameTypeId].join('#');
  try {
    yield put(actions.creators.getDrawSystemGameTypeAteVolumeByCustomerIdRequest({ key: by }));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-get-draw-system-game-type-ate-volume-by-customer-id'], data);
    yield put(actions.creators.getDrawSystemGameTypeAteVolumeByCustomerIdSuccess({
      key: by,
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.getDrawSystemGameTypeAteVolumeByCustomerIdFail({
      key: by,
      metadata: error.metadata,
    }));
  } finally {
    yield put(actions.creators.getDrawSystemGameTypeAteVolumeByCustomerIdFulfill({ key: by }));
  }
};
const requestGetDrawSystemGameTypeOverallVolumeByCustomerId = function* (action) {
  const { data } = action;
  const by = [data.params.customerId, data.params.drawId, data.params.systemGameTypeId].join('#');
  try {
    yield put(actions.creators.getDrawSystemGameTypeOverallVolumeByCustomerIdRequest({ key: by }));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-get-draw-system-game-type-overall-volume-by-customer-id'], data);
    yield put(actions.creators.getDrawSystemGameTypeOverallVolumeByCustomerIdSuccess({
      key: by,
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.getDrawSystemGameTypeOverallVolumeByCustomerIdFail({
      key: by,
      metadata: error.metadata,
    }));
  } finally {
    yield put(actions.creators.getDrawSystemGameTypeOverallVolumeByCustomerIdFulfill({ key: by }));
  }
};
const requestGetDrawSystemGameTypeTankedVolumeByCustomerId = function* (action) {
  const { data } = action;
  const by = [data.params.customerId, data.params.drawId, data.params.systemGameTypeId].join('#');
  try {
    yield put(actions.creators.getDrawSystemGameTypeTankedVolumeByCustomerIdRequest({ key: by }));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-get-draw-system-game-type-tanked-volume-by-customer-id'], data);
    yield put(actions.creators.getDrawSystemGameTypeTankedVolumeByCustomerIdSuccess({
      key: by,
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.getDrawSystemGameTypeTankedVolumeByCustomerIdFail({
      key: by,
      metadata: error.metadata,
    }));
  } finally {
    yield put(actions.creators.getDrawSystemGameTypeTankedVolumeByCustomerIdFulfill({ key: by }));
  }
};
const watchRequestGetDrawSystemGameTypeAteVolumeByCustomerId = function* () {
  yield takeEvery(actions.types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_TRIGGER, requestGetDrawSystemGameTypeAteVolumeByCustomerId);
};
const watchRequestGetDrawSystemGameTypeOverallVolumeByCustomerId = function* () {
  yield takeEvery(actions.types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_TRIGGER, requestGetDrawSystemGameTypeOverallVolumeByCustomerId);
};
const watchRequestGetDrawSystemGameTypeTankedVolumeByCustomerId = function* () {
  yield takeEvery(actions.types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_TRIGGER, requestGetDrawSystemGameTypeTankedVolumeByCustomerId);
};
export default {
  watchRequestGetDrawSystemGameTypeAteVolumeByCustomerId,
  watchRequestGetDrawSystemGameTypeOverallVolumeByCustomerId,
  watchRequestGetDrawSystemGameTypeTankedVolumeByCustomerId,
};
