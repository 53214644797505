/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntTab from '../../Vendor/Ant/Tab';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntTab)`
  .ant-tabs-nav {
    height: ${scale.major(8)}px;
    margin-bottom: -${scale.minor(0.25)}px;
    padding: 0 ${scale.minor(6)}px;
  }
  .ant-tabs-bar,
  .ant-tabs-nav-wrap,
  .ant-tabs-tab {
    height: ${scale.major(8)}px;
    margin-bottom: -${scale.minor(0.25)}px;
    z-index: 1000;
  }
  div.ant-card-bordered {
    border-left: none;
    border-right: none;
  }
`;
