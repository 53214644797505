/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.img`
  border-radius: ${scale.major(1)}px;
  height: ${scale.minor(8)}px;
  margin-right: ${scale.major(1)}px;
  width: ${scale.minor(8)}px;
`;
