/**
 * Node modules
 */
import React from 'react';

/**
 * Betlytical modules
 */
import { permissionIdentities } from '@betlytical/api-schema';

/**
 * Lazy components
 */
const LazyPageAccountActivities = React.lazy(() => import('../../application/containers/Page/Account/Activities'));
const LazyPageAccountCustomerOverview = React.lazy(() => import('../../application/containers/Page/Account/CustomerOverview'));
const LazyPageAccountDealerEatLimits = React.lazy(() => import('../../application/containers/Page/Account/DealerEatLimits'));
const LazyPageAccountDealerFights = React.lazy(() => import('../../application/containers/Page/Account/DealerFights'));
const LazyPageAccountDealerProperty = React.lazy(() => import('../../application/containers/Page/Account/DealerProperty'));
const LazyPageAccountDealerTankLimits = React.lazy(() => import('../../application/containers/Page/Account/DealerTankLimits'));
const LazyPageAccountDownlines = React.lazy(() => import('../../application/containers/Page/Account/Downlines'));
const LazyPageAccountGameTypes = React.lazy(() => import('../../application/containers/Page/Account/GameTypes'));
const LazyPageAccountOverview = React.lazy(() => import('../../application/containers/Page/Account/Overview'));
const LazyPageAccountPermissions = React.lazy(() => import('../../application/containers/Page/Account/Permissions'));
const LazyPageAccountPrinters = React.lazy(() => import('../../application/containers/Page/Account/Printers'));
const LazyPageAccountRunnerContracts = React.lazy(() => import('../../application/containers/Page/Account/RunnerContracts'));
const LazyPageAccountRunnerProperty = React.lazy(() => import('../../application/containers/Page/Account/RunnerProperty'));
const LazyPageAccountSessions = React.lazy(() => import('../../application/containers/Page/Account/Sessions'));
const LazyPageAccountTicketAddons = React.lazy(() => import('../../application/containers/Page/Account/TicketAddons'));
const LazyPageAccountTicketConversions = React.lazy(() => import('../../application/containers/Page/Account/TicketConversions'));
const LazyPageAccountTicketHooks = React.lazy(() => import('../../application/containers/Page/Account/TicketHooks'));
const LazyPageAccountTicketNumberPlugins = React.lazy(() => import('../../application/containers/Page/Account/TicketNumberPlugins'));
const LazyPageAccountTicketSchemes = React.lazy(() => import('../../application/containers/Page/Account/TicketSchemes'));
const LazyPageAccountCustomerRequestLimits = React.lazy(() => import('../../application/containers/Page/Account/CustomerRequestLimits'));

const LazyPageAccountWorkers = React.lazy(() => import('../../application/containers/Page/Account/Workers'));
const LazyPageAccountPartners = React.lazy(() => import('../../application/containers/Page/Account/Partners'));
export default {
  children: [
    {
      LazyComponent: LazyPageAccountOverview,
      name: 'overview',
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
        'User',
      ]),
      path: 'overview',
      permissionIdentity: permissionIdentities.GET_ACCOUNT,
      section: '1.0.0',
    },
    {
      LazyComponent: LazyPageAccountActivities,
      isHiddenFromDelegation: true,
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
        'User',
      ]),
      name: 'activities',
      path: 'activities',
      permissionIdentity: permissionIdentities.GET_ACCOUNT_ACTIVITY,
      section: '1.0.1',
    },
    {
      LazyComponent: LazyPageAccountPermissions,
      isHiddenFromDelegation: true,
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
        'User',
      ]),
      name: 'permissions',
      path: 'permissions',
      permissionIdentity: permissionIdentities.GET_ACCOUNT_PERMISSION,
      section: '1.0.2',
    },
    {
      LazyComponent: LazyPageAccountPrinters,
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
      ]),
      name: 'printers',
      path: 'printers',
      permissionIdentity: permissionIdentities.GET_ACCOUNT_PRINTER,
      section: '1.0.3',
    },
    {
      LazyComponent: LazyPageAccountWorkers,
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
      ]),
      name: 'workers',
      path: 'workers',
      permissionIdentity: permissionIdentities.GET_ACCOUNT_WORKER,
      section: '1.0.4',
    },
    {
      LazyComponent: LazyPageAccountSessions,
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
        'User',
      ]),
      isHiddenFromDelegation: true,
      name: 'sessions',
      path: 'sessions',
      permissionIdentity: permissionIdentities.GET_ACCOUNT_SESSION,
      section: '1.0.5',
    },
    {
      LazyComponent: LazyPageAccountCustomerOverview,
      accountTypeSet: new Set([
        'Admin',
        'Super admin',
      ]),
      group: 'customer',
      name: 'overview',
      path: 'customer-overview',
      permissionIdentity: permissionIdentities.GET_CUSTOMER,
      section: '2.0.0',
    },
    {
      LazyComponent: LazyPageAccountGameTypes,
      accountTypeSet: new Set([
        'Admin',
      ]),
      group: 'customer',
      name: 'game-types',
      path: 'customer-game-types',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_SYSTEM_GAME_TYPE,
      section: '2.0.1',
    },
    {
      LazyComponent: LazyPageAccountCustomerRequestLimits,
      accountTypeSet: new Set(['Super admin']),
      group: 'customer',
      name: 'request-limits',
      path: 'request-limits',
      section: '2.0.2',
    },
    {
      LazyComponent: LazyPageAccountCustomerRequestLimits,
      accountTypeSet: new Set(['Super admin']),
      group: 'customer',
      name: 'reward-limits',
      path: 'reward-limits',
      section: '2.0.3',
    },
    {
      LazyComponent: LazyPageAccountPartners,
      accountTypeSet: new Set([
        'Admin',
        'Delegated admin',
        'Super admin',
      ]),
      group: 'customer',
      name: 'partners',
      path: 'customer-partners',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_PARTNER,
      section: '2.0.4',
    },
    {
      LazyComponent: LazyPageAccountDownlines,
      accountTypeSet: new Set([
        'Admin',
        'Super admin',
      ]),
      group: 'customer',
      isHidden: true,
      name: 'downlines',
      path: '',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_DOWNLINE,
      section: '2.0.5',
    },
    {
      LazyComponent: LazyPageAccountDealerProperty,
      accountTypeSet: new Set([
        'Admin',
        'Super admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Dealer',
        'Super dealer',
      ]),
      group: 'dealer',
      name: 'property',
      path: 'dealer-property',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_DEALER_PROPERTY,
      section: '2.1.0',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountDealerFights,
      accountTypeSet: new Set([
        'Admin',
        'Super admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Dealer',
        'Super dealer',
      ]),
      group: 'dealer',
      name: 'fights',
      path: 'dealer-fights',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_DEALER_FIGHT,
      section: '2.1.2',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountDealerEatLimits,
      accountTypeSet: new Set([
        'Admin',
        'Super admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Dealer',
        'Super dealer',
      ]),
      group: 'dealer',
      name: 'eat-limits',
      path: 'dealer-eat-limits',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_DEALER_EAT_LIMIT,
      section: '2.1.3',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountDealerTankLimits,
      accountTypeSet: new Set([
        'Admin',
        'Super admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Dealer',
        'Super dealer',
      ]),
      group: 'dealer',
      name: 'tank-limits',
      path: 'dealer-tank-limits',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_DEALER_TANK_LIMIT,
      section: '2.1.4',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountRunnerProperty,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'runner',
      name: 'property',
      path: 'runner-property',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_PROPERTY,
      section: '2.3.0',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountRunnerContracts,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'runner',
      name: 'contracts',
      path: 'runner-contracts',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_CONTRACT,
      section: '2.3.1',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountTicketConversions,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'ticket',
      name: 'conversions',
      path: 'ticket-conversions',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_TICKET_CONVERSION,
      section: '2.3.2',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountTicketAddons,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'ticket',
      name: 'addons',
      path: 'ticket-addons',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_TICKET_ADDON,
      section: '2.3.3',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountTicketHooks,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'ticket',
      name: 'hooks',
      path: 'ticket-hooks',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_TICKET_HOOK,
      section: '2.3.4',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountTicketSchemes,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'ticket',
      name: 'schemes',
      path: 'ticket-schemes',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_TICKET_SCHEME,
      section: '2.3.5',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountTicketNumberPlugins,
      accountTypeSet: new Set([
        'Admin',
      ]),
      customerPropertyTypeSet: new Set([
        'Agent',
        'Dealer',
        'Runner',
      ]),
      group: 'ticket',
      name: 'plugins',
      path: 'ticket-plugins',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_SYSTEM_TICKET_NUMBER_PLUGIN,
      section: '2.3.6',
      type: 'collapse',
    },
    {
      LazyComponent: LazyPageAccountCustomerOverview,
      accountTypeSet: new Set([
        'Partner',
      ]),
      group: 'partner',
      name: 'overview',
      path: 'partner-overview',
      permissionIdentity: permissionIdentities.GET_CUSTOMER,
      section: '2.4.0',
    },
    {
      LazyComponent: LazyPageAccountGameTypes,
      accountTypeSet: new Set([
        'Partner',
      ]),
      group: 'partner',
      name: 'game-types',
      path: 'partner-game-types',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_SYSTEM_GAME_TYPE,
      section: '2.4.1',
    },
    {
      LazyComponent: LazyPageAccountRunnerContracts,
      accountTypeSet: new Set([
        'Partner',
      ]),
      customerPropertyTypeSet: new Set([
        'Dealer',
      ]),
      group: 'partner',
      name: 'contracts',
      path: 'partner-contracts',
      permissionIdentity: permissionIdentities.GET_CUSTOMER_RUNNER_CONTRACT,
      section: '2.4.2',
    },
  ],
};
