/**
 * Node modules
 */
import Input from 'antd/lib/input';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntGroupInput = (props) => {
  const {
    isCompact,
    ...rest
  } = props;
  return (
    <Input.Group
      compact={isCompact}
      {...rest}
    />
  );
};
VendorAntGroupInput.defaultProps = { isCompact: false };
VendorAntGroupInput.displayName = 'VendorAntGroupInput';
VendorAntGroupInput.propTypes = { isCompact: PropTypes.bool };
export default VendorAntGroupInput;
