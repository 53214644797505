/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntLayout from '../../Vendor/Ant/Layout';

/**
 * Styled components
 */
export default styled(VendorAntLayout.Sidebar)`
  background-color: inherit;
`;
