/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntDrawer from '../../Vendor/Ant/Drawer';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntDrawer)`
  .ant-drawer-body {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-drawer-content {
    background-color: rgba(53, 58, 64, 1);
  }
  .ant-drawer-header {
    align-items: center;
    background-color: rgba(53, 58, 64, 1);
    border-bottom: none;
    display: flex;
    height: ${scale.major(8)}px;
    justify-content: center;
  }
`;
