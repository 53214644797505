/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntBreadcrumb from '../../Vendor/Ant/Breadcrumb';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntBreadcrumb)`
  margin-bottom: ${scale.minor(3)}px;
`;
