/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
 height: 100vh;
  display: flex;
  flex-direction: column;
`;
