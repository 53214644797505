/**
 * Node modules
 */
import React from 'react';

export default React.memo(props => (
  <svg
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>
      Expand
    </title>
    <polyline
      fill="none"
      points="432 320 432 432 320 432"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="421.8"
      x2="304"
      y1="421.77"
      y2="304"
    />
    <polyline
      fill="none"
      points="80 192 80 80 192 80"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="90.2"
      x2="208"
      y1="90.23"
      y2="208"
    />
    <polyline
      fill="none"
      points="320 80 432 80 432 192"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="421.77"
      x2="304"
      y1="90.2"
      y2="208"
    />
    <polyline
      fill="none"
      points="192 432 80 432 80 320"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      x1="90.23"
      x2="208"
      y1="421.8"
      y2="304"
    />
  </svg>
));
