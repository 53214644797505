/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */

export default styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  img {
    border-radius: 8px;
    height: 32px;
    margin: 0;
    width: 32px;
  }
  button {
    padding: 8px;
  }
`;
