/**
 * Node modules
 */
import Form from 'antd/lib/form';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntFormItem = (props) => {
  const {
    children,
    hasColon,
    isRequired,
    ...rest
  } = props;
  return (
    <Form.Item
      colon={hasColon}
      required={isRequired}
      {...rest}
    >
      {children}
    </Form.Item>
  );
};
VendorAntFormItem.defaultProps = {
  hasColon: true,
  isRequired: false,
};
VendorAntFormItem.displayName = 'VendorAntFormItem';
VendorAntFormItem.propTypes = {
  children: PropTypes.node.isRequired,
  hasColon: PropTypes.bool,
  isRequired: PropTypes.bool,
};
export default VendorAntFormItem;
