/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntDropdown from '../../Vendor/Ant/Dropdown';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntDropdown)`
  cursor: pointer;
  font-size: ${scale.major(2)}px;
  padding: 0 ${scale.major(1)}px;
  &:hover {
    background: rgba(16, 112, 202, 1);
    color: white;
  }
`;
