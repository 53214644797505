/**
 * Node modules
 */
import { createSelector } from 'reselect';

/**
 * Functions
 */
const currentAccountIdentityForPermissionSelector = createSelector(
  state => state.reducers.response.getCurrentAccountPermissions,
  (getCurrentAccountPermissionsResponse) => {
    const identityForPermission = {};
    const permissions = getCurrentAccountPermissionsResponse.payload;
    for (let i = 0; i < permissions.length; i += 1) {
      const {
        identity,
        isActivated,
      } = permissions[i];
      if (isActivated) {
        identityForPermission[identity] = permissions[i];
      }
    }
    return identityForPermission;
  },
);

/**
 * Functions
 */
const getCurrentAccountPermissionsRequestSelector = state => state.reducers.request.getCurrentAccountPermissions;
const getCurrentAccountPermissionsResponseSelector = state => state.reducers.response.getCurrentAccountPermissions;
const isGetCurrentAccountPermissionsLoadingSelector = state => state.reducers.isLoading.getCurrentAccountPermissions;
export default {
  currentAccountIdentityForPermissionSelector,
  getCurrentAccountPermissionsRequestSelector,
  getCurrentAccountPermissionsResponseSelector,
  isGetCurrentAccountPermissionsLoadingSelector,
};
