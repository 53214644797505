/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: ${scale.major(2)}px;
  padding-right: ${scale.major(2)}px;
  width: 100%;
  @media screen and (max-width: ${scale.major(124)}px) {
    flex-wrap: wrap;
  }
`;
