/**
 * Node modules
 */
import Layout from 'antd/lib/layout';
import React from 'react';

/**
 * Internals
 */
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

/**
 * Functional components
 */
const VendorAntLayout = props => (<Layout {...props} />);
VendorAntLayout.Footer = Footer;
VendorAntLayout.Header = Header;
VendorAntLayout.Sidebar = Sidebar;
VendorAntLayout.displayName = 'VendorAntLayout';
export default VendorAntLayout;
