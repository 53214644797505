/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.tfoot`
  font-weight: 600;
  > tr {
    td:first-child {
      text-align: center;
    }
  }
`;
