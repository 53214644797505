/**
 * Node modules
 */
import Layout from 'antd/lib/layout';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntLayoutSidebar = (props) => {
  const {
    isCollapsed,
    isCollapsible,
    trigger,
    ...rest
  } = props;
  return (
    <Layout.Sider
      collapsed={isCollapsed}
      collapsible={isCollapsible}
      trigger={trigger}
      {...rest}
    />
  );
};
VendorAntLayoutSidebar.defaultProps = {
  isCollapsed: false,
  isCollapsible: false,
  trigger: null,
};
VendorAntLayoutSidebar.displayName = 'VendorAntLayoutSidebar';
VendorAntLayoutSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  trigger: PropTypes.node,
};
export default VendorAntLayoutSidebar;
