/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: ${scale.major(2)}px;
  height: ${scale.major(8)}px;
  justify-content: center;
  padding: 0 ${scale.minor(3)}px;
  white-space: nowrap;
  &:hover {
    background: rgba(16, 112, 202, 1);
    color: white;
  }
  img {
    background-color: rgba(240, 240, 240, 1);
    border-radius: 50%;
    box-sizing: content-box;
    padding: ${scale.minor(1)}px;
  }
  > span {
    margin-left: ${scale.major(1)}px;
  }
`;
