/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  && {
    width: ${scale.major(40)}px;
  }
  > form {
    width: 100%;
    .ant-form-item {
      margin: 0;
      :first-child {
        .ant-input-affix-wrapper {
          border-radius: ${scale.minor(1)}px ${scale.minor(1)}px 0 0;
          padding: 0;
        }
        .ant-input:hover {
          border-color: rgba(64, 169, 255, 1);
          z-index: 1000;
        }
      }
      :nth-child(2) {
        margin-top: -${scale.minor(0.25)}px;
        .ant-input-affix-wrapper {
          border-radius: 0 0 ${scale.minor(1)}px ${scale.minor(1)}px;
          padding: 0;
        }
      }
      :nth-child(3) {
        margin-top: ${scale.major(2)}px;
      }
      button {
        height: ${scale.major(4)}px;
      }
      input {
        height: ${scale.major(4)}px;
      }
    }
  }
`;
