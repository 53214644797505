/**
 * Node modules
 */
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';
import VendorAntBreadcrumb from '../../Vendor/Ant/Breadcrumb';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitLayoutTitle = (props) => {
  const {
    customerRelationshipsAncestorBundle,
    markers,
  } = props;
  const translate = useTranslate();
  const breadcrumb = React.useMemo(() => {
    const components = [];
    for (let i = 0; i < markers.length; i += 1) {
      const marker = markers[i];
      const {
        isClickable,
        link,
        names,
        relativeCount,
      } = marker;
      const relativePath = _.repeat('../', relativeCount);
      const composedName = (
        <Styled.SpanComposedName>
          {names.map(name => (
            <Styled.Span key={name}>
              {translate(`title.${name}`)}
            </Styled.Span>
          ))}
        </Styled.SpanComposedName>
      );
      components.push((
        <VendorAntBreadcrumb.Item key={link}>
          {isClickable && (
            <Link to={relativePath}>
              {composedName}
            </Link>
          )}
          {!isClickable && (
            <Styled.Span>
              {composedName}
            </Styled.Span>
          )}
        </VendorAntBreadcrumb.Item>
      ));
    }
    return components;
  }, [markers, translate]);
  const title = React.useMemo(() => {
    const lastMarker = markers[markers.length - 1];
    const names = [];
    for (let i = 0; i < lastMarker.names.length; i += 1) {
      names.push(translate(`title.${lastMarker.names[i]}`));
    }
    return names.join(' ');
  }, [markers, translate]);
  const customerRelationshipsAncestor = customerRelationshipsAncestorBundle.response.payload;
  const { descendantUsername } = _.last(customerRelationshipsAncestor) || {};
  return (
    <Styled.DivisionLayoutTitle>
      <Styled.VendorAntBreadcrumb>
        {breadcrumb}
      </Styled.VendorAntBreadcrumb>
      <Styled.SpanPageTitle>
        {title}
      </Styled.SpanPageTitle>
      <Styled.DivisionAncestors>
        {customerRelationshipsAncestor.map((customerRelationshipAncestor) => {
          const { ancestorUsername } = customerRelationshipAncestor;
          return (
            <React.Fragment key={ancestorUsername}>
              <Styled.RouterLink to={['../', ancestorUsername.toLowerCase()].join('')}>
                {ancestorUsername}
              </Styled.RouterLink>
              <Styled.Icon.IonicArrowForward />
            </React.Fragment>
          );
        })}
        {descendantUsername && (
          <Styled.SpanBold>
            {descendantUsername}
          </Styled.SpanBold>
        )}
      </Styled.DivisionAncestors>
    </Styled.DivisionLayoutTitle>
  );
};
BitLayoutTitle.defaultProps = {
  customerRelationshipsAncestorBundle: {
    isFirstBoot: true,
    isLoading: false,
    response: {
      payload: [],
    },
  },
};
BitLayoutTitle.displayName = 'BitLayoutTitle';
BitLayoutTitle.propTypes = {
  customerRelationshipsAncestorBundle: PropTypes.objectOf(PropTypes.any),
  markers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default React.memo(BitLayoutTitle);
