/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { zoom } = props;
  const styles = [];
  if (zoom !== undefined) {
    styles.push(`zoom: ${zoom};`);
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
