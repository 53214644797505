/**
 * Node modules
 */
import React from 'react';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitSuspenseScreen = () => (
  <Styled.DivisionOverlay>
    <Styled.Image
      majorScaledHeight={10}
      majorScaledWidth={10}
      src={images.PageLoader}
    />
  </Styled.DivisionOverlay>
);
BitSuspenseScreen.displayName = 'BitSuspenseScreen';
export default React.memo(BitSuspenseScreen);
