/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  img {
    margin-right: ${scale.major(1)}px;
  }
  span:not(:last-child) {
    margin-right: ${scale.major(1)}px;
  }
  span > small {
    justify-content: center;
  }
  > div {
    width: auto;
  }
`;
