/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.button`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  outline: none;
  transition: all 0.4s ease;
  width: 100%;
`;
