/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    mode,
    state,
  } = props;
  const styles = [];
  switch (state) {
    case 'selected': {
      styles.push('background-color: rgba(249, 240, 255, 1);');
      styles.push('color: rgba(114, 46, 209, 1);');
      break;
    }
    default: {
      styles.push('background-color: rgba(250, 250, 250, 1);');
      styles.push(`
        :hover {
          background-color: rgba(242, 242, 242, 1);
        }
      `);
      break;
    }
  }
  switch (mode) {
    case 'input': {
      styles.push('cursor: pointer;');
      break;
    }
    case 'window': {
      styles.push(`
        > div > div:first-child {
          height: 40px;
          font-weight: bold;
        }
        > div > div:not(:first-child) {
          border-top: 1px solid rgba(240,240,240,1);
        }
      `);
      break;
    }
    default: {
      styles.push(`padding: ${scale.minor(1)}px ${scale.major(2)}px;`);
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  border: ${scale.minor(0.25)}px solid rgba(242, 242, 242, 1);
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  && > span:not(:last-child) {
    margin-right: 0;
  }
`;
