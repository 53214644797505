/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';
import VendorAntSkeleton from '../../Vendor/Ant/Skeleton';

/**
 * Functional components
 */
const BitBoard = (props) => {
  const {
    actions,
    description,
    header,
    isFirstBoot,
    isLoading,
    mode,
  } = props;
  const descriptions = React.useMemo(() => {
    if (_.isArray(description)) {
      const items = [];
      for (let i = 0; i < description.length; i += 1) {
        const {
          title,
          value,
        } = description[i];
        items.push((
          <Styled.DivisionBoardDescriptionItem key={title}>
            <Styled.SpanItemTitle>
              {title}
            </Styled.SpanItemTitle>
            <Styled.Span>
              {value}
            </Styled.Span>
          </Styled.DivisionBoardDescriptionItem>
        ));
      }
      return items;
    }
    return (
      <Styled.DivisionRow>
        <Styled.DivisionBoardDescriptionItem>
          {description}
        </Styled.DivisionBoardDescriptionItem>
      </Styled.DivisionRow>
    );
  }, [description]);
  return (
    <VendorAntSkeleton
      isActive
      isLoading={isFirstBoot}
    >
      <Styled.DivisionBoard mode={mode}>
        <Styled.DivisionBoardContent>
          <Styled.DivisionBoardHeader>
            {header}
          </Styled.DivisionBoardHeader>
          {descriptions}
        </Styled.DivisionBoardContent>
        {!isLoading && actions && (
          <Styled.DivisionBoardControlPanel>
            {actions}
          </Styled.DivisionBoardControlPanel>
        )}
      </Styled.DivisionBoard>
    </VendorAntSkeleton>
  );
};
BitBoard.defaultProps = {
  actions: null,
  isFirstBoot: false,
  isLoading: false,
  mode: 'default',
};
BitBoard.displayName = 'BitBoard';
BitBoard.propTypes = {
  actions: PropTypes.node,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.node.isRequired,
  isFirstBoot: PropTypes.bool,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
};
export default React.memo(BitBoard);
