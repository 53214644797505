/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';
import { VariableSizeGrid } from 'react-window';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitBoard from '../Board';
import BitMask from '../Mask';
import BitPacket from '../Packet';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import VendorAntTooltip from '../../Vendor/Ant/Tooltip';

/**
 * Functional components
 */
const BitPacketBoards = (props) => {
  const {
    setChildrenForActive,
    entries,
    numberLimit = {},
    handleShowRecipes,
    socket,
    systemGameCategoryAlias,
    systemGameCategoryCode,
    setKeyForGrandTotal,
    systemGameCategoryId,
    systemGameCategoryNumberManipulator,
    systemGameCategoryNumberOfDigits,
    totalAmount,
    type,
    totalQuantity,
  } = props;
  const searchInputRef = React.useRef(null);
  const [currentSearchValue, setCurrentSearchValue] = React.useState('');
  const [currentEntries, setCurrentEntries] = React.useState(_.orderBy(entries, ['quantity', 'number'], ['desc', 'asc']));
  const [currentTotalAmount, setCurrentTotalAmount] = React.useState(totalAmount);
  const [currentTotalQuantity, setCurrentTotalQuantity] = React.useState(totalQuantity);
  React.useEffect(() => {
    setCurrentEntries(_.orderBy(entries, ['quantity', 'number'], ['desc', 'asc']));
    setCurrentTotalAmount(totalAmount);
    setCurrentTotalQuantity(totalQuantity);
  }, [entries, totalAmount, totalQuantity]);
  React.useEffect(() => {
    if (setKeyForGrandTotal) {
      setKeyForGrandTotal(previousKeyForGrandTotal => ({
        ...previousKeyForGrandTotal,
        [systemGameCategoryId]: {
          totalAmount: currentTotalAmount,
          totalQuantity: currentTotalQuantity,
        },
      }));
    }
  }, [currentTotalAmount, currentTotalQuantity, setKeyForGrandTotal, systemGameCategoryId]);
  const handleSearchChange = React.useCallback((text) => {
    if (!text) {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
      setCurrentSearchValue('');
    } else {
      if (systemGameCategoryNumberManipulator) {
        setCurrentSearchValue(text.split('').sort().join(''));
      } else {
        setCurrentSearchValue(text);
      }
    }
  }, [systemGameCategoryNumberManipulator]);
  const translate = useTranslate();
  const debouncedSearch = _.debounce(handleSearchChange, 500);
  const debouncedHandleSearchChange = React.useCallback(e => debouncedSearch(e.target.value), [debouncedSearch]);
  const handleSearchClear = React.useCallback(() => handleSearchChange(''), [handleSearchChange]);
  const filteredEntries = React.useMemo(() => {
    const list = [];
    if (currentSearchValue) {
      if (currentSearchValue.startsWith('*')) {
        const finalSearch = currentSearchValue.replace(/\D/g, '').split('').sort().join('');
        for (let i = 0; i < currentEntries.length; i += 1) {
          if (currentEntries[i].number.split("").sort().join('').replace(/\D/g, '').includes(finalSearch)) {
            list.push({
              rank: i + 1,
              ...currentEntries[i],
            });
          }
        }
      } else if (currentSearchValue.startsWith('x')) {
        const searches = new Set();
        for (let i = 0; i <=9;i+=1) {
          searches.add(currentSearchValue.replace(/x/g, String(i)))
        }
        for (let i = 0; i < currentEntries.length; i += 1) {
          if (searches.has(currentEntries[i].number)) {
            list.push({
              rank: i + 1,
              ...currentEntries[i],
            });
          }
        }
      } else {
        for (let i = 0; i < currentEntries.length; i += 1) {
          if (currentEntries[i].number.replace(/\D/g, '').includes(currentSearchValue)) {
            list.push({
              rank: i + 1,
              ...currentEntries[i],
            });
          }
        }
      }
      return list;
    }
    return currentEntries;
  }, [currentSearchValue, currentEntries]);
  const { length } = currentEntries;
  React.useEffect(() => {
    if (setChildrenForActive) {
      setChildrenForActive((previousChildrenForActive) => {
        const cloned = { ...previousChildrenForActive };
        cloned[systemGameCategoryId] = length !== 0;
        return cloned;
      });
    }
  }, [length, setChildrenForActive, systemGameCategoryId]);
  const socketId = socket ? socket.id : '';
  React.useEffect(() => {
    if (socket && socket.id) {
      socket.emit('ready', systemGameCategoryId);
    }
  }, [socket, socketId, systemGameCategoryId]);
  React.useEffect(() => {
    if (socket) {
      socket.on(`data-${systemGameCategoryId}`, (data) => {
        utilities.digestEntries({
          data,
          setCurrentEntries,
          setCurrentTotalAmount,
          setCurrentTotalQuantity,
        });
      });
    }
  }, [socket, systemGameCategoryId]);
  return React.useMemo(() => {
    const windowContent = (
      <Styled.DivisionWindow>
        <Styled.DivisionWindowHeader>
          <Styled.DivisionWindowHeaderLabel mode="rank">
            {translate('title.rank')}
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="number">
            {translate('title.number')}
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="value">
            <Styled.Span>
              {translate('title.value')}
            </Styled.Span>
            <Styled.Image
              minorScaledHeight={5}
              minorScaledWidth={5}
              src={images.Coin}
            />
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="amount">
            <Styled.Span>
              {translate('title.amount')}
            </Styled.Span>
            <Styled.Image
              minorScaledHeight={5}
              minorScaledWidth={5}
              src={images.Baht}
            />
          </Styled.DivisionWindowHeaderLabel>
        </Styled.DivisionWindowHeader>
        <VariableSizeGrid
          columnCount={4}
          columnWidth={columnIndex => [80, 100, 132, 132][columnIndex]}
          height={400}
          rowCount={filteredEntries.length}
          rowHeight={() => 40}
          width={470}
        >
          {(window) => {
            const {
              columnIndex,
              rowIndex,
              style,
            } = window;
            const {
              rank,
              amount,
              number,
              quantity,
            } = filteredEntries[rowIndex];
            let limit = numberLimit['-'];
            if (numberLimit[number] !== undefined) {
              limit = numberLimit[number];
            }
            const filled = limit ? quantity / limit : null;
            const bundle = {
              ...filteredEntries[rowIndex],
              systemGameCategoryAlias,
              systemGameCategoryCode,
              systemGameCategoryId,
              systemGameCategoryNumberOfDigits,
            };
            return (
              <Styled.DivisionWindowRow
                mode={(rowIndex + 1) % 2 === 0 ? 'even' : 'odd'}
                style={style}
              >
                {columnIndex === 0 && (
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {rank || rowIndex + 1}
                    </Styled.Span>
                    {filled !== null && (
                      <Styled.DivisionlFill filled={filled}>
                        {numeral(limit).format('0.00a')}
                      </Styled.DivisionlFill>
                    )}
                  </Styled.DivisionColumn>
                )}
                {columnIndex === 1 && (
                  <Styled.DivisionTicketNumber
                    mode="link"
                    onClick={() => handleShowRecipes(bundle)}
                  >
                    {number}
                  </Styled.DivisionTicketNumber>
                )}
                {columnIndex === 2 && (
                  <Styled.Division>
                    <Styled.SpanRightAlign>
                      {utilities.toLocaleString(quantity, 2)}
                    </Styled.SpanRightAlign>
                  </Styled.Division>
                )}
                {columnIndex === 3 && (
                  <Styled.Division name="division">
                    <Styled.SpanRightAlign>
                      {utilities.toLocaleString(amount, 2)}
                    </Styled.SpanRightAlign>
                  </Styled.Division>
                )}
              </Styled.DivisionWindowRow>
            );
          }}
        </VariableSizeGrid>
        <Styled.DivisionWindowFooter>
          <Styled.Span>
            {`${translate('label.entries')}: ${currentEntries.length.toLocaleString()}`}
          </Styled.Span>
          <Styled.Span>
            {utilities.toLocaleString(currentTotalQuantity, 2)}
          </Styled.Span>
          <Styled.Span>
            {utilities.toLocaleString(currentTotalAmount, 2)}
          </Styled.Span>
        </Styled.DivisionWindowFooter>
      </Styled.DivisionWindow>
    );
    const searchClear = currentSearchValue !== undefined && (
      <Styled.DivisionSearchClear onClick={handleSearchClear}>
        <Styled.Icon.IonicClose />
      </Styled.DivisionSearchClear>
    );
    const title = (
      <Styled.DivisionWindowTitle>
        <Styled.Division>
          {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
        </Styled.Division>
        <Styled.DivisionSearch>
          <Styled.DivisionTableHandlerIcon>
            <Styled.Icon.IonicSearch />
          </Styled.DivisionTableHandlerIcon>
          <Styled.InputSearch
            onChange={debouncedHandleSearchChange}
            placeholder={`${translate('label.placeholder.search')}...`}
            ref={searchInputRef}
          />
          {searchClear}
        </Styled.DivisionSearch>
      </Styled.DivisionWindowTitle>
    );
    return (
      <Styled.DivisionChain mode={currentEntries.length === 0 ? 'hidden' : 'default'}>
        <BitPacket
          mode="window"
          title={title}
          windowContent={windowContent}
        />
      </Styled.DivisionChain>
    );
  }, [type, currentEntries, currentSearchValue, currentTotalAmount, currentTotalQuantity, debouncedHandleSearchChange, filteredEntries, handleSearchClear, handleShowRecipes, numberLimit, systemGameCategoryAlias, systemGameCategoryCode, systemGameCategoryId, systemGameCategoryNumberOfDigits, translate]);
};
BitPacketBoards.displayName = 'BitPacketBoards';
BitPacketBoards.propTypes = {
  contentKey: PropTypes.string,
  dataType: PropTypes.string,
  handleShowRecipes: PropTypes.func,
  isInput: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  maskOptions: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  path: PropTypes.string,
  possibleLinkKeySet: PropTypes.instanceOf(Set),
  recommendedContentKey: PropTypes.string,
  referenceContentKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  symbol: PropTypes.node,
  type: PropTypes.string,
  unit: PropTypes.node,
};
export default React.memo(BitPacketBoards);
