/**
 * Node modules
 */
import Popover from 'antd/lib/popover';
import React from 'react';

/**
 * Functional components
 */
const VendorAntPopover = props => (<Popover {...props} />);
VendorAntPopover.displayName = 'VendorAntPopover';
export default VendorAntPopover;
