/**
 * Node modules
 */
import React from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';

/**
 * Components
 */
import * as Bit from '../../../components/Bit';

/**
 * Helpers
 */
import {
  Contexts,
  hooks,
} from '../../../../helpers';

/**
 * Redux
 */
import { selectors } from '../../../../redux';

/**
 * Constant variables
 */
const { createAccountSessionResponseSelector } = selectors;

/**
 * Function hooks
 */
const useAuthorizedAccountQueriedBundle = props => hooks.useQueriedBundle({
  actionType: 'AUTHORIZE_ACCOUNT',
  body: {
    bearerToken: props.bearerToken,
  },
  condition: resource => !resource.isAuthorized,
});

/**
 * Lazy components
 */
const LazyBootstrapAuthorizedEntry = React.lazy(() => import('../AuthorizedEntry'));
const LazyPageDesktopLogin = React.lazy(() => import('../../Page/Desktop/Login'));

/**
 * Functional components
 */
const BootstrapPublicEntry = () => {
  const createAccountSessionResponse = useSelector(state => createAccountSessionResponseSelector(state));
  const {
    metadata,
    payload,
  } = createAccountSessionResponse;
  const authorizedAccountQueriedBundle = useAuthorizedAccountQueriedBundle({ bearerToken: payload.token ? `Bearer ${payload.token}` : '' });
  hooks.useRedirectToLogin({ token: payload.token });
  hooks.useRedirectToBefore({ status: metadata.status });
  return (
    <Contexts.LoginBundle.Provider value={authorizedAccountQueriedBundle}>
      <Contexts.Token.Provider value={payload.token}>
        <React.Suspense fallback={(<Bit.SuspenseScreen />)}>
          <Router
            component="section"
            id="bootstrap-public-entry"
            primary={false}
          >
            {!payload.token && (<LazyPageDesktopLogin path="/login" />)}
            { authorizedAccountQueriedBundle.response.payload.objectId && <LazyBootstrapAuthorizedEntry default />}
          </Router>
        </React.Suspense>
      </Contexts.Token.Provider>
    </Contexts.LoginBundle.Provider>
  );
};
BootstrapPublicEntry.displayName = 'BootstrapPublicEntry';
export default React.memo(BootstrapPublicEntry);
