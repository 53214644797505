/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${scale.major(-1)}px;
  margin-top: ${scale.major(1)}px;
  width: 100%;
  > div {
    margin-top: ${scale.major(1)}px;
  }
`;
