/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

export default styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:nth-child(1) {
    margin-top: 24px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    width: 100%;
  }

  > div:nth-child(2) {
    margin: 12px;
    //flex: 1;
  }

  > div:nth-child(4) {
    > .ant-card-body {
      > div:nth-child(1) {
        font-size: ${scale.major(2)}px;
        font-weight: bold;
        > span {
          height: 64px;
        }
        border-bottom: 1px solid #f0f0f0;
      }
      > div:nth-child(2) {
        height: 100vh;
        overflow: auto;
        > div {
          padding: 8px;
        }
      }
      display: flex;
      flex-direction: column;
    }
    margin: 12px;
    min-width: 320px;
  }

  > div:nth-child(3) {
    > .ant-card-body > div:nth-child(1) {
      //height: 64px;
      //display: flex;
      //align-items: center;
      //border-bottom: 1px solid #f0f0f0;
    }
    margin: 12px;
    min-width: 450px;
  }
`;
