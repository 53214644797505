/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitField = (props) => {
  const {
    index,
    name,
    setFieldValue,
    value,
  } = props;
  const [text, setText] = React.useState(value);
  const handleChange = React.useCallback((e) => {
    setText(e.target.value);
    setFieldValue(e, name, index);
  }, [index, name, setFieldValue]);
  return (
    <Styled.Input
      onChange={handleChange}
      type="text"
      value={text}
    />
  );
};
BitField.defaultProps = { value: '' };
BitField.displayName = 'BitField';
BitField.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};
export default React.memo(BitField);
