/**
 * Node modules
 */
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

/**
 * Clients
 */
import clients from '../../../../clients';

/**
 * Internals
 */
import actions from './actions';

/**
 * Private modules
 */
import constants from '../../constants';

/**
 * Functions
 */
const requestCreateAccountSession = function* (action) {
  const { data } = action;
  try {
    yield put(actions.creators.createAccountSessionRequest(null));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-create-account-session'], data);
    yield put(actions.creators.createAccountSessionSuccess({
      metadata,
      payload,
    }));
    localStorage.setItem(constants.localStorageTokenKey, payload.token);
  } catch (error) {
    yield put(actions.creators.createAccountSessionFail({ metadata: error.metadata || {} }));
  } finally {
    yield put(actions.creators.createAccountSessionFulfill(null));
  }
};
const watchRequestCreateAccountSession = function* () {
  yield takeEvery(actions.types.CREATE_ACCOUNT_SESSION_TRIGGER, requestCreateAccountSession);
};
export default { watchRequestCreateAccountSession };
