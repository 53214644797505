/**
 * Betlytical modules
 */
import { routes } from '@betlytical/api-schema';

export default [
  ...routes.drawSystemGameTypeInvoices,
  ...routes.drawSystemGameTypeReports,
  ...routes.drawSystemGameTypeResults,
  ...routes.drawSystemGameTypes,
  ...routes.draws,
];
