/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

export default styled.div`
  align-items: center;
  border-bottom: solid ${scale.minor(0.25)}px rgba(242, 242, 242, 1);
  display: flex;
  font-weight: bold;
  height: ${scale.major(9)}px;
`;
