/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'downline': {
      styles.push('background-color: rgba(46, 204, 113, 0.3);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'self': {
      styles.push('background-color: rgba(241, 196, 15, 0.3);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'upline': {
      styles.push('background-color: rgba(155, 89, 182, 0.3);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    default: {
      styles.push('background-color: rgba(52, 73, 94, 1);');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      styles.push('color: white;');
      break;
    }
  }
  return styles.join('');
};
export default styled.th`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  height: ${scale.minor(9)}px;
  padding: ${scale.major(1)}px;
  text-align: center;
`;
