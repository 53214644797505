/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { Contexts } from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitTableHandler = (props) => {
  const {
    choices = [],
    field,
    position,
    hasSearcher,
    hasSorter,
    title,
  } = props;
  const translate = useTranslate();
  const searchInputRef = React.useRef(null);
  const {
    limit,
    params,
    search,
    setInput,
    setKeyset,
    setSearch,
    setSort,
    setStack,
    sort,
    template,
  } = React.useContext(Contexts.Table);
  const handleSorterClick = React.useCallback(() => {
    const input = {
      query: {
        keyset: {},
        limit,
        search: { ...search },
      },
    };
    if (!sort[field]) {
      input.query.sort = { [field]: 'asc' };
      setSort(input.query.sort);
    } else if (sort[field] === 'asc') {
      input.query.sort = { [field]: 'desc' };
      setSort(input.query.sort);
    } else if (sort[field] === 'desc') {
      input.query.sort = { [field]: 'asc' };
      setSort(input.query.sort);
    } else {
      delete input.query.sort;
    }
    if (params) {
      input.params = params;
    }
    setInput((previousInput) => {
      const { by } = previousInput;
      return {
        by,
        ...input,
      };
    });
    setStack([]);
    setKeyset({});
  }, [field, limit, params, search, setInput, setKeyset, setSort, setStack, sort]);
  const handleSearchChange = React.useCallback((text, type) => {
    const input = {
      query: {
        keyset: {},
        limit,
        search: { ...search },
        sort: { ...sort },
      },
    };
    if (text === undefined) {
      delete input.query.search[field];
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
      setSearch({
        ...input.query.search,
        [field]: undefined,
      });
    } else if (_.isBoolean(text)) {
      input.query.search = {
        ...input.query.search,
        [field]: text,
      };
      setSearch(input.query.search);
    } else {
      if (type === 'radio') {
        input.query.search = {
          ...input.query.search,
          [field]: text,
        };
      } else {
        input.query.search = {
          ...input.query.search,
          [field]: (template[field] || '%#%').replace('#', text.replace(/%/g, '/%')),
        };
      }
      setSearch(input.query.search);
    }
    if (params) {
      input.params = params;
    }
    setInput((previousInput) => {
      const { by } = previousInput;
      return {
        by,
        ...input,
      };
    });
    setStack([]);
    setKeyset({});
  }, [field, limit, params, search, setInput, setKeyset, setSearch, setStack, sort, template]);
  const debouncedSearch = _.debounce(handleSearchChange, 500);
  const debouncedHandleSearchChange = React.useCallback(e => debouncedSearch(e.target.value), [debouncedSearch]);
  const handleRadioChange = React.useCallback(e => handleSearchChange(e.target.value, 'radio'), [handleSearchChange]);
  const handleSearchClear = React.useCallback(() => handleSearchChange(), [handleSearchChange]);
  const searchValue = React.useMemo(() => {
    if (choices.length > 0) {
      for (let i = 0; i < choices.length; i += 1) {
        if (choices[i].value === search[field]) {
          return choices[i].name;
        }
      }
    }
    return search[field];
  }, [choices, field, search]);
  const popoverContent = (
    <Styled.VendorAntGroupRadio
      onChange={handleRadioChange}
      value={search[field]}
    >
      {choices.map(choice => (
        <Styled.VendorAntRadio
          key={choice.value}
          value={choice.value}
        >
          {choice.name}
        </Styled.VendorAntRadio>
      ))}
    </Styled.VendorAntGroupRadio>
  );
  const [sortField, sortDirection] = Object.entries(sort)[0];
  const searchClear = searchValue !== undefined && (
    <Styled.DivisionSearchClear onClick={handleSearchClear}>
      <Styled.Icon.IonicClose />
    </Styled.DivisionSearchClear>
  );
  return (
    <Styled.DivisionTableHeaderColumn>
      {hasSearcher && choices.length === 0 && (
        <Styled.DivisionSearch>
          <Styled.DivisionTableHandlerIcon>
            <Styled.Icon.IonicSearch />
          </Styled.DivisionTableHandlerIcon>
          <Styled.InputSearch
            onChange={debouncedHandleSearchChange}
            placeholder={`${translate('label.placeholder.search')}...`}
            ref={searchInputRef}
          />
          {searchClear}
        </Styled.DivisionSearch>
      )}
      {hasSearcher && choices.length > 0 && (
        <Styled.DivisionFilter>
          <Styled.VendorAntPopover
            content={popoverContent}
            placement="bottomLeft"
            trigger="click"
          >
            <Styled.DivisionTableHandlerIcon>
              <Styled.Icon.IonicFunnel />
            </Styled.DivisionTableHandlerIcon>
            {searchValue !== undefined && (
              <Styled.Small name="small">
                {searchValue}
              </Styled.Small>
            )}
          </Styled.VendorAntPopover>
          {searchClear}
        </Styled.DivisionFilter>
      )}
      {hasSorter && (
        <Styled.DivisionSorter
          mode={hasSearcher ? 'share' : 'default'}
          onClick={handleSorterClick}
        >
          <Styled.Span>
            {title}
          </Styled.Span>
          <Styled.DivisionTableHandlerCaret>
            <Styled.Icon.IonicCaretUp
              mode={sortField === field && sortDirection === 'asc' ? 'selected' : 'default'}
              size={3}
            />
            <Styled.Icon.IonicCaretDown
              mode={sortField === field && sortDirection === 'desc' ? 'selected' : 'default'}
              size={3}
            />
          </Styled.DivisionTableHandlerCaret>
        </Styled.DivisionSorter>
      )}
      {!hasSorter && position === 'center' && (
        <Styled.Division>
          {title}
        </Styled.Division>
      )}
      {!hasSorter && position !== 'center' && (
        <Styled.DivisionTableHeader>
          <Styled.Span>
            {title}
          </Styled.Span>
        </Styled.DivisionTableHeader>
      )}
    </Styled.DivisionTableHeaderColumn>
  );
};
BitTableHandler.defaultProps = {
  choices: [],
  hasSearcher: true,
  hasSorter: true,
};
BitTableHandler.displayName = 'BitTableHandler';
BitTableHandler.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.any),
  field: PropTypes.string.isRequired,
  hasSearcher: PropTypes.bool,
  hasSorter: PropTypes.bool,
  title: PropTypes.node.isRequired,
};
export default React.memo(BitTableHandler);
