/**
 * Node modules
 */
import React from 'react';

/**
 * Lazy components
 */
const LazyPagePlayATypeTH = React.lazy(() => import('../../application/containers/Page/Play/ATypeTH'));
const LazyPagePlayATypeTHX = React.lazy(() => import('../../application/containers/Page/Play/ATypeTHX'));
const LazyPagePlayBTypeTH = React.lazy(() => import('../../application/containers/Page/Play/BTypeTH'));
const LazyPagePlayCTypeTH = React.lazy(() => import('../../application/containers/Page/Play/CTypeTH'));
const LazyPagePlayCTypeTHX = React.lazy(() => import('../../application/containers/Page/Play/CTypeTHX'));
export default {
  children: [
    {
      LazyComponent: LazyPagePlayATypeTH,
      name: 'A-type-TH',
      path: 'a-type-th',
      section: '1.0.0',
      systemGameSet: new Set([
        'TH',
      ]),
    },
    {
      LazyComponent: LazyPagePlayBTypeTH,
      name: 'B-type-TH',
      path: 'b-type-th',
      section: '1.0.1',
      systemGameSet: new Set([
        'TH',
      ]),
    },
    {
      LazyComponent: LazyPagePlayCTypeTH,
      name: 'C-type-TH',
      path: 'c-type-th',
      section: '1.0.2',
      systemGameSet: new Set([
        'TH',
      ]),
    },
    {
      LazyComponent: LazyPagePlayATypeTHX,
      name: 'A-type-THX',
      path: 'a-type-th-x',
      section: '1.0.3',
      systemGameSet: new Set([
        'TH',
      ]),
    },
    {
      LazyComponent: LazyPagePlayCTypeTHX,
      name: 'C-type-THX',
      path: 'c-type-th-x',
      section: '1.0.4',
      systemGameSet: new Set([
        'TH',
      ]),
    },
  ],
};
