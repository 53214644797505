/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'amount': {
      styles.push(`
        input {
          text-align: right;
          width: ${scale.major(15)}px;
        }
      `);
      styles.push(`width: 220px;`);
      break;
    }
    case 'number': {
      styles.push(`
        input {
          width: ${scale.major(20)}px;
        }
      `);
      break;
    }
    case 'schemes': {
      styles.push(`height: ${scale.major(8)}px;`);
      styles.push(`
        input {
          text-align: right;
          width: ${scale.major(18)}px;
        }
      `);
      break;
    }
    default: {
      styles.push(`
        input[type=text] {
          width: ${scale.major(18)}px;
        }
      `);
      break;
    }
  }
  return styles.join('');
};

export default styled.td`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  border: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 0.5);
  padding: ${scale.minor(1)}px;
  span.ant-checkbox-inner {
    margin-left: 0; 
  }
  
  > div {
    flex-wrap: wrap;
    > small {
      align-items: center;
      display: flex;
      justify-content: center;
      width: ${scale.major(12)}px;
    }
  }
  .ant-select {
    width: 100%;
  }
`;
