/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import BitBoard from '../Board';
import BitLiveBoard from '../LiveBoard';
import BitPacket from '../Packet';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { hooks } from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitPacketBoards = (props) => {
  const {
    socket,
    forwards,
    setKeyForGrandTotal,
    stepGateMap,
    handleShowRecipes,
    customerRequestLimitstags = [],
    systemGameCategoryIdForNumberLimit,
    systemGamePrizeIdForNumberLimit,
    list,
    type,
  } = props;
  const translate = useTranslate();
  const sectionBundle = hooks.useSectionBundle({
    list,
    type,
  });
  const {
    keyForElements,
    keys,
  } = sectionBundle;
  return React.useMemo(() => {
    const components = [];
    const modelAndPrizeForEntries = {};
    for (let i = 0; i < customerRequestLimitstags.length; i += 1) {
      const customerRequestLimitstag = customerRequestLimitstags[i];
      const {
        affectedNumber,
        multiplier,
        systemGameCategoryAlias,
        systemGameCategoryCode,
        systemGamePrizeId,
      } = customerRequestLimitstag;
      const indiccatore = [affectedNumber, systemGamePrizeId].join('#').replace("???",'');
      if (!modelAndPrizeForEntries[indiccatore]) {
        modelAndPrizeForEntries[indiccatore] = [];
      }
      modelAndPrizeForEntries[indiccatore].push({
        affectedNumber,
        multiplier,
        systemGameCategoryAlias,
        systemGameCategoryCode,
        systemGamePrizeId,
      });
    }
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const elements = keyForElements[key];
      const items = [];
      for (let j = 0; j <= elements.length; j += 1) {
        const element = elements[j];
        if (element) {
          const {
            systemGameCategoryId,
            systemGamePrizeId,
          } = element;
          let numberLimit = {};
          let key = systemGameCategoryId;
          if (type === 'prize-volume') {
            key = systemGamePrizeId;
            // for (let k = 0; k < element.entries.length; k += 1) {
            //   const elementEntry = element.entries[k];
            //   const { number } = elementEntry;
            //   const ind = [number, systemGamePrizeId].join('#').replace('???', '');
            //
            //   if (modelAndPrizeForEntries[ind]) {
            //     element.entries[k].tags = modelAndPrizeForEntries[ind];
            //
            //   }
            // }
            numberLimit = systemGamePrizeIdForNumberLimit[systemGamePrizeId];
          } else if (type === 'category-volume') {
            key = systemGameCategoryId;
            numberLimit = systemGameCategoryIdForNumberLimit[systemGameCategoryId];
          }
          items.push({
            handleShowRecipes,
            numberLimit,
            key,
            socket,
            ...element,
          });
        }
      }
      let label = '';
      if (type === 'category-volume' || type === 'overall-volume' || type.startsWith('report') || type === 'request-volume') {
        if (key === '-') {
          label = translate('display.type.Manipulated');
        } else {
          label = translate(`display.type.${key}-Digit`);
        }
      } else if (type === 'prize-volume') {
        label = translate(`display.alias.${key}`);
      }
      components.push((
        <BitLiveBoard
          forwards={forwards}
          stepGateMap={stepGateMap}
          modelAndPrizeForEntries={modelAndPrizeForEntries}
          setKeyForGrandTotal={setKeyForGrandTotal}
          items={items}
          key={key}
          label={label}
          type={type}
        />
      ));
    }
    return components;
  }, [forwards, customerRequestLimitstags, handleShowRecipes, keyForElements, keys, setKeyForGrandTotal, socket, systemGameCategoryIdForNumberLimit, systemGamePrizeIdForNumberLimit, translate, type]);
};
BitPacketBoards.defaultProps = {
  contentKey: '',
  dataType: 'number',
  handleShowRecipes: _.noop(),
  isInput: false,
  maskOptions: {},
  mode: 'default',
  path: '',
  possibleLinkKeySet: new Set(),
  recommendedContentKey: '',
  referenceContentKey: '',
  setFieldValue: _.noop(),
  symbol: null,
  type: '',
  unit: null,
};
BitPacketBoards.displayName = 'BitPacketBoards';
BitPacketBoards.propTypes = {
  contentKey: PropTypes.string,
  dataType: PropTypes.string,
  handleShowRecipes: PropTypes.func,
  isInput: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  maskOptions: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  path: PropTypes.string,
  possibleLinkKeySet: PropTypes.instanceOf(Set),
  recommendedContentKey: PropTypes.string,
  referenceContentKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  symbol: PropTypes.node,
  type: PropTypes.string,
  unit: PropTypes.node,
};
export default React.memo(BitPacketBoards);
