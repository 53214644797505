/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import { VariableSizeGrid } from 'react-window';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitBoard from '../Board';
import BitMask from '../Mask';
import BitPacket from '../Packet';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitPacketBoards = (props) => {
  const {
    contentKey,
    dataType,
    isInput,
    list,
    maskOptions,
    mode,
    path,
    possibleLinkKeySet,
    recommendedContentKey,
    referenceContentKey,
    setFieldValue,
    symbol,
    type,
    unit,
  } = props;
  const translate = useTranslate();
  const [selectedChainSet, setSelectedChainSet] = React.useState(new Set());
  const [selectedLinkKey, setSelectedLinkKey] = React.useState(contentKey);
  const handleFocus = React.useCallback((name) => {
    if (name) {
      const chunks = name.split('.');
      setSelectedLinkKey(chunks[chunks.length - 1]);
    }
  }, []);
  const handleOnClick = React.useCallback((set, element) => {
    const clonedSet = new Set(set);
    if (clonedSet.has(element)) {
      setSelectedChainSet(new Set());
    } else {
      clonedSet.add(element);
      setSelectedChainSet(clonedSet);
    }
  }, []);
  const nameForRef = React.useMemo(() => {
    const map = {};
    if (!isInput) {
      return map;
    }
    for (let i = 0; i < list.length; i += 1) {
      const possibleLinkKeys = [...possibleLinkKeySet];
      for (let j = 0; j < possibleLinkKeys.length; j += 1) {
        const name = [path, i, possibleLinkKeys[j]].join('.');
        map[name] = React.createRef();
      }
    }
    return map;
  }, [isInput, list, path, possibleLinkKeySet]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const previousList = hooks.usePrevious(list) || [];
  const sectionBundle = hooks.useSectionBundle({
    list,
    type,
  });
  React.useEffect(() => {
    if (list.length !== previousList.length) {
      setSelectedChainSet(new Set());
    }
  }, [list, previousList]);
  const {
    keyForElements,
    keys,
  } = sectionBundle;
  return React.useMemo(() => {
    const components = [];
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const elements = keyForElements[key];
      let previousSection = '0.0.0';
      const chains = [];
      const items = [];
      const names = [];
      const codeSet = new Set();
      for (let j = 0; j <= elements.length; j += 1) {
        const element = elements[j] || { section: '...' };
        const {
          index,
          objectId,
        } = element;
        const resolvedSection = utilities.getResolvedValue(element, 'section');
        const resolvedAlias = utilities.getResolvedValue(element, 'alias');
        const resolvedCode = utilities.getResolvedValue(element, 'code');
        const resolvedNumberOfDigits = utilities.getResolvedValue(element, 'numberOfDigits');
        const previousMinor = previousSection.split('.')[1];
        const minor = resolvedSection.split('.')[1];
        if (minor !== previousMinor || minor === undefined || type === 'report-amount' || type === 'report-value' || type === 'payout') {
          const packets = [];
          const chainId = names.join('#');
          const onClick = () => handleOnClick(selectedChainSet, chainId);
          const state = selectedChainSet.has(chainId) ? 'selected' : 'default';
          for (let k = 0; k < items.length; k += 1) {
            const item = items[k];
            const packet = (
              <BitPacket
                isInput={isInput}
                key={item.objectId || item.title}
                onClick={onClick}
                state={state}
                {...item}
              />
            );
            if (type === 'payout') {
              if (!codeSet.has(item.title)) {
                codeSet.add(item.title);
                packets.push(packet);
              }
            } else {
              packets.push(packet);
            }
          }
          if (packets.length > 0) {
            const chain = (
              <Styled.DivisionChain key={chainId}>
                {packets}
              </Styled.DivisionChain>
            );
            chains.push(chain);
          }
          items.length = 0;
          names.length = 0;
        }
        if (elements[j]) {
          names.push([path, index, '?'].join('.'));
          const composedContentKey = [path, index, contentKey].join('.');
          const composedRecommendedContentKey = [path, index, recommendedContentKey].join('.');
          const composedReferenceContentKey = [path, index, referenceContentKey].join('.');
          const content = (!_.isNil(element[contentKey]) || type === 'result') && (
            <BitMask
              dataType={dataType}
              isInput={isInput && possibleLinkKeySet.has(contentKey)}
              key={composedContentKey}
              mode={mode}
              name={composedContentKey}
              nameForRef={nameForRef}
              onFocus={handleFocus}
              options={type === 'result' ? masks.createTicketNumber([...new Array(resolvedNumberOfDigits)].map(() => '.').join('#')) : maskOptions}
              selectedChainSet={selectedChainSet}
              selectedLinkKey={selectedLinkKey}
              setFieldValue={setFieldValue}
              value={element[contentKey] === null ? '' : element[contentKey]}
            />
          );
          const recommendedContent = !_.isNil(element[recommendedContentKey]) && (
            <BitMask
              dataType={dataType}
              isInput={isInput && possibleLinkKeySet.has(recommendedContentKey)}
              key={composedRecommendedContentKey}
              mode={mode}
              name={composedRecommendedContentKey}
              nameForRef={nameForRef}
              onFocus={handleFocus}
              options={type === 'result' ? masks.createTicketNumber([...new Array(resolvedNumberOfDigits)].map(() => '.').join('#')) : maskOptions}
              selectedChainSet={selectedChainSet}
              selectedLinkKey={selectedLinkKey}
              setFieldValue={setFieldValue}
              value={element[recommendedContentKey]}
            />
          );
          const referenceContent = !_.isNil(element[referenceContentKey]) && (
            <BitMask
              dataType={dataType}
              isInput={isInput && possibleLinkKeySet.has(referenceContentKey)}
              key={composedReferenceContentKey}
              mode={mode}
              name={composedReferenceContentKey}
              nameForRef={nameForRef}
              onFocus={handleFocus}
              options={type === 'result' ? masks.createTicketNumber([...new Array(resolvedNumberOfDigits)].map(() => '.').join('#')) : maskOptions}
              selectedChainSet={selectedChainSet}
              selectedLinkKey={selectedLinkKey}
              setFieldValue={setFieldValue}
              value={element[referenceContentKey]}
            />
          );
          if (type === 'prize' || type === 'result') {
            items.push({
              content,
              objectId,
              recommendedContent,
              referenceContent,
              title: translate(`display.alias.${resolvedAlias}`),
            });
          } else {
            items.push({
              content,
              objectId,
              recommendedContent,
              referenceContent,
              title: translate(`display.alias.${resolvedCode}-${resolvedAlias}`),
            });
          }
          previousSection = resolvedSection;
        }
      }
      let label = '';
      if (type === 'category' || type.startsWith('report')) {
        if (key === '-') {
          label = translate('display.type.Manipulated');
        } else {
          label = translate(`display.type.${key}-Digit`);
        }
      } else if (type === 'prize' || type === 'result') {
        label = translate(`display.alias.${key}`);
      } else if (type === 'payout') {
        const nameSet = new Set();
        const payoutElements = keyForElements[key];
        for (let j = 0; j < payoutElements.length; j += 1) {
          const payoutElement = payoutElements[j];
          const { systemGamePrizeAlias } = payoutElement;
          const alias = _.head(systemGamePrizeAlias.split(' '));
          nameSet.add(alias);
        }
        label = [...nameSet].map(name => translate(`display.alias.${name}`)).join(' & ');
      }
      const header = (
        <Styled.DivisionRow>
          {symbol}
          <Styled.Span>
            {label}
          </Styled.Span>
          <Styled.Span>
            {unit}
          </Styled.Span>
        </Styled.DivisionRow>
      );
      const description = (
        <Styled.DivisionWrap majorScaledOffset={2}>
          {chains}
        </Styled.DivisionWrap>
      );
      components.push((
        <BitBoard
          description={description}
          header={header}
          key={key}
          mode={mode}
        />
      ));
    }
    return components;
  }, [contentKey, dataType, handleFocus, handleOnClick, isInput, keyForElements, keys, maskOptions, mode, nameForRef, path, possibleLinkKeySet, recommendedContentKey, referenceContentKey, selectedChainSet, selectedLinkKey, setFieldValue, symbol, translate, type, unit]);
};
BitPacketBoards.defaultProps = {
  contentKey: '',
  dataType: 'number',
  handleShowRecipes: _.noop(),
  isInput: false,
  maskOptions: {},
  mode: 'default',
  path: '',
  possibleLinkKeySet: new Set(),
  recommendedContentKey: '',
  referenceContentKey: '',
  setFieldValue: _.noop(),
  symbol: null,
  type: '',
  unit: null,
};
BitPacketBoards.displayName = 'BitPacketBoards';
BitPacketBoards.propTypes = {
  contentKey: PropTypes.string,
  dataType: PropTypes.string,
  handleShowRecipes: PropTypes.func,
  isInput: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  maskOptions: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  path: PropTypes.string,
  possibleLinkKeySet: PropTypes.instanceOf(Set),
  recommendedContentKey: PropTypes.string,
  referenceContentKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  symbol: PropTypes.node,
  type: PropTypes.string,
  unit: PropTypes.node,
};
export default React.memo(BitPacketBoards);
