/**
 * Node modules
 */
import Checkbox from 'antd/lib/checkbox';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntCheckbox = (props) => {
  const {
    isChecked,
    isDisabled,
    isIndeterminate,
    ...rest
  } = props;
  return (
    <Checkbox
      checked={isChecked}
      disabled={isDisabled}
      indeterminate={isIndeterminate}
      {...rest}
    />
  );
};
VendorAntCheckbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  isIndeterminate: false,
};
VendorAntCheckbox.displayName = 'VendorAntCheckbox';
VendorAntCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
};
export default VendorAntCheckbox;
