/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  margin-top: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  > div {
    background-color: rgba(241, 196, 15, 0.1);
    padding: 8px;
    border-radius: 8px;
    width: 228px;
    > small {
      white-space: break-spaces;
    }
  }
`;
