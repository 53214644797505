/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import utilities from '../../../../helpers/utilities';

/**
 * Functional components
 */
const BitEmptySpace = (props) => {
  const { type } = props;
  const translate = useTranslate();
  if (type === 'office') {
    return (
      <Styled.DivisionEmptySpace>
        <Styled.Image src={images.OfficeLanding} />
        <Styled.Span>
          {translate('label.there-are-no-games')}
        </Styled.Span>
      </Styled.DivisionEmptySpace>
    );
  }
  if (type === 'calendar') {
    return (
      <Styled.DivisionEmptySpace>
        <Styled.Image src={images.CalendarLanding} />
        <Styled.Span>
          {translate('label.please-choose-a-game')}
        </Styled.Span>
      </Styled.DivisionEmptySpace>
    );
  }
  if (type === 'empty') {
    return (
      <Styled.DivisionEmptySpace>
        <Styled.Image
          majorScaledHeight={30}
          majorScaledWidth={30}
          src={images.Empty}
        />
        <Styled.Span>
          {translate('label.there-are-nothing-to-display')}
        </Styled.Span>
      </Styled.DivisionEmptySpace>
    );
  }
  return null;
};
BitEmptySpace.displayName = 'BitEmptySpace';
BitEmptySpace.propTypes = { type: PropTypes.string.isRequired };
export default React.memo(BitEmptySpace);
