/**
 * Node modules
 */
import _ from 'lodash';
import { createSelector } from 'reselect';

/**
 * Functions
 */
const getDrawSystemGameTypeAteVolumeByCustomerIdRequestSelector = createSelector(
  state => state.reducers.request.getDrawSystemGameTypeAteVolumeByCustomerId,
  object => _.memoize((by) => {
    const request = {
      count: 0,
      status: 'READY',
    };
    if (by !== undefined && object[by]) {
      request.count = object[by].count || 0;
      request.status = object[by].status || 'READY';
    }
    return request;
  }),
);

const getDrawSystemGameTypeAteVolumeByCustomerIdResponseSelector = createSelector(
  state => state.reducers.response.getDrawSystemGameTypeAteVolumeByCustomerId,
  object => _.memoize((by) => {
    const response = {
      headers: {},
      metadata: {},
      payload: {},
    };
    if (by !== undefined && object[by]) {
      response.headers = object[by].headers || {};
      response.metadata = object[by].metadata || {};
      response.payload = object[by].payload || {};
    }
    return response;
  }),
);
const getDrawSystemGameTypeOverallVolumeByCustomerIdResponseSelector = createSelector(
  state => state.reducers.response.getDrawSystemGameTypeOverallVolumeByCustomerId,
  object => _.memoize((by) => {
    const response = {
      headers: {},
      metadata: {},
      payload: {},
    };
    if (by !== undefined && object[by]) {
      response.headers = object[by].headers || {};
      response.metadata = object[by].metadata || {};
      response.payload = object[by].payload || {};
    }
    return response;
  }),
);
const getDrawSystemGameTypeOverallVolumeByCustomerIdRequestSelector = createSelector(
  state => state.reducers.request.getDrawSystemGameTypeOverallVolumeByCustomerId,
  object => _.memoize((by) => {
    const request = {
      count: 0,
      status: 'READY',
    };
    if (by !== undefined && object[by]) {
      request.count = object[by].count || 0;
      request.status = object[by].status || 'READY';
    }
    return request;
  }),
);
const getDrawSystemGameTypeTankedVolumeByCustomerIdRequestSelector = createSelector(
  state => state.reducers.request.getDrawSystemGameTypeTankedVolumeByCustomerId,
  object => _.memoize((by) => {
    const request = {
      count: 0,
      status: 'READY',
    };
    if (by !== undefined && object[by]) {
      request.count = object[by].count || 0;
      request.status = object[by].status || 'READY';
    }
    return request;
  }),
);
const getDrawSystemGameTypeTankedVolumeByCustomerIdResponseSelector = createSelector(
  state => state.reducers.response.getDrawSystemGameTypeTankedVolumeByCustomerId,
  object => _.memoize((by) => {
    const response = {
      headers: {},
      metadata: {},
      payload: {},
    };
    if (by !== undefined && object[by]) {
      response.headers = object[by].headers || {};
      response.metadata = object[by].metadata || {};
      response.payload = object[by].payload || {};
    }
    return response;
  }),
);
const isGetDrawSystemGameTypeAteVolumeByCustomerIdLoadingSelector = createSelector(
  state => state.reducers.isLoading.getDrawSystemGameTypeAteVolumeByCustomerId,
  object => _.memoize(by => (by !== undefined && object[by]) || false),
);
const isGetDrawSystemGameTypeOverallVolumeByCustomerIdLoadingSelector = createSelector(
  state => state.reducers.isLoading.getDrawSystemGameTypeOverallVolumeByCustomerId,
  object => _.memoize(by => (by !== undefined && object[by]) || false),
);
const isGetDrawSystemGameTypeTankedVolumeByCustomerIdLoadingSelector = createSelector(
  state => state.reducers.isLoading.getDrawSystemGameTypeTankedVolumeByCustomerId,
  object => _.memoize(by => (by !== undefined && object[by]) || false),
);
export default {
  getDrawSystemGameTypeAteVolumeByCustomerIdRequestSelector,
  getDrawSystemGameTypeAteVolumeByCustomerIdResponseSelector,
  getDrawSystemGameTypeOverallVolumeByCustomerIdRequestSelector,
  getDrawSystemGameTypeOverallVolumeByCustomerIdResponseSelector,
  getDrawSystemGameTypeTankedVolumeByCustomerIdRequestSelector,
  getDrawSystemGameTypeTankedVolumeByCustomerIdResponseSelector,
  isGetDrawSystemGameTypeAteVolumeByCustomerIdLoadingSelector,
  isGetDrawSystemGameTypeOverallVolumeByCustomerIdLoadingSelector,
  isGetDrawSystemGameTypeTankedVolumeByCustomerIdLoadingSelector,
};
