/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntGroup from '../../Vendor/Ant/Group';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntGroup.Radio)`
  padding: ${scale.major(2)}px;
`;
