/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  height: 100%;
  .ant-tabs-nav-container {
    height: 100%;
  }
`;
