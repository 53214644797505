/**
 * Node modules
 */
import { IonAlert } from '@ionic/react';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';

/**
 * Components
 */
import * as Styled from '../../../components/Styled';
import VendorAntButton from '../../../components/Vendor/Ant/Button';
import VendorAntCheckbox from '../../../components/Vendor/Ant/Checkbox';
import VendorAntDialog from '../../../components/Vendor/Ant/Dialog';
import VendorAntForm from '../../../components/Vendor/Ant/Form';
import VendorAntGroup from '../../../components/Vendor/Ant/Group';
import VendorAntInput from '../../../components/Vendor/Ant/Input';
import VendorAntInputTextArea from '../../../components/Vendor/Ant/Input/TextArea';
import VendorAntRadio from '../../../components/Vendor/Ant/Radio';

/**
 * Helpers
 */
import {
  Contexts,
  hooks,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Private modules
 */
import {
  generateInitialValues,
  validationSchemas,
  validator,
} from './.private';
import utilities from '../../../../helpers/utilities';
import Toaster from '../../../components/Vendor/Ant/Toaster';

/**
 * Functional components
 */
const AdvancedForm = (props) => {
  const {
    as,
    context,
    fields,
    hasConfirmation,
    isDirty,
    isIgnoringSuccess,
    isReinitializing,
    exportExcel,
    isResettingForm,
    sharePayloadFormat,
    width,
  } = props;
  const {
    actionType,
    by,
    permissionIdentity,
  } = context;
  const [hasReconfirmed, setHasReconfirmed] = React.useState(false);
  const action = _.camelCase(actionType);
  const translate = useTranslate();
  const device = React.useContext(Contexts.Device);
  const actionBundle = hooks.useActionBundle({
    actionType,
    by,
    permissionIdentity,
  });
  const {
    dispatch,
    handleHideDialog,
    handleShowDialog,
    isActionLoading,
    isDialogShown,
    isPermitted,
    request,
    response,
  } = actionBundle;
  const handleCancelDialog = React.useCallback(() => {
    setHasReconfirmed(false);
    handleHideDialog();
  }, [setHasReconfirmed, handleHideDialog]);
  const onSubmit = React.useCallback((values) => {
    dispatch(values);
  }, [dispatch]);
  const validate = React.useCallback((values) => {
    const errors = {};
    const process = validator.compile(validationSchemas[action]);
    const isValid = process(values);
    if (!isValid) {
      return {};
    }
    return errors;
  }, [action]);
  const initialValues = React.useMemo(() => generateInitialValues(fields), [fields]);
  const formik = useFormik({
    enableReinitialize: isReinitializing,
    initialValues,
    onSubmit,
    validate,
  });
  const {
    dirty: isFormDirty,
    handleSubmit,
    isSubmitting,
    isValid: isFormValid,
    resetForm,
    setSubmitting,
    values,
  } = formik;
  hooks.useFeedback({
    exportExcel,
    handleHideDialog,
    isIgnoringSuccess,
    resetForm: isResettingForm ? resetForm : undefined,
    response,
    setHasReconfirmed,
    setSubmitting,
    sharePayloadFormat,
  });
  const { payload, metadata } = response;
  const handleReconfirm = React.useCallback(() => {
    utilities.webShare({ ...payload, format: sharePayloadFormat }).then(() => {
      Toaster.success(translate(metadata.message));
    });
    setHasReconfirmed(false);
  }, [metadata.message, payload, sharePayloadFormat, translate]);
  const isFormReady = request.status === 'READY';
  const isSubmittingForm = isSubmitting || isActionLoading;
  const isFormLoading = isSubmittingForm || isActionLoading;
  const isFormDisabled = !(isFormDirty || isDirty) || !isFormValid || !isFormReady || isFormLoading;
  const title = translate(`title.${_.kebabCase(actionType.replace(/_/g, '.').toLowerCase().split('.by')[0])}`);
  const submitLabel = translate(`label.${_.kebabCase(_.first(_.words(actionType)))}`);
  const items = React.useMemo(() => {
    const components = [];
    for (let i = 0; i < fields.length; i += 1) {
      const {
        hasLabel,
        isRequired,
        metadata = {},
        path = '',
        name: providedName,
        render,
        shouldDisplay = () => true,
        type,
      } = fields[i];
      const name = providedName || _.last(path.split('.'));
      const formItemProps = {
        isRequired,
        key: path || 'key',
        label: hasLabel ? translate(`label.${_.kebabCase(name)}`) : null,
      };
      const value = _.get(values, path);
      const handleChange = metadata.handleChange ? (...args) => metadata.handleChange(...args, formik) : formik.handleChange(path);
      const handleBlur = metadata.handleBlur ? (...args) => metadata.handleBlur(...args, formik) : formik.handleBlur(path);
      let placeholder = null;
      if (metadata.hasPlaceholder) {
        if (metadata.isSelectPlaceHolder) {
          placeholder = `${translate('label.placeholder.select')}...`;
        } else {
          placeholder = translate(`label.placeholder.${_.kebabCase(name)}`);
        }
      }
      metadata.generateProps = metadata.generateProps || (() => ({}));
      metadata.props = metadata.props || {};
      if (shouldDisplay(values) && device === 'desktop') {
        switch (type) {
          case 'checkbox': {
            components.push((
              <VendorAntForm.Item {...formItemProps}>
                <VendorAntCheckbox
                  isChecked={value}
                  name={path}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={value}
                  {...metadata.generateProps(formik)}
                  {...metadata.props}
                >
                  {translate(`label.${_.kebabCase(name)}`)}
                </VendorAntCheckbox>
              </VendorAntForm.Item>
            ));
            break;
          }
          case 'custom': {
            components.push(render(formik));
            break;
          }
          case 'text': {
            components.push((
              <VendorAntForm.Item {...formItemProps}>
                <VendorAntInput
                  autoComplete="off"
                  name={path}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  type="text"
                  value={value}
                  {...metadata.generateProps(formik)}
                  {...metadata.props}
                />
              </VendorAntForm.Item>
            ));
            break;
          }
          case 'text-area': {
            components.push((
              <VendorAntForm.Item {...formItemProps}>
                <VendorAntInputTextArea
                  name={path}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  value={value}
                  {...metadata.generateProps(formik)}
                  {...metadata.props}
                />
              </VendorAntForm.Item>
            ));
            break;
          }
          case 'password': {
            components.push((
              <VendorAntForm.Item {...formItemProps}>
                <VendorAntInput
                  autoComplete="off"
                  name={path}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={placeholder}
                  type="password"
                  value={value}
                  {...metadata.generateProps(formik)}
                  {...metadata.props}
                />
              </VendorAntForm.Item>
            ));
            break;
          }
          case 'radio': {
            const { options } = metadata;
            components.push((
              <VendorAntForm.Item {...formItemProps}>
                <VendorAntGroup.Radio
                  key={path}
                  name={path}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={value}
                  {...metadata.generateProps(formik)}
                  {...metadata.props}
                >
                  {options.map(option => (
                    <VendorAntRadio
                      key={option}
                      value={option}
                    >
                      {translate(`display.${name}.${option}`)}
                    </VendorAntRadio>
                  ))}
                </VendorAntGroup.Radio>
              </VendorAntForm.Item>
            ));
            break;
          }
          case 'submit': {
            components.push((
              <VendorAntForm.Item key={path}>
                <VendorAntButton
                  appearance="primary"
                  htmlType="submit"
                  isDisabled={isFormDisabled}
                  isLoading={isFormLoading}
                  {...metadata.generateProps(formik)}
                  {...metadata.props}
                >
                  {metadata.submitLabel || submitLabel}
                </VendorAntButton>
              </VendorAntForm.Item>
            ));
            break;
          }
          default:
            if (_.isObject(type)) {
              const Component = type;
              components.push((
                <VendorAntForm.Item {...formItemProps}>
                  <Component
                    autoComplete="off"
                    key={path}
                    name={path}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={value}
                    {...metadata.generateProps(formik)}
                    {...metadata.props}
                  />
                </VendorAntForm.Item>
              ));
            }
            break;
        }
      }
    }
    return components;
  }, [device, fields, formik, isFormDisabled, isFormLoading, submitLabel, translate, values]);
  if (!isPermitted) {
    return null;
  }
  if (device === 'mobile') {
    const buttons = [
      {
        role: 'cancel',
        text: translate('label.cancel'),
      },
      {
        handler: hasReconfirmed ? handleReconfirm : handleSubmit,
        text: translate('label.okay'),
      },
    ];
    const isNodeMode = React.isValidElement(as);
    return (
      <React.Fragment>
        {!_.isFunction(as) && !isNodeMode && (
          <VendorAntButton
            isLoading={isFormLoading}
            onClick={hasConfirmation ? handleShowDialog : handleSubmit}
          >
            {as}
          </VendorAntButton>
        )}
        {!_.isFunction(as) && isNodeMode && (
          <VendorAntButton
            icon={as}
            isLoading={isFormLoading}
            onClick={hasConfirmation ? handleShowDialog : handleSubmit}
          />
        )}
        <IonAlert
          buttons={buttons}
          header={title}
          isOpen={isDialogShown || hasReconfirmed}
          message={`${translate('label.are-you-sure')}?`}
          onDidDismiss={hasReconfirmed ? handleCancelDialog : handleHideDialog}
        />
      </React.Fragment>
    );
  }
  const dialog = as ? {
    as,
    handleHideDialog,
    handleShowDialog,
    handleSubmit,
    isDialogShown,
    isFormDisabled,
    isFormLoading,
    submitLabel,
    width,
  } : null;
  const children = items.length > 0 && (
    <VendorAntForm onSubmit={handleSubmit}>
      {items}
    </VendorAntForm>
  );
  const isDialogMode = !_.isEmpty(dialog);
  if (!isDialogMode) {
    return children;
  }
  const isNodeMode = React.isValidElement(as);
  return (
    <React.Fragment>
      {!_.isFunction(as) && !isNodeMode && (
        <Styled.VendorAntButton
          isLoading={isFormLoading}
          onClick={hasConfirmation ? handleShowDialog : handleSubmit}
        >
          {as}
        </Styled.VendorAntButton>
      )}
      {!_.isFunction(as) && isNodeMode && (
        <Styled.VendorAntButton
          icon={as}
          isLoading={isFormLoading}
          onClick={hasConfirmation ? handleShowDialog : handleSubmit}
          shape="circle"
        />
      )}
      {_.isFunction(as) && as({
        isLoading: isFormLoading,
        onClick: hasConfirmation ? handleShowDialog : handleSubmit,
      })}
      <VendorAntDialog
        cancelLabel={translate('label.cancel')}
        confirmLabel={children ? submitLabel : translate('label.confirm')}
        hasClose={false}
        isConfirmDisabled={isFormDisabled}
        isConfirmLoading={isFormLoading}
        isShown={isDialogShown}
        onCancel={handleHideDialog}
        onConfirm={handleSubmit}
        title={title}
        width={width}
      >
        {children || `${translate('label.are-you-sure')}?`}
      </VendorAntDialog>
    </React.Fragment>
  );
};
AdvancedForm.defaultProps = {
  as: null,
  isDirty: false,
  isIgnoringSuccess: false,
  isReinitializing: true,
  isResettingForm: false,
  hasConfirmation: true,
  width: 520,
};
AdvancedForm.displayName = 'AdvancedForm';
AdvancedForm.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  isDirty: PropTypes.bool,
  isIgnoringSuccess: PropTypes.bool,
  isReinitializing: PropTypes.bool,
  isResettingForm: PropTypes.bool,
  width: PropTypes.number,
};
export default React.memo(AdvancedForm);
