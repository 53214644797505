/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorCollapse from '../../Vendor/Ant/Collapse';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorCollapse)`
  .ant-collapse-item .ant-collapse-header {
    align-items: center;
    background-color: white;
    display: flex;
    font-size: ${scale.major(2)}px;
    font-weight: bold;
    height: ${scale.major(7)}px;
    padding-left: ${scale.major(3)}px;
  }
`;
