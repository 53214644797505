/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'display': {
      styles.push('color: rgba(231, 76, 60,1.0);');
      break;
    }
    case 'link': {
      styles.push('color: rgba(52, 152, 219, 1);');
      styles.push('cursor: pointer;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  letter-spacing: ${scale.major(1)}px;
  text-indent: 8px;
  width: 100%;
`;
