/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3); /* Black background with opacity */
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  > div {
    > img {
      margin-left: 0;
    }
    > span {
      margin-left: ${scale.major(1)}px;
    }
    background: rgba(249, 249, 249, 1);
    border-radius: ${scale.major(1)}px;
    font-size: ${scale.major(2)}px;
    padding: ${scale.minor(5)}px;
    width: auto;
  }
`;
