/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  width: 100%;
  > a:first-child, > div:first-child {
    border-top: ${scale.minor(0.25)}px solid rgba(225, 228, 232, 1);
  }
`;
