/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */

export default styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  padding-top: 16px;
  padding-bottom: 8px;
`;
