/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.table`
  border-collapse: collapse;
  border: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 0.5);
`;
