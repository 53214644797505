/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.input.attrs({ className: 'ant-input' })`
  min-width: ${scale.major(10)}px;
`;
