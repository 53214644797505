/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import { images } from '../../../../assets';
import { useTranslate } from '../../../../locales';
import BitCard from '../Card';
import * as Styled from '../../Styled';
import { utilities } from '../../../../helpers';

/**
 * Functional components
 */
const BitBoard = (props) => {
  const {
    systemGameTypeAlias,
    downlines,
    model,
  } = props;
  const logo = systemGameTypeAlias.replace(/\s/g, '');
  const translate = useTranslate();
  return (
    <BitCard
      isBordered
      logo={logo}
      state="normal"
      title={translate(`display.alias.${systemGameTypeAlias}`)}
    >
      <Styled.TablePortal>
        <Styled.TableHeader>
          <Styled.TableRow>
            <Styled.TableHeadingPortal>
              {translate('title.username')}
            </Styled.TableHeadingPortal>
            <Styled.TableHeadingPortal>
              {translate('title.nickname')}
            </Styled.TableHeadingPortal>
            <Styled.TableHeadingPortal>
              {translate('title.base-percent')}
            </Styled.TableHeadingPortal>
            <Styled.TableHeadingPortal>
              {translate('title.offset-percent')}
            </Styled.TableHeadingPortal>
            <Styled.TableHeadingPortal>
              {translate('title.calculations')}
            </Styled.TableHeadingPortal>
            <Styled.TableHeadingPortal>
              {translate('title.limits')}
            </Styled.TableHeadingPortal>
          </Styled.TableRow>
        </Styled.TableHeader>
        <Styled.TableBodyPortal>
          {_.sortBy(downlines, 'username').map((downline, i) => {
            const {
              username,
              nickname,
              basePercent,
              offsetPercent,
              bundle,
            } = downline;
            return (
              <Styled.TableRow key={username}>
                <Styled.TableDataStrike>
                  {username}
                </Styled.TableDataStrike>
                <Styled.TableDataStrike>
                  {nickname}
                </Styled.TableDataStrike>
                <Styled.TableDataStrike>
                  <Styled.DivisionAmount>
                  {utilities.toLocaleString(basePercent, 2)}
                  </Styled.DivisionAmount>
                </Styled.TableDataStrike>
                <Styled.TableDataStrike>
                  {utilities.toLocaleString(offsetPercent, 2)}
                </Styled.TableDataStrike>
                <Styled.TableDataStrike>
                  {bundle.contributions.map(contribution => {

                    return (
                      <Styled.Division>
                        {`${contribution.date}: ${utilities.toLocaleString(contribution.expectedRetainedAmountPercent *100, 10)}%`}
                      </Styled.Division>
                    );
                  })}
                  <Styled.Division>
                    ------
                  </Styled.Division>
                  <Styled.Division>
                    {bundle.percent}%
                  </Styled.Division>
                </Styled.TableDataStrike>

                <Styled.TableDataStrike>
                  {_.sortBy(bundle.results, 'section').map(result => (
                    <Styled.Division>
                      {`${result.model}  x${result.multiplier} ${result.systemGameCategoryAlias}: ${utilities.toLocaleString(result.amount, 2, 'THB')}`}
                    </Styled.Division>
                  ))}
                </Styled.TableDataStrike>
              </Styled.TableRow>
            );
          })}
        </Styled.TableBodyPortal>
      </Styled.TablePortal>
    </BitCard>
  );
};
BitBoard.defaultProps = {
  actions: null,
  isFirstBoot: false,
  isLoading: false,
  mode: 'default',
};
BitBoard.displayName = 'BitBoard';
BitBoard.propTypes = {
  actions: PropTypes.node,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.node.isRequired,
  isFirstBoot: PropTypes.bool,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
};
export default React.memo(BitBoard);
