/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'embedded': {
      styles.push(`padding: ${scale.major(3)}px 0;`);
      break;
    }
    case 'hidden': {
      styles.push('height: 0;');
      styles.push('visibility:hidden;');
      styles.push('width: 0;');
      break;
    }
    default: {
      styles.push(`padding: ${scale.major(2)}px ${scale.major(3)}px;`);
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  display: flex;
  :not(:last-child) {
    border-bottom: ${scale.minor(0.25)}px solid rgba(240, 240, 240, 1);
  }
`;
