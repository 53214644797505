/**
 * Node modules
 */
import styled from 'styled-components';

import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  > div:first-child {
    align-items: center;
    background-color: rgba(52, 73, 94,1.0);
    border-top-left-radius: ${scale.major(1)}px;
    border-top-right-radius: ${scale.major(1)}px;
    color: white;
    display: flex;
    height: ${scale.major(6)}px;
    justify-content: space-between;
    padding: ${scale.major(1)}px ${scale.major(2)}px;
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
      width: auto;
      > img {
        border-radius: 50%;
        height: ${scale.major(3)}px;
        margin-left: 0;
        margin-right: ${scale.major(1)}px;
        width: ${scale.major(3)}px;
      }
    }
  }
  > div:nth-child(n + 2) {
    border-bottom: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 1);
    padding: ${scale.major(1)}px;
    > div:first-child {
      height: ${scale.major(4)}px;
    }
    > div:not(:first-child) {
      align-items: center;
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;
