/**
 * Node modules
 */
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

/**
 * Clients
 */
import clients from '../../../../clients';

/**
 * Internals
 */
import actions from './actions';

/**
 * Functions
 */
const requestGetCurrentAccountPermissions = function* (action) {
  const { data } = action;
  try {
    yield put(actions.creators.getCurrentAccountPermissionsRequest(null));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-get-account-permissions'], data);
    yield put(actions.creators.getCurrentAccountPermissionsSuccess({
      metadata,
      payload,
    }));
  } catch (error) {
    yield put(actions.creators.getCurrentAccountPermissionsFail({ metadata: error.metadata }));
  } finally {
    yield put(actions.creators.getCurrentAccountPermissionsFulfill(null));
  }
};
const watchRequestGetCurrentAccountPermissions = function* () {
  yield takeEvery(actions.types.GET_CURRENT_ACCOUNT_PERMISSIONS_TRIGGER, requestGetCurrentAccountPermissions);
};
export default { watchRequestGetCurrentAccountPermissions };
