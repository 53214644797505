/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.section`
  margin-bottom: ${scale.minor(2)}px;
  padding: ${scale.major(1)}px 0;
`;
