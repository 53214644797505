/**
 * Node modules
 */
import React from 'react';

/**
 * Internals
 */
import Input from './Input';
import Radio from './Radio';

/**
 * Functional components
 */
const VendorAntGroup = props => <div {...props} />;
VendorAntGroup.Input = Input;
VendorAntGroup.Radio = Radio;
VendorAntGroup.displayName = 'VendorAntGroup';
export default VendorAntGroup;
