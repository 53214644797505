/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    mode,
    type,
  } = props;
  const styles = [];
  switch (mode) {
    case 'approved': {
      styles.push('color: rgba(46, 204, 113, 1);');
      break;
    }
    case 'bypassed': {
      styles.push('color: rgba(52, 152, 219, 1.0);');
      break;
    }
    case 'edited': {
      styles.push('color: rgba(142, 68, 173, 1.0);');
      break;
    }
    case 'canceled': {
      styles.push('color: rgba(231, 76, 60, 1);');
      break;
    }
    case 'rejected': {
      styles.push('color: rgba(231, 76, 60, 1);');
      break;
    }
    case 'corrupted': {
      styles.push('color: rgba(231, 76, 60, 1);');
      break;
    }
    case 'errored': {
      styles.push('color: rgba(231, 76, 60, 1);');
      break;
    }
    case 'warned': {
      styles.push('color: rgba(241, 196, 15, 1);');
      break;
    }
    default: {
      break;
    }
  }
  switch (type) {
    case 'additional': {
      styles.push('font-size:24px;');
      styles.push('font-weight: normal;');
      styles.push('margin-left: 8px;');
      break;
    }
    default: {
      styles.push('font-size:32px;');
      styles.push('font-weight: bolder;');
      styles.push('flex-direction: column;');
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  justify-content: center;
  > small {
  font-size: 12px;
  margin-bottom: -8px;
  font-weight: normal;
    width: auto;
    color:#bdc3c7;
  }
`;
