/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  > small {
    width: auto;
    font-size: ${scale.minor(3)}px;
    margin-bottom: -8px;
  }
  > span {
    ::before {
      margin-right: ${scale.minor(1)}px;
    }
    font-size: ${scale.major(4)}px;
    font-weight: 800;
  }
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -24px;
  padding: ${scale.major(2)}px ${scale.major(3)}px;
  width: 100%;
`;
