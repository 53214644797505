/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: ${scale.major(10)}px 0;
  width: 100%;
  img {
    width: ${scale.major(60)}px;
  }
  > span {
    margin-top: ${scale.minor(5)}px;
    font-size: ${scale.minor(4)}px;
    font-style: italic;
  }
`;
