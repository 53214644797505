/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.16);
  display: flex;
  height: ${scale.major(6)}px;
  padding: ${scale.major(2)}px;
  > img {
    border-radius: 50%;
    height: ${scale.major(3)}px;
    margin-right: ${scale.major(1)}px;
    width: ${scale.major(3)}px;
  }
`;
