/**
 * Node modules
 */
import Menu from 'antd/lib/menu';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntMenuItem = (props) => {
  const {
    isDisabled,
    ...rest
  } = props;
  return (
    <Menu.Item
      disabled={isDisabled}
      {...rest}
    />
  );
};
VendorAntMenuItem.defaultProps = { isDisabled: false };
VendorAntMenuItem.displayName = 'VendorAntMenuItem';
VendorAntMenuItem.propTypes = { isDisabled: PropTypes.bool };
export default VendorAntMenuItem;
