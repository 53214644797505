/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'amount': {
      break;
    }
    case 'function': {
      styles.push(`min-width: ${scale.minor(19)}px;`);
      break;
    }
    case 'number': {
      styles.push(`min-width: ${scale.minor(40)}px;`);
      break;
    }
    case 'rank': {
      styles.push(`min-width: ${scale.minor(13)}px;`);
      break;
    }
    case 'selections': {
      styles.push(`min-width: ${scale.major(15)}px;`);
      break;
    }
    case 'schemes': {
      styles.push(`min-width: ${scale.major(0)}px;`);
      styles.push(`width: ${scale.major(128)}px;`);
      break;
    }

    case 'amount-x': {
      break;
    }
    case 'function-x': {
      styles.push(`min-width: ${scale.minor(4)}px;`);
      break;
    }
    case 'number-x': {
      styles.push(`min-width: ${scale.minor(25)}px;`);
      break;
    }
    case 'rank-x': {
      styles.push(`min-width: ${scale.minor(13)}px;`);
      break;
    }
    case 'selections-x': {
      styles.push(`min-width: ${scale.major(15)}px;`);
      break;
    }
    case 'schemes-x': {
      styles.push(`min-width: ${scale.major(0)}px;`);
      styles.push(`width: ${scale.major(128)}px;`);
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.th`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  height: ${scale.minor(12)}px;
  padding: ${scale.minor(1)}px;
`;
