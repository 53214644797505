/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitSideMenu = (props) => {
  const {
    processedSections,
    selectedKey,
  } = props;
  const translate = useTranslate();
  const components = [];
  for (let i = 0; i < processedSections.length; i += 1) {
    const section = processedSections[i];
    const {
      header,
      links,
    } = section;
    const items = [];
    for (let j = 0; j < links.length; j += 1) {
      const {
        key,
        name,
        panels,
        path,
        type,
      } = links[j];
      if (type === 'collapse') {
        items.push((
          <Styled.VendorCollapse
            isAccordion
            key={key}
          >
            {panels.map(panel => (
              <Styled.VendorCollapsePanel
                header={translate(`title.${panel.name}`)}
                key={panel.key}
              >
                {panel.links.map(link => (
                  <Styled.RouterLinkMenu
                    key={link.key}
                    state={selectedKey === link.path ? 'selected' : 'default'}
                    to={link.path}
                  >
                    {translate(`title.${link.name}`)}
                  </Styled.RouterLinkMenu>
                ))}
              </Styled.VendorCollapsePanel>
            ))}
          </Styled.VendorCollapse>
        ));
      } else {
        items.push((
          <Styled.RouterLinkMenu
            key={key}
            state={selectedKey === path ? 'selected' : 'default'}
            to={path}
          >
            {translate(`title.${name}`)}
          </Styled.RouterLinkMenu>
        ));
      }
    }
    components.push((
      <Styled.SectionSideMenu key={section.key}>
        {header && (
          <Styled.DivisionSideMenuHeader>
            {translate(`title.${header}`)}
          </Styled.DivisionSideMenuHeader>
        )}
        <Styled.DivisionSideMenuBody>
          {items}
        </Styled.DivisionSideMenuBody>
      </Styled.SectionSideMenu>
    ));
  }
  return components;
};
BitSideMenu.displayName = 'BitSideMenu';
BitSideMenu.propTypes = {
  processedSections: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedKey: PropTypes.string.isRequired,
};
export default React.memo(BitSideMenu);
