/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'icon': {
      styles.push('justify-content: center;');
      styles.push(`
        :not(:first-child) {
          margin-left: ${scale.major(4)}px;
        }
      `);
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  > .ant-tag {
    margin-top: ${scale.major(1)}px;
  }
  > small {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  > div:nth-child(2) {
    height: 56px;
  }
`;
