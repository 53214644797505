/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -8px;
  margin-top: -8px;
  width: 100%;
  > * {
    margin-left: 8px;
    margin-top: 8px;
  }
`;
