/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.section`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
