/**
 * Configurations
 */
import { app } from '../../../config';

/**
 * Constant variables
 */
const localStorageTokenKey = `${app.SITE_ID}#token`;
export default { localStorageTokenKey };
