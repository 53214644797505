/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${scale.major(30)}px;
  justify-content: center;
  width: 100%;
  .ant-select {
    width: 80%;
  }
  .ant-select-selection-item {
    display: flex;
    justify-content: center;
  }
`;
