/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitLayoutLogin = (props) => {
  const { children } = props;
  const translate = useTranslate();
  return (
    <Styled.DivisionLogin>
      <Styled.ImageLogin src={images.App} />
      <Styled.SpanLoginTitle>
        {translate('label.please-sign-in')}
      </Styled.SpanLoginTitle>
      {children}
    </Styled.DivisionLogin>
  );
};
BitLayoutLogin.displayName = 'BitLayoutLogin';
BitLayoutLogin.propTypes = { children: PropTypes.node.isRequired };
export default React.memo(BitLayoutLogin);
