/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */
export default styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
`;
