/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import Spin from 'antd/lib/spin';

/**
 * Functional components
 */
const VendorAntSpin = (props) => {
  const {
    isSpinning,
    ...rest
  } = props;
  return (
    <Spin
      spinning={isSpinning}
      {...rest}
    />
  );
};
VendorAntSpin.defaultProps = { isSpinning: true };
VendorAntSpin.displayName = 'VendorAntSpin';
VendorAntSpin.propTypes = { isSpinning: PropTypes.bool };
export default VendorAntSpin;
