/**
 * Node modules
 */
import React from 'react';
import { format } from 'date-fns';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { hooks } from '../../../../helpers';

/**
 * Functional components
 */
const BitClock = () => {
  const systemTime = hooks.useSystemTime();
  return (
    <Styled.DivisionClock>
      {systemTime ? format(new Date(Number(systemTime)), 'hh:mm:ss a') : ''}
    </Styled.DivisionClock>
  );
};
BitClock.displayName = 'BitClock';
export default React.memo(BitClock);
