/**
 * Node modules
 */
import Card from 'antd/lib/card';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntCard = (props) => {
  const {
    isBordered,
    isLoading,
    ...rest
  } = props;
  return (
    <Card
      bordered={isBordered}
      loading={isLoading}
      {...rest}
    />
  );
};
VendorAntCard.defaultProps = {
  isBordered: false,
  isLoading: false,
};
VendorAntCard.displayName = 'VendorAntCard';
VendorAntCard.propTypes = {
  isBordered: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default VendorAntCard;
