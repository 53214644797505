/**
 * Node modules
 */
import socketIO from 'socket.io-client';

/**
 * Configurations
 */
import { app } from '../config';

export default (input) => {
  const {
    namespace = '/',
    room,
    token,
  } = input;
  const bearerToken = token ? `Bearer ${token}` : undefined;
  const auth = {};
  if (room) {
    auth.room = room;
  }
  if (bearerToken) {
    auth.bearerToken = bearerToken;
  }
  return socketIO(`${app.SOCKET_API_PROTOCOL}://${app.SOCKET_API_HOST}:${app.SOCKET_API_PORT}`.concat(namespace), {
    allowUpgrades: false,
    auth,
    path: '/socket.io',
    secure: true,
    transports: ['websocket'],
  });
};
