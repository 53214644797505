/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.img`
  height: ${scale.major(10)}px;
  margin-bottom: ${scale.minor(5)}px;
  width: ${scale.major(10)}px;
`;
