/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functions
 */
const BitTerminalLine = React.forwardRef((props, ref) => {
  const {
    customerRunnerTicketScheme,
    index,
    isPC,
    isFocused,
    setPlayCode,
    text,
  } = props;
  const translate = useTranslate();
  const handleClick = React.useCallback(() => {
    setPlayCode(state => ({
      ...state,
      index,
    }));
    window.navigator.vibrate(5);
  }, [index, setPlayCode]);
  const tokens = React.useMemo(() => {
    const components = [];
    if (text.length === 0) {
      components.push((<Styled.SpanTerminalToken key={_.uniqueId()} />));
    }
    const isStartedWithBoxed = text.startsWith('*');
    let hashtagIndex = 0;
    let mode = 'default';
    const numberOfDigits = text.split('#')[0].replace(/\*/g, '').length;
    const { entries = [] } = customerRunnerTicketScheme;
    const validCustomerRunnerTicketSchemes = [];
    for (let i = 0; i < entries.length; i += 1) {
      const {
        systemGameCategoryNumberManipulator,
        systemGameCategoryNumberOfDigits,
      } = entries[i];
      if (!(isStartedWithBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits) {
        validCustomerRunnerTicketSchemes.push(entries[i]);
      }
    }
    for (let i = 0; i < text.length; i += 1) {
      const character = text[i];
      if (character === '*' && (i === 0 || text[i - 1] === '#')) {
        mode = 'special';
      } else if ((/\d/).test(character) && hashtagIndex === 0) {
        mode = 'number';
      } else if ((/\d/).test(character) && hashtagIndex > 0) {
        mode = 'amount';
      } else if (character === '*') {
        mode = 'end';
      } else if (character === '#') {
        mode = 'hash';
      }
      if (character === '#') {
        if (hashtagIndex < validCustomerRunnerTicketSchemes.length) {
          const {
            objectId,
            systemGameCategoryAlias,
            systemGameCategoryCode,
            systemGameCategoryNumberManipulator,
          } = validCustomerRunnerTicketSchemes[hashtagIndex];
          let color = '';
          if (systemGameCategoryNumberManipulator) {
            color = '#e67e22';
          } else {
            color = '#27ae60';
          }
          components.push((
            <Styled.SpanTerminalTag
              color={color}
              key={objectId}
            >
              {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
            </Styled.SpanTerminalTag>
          ));
        } else {
          components.push((
            <Styled.SpanTerminalTag key={_.uniqueId()}>
              {translate('label.error')}
            </Styled.SpanTerminalTag>
          ));
        }
        hashtagIndex += 1;
      } else {
        components.push((
          <Styled.SpanTerminalToken
            key={_.uniqueId()}
            mode={mode}
          >
            {character}
          </Styled.SpanTerminalToken>
        ));
      }
    }
    if (isFocused) {
      components.push(
        <Styled.SpanTerminalToken key={_.uniqueId()}>
          <Styled.DivisionTerminalCursor />
        </Styled.SpanTerminalToken>,
      );
    }
    return components;
  }, [isFocused, customerRunnerTicketScheme, text, translate]);
  return (
    <Styled.DivisionTerminalLine
      type={isPC? 'pc':'def'}
      mode={isFocused ? 'selected' : 'default'}
      onClick={handleClick}
      ref={ref}
    >
      {tokens}
    </Styled.DivisionTerminalLine>
  );
});
BitTerminalLine.displayName = 'BitTerminalLine';
BitTerminalLine.propTypes = {
  customerRunnerTicketScheme: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  isFocused: PropTypes.bool.isRequired,
  setPlayCode: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
export default React.memo(BitTerminalLine);
