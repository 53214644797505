/**
 * Node modules
 */
import _ from 'lodash';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';

/**
 * Clients
 */
import clients from '../../clients';

/**
 * Internals
 */
import helpers from './helpers';
import override from './override';

/**
 * Routes
 */
import * as routes from '../../routes';

/**
 * Functions
 */
const getByParameter = (actionType) => {
  const byWhat = actionType.split('_BY_')[1];
  if (!byWhat) {
    return undefined;
  }
  return _.camelCase(byWhat === 'ID' ? 'OBJECT_ID' : byWhat);
};
const generateActionPack = () => {
  const getActionType = action => _.snakeCase(action).toUpperCase();
  const getClientKey = action => _.kebabCase(`REQUEST_${action}`);
  const getFailActionType = action => `${action}_FAIL`;
  const getFulfillActionType = action => `${action}_FULFILL`;
  const getRequestActionType = action => `${action}_REQUEST`;
  const getResetActionType = action => `${action}_RESET`;
  const getSuccessActionType = action => `${action}_SUCCESS`;
  const getTriggerActionType = action => `${action}_TRIGGER`;
  const actionTypeForBundle = {};
  const types = {};
  const creators = {};
  const actionTypeForDefaultPayload = {};
  const populateActionType = (input) => {
    const {
      actionType,
      clientKey,
      payloadType,
      scope,
    } = input;
    const failActionType = getFailActionType(actionType);
    const fulfillActionType = getFulfillActionType(actionType);
    const requestActionType = getRequestActionType(actionType);
    const resetActionType = getResetActionType(actionType);
    const successActionType = getSuccessActionType(actionType);
    const triggerActionType = getTriggerActionType(actionType);
    types[failActionType] = failActionType;
    types[fulfillActionType] = fulfillActionType;
    types[requestActionType] = requestActionType;
    types[resetActionType] = resetActionType;
    types[successActionType] = successActionType;
    types[triggerActionType] = triggerActionType;
    creators[_.camelCase(failActionType)] = helpers.createActionCreator(types[failActionType]);
    creators[_.camelCase(fulfillActionType)] = helpers.createActionCreator(types[fulfillActionType]);
    creators[_.camelCase(requestActionType)] = helpers.createActionCreator(types[requestActionType]);
    creators[_.camelCase(resetActionType)] = helpers.createActionCreator(types[resetActionType]);
    creators[_.camelCase(successActionType)] = helpers.createActionCreator(types[successActionType]);
    creators[_.camelCase(triggerActionType)] = helpers.createActionCreator(types[triggerActionType]);
    if (payloadType === 'collection') {
      actionTypeForDefaultPayload[actionType] = [];
    }
    if (payloadType === 'resource') {
      actionTypeForDefaultPayload[actionType] = {};
    }
    actionTypeForBundle[actionType] = {
      clientKey,
      failActionType,
      fulfillActionType,
      requestActionType,
      resetActionType,
      scope,
      successActionType,
      triggerActionType,
    };
  };
  const flattenedRoutes = _.flatten(Object.values(routes));
  for (let i = 0; i < flattenedRoutes.length; i += 1) {
    const {
      action,
      method,
      payloadType,
      scopes,
    } = flattenedRoutes[i];
    const actionType = getActionType(action);
    const clientKey = getClientKey(actionType);
    populateActionType({
      actionType,
      clientKey,
      payloadType,
    });
    if (scopes && method === 'GET') {
      const filteredScopes = scopes.filter(scope => scope !== 'DEFAULT');
      for (let j = 0; j < filteredScopes.length; j += 1) {
        const filteredScope = filteredScopes[j];
        const scopeActionType = `GET_${filteredScope}`;
        populateActionType({
          actionType: scopeActionType,
          clientKey,
          payloadType,
          scope: filteredScope,
        });
      }
    }
  }
  return {
    actionTypeForBundle,
    actionTypeForDefaultPayload,
    creators,
    types,
  };
};
const generateReducerAndSelectors = (generatedActionPack) => {
  const revertToActionType = convertedAction => convertedAction.replace(/_FAIL$/, '').replace(/_FULFILL$/, '').replace(/_RESET$/, '').replace(/_REQUEST$/, '').replace(/_SUCCESS$/, '').replace(/_TRIGGER$/, '');
  const {
    actionTypeForBundle,
    actionTypeForDefaultPayload,
  } = generatedActionPack;
  const actionTypes = Object.keys(actionTypeForBundle);
  const failActionTypes = [];
  const fulfillActionTypes = [];
  const requestActionTypes = [];
  const resetActionTypes = [];
  const successActionTypes = [];
  const triggerActionTypes = [];
  for (let i = 0; i < actionTypes.length; i += 1) {
    const actionType = actionTypes[i];
    const bundle = actionTypeForBundle[actionType];
    const {
      failActionType,
      fulfillActionType,
      requestActionType,
      resetActionType,
      successActionType,
      triggerActionType,
    } = bundle;
    failActionTypes.push(failActionType);
    fulfillActionTypes.push(fulfillActionType);
    requestActionTypes.push(requestActionType);
    successActionTypes.push(successActionType);
    triggerActionTypes.push(triggerActionType);
    resetActionTypes.push(resetActionType);
  }
  const getSelectorKey = actionType => _.camelCase(`${actionType}_SELECTOR`);
  const getStateKey = actionType => _.camelCase(actionType);
  const constructResponseState = (byParameter, defaultPayload) => (byParameter ? {} : {
    headers: {},
    metadata: {},
    payload: defaultPayload,
  });
  const constructLoadingState = byParameter => (byParameter ? {} : false);
  const constructRequestState = byParameter => (byParameter ? {} : {
    count: 0,
    status: 'READY',
  });
  const constructFailReducer = (stateKey, byParameter) => (byParameter ? (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data[byParameter];
    return {
      ...state,
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          [by]: {
            ...state.request[stateKey][by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        [stateKey]: {
          ...state.response[stateKey],
          [by]: {
            ...state.response[stateKey][by],
            metadata,
          },
        },
      },
    };
  } : (state, action) => {
    const { data } = action;
    const { metadata } = data;
    return {
      ...state,
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          status: 'FAIL',
        },
      },
      response: {
        ...state.response,
        [stateKey]: {
          ...state.response[stateKey],
          metadata,
        },
      },
    };
  });
  const constructFulfillReducer = (stateKey, byParameter) => (byParameter ? (state, action) => {
    const { data } = action;
    const by = data[byParameter];
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [stateKey]: {
          ...state.isLoading[stateKey],
          [by]: false,
        },
      },
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          [by]: {
            ...state.request[stateKey][by],
            count: state.request[stateKey][by].count ? state.request[stateKey][by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  } : state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      [stateKey]: false,
    },
    request: {
      ...state.request,
      [stateKey]: {
        ...state.request[stateKey],
        count: state.request[stateKey].count + 1,
        status: 'READY',
      },
    },
  }));
  const constructResetReducer = (stateKey, byParameter, defaultPayload) => (byParameter ? (state, action) => {
    const { data } = action;
    const by = data.params[byParameter];
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [stateKey]: {
          ...state.isLoading[stateKey],
          [by]: false,
        },
      },
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          [by]: {
            ...state.request[stateKey][by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        [stateKey]: {
          ...state.response[stateKey],
          [by]: {
            ...state.response[stateKey][by],
            headers: {},
            metadata: {},
            payload: defaultPayload,
          },
        },
      },
    };
  } : state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      [stateKey]: false,
    },
    request: {
      ...state.request,
      [stateKey]: {
        ...state.request[stateKey],
        count: 0,
        status: 'READY',
      },
    },
    response: {
      ...state.response,
      [stateKey]: {
        ...state.response[stateKey],
        headers: {},
        metadata: {},
        payload: defaultPayload,
      },
    },
  }));
  const constructRequestReducer = (stateKey, byParameter) => (byParameter ? (state, action) => {
    const { data } = action;
    const by = data[byParameter];
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [stateKey]: {
          ...state.isLoading[stateKey],
          [by]: true,
        },
      },
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          [by]: {
            ...state.request[stateKey][by],
            status: 'REQUEST',
          },
        },
      },
    };
  } : state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      [stateKey]: true,
    },
    request: {
      ...state.request,
      [stateKey]: {
        ...state.request[stateKey],
        status: 'REQUEST',
      },
    },
  }));
  const constructSuccessReducer = (stateKey, byParameter) => (byParameter ? (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data[byParameter];
    return {
      ...state,
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          [by]: {
            ...state.request[stateKey][by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        [stateKey]: {
          ...state.response[stateKey],
          [by]: {
            ...state.response[stateKey][by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  } : (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    return {
      ...state,
      request: {
        ...state.request,
        [stateKey]: {
          ...state.request[stateKey],
          status: 'SUCCESS',
        },
      },
      response: {
        ...state.response,
        [stateKey]: {
          ...state.response[stateKey],
          headers,
          metadata,
          payload,
        },
      },
    };
  });
  const constructResponseSelector = (stateKey, byParameter, defaultPayload) => (byParameter ? createSelector(
    state => state.reducers.response[stateKey],
    object => _.memoize((by) => {
      const response = {
        headers: {},
        metadata: {},
        payload: defaultPayload,
      };
      if (by !== undefined && object[by]) {
        response.headers = object[by].headers || {};
        response.metadata = object[by].metadata || {};
        response.payload = object[by].payload || defaultPayload;
      }
      return response;
    }),
  ) : state => state.reducers.response[stateKey]);
  const constructRequestSelector = (stateKey, byParameter) => (byParameter ? createSelector(
    state => state.reducers.request[stateKey],
    object => _.memoize((by) => {
      const request = {
        count: 0,
        status: 'READY',
      };
      if (by !== undefined && object[by]) {
        request.count = object[by].count || 0;
        request.status = object[by].status || 'READY';
      }
      return request;
    }),
  ) : state => state.reducers.request[stateKey]);
  const constructLoadingSelector = (stateKey, byParameter) => (byParameter ? createSelector(
    state => state.reducers.isLoading[stateKey],
    object => _.memoize(by => (by !== undefined && object[by]) || false),
  ) : state => state.reducers.isLoading[stateKey]);
  const initialState = {
    isLoading: {},
    request: {},
    response: {},
  };
  const handlers = {};
  const selectors = {};
  for (let i = 0; i < failActionTypes.length; i += 1) {
    const failActionType = failActionTypes[i];
    const actionType = revertToActionType(failActionType);
    const byParameter = getByParameter(actionType);
    const stateKey = getStateKey(actionType);
    handlers[failActionType] = constructFailReducer(stateKey, byParameter);
  }
  for (let i = 0; i < fulfillActionTypes.length; i += 1) {
    const fulfillActionType = fulfillActionTypes[i];
    const actionType = revertToActionType(fulfillActionType);
    const byParameter = getByParameter(actionType);
    const stateKey = getStateKey(actionType);
    handlers[fulfillActionType] = constructFulfillReducer(stateKey, byParameter);
  }
  for (let i = 0; i < resetActionTypes.length; i += 1) {
    const resetActionType = resetActionTypes[i];
    const actionType = revertToActionType(resetActionType);
    const byParameter = getByParameter(actionType);
    const stateKey = getStateKey(actionType);
    handlers[resetActionType] = constructResetReducer(stateKey, byParameter, actionTypeForDefaultPayload[actionType]);
  }
  for (let i = 0; i < requestActionTypes.length; i += 1) {
    const requestActionType = requestActionTypes[i];
    const actionType = revertToActionType(requestActionType);
    const byParameter = getByParameter(actionType);
    const stateKey = getStateKey(actionType);
    handlers[requestActionType] = constructRequestReducer(stateKey, byParameter);
  }
  for (let i = 0; i < successActionTypes.length; i += 1) {
    const successActionType = successActionTypes[i];
    const actionType = revertToActionType(successActionType);
    const byParameter = getByParameter(actionType);
    const stateKey = getStateKey(actionType);
    handlers[successActionType] = constructSuccessReducer(stateKey, byParameter);
  }
  for (let i = 0; i < triggerActionTypes.length; i += 1) {
    const triggerActionType = triggerActionTypes[i];
    const actionType = revertToActionType(triggerActionType);
    const byParameter = getByParameter(actionType);
    const stateKey = getStateKey(actionType);
    initialState.isLoading[stateKey] = constructLoadingState(byParameter);
    initialState.request[stateKey] = constructRequestState(byParameter);
    initialState.response[stateKey] = constructResponseState(byParameter, actionTypeForDefaultPayload[actionType]);
    selectors[getSelectorKey(`${actionType}_REQUEST`)] = constructRequestSelector(stateKey, byParameter);
    selectors[getSelectorKey(`${actionType}_RESPONSE`)] = constructResponseSelector(stateKey, byParameter, actionTypeForDefaultPayload[actionType]);
    selectors[getSelectorKey(`IS_${actionType}_LOADING`)] = constructLoadingSelector(stateKey, byParameter);
  }
  return {
    reducers: {
      handlers,
      initialState,
    },
    selectors,
  };
};
const generateSagaWatcher = (generatedActionPack) => {
  const {
    actionTypeForBundle,
    creators,
  } = generatedActionPack;
  const getSagaWatcherKey = triggerActionType => _.camelCase(`WATCH_REQUEST_${triggerActionType}`);
  const getDecomposedBy = (data) => {
    const {
      params = {},
    } = data;
    const keys = Object.keys(params);
    const constructedParams = {};
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const by = String(params[key]);
      const decomposedBys = by.split('#');
      const decomposedByParameters = key.split('And');
      if (decomposedBys.length === decomposedByParameters.length) {
        for (let j = 0; j < decomposedByParameters.length; j += 1) {
          constructedParams[_.camelCase(decomposedByParameters[j])] = decomposedBys[j];
        }
      } else {
        constructedParams[key] = by;
      }
    }
    return {
      ...data,
      params: constructedParams,
    };
  };
  const constructSagaWorker = (actionCreatorBundle, byParameter, clientKey, scope) => {
    const {
      failActionCreator,
      fulfillActionCreator,
      requestActionCreator,
      successActionCreator,
    } = actionCreatorBundle;
    if (byParameter) {
      // eslint-disable-next-line func-names
      return function* (action) {
        const { data } = action;
        const clonedData = _.cloneDeep(data);
        const by = data.params[byParameter];
        try {
          yield put(requestActionCreator({ [byParameter]: by }));
          if (scope) {
            clonedData.query.scope = scope;
          }
          const {
            headers,
            metadata,
            payload,
          } = yield call(clients.main[clientKey], getDecomposedBy(clonedData));
          yield put(successActionCreator({
            [byParameter]: by,
            headers: {
              keyset: headers['private-pagination-keyset'],
              limit: headers['private-pagination-limit'],
              scope: headers['private-scope'],
              search: headers['private-search'],
              sort: headers['private-pagination-sort'],
            },
            metadata,
            payload,
          }));
        } catch (error) {
          yield put(failActionCreator({
            [byParameter]: by,
            metadata: error.metadata,
          }));
        } finally {
          yield put(fulfillActionCreator({ [byParameter]: by }));
        }
      };
    }
    // eslint-disable-next-line func-names
    return function* (action) {
      const { data } = action;
      const clonedData = _.cloneDeep(data);
      try {
        yield put(requestActionCreator(null));
        if (scope) {
          clonedData.query.scope = scope;
        }
        const {
          headers,
          metadata,
          payload,
        } = yield call(clients.main[clientKey], getDecomposedBy(clonedData));
        yield put(successActionCreator({
          headers: {
            lastSeen: headers['private-pagination-last-seen'],
            limit: headers['private-pagination-limit'],
            scope: headers['private-scope'],
            search: headers['private-search'],
            sort: headers['private-pagination-sort'],
          },
          metadata,
          payload,
        }));
      } catch (error) {
        yield put(failActionCreator({ metadata: error.metadata }));
      } finally {
        yield put(fulfillActionCreator(null));
      }
    };
  };
  // eslint-disable-next-line func-names
  const constructSagaWatcher = (triggerActionType, sagaWorker) => function* () {
    yield takeEvery(triggerActionType, sagaWorker);
  };
  const actionTypes = Object.keys(actionTypeForBundle);
  const sagaWatcher = {};
  for (let i = 0; i < actionTypes.length; i += 1) {
    const actionType = actionTypes[i];
    const bundle = actionTypeForBundle[actionType];
    const {
      clientKey,
      failActionType,
      fulfillActionType,
      requestActionType,
      scope,
      successActionType,
      triggerActionType,
    } = bundle;
    const failActionCreator = creators[_.camelCase(failActionType)];
    const fulfillActionCreator = creators[_.camelCase(fulfillActionType)];
    const requestActionCreator = creators[_.camelCase(requestActionType)];
    const successActionCreator = creators[_.camelCase(successActionType)];
    const triggerActionCreator = creators[_.camelCase(triggerActionType)];
    const sagaWatcherKey = getSagaWatcherKey(actionType);
    const byParameter = getByParameter(actionType);
    const sagaWorker = constructSagaWorker({
      failActionCreator,
      fulfillActionCreator,
      requestActionCreator,
      successActionCreator,
      triggerActionCreator,
    }, byParameter, clientKey, scope);
    sagaWatcher[sagaWatcherKey] = constructSagaWatcher(triggerActionType, sagaWorker);
  }
  return sagaWatcher;
};
const generatedActionPack = generateActionPack();
const generatedReducersAndSelectors = generateReducerAndSelectors(generatedActionPack);
const generatedSagaWatchers = generateSagaWatcher(generatedActionPack);
const initialState = _.merge(
  {},
  { ...generatedReducersAndSelectors.reducers.initialState },
  { ...override.reducers.initialState },
);

/**
 * Constant variables
 */
const creators = {
  ...generatedActionPack.creators,
  ...override.actions.creators,
};
const handlers = {
  ...generatedReducersAndSelectors.reducers.handlers,
  ...override.reducers.handlers,
};
const sagaWatchers = {
  ...generatedSagaWatchers,
  ...override.sagaWatchers,
};
const selectors = {
  ...generatedReducersAndSelectors.selectors,
  ...override.selectors,
};
const types = {
  ...generatedActionPack.types,
  ...override.actions.types,
};
const actions = {
  creators,
  types,
};
const reducers = helpers.handleActions(handlers, initialState);
const saga = function* () {
  return yield all(Object.values(sagaWatchers).map(watcher => fork(watcher)));
};
export {
  actions,
  reducers,
  saga,
  selectors,
};
