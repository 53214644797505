/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    mode,
    type = '',
  } = props;
  const styles = [];
  switch (mode) {
    case 'even': {
      styles.push('background-color: #fafafa;');
      break;
    }
    default: {
      styles.push('background-color: white;');
      break;
    }
  }
  if (type === 'tags') {
    styles.push(`
      :not(:nth-child(8n - 7)) {
        padding: 0 ${scale.major(2)}px;
      }
      :nth-child(8n - 7) {
        justify-content: center;
        width: 100%;
      }
    `)
  } else if (!type.startsWith('report')) {
    styles.push(`
      :not(:nth-child(4n - 3)) {
        padding: 0 ${scale.major(2)}px;
      }
      :nth-child(4n - 3) {
        justify-content: center;
        width: 100%;
      }
    `)
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  
  > div > span:not(:last-child) {
    margin-right: 0;
  }
`;
