/**
 * Node modules
 */
import Modal from 'antd/lib/modal';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import VendorAntButton from '../Button';

/**
 * Functional components
 */
const VendorAntDialog = (props) => {
  const {
    cancelLabel,
    confirmLabel,
    hasCancel,
    hasClose,
    hasOverlay,
    intent,
    isCentered,
    isClosingOnOverlayClick,
    isConfirmDisabled,
    isConfirmLoading,
    isDestroyedOnClose,
    isShown,
    onCancel: handleCancel,
    onConfirm: handleConfirm,
    title,
    ...rest
  } = props;
  const footer = [];
  footer.push((
    <VendorAntButton
      intent={intent}
      isDisabled={isConfirmDisabled}
      isLoading={isConfirmLoading}
      key="confirm"
      onClick={handleConfirm}
    >
      {confirmLabel}
    </VendorAntButton>
  ));
  if (hasCancel) {
    footer.push((
      <VendorAntButton
        key="cancel"
        onClick={handleCancel}
      >
        {cancelLabel}
      </VendorAntButton>
    ));
  }
  return (
    <Modal
      cancelText={cancelLabel}
      centered={isCentered}
      closable={hasClose}
      destroyOnClose={isDestroyedOnClose}
      footer={footer}
      mask={hasOverlay}
      maskClosable={isClosingOnOverlayClick}
      onCancel={handleCancel}
      title={title}
      visible={isShown}
      {...rest}
    />
  );
};
VendorAntDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  hasCancel: true,
  hasClose: false,
  hasOverlay: true,
  intent: 'none',
  isCentered: false,
  isClosingOnOverlayClick: false,
  isConfirmDisabled: false,
  isConfirmLoading: false,
  isDestroyedOnClose: false,
  isShown: false,
  onCancel: _.noop,
  onConfirm: _.noop,
};
VendorAntDialog.displayName = 'VendorAntDialog';
VendorAntDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  hasCancel: PropTypes.bool,
  hasClose: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  intent: PropTypes.string,
  isCentered: PropTypes.bool,
  isClosingOnOverlayClick: PropTypes.bool,
  isConfirmDisabled: PropTypes.bool,
  isConfirmLoading: PropTypes.bool,
  isDestroyedOnClose: PropTypes.bool,
  isShown: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.node.isRequired,
};
export default VendorAntDialog;
