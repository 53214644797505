/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.thead`
  font-weight: bold;
  > tr {
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    > th:not(:last-child) {
      border-right: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 0.5);
    }
  }
`;
