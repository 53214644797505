/**
 * Node modules
 */
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitLayoutMenu = (props) => {
  const {
    accessibleMenus,
    loginBundle,
    pathname,
    type,
  } = props;
  const translate = useTranslate();
  const selectedKeys = [pathname.split('/')[2]];
  const isSidebar = type === 'sidebar';
  const login = loginBundle.response.payload;
  return (
    <Styled.VendorAntMenu
      layout={isSidebar ? 'inline' : 'horizontal'}
      selectedKeys={selectedKeys}
      theme="dark"
      type={type}
    >
      {accessibleMenus.map((accessibleMenu) => {
        const {
          alias,
          children,
          name,
          path,
        } = accessibleMenu;
        const sanitizedPath = path.replace('/*', '');
        const MenuIcon = Styled.Icon[alias];
        if (children.length === 0) {
          return (
            <Styled.VendorAntMenuItem
              key={sanitizedPath}
              type={type}
            >
              {MenuIcon && (<MenuIcon />)}
              <Link to={sanitizedPath === 'accounts' ? `${sanitizedPath}/${(login.employerUsername || login.username).toLowerCase()}` : sanitizedPath}>
                {translate(`title.${name}`)}
              </Link>
            </Styled.VendorAntMenuItem>
          );
        }
        const title = (
          <Styled.DivisionMenuTitle>
            <MenuIcon />
            <Styled.Span>
              {translate(`title.${name}`)}
            </Styled.Span>
          </Styled.DivisionMenuTitle>
        );
        return (
          <Styled.VendorAntMenuSub
            key={name}
            title={title}
            type={type}
          >
            {children.map(child => (
              <Styled.VendorAntMenuItem key={child.path}>
                <Link to={child.path}>
                  {translate(`title.${child.name}`)}
                </Link>
              </Styled.VendorAntMenuItem>
            ))}
          </Styled.VendorAntMenuSub>
        );
      })}
    </Styled.VendorAntMenu>
  );
};
BitLayoutMenu.displayName = 'BitLayoutMenu';
BitLayoutMenu.propTypes = {
  accessibleMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  loginBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  pathname: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
export default React.memo(BitLayoutMenu);
