/**
 * Internals
 */
import account from './account';
import analytics from './analytics';
import desktop from './desktop';
import draw from './draw';
import play from './play';
import portal from './portal';
import system from './system';

export default {
  account,
  analytics,
  desktop,
  draw,
  play,
  portal,
  system,
};
