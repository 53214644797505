/**
 * Node modules
 */
import PropTypes from 'prop-types';
import Radio from 'antd/lib/radio';
import React from 'react';

/**
 * Internals
 */
import Button from './Button';

/**
 * Functional components
 */
const VendorAntRadio = (props) => {
  const {
    isChecked,
    isDisabled,
    ...rest
  } = props;
  return (
    <Radio
      checked={isChecked}
      disabled={isDisabled}
      {...rest}
    />
  );
};
VendorAntRadio.Button = Button;
VendorAntRadio.defaultProps = {
  isChecked: false,
  isDisabled: false,
};
VendorAntRadio.displayName = 'VendorAntRadio';
VendorAntRadio.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
export default VendorAntRadio;
