/**
 * Private modules
 */
import helpers from '../../helpers';

/**
 * Constant variables
 */
const types = {
  GET_DEAL_RECEIPT_FAIL: 'GET_DEAL_RECEIPT_FAIL',
  GET_DEAL_RECEIPT_FULFILL: 'GET_DEAL_RECEIPT_FULFILL',
  GET_DEAL_RECEIPT_REQUEST: 'GET_DEAL_RECEIPT_REQUEST',
  GET_DEAL_RECEIPT_RESET: 'GET_DEAL_RECEIPT_RESET',
  GET_DEAL_RECEIPT_SUCCESS: 'GET_DEAL_RECEIPT_SUCCESS',
  GET_DEAL_RECEIPT_TRIGGER: 'GET_DEAL_RECEIPT_TRIGGER',
  PRINT_DEAL_RECEIPT_FAIL: 'PRINT_DEAL_RECEIPT_FAIL',
  PRINT_DEAL_RECEIPT_FULFILL: 'PRINT_DEAL_RECEIPT_FULFILL',
  PRINT_DEAL_RECEIPT_REQUEST: 'PRINT_DEAL_RECEIPT_REQUEST',
  PRINT_DEAL_RECEIPT_RESET: 'PRINT_DEAL_RECEIPT_RESET',
  PRINT_DEAL_RECEIPT_SUCCESS: 'PRINT_DEAL_RECEIPT_SUCCESS',
  PRINT_DEAL_RECEIPT_TRIGGER: 'PRINT_DEAL_RECEIPT_TRIGGER',
  SHARE_DEAL_RECEIPT_FAIL: 'SHARE_DEAL_RECEIPT_FAIL',
  SHARE_DEAL_RECEIPT_FULFILL: 'SHARE_DEAL_RECEIPT_FULFILL',
  SHARE_DEAL_RECEIPT_REQUEST: 'SHARE_DEAL_RECEIPT_REQUEST',
  SHARE_DEAL_RECEIPT_RESET: 'SHARE_DEAL_RECEIPT_RESET',
  SHARE_DEAL_RECEIPT_SUCCESS: 'SHARE_DEAL_RECEIPT_SUCCESS',
  SHARE_DEAL_RECEIPT_TRIGGER: 'SHARE_DEAL_RECEIPT_TRIGGER',
};
const creators = {
  getDealReceiptFail: helpers.createActionCreator(types.GET_DEAL_RECEIPT_FAIL),
  getDealReceiptFulfill: helpers.createActionCreator(types.GET_DEAL_RECEIPT_FULFILL),
  getDealReceiptRequest: helpers.createActionCreator(types.GET_DEAL_RECEIPT_REQUEST),
  getDealReceiptReset: helpers.createActionCreator(types.GET_DEAL_RECEIPT_RESET),
  getDealReceiptSuccess: helpers.createActionCreator(types.GET_DEAL_RECEIPT_SUCCESS),
  getDealReceiptTrigger: helpers.createActionCreator(types.GET_DEAL_RECEIPT_TRIGGER),
  printDealReceiptFail: helpers.createActionCreator(types.PRINT_DEAL_RECEIPT_FAIL),
  printDealReceiptFulfill: helpers.createActionCreator(types.PRINT_DEAL_RECEIPT_FULFILL),
  printDealReceiptRequest: helpers.createActionCreator(types.PRINT_DEAL_RECEIPT_REQUEST),
  printDealReceiptReset: helpers.createActionCreator(types.PRINT_DEAL_RECEIPT_RESET),
  printDealReceiptSuccess: helpers.createActionCreator(types.PRINT_DEAL_RECEIPT_SUCCESS),
  printDealReceiptTrigger: helpers.createActionCreator(types.PRINT_DEAL_RECEIPT_TRIGGER),
  shareDealReceiptFail: helpers.createActionCreator(types.SHARE_DEAL_RECEIPT_FAIL),
  shareDealReceiptFulfill: helpers.createActionCreator(types.SHARE_DEAL_RECEIPT_FULFILL),
  shareDealReceiptRequest: helpers.createActionCreator(types.SHARE_DEAL_RECEIPT_REQUEST),
  shareDealReceiptReset: helpers.createActionCreator(types.SHARE_DEAL_RECEIPT_RESET),
  shareDealReceiptSuccess: helpers.createActionCreator(types.SHARE_DEAL_RECEIPT_SUCCESS),
  shareDealReceiptTrigger: helpers.createActionCreator(types.SHARE_DEAL_RECEIPT_TRIGGER),
};
export default {
  creators,
  types,
};
