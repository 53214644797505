/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Styled components
 */

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'light': {
      break;
    }
    default: {
      styles.push('color: rgba(44, 62, 80,1.0);');
      break;
    }
  }
  return styles.join('');
};
export default styled.span`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
  font-weight: bold;
`;
