/**
 * Node modules
 */
import _ from 'lodash';

/**
 * Constant variables
 */
const createTicketNumber = _.memoize((model, length) => {
  if (model) {
    const modelComponents = model.split('#');
    const chunks = [];
    for (let i = 0; i < modelComponents.length; i += 1) {
      if (modelComponents[i] !== '.') {
        chunks.push('{☸}');
      } else {
        chunks.push('0');
      }
    }
    return {
      lazy: false,
      mask: chunks.join(''),
    };
  }
  return {
    lazy: true,
    mask: [...new Array(length)].map(() => '0').join(''),
  };
});
const integer = {
  mapToRadix: ['.'],
  mask: Number,
  normalizeZeros: false,
  padFractionalZeros: false,
  radix: '.',
  scale: 0,
  signed: true,
  thousandsSeparator: ',',
};
const integerAmount = {
  blocks: {
    amount: {
      mapToRadix: ['.'],
      mask: Number,
      normalizeZeros: false,
      padFractionalZeros: false,
      radix: '.',
      scale: 0,
      signed: true,
      thousandsSeparator: ',',
    },
  },
  mask: '฿ amount',
};
const precise = {
  mapToRadix: ['.'],
  mask: Number,
  normalizeZeros: true,
  padFractionalZeros: false,
  radix: '.',
  scale: 2,
  signed: false,
  thousandsSeparator: ',',
};
const preciseAmount = {
  blocks: {
    amount: {
      mapToRadix: ['.'],
      mask: Number,
      normalizeZeros: true,
      padFractionalZeros: false,
      radix: '.',
      scale: 2,
      signed: false,
      thousandsSeparator: ',',
    },
  },
  mask: '฿ amount',
};
export default {
  createTicketNumber,
  integer,
  integerAmount,
  precise,
  preciseAmount,
};
