/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitMask from '../Mask';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  Contexts,
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import VendorAntDialog from '../../Vendor/Ant/Dialog';
import VendorAntTag from '../../Vendor/Ant/Tag';
import Button from 'antd/lib/button';

/**
 * Functional components
 */
const BitReport = (props) => {
  const { payload, actions } = props;
  const translate = useTranslate();
  const loginBundle = React.useContext(Contexts.LoginBundle);
  const login = loginBundle.response.payload;
  const {
    customerId,
    customerNickname,
    customerUsername,
    dateFrom,
    dateTo,
    drawId,
    entries=[],
    systemGameTypeAlias,
    systemGameTypeCode,
    systemGameTypeId,
    unfilledResultCount,
    reports = [],
  } = payload;
  const isPopulating = reports.some(r => r.status.includes('Populating'));
  const isFullyCompiled = reports.every(r => r.version === r.currentVersion || r.status !== 'Current');
  const isRunner = entries.length === 1 && entries[0].downlineId === null;
  const [currentEntries, setCurrentEntries] = React.useState(entries);
  const [currentSort, setCurrentSort] = React.useState(['downlineUsername', 'asc']);
  React.useEffect(() => {
    setCurrentEntries(_.orderBy(entries, entry => entry[currentSort[0]] || '', currentSort[1]));
  }, [currentSort, entries]);
  const handleSort = React.useCallback((field) => {
    setCurrentSort((previousSort) => {
      const [f, dir] = previousSort;
      if (f === field) {
        return dir === 'asc' ? [field, 'desc'] : [field, 'asc'];
      }
      return [field, 'asc'];
    });
  }, []);
  const compositeKeys = [
    'grossValue#whole#link#special',
    'downlineAteValue#whole#link#special',
    'receivedValue#downline#link#special',
    'respondedCommissionAmount#downline#link#special',
    'respondedAmount#downline',
    'respondedRewardAmount#downline#link#special',
    'respondedBalanceAmount#downline',
    'selfAteValue#self#link',
    'selfAteCommissionAmount#self#link',
    'selfAmount#self',
    'selfRiskAmount#self#link',
    'selfBalanceAmount#self',
    customerUsername !== 'ROOT' && 'requestedValue#upline#link',
    customerUsername !== 'ROOT' && 'receivedCommissionAmount#upline#link',
    customerUsername !== 'ROOT' && 'requestedAmount#upline',
    customerUsername !== 'ROOT' && 'receivedRewardAmount#upline#link',
    customerUsername !== 'ROOT' && 'requestedBalanceAmount#upline',
    customerUsername !== 'ROOT' && 'retainedCommissionAmount',
    customerUsername !== 'ROOT' && 'strikeProfitAmount',
  ].filter(Boolean);
  const coin = (
    <Styled.DivisionCoin>
      <Styled.Image
        minorScaledHeight={5}
        minorScaledWidth={5}
        src={images.Coin}
      />
    </Styled.DivisionCoin>
  );
  const currency = (
    <Styled.DivisionCoin>
      <Styled.Image
        minorScaledHeight={5}
        minorScaledWidth={5}
        src={images.Baht}
      />
    </Styled.DivisionCoin>
  );
  return !_.isEmpty(payload) && (
    <Styled.DivisionViewPort>
      {!isFullyCompiled && !isPopulating && (
        <Styled.DivisionReportBanner>
          มีรายงานเวอร์ชั่นใหม่
          <>
            {reports.length === 1 && (
              <small style={{ marginLeft: 8 }}>
                {`(${reports[0].version}/${reports[0].currentVersion})`}
              </small>
            )}
          </>
          <Button  shape="round" style={{marginLeft:8}} onClick={()=>setDrawCount(p=>p+1)}>โหลดใหม่</Button>
        </Styled.DivisionReportBanner>
      )}

      {!isPopulating && unfilledResultCount > 0 && (
        <Styled.DivisionReportBanner>
          <Styled.SpanItemTitle>
            {translate('label.unfilled-prizes')}
          </Styled.SpanItemTitle>
          {unfilledResultCount}
        </Styled.DivisionReportBanner>
      )}

      <Styled.DivisionReportVolumeHeader>
        <Styled.Division name="division">
          <Styled.ImageLogo src={images[systemGameTypeAlias.replace(/\s/g, '')]} />
          <Styled.Division>
            {`${translate(`display.alias.${systemGameTypeAlias}`)} (${systemGameTypeCode})`}
          </Styled.Division>
        </Styled.Division>
        <Styled.DivisionColumn>
          <Styled.Span>
            {dateFrom === dateTo && (
              <Styled.Span>
                {format(new Date(dateFrom), 'dd-MM-yy')}
              </Styled.Span>
            )}
            {dateFrom !== dateTo && (
              <Styled.Division>
                {format(new Date(dateFrom), 'dd-MM-yy')}
                <Styled.Icon.IonicArrowForward />
                {format(new Date(dateTo), 'dd-MM-yy')}
              </Styled.Division>
            )}
          </Styled.Span>
          <Styled.Small name="small">
            {`${customerUsername} (${customerNickname})`}
          </Styled.Small>
        </Styled.DivisionColumn>
      </Styled.DivisionReportVolumeHeader>
      {isPopulating && (
        <Styled.Image
          majorScaledHeight={12}
          majorScaledWidth={12}
          src={images.BillLoader}
        />
      )}
      {!isPopulating && (
        <Styled.DivisionPortal>
          <Styled.TablePortal>
            <Styled.TableHeader>
              <Styled.TableRow>
                <Styled.TableHeadingPortal
                  colSpan={2}
                  mode="empty-left"
                >
                  {actions}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal
                  colSpan={2}
                  mode="whole"
                >
                  <Styled.Division>
                    {translate('title.whole')}
                  </Styled.Division>
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal
                  colSpan={5}
                  mode="downline"
                >
                  <Styled.Division>
                    {translate('title.downline')}
                  </Styled.Division>
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal
                  colSpan={5}
                  mode="self"
                >
                  <Styled.Division>
                    {translate('title.self')}
                  </Styled.Division>
                </Styled.TableHeadingPortal>
                {customerUsername !== 'ROOT' && (
                  <React.Fragment>
                    <Styled.TableHeadingPortal
                      colSpan={5}
                      mode="upline"
                    >
                      <Styled.Division>
                        {translate('title.upline')}
                      </Styled.Division>
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal
                      colSpan={2}
                      mode="empty-right"
                    />
                  </React.Fragment>
                )}
                {customerUsername === 'ROOT' && login.type !== 'User' && (
                  <Styled.TableHeadingPortal
                    colSpan={5}
                    mode="expected"
                  >
                    <Styled.Division>
                      {translate('title.expected')}
                    </Styled.Division>
                  </Styled.TableHeadingPortal>
                )}
              </Styled.TableRow>
              <Styled.TableRow>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('downlineUsername')} rowSpan={2}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.username')}
                    </Styled.Span>
                    {currentSort[0] === 'downlineUsername' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'downlineUsername' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('downlineNickname')} rowSpan={2}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.nickname')}
                    </Styled.Span>
                    {currentSort[0] === 'downlineNickname' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'downlineNickname' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {coin}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {coin}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {coin}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {coin}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal>
                  {currency}
                </Styled.TableHeadingPortal>
                {customerUsername !== 'ROOT' && (
                  <React.Fragment>
                    <Styled.TableHeadingPortal>
                      {coin}
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal>
                      {currency}
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal>
                      {currency}
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal>
                      {currency}
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal>
                      {currency}
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal>
                      {currency}
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal>
                      {currency}
                    </Styled.TableHeadingPortal>
                  </React.Fragment>
                )}
                {customerUsername === 'ROOT' && login.type !== 'User' && (
                  <Styled.TableHeadingPortal>
                    {currency}
                  </Styled.TableHeadingPortal>
                )}
              </Styled.TableRow>
              <Styled.TableRow>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('grossValue')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.gross')}
                    </Styled.Span>
                    {currentSort[0] === 'grossValue' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'grossValue' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('downlineAteValue')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.downline-ate')}
                    </Styled.Span>
                    {currentSort[0] === 'downlineAteValue' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'downlineAteValue' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>

                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('receivedValue')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.received')}
                    </Styled.Span>
                    {currentSort[0] === 'receivedValue' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'receivedValue' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('respondedCommissionAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.commission')}
                    </Styled.Span>
                    {currentSort[0] === 'respondedCommissionAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'respondedCommissionAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('respondedAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.net')}
                    </Styled.Span>
                    {currentSort[0] === 'respondedAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'respondedAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('respondedRewardAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.strike')}
                    </Styled.Span>
                    {currentSort[0] === 'respondedRewardAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'respondedRewardAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('respondedBalanceAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.balance')}
                    </Styled.Span>
                    {currentSort[0] === 'respondedBalanceAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'respondedBalanceAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>
                </Styled.TableHeadingPortal>

                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('selfAteValue')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.retained')}
                    </Styled.Span>
                    {currentSort[0] === 'selfAteValue' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'selfAteValue' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('selfAteCommissionAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.commission')}
                    </Styled.Span>
                    {currentSort[0] === 'selfAteCommissionAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'selfAteCommissionAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('selfAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.net')}
                    </Styled.Span>
                    {currentSort[0] === 'selfAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'selfAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('selfRiskAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.strike')}
                    </Styled.Span>
                    {currentSort[0] === 'selfRiskAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'selfRiskAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('selfBalanceAmount')}>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate('title.balance')}
                    </Styled.Span>
                    {currentSort[0] === 'selfBalanceAmount' && currentSort[1] === 'asc' && (
                      <Styled.Icon.IonicCaretUp
                        mode="default"
                        size={3}
                      />
                    )}
                    {currentSort[0] === 'selfBalanceAmount' && currentSort[1] === 'desc' && (
                      <Styled.Icon.IonicCaretDown
                        mode="default"
                        size={3}
                      />
                    )}
                  </Styled.DivisionColumn>

                </Styled.TableHeadingPortal>
                {customerUsername !== 'ROOT' && (
                  <React.Fragment>
                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('requestedValue')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.requested')}
                        </Styled.Span>
                        {currentSort[0] === 'requestedValue' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'requestedValue' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>

                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('receivedCommissionAmount')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.commission')}
                        </Styled.Span>
                        {currentSort[0] === 'receivedCommissionAmount' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'receivedCommissionAmount' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>
                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('requestedAmount')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.net')}
                        </Styled.Span>
                        {currentSort[0] === 'requestedAmount' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'requestedAmount' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>

                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('receivedRewardAmount')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.strike')}
                        </Styled.Span>
                        {currentSort[0] === 'receivedRewardAmount' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'receivedRewardAmount' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>

                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('requestedBalanceAmount')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.balance')}
                        </Styled.Span>
                        {currentSort[0] === 'requestedBalanceAmount' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'requestedBalanceAmount' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>

                    </Styled.TableHeadingPortal>

                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('retainedCommissionAmount')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.retained-commission')}
                        </Styled.Span>
                        {currentSort[0] === 'retainedCommissionAmount' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'retainedCommissionAmount' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>

                    </Styled.TableHeadingPortal>
                    <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('strikeProfitAmount')}>
                      <Styled.DivisionColumn>
                        <Styled.Span>
                          {translate('title.strike-profit')}
                        </Styled.Span>
                        {currentSort[0] === 'strikeProfitAmount' && currentSort[1] === 'asc' && (
                          <Styled.Icon.IonicCaretUp
                            mode="default"
                            size={3}
                          />
                        )}
                        {currentSort[0] === 'strikeProfitAmount' && currentSort[1] === 'desc' && (
                          <Styled.Icon.IonicCaretDown
                            mode="default"
                            size={3}
                          />
                        )}
                      </Styled.DivisionColumn>
                    </Styled.TableHeadingPortal>

                  </React.Fragment>
                )}
                {customerUsername === 'ROOT' && login.type !== 'User' && (
                  <Styled.TableHeadingPortal type="sort" onClick={() => handleSort('expectedRetainedAmount')}>
                    <Styled.DivisionColumn>
                      <Styled.Span>
                        {translate('title.earnings')}
                      </Styled.Span>
                      {currentSort[0] === 'expectedRetainedAmount' && currentSort[1] === 'asc' && (
                        <Styled.Icon.IonicCaretUp
                          mode="default"
                          size={3}
                        />
                      )}
                      {currentSort[0] === 'expectedRetainedAmount' && currentSort[1] === 'desc' && (
                        <Styled.Icon.IonicCaretDown
                          mode="default"
                          size={3}
                        />
                      )}
                    </Styled.DivisionColumn>

                  </Styled.TableHeadingPortal>
                )}
              </Styled.TableRow>
            </Styled.TableHeader>
            <Styled.TableBodyPortal>
              {currentEntries.map((entry) => {
                const {
                  downlineId,
                  downlineNickname,
                  downlineUsername,
                } = entry;
                return (
                  <Styled.TableRow key={downlineId}>
                    <Styled.TableDataPortal mode="heading">
                      {downlineId && (
                        <Styled.RouterLink to={`/portal/report/${format(new Date(dateFrom), 'yyyyMMdd')}-${format(new Date(dateTo), 'yyyyMMdd')}/system-game-type/${systemGameTypeId}/customer/${downlineId}`}>
                          {downlineUsername}
                        </Styled.RouterLink>
                      )}
                      {!downlineId && translate('label.self')}
                    </Styled.TableDataPortal>
                    <Styled.TableDataPortal mode="nowrap">
                      {downlineNickname || translate('label.self')}
                    </Styled.TableDataPortal>
                    {compositeKeys.map((compositeKey) => {
                      const [context, mode, link, special] = compositeKey.split('#');
                      const hasLink = (special ? link && downlineId : link) && drawId;

                      return (
                        <Styled.TableDataPortal
                          key={compositeKey}
                          mode={mode}
                        >
                          <Styled.DivisionAmount>
                            {hasLink && entry[context] > 0 && (
                              <Styled.RouterLink to={`/portal/report/${drawId}/system-game-type/${systemGameTypeId}/customer/${customerId}/downline/${downlineId || '-'}/volume/${_.kebabCase(context)}`}>
                                <Styled.Span>
                                  {utilities.toLocaleString(entry[context], 2)}
                                </Styled.Span>
                              </Styled.RouterLink>
                            )}
                            {(!hasLink || entry[context] === 0) && (
                              <Styled.Span>
                                {utilities.toLocaleString(entry[context], 2)}
                              </Styled.Span>
                            )}
                          </Styled.DivisionAmount>
                        </Styled.TableDataPortal>
                      );
                    })}
                    {customerUsername === 'ROOT' && login.type !== 'User' && (
                      <Styled.TableDataPortal
                        key={downlineId}
                        mode="expected"
                      >
                        <Styled.Span>
                          {utilities.toLocaleString(entry.expectedRetainedAmount, 2)}
                        </Styled.Span>
                      </Styled.TableDataPortal>
                    )}
                  </Styled.TableRow>
                );
              })}
            </Styled.TableBodyPortal>
            <Styled.TableFooterPortal>
              <Styled.TableRow>
                <Styled.TableDataPortal colSpan={2}>
                  <Styled.Span>
                    {translate('title.total')}
                  </Styled.Span>
                </Styled.TableDataPortal>
                {compositeKeys.map((compositeKey) => {
                  const [context] = compositeKey.split('#');
                  if (payload[`total${_.upperFirst(context)}`] > 0 && (compositeKey.includes('selfRiskAmount') || compositeKey.includes('respondedRewardAmount') || compositeKey.includes('receivedRewardAmount'))) {
                    if (isRunner && `total${_.upperFirst(context)}` === 'totalRespondedRewardAmount') {
                      return (
                        <Styled.TableDataPortal key={compositeKey}>
                          <Styled.Span>
                            {utilities.toLocaleString(payload[`total${_.upperFirst(context)}`], 2)}
                          </Styled.Span>
                        </Styled.TableDataPortal>
                      );
                    }
                    return (
                      <Styled.TableDataPortal key={compositeKey}>
                        <Styled.RouterLink to={`/portal/report/${drawId}/system-game-type/${systemGameTypeId}/customer/${customerId}/overview/${_.kebabCase(`total${_.upperFirst(context)}`)}`}>
                          <Styled.Span>
                            {utilities.toLocaleString(payload[`total${_.upperFirst(context)}`], 2)}
                          </Styled.Span>
                        </Styled.RouterLink>
                      </Styled.TableDataPortal>
                    );
                  }
                  return (
                    <Styled.TableDataPortal key={compositeKey}>
                      <Styled.Span>
                        {utilities.toLocaleString(payload[`total${_.upperFirst(context)}`], 2)}
                      </Styled.Span>
                    </Styled.TableDataPortal>
                  );
                })}
                {customerUsername === 'ROOT' && login.type !== 'User' && (
                  <Styled.TableDataPortal
                    key="expected"
                  >
                    <Styled.Span>
                      {utilities.toLocaleString(payload.totalExpectedRetainedAmount, 2)}
                    </Styled.Span>
                  </Styled.TableDataPortal>
                )}
              </Styled.TableRow>
            </Styled.TableFooterPortal>
          </Styled.TablePortal>
        </Styled.DivisionPortal>

      )}
    </Styled.DivisionViewPort>
  );
};
BitReport.displayName = 'BitReport';
BitReport.propTypes = { payload: PropTypes.objectOf(PropTypes.any).isRequired };
export default React.memo(BitReport);
