/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntButton from '../../Vendor/Ant/Button';

/**
 * Styled components
 */
export default styled(VendorAntButton)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
