/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'Extra Large': {
      styles.push(`
        > div:nth-child(n+3) {
          > span {
            top: 0;
            height: 56px;
          }
        }
      `);
      styles.push(`font-size: ${scale.minor(9)}px;`);
      break;
    }
    case 'Large': {
      styles.push(`font-size: ${scale.minor(7)}px;`);
      break;
    }
    case 'Small': {
      styles.push(`font-size: ${scale.minor(5)}px;`);
      break;
    }
    default: {
      styles.push(`font-size: ${scale.minor(6)}px;`);
      break;
    }
  }
  return styles.join('');
};

export default
styled.div`
  height: 500px;
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  background-color: rgba(38, 50, 56, 1);
  flex-grow: 1;
  letter-spacing: ${scale.minor(0.5)}px;
  overflow-y: auto;
`;
