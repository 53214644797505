/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitMask from '../Mask';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  constants, Contexts,
  masks, utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import ActionCancelDealById from '../../../containers/Action/Cancel/DealById';

import styled from 'styled-components';


/**
 * Styled components
 */
const TempRow = styled.table`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: #e74c3c;
  color: black;
  font-size: 15px;
  padding: 4px;
`;
const TempBammer  = styled.div`
  > div {
    margin-top: 0px;
  }
`;
/**
 * Functional components
 */
const toLocaleString = (number, decimalPlaces = 0, currency) => {
  const option = {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: 0,
  };
  if (currency === 'THB') {
    return `฿ ${Number(number).toLocaleString('en-US', option)}`;
  }
  return Number(number).toLocaleString('en-US', option);
};
const BitReceipt = (props) => {
  const {
    compositeKeyForBundle,
    actions,
    customerIdentifier,
    objectId,
    identifier,
    isLoading,
    status,
    tag,
    sources,
    timestamp,
    totalAcceptedAddonAmount,
    totalAcceptedAmount,
    totalRejectedAddonAmount,
    totalRejectedAmount,
    activities,
  } = props;
  const systemGameTypesBundle = React.useContext(Contexts.SystemGameTypesBundle);

  const systemGameTypeIdSet = new Set();
  // console.log('sources', sources)
  const systemGameTypes = systemGameTypesBundle.response.payload;
  _.each(sources||[], s=>{
    systemGameTypeIdSet.add(s?.systemGameType?.objectId);
  })
  const lastActivity = activities[activities.length - 1];
  const translate = useTranslate();
  const desktopReceiptConten = React.useMemo(() => {
    const compositeKeys = Object.keys(compositeKeyForBundle);
    const rows = [];
    const systemGameCategoryCodeForWindow = {};
    // const summary = {
    //   windows: [],
    // };
    _.each(compositeKeys, compositeKey => {
      const [mode, number, rank, slot] = compositeKey.split('#');
      const {
        entries,
        isPartial,
        systemGameCategoryAlias,
        systemGameCategoryCode,
        systemGameCategoryId,
        systemGameCategoryNumberManipulator,
        systemGameCategoryNumberOfDigits,
        systemGameCategorySection,
        systemGameTypeAlias,
        systemGameTypeCode,
      } = compositeKeyForBundle[compositeKey];
      if (!systemGameCategoryCodeForWindow[systemGameCategoryCode]) {
        systemGameCategoryCodeForWindow[systemGameCategoryCode] = {
          numberForEntry: {},
          systemGameCategoryAlias,
          systemGameCategoryCode,
          systemGameCategoryId,
          systemGameCategoryNumberManipulator,
          systemGameCategoryNumberOfDigits,
          systemGameCategorySection,
          systemGameTypeAlias,
          systemGameTypeCode,
          totalQuantity: 0,
        };
      }
      _.each(entries, entry => {
        if (!systemGameCategoryCodeForWindow[systemGameCategoryCode].numberForEntry[entry.number]) {
          systemGameCategoryCodeForWindow[systemGameCategoryCode].numberForEntry[entry.number] = 0;
        }
        systemGameCategoryCodeForWindow[systemGameCategoryCode].numberForEntry[entry.number] += entry.acceptedAmount;
        systemGameCategoryCodeForWindow[systemGameCategoryCode].totalQuantity += entry.acceptedAmount;
      });
      const isPlugin = mode !== 'straight';
      let modee = '';
      if (status === constants.canceled || status === 'Rejected' || status === 'Errored' || status === 'Corrupted') {
        modee = 'rejected';
      } else if (status === 'Processing') {
        modee = 'pending';
      } else {
        if (isPartial) {
          modee = 'partial';
        } else {
          modee = 'whole';
        }
      }
      if (compositeKeyForBundle[compositeKey].status === 'Edited') {
        modee = 'edited';
      }
      if (isPartial) {
        rows.push((
          <Styled.TableRow key={compositeKey}>
            <Styled.TableDataReceipt>
              {isPlugin && (
                <Styled.DivisionTableDataTicket>
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {translate(`label.${mode}`)}
                    </Styled.Span>
                    <Styled.Span>
                      {number}
                    </Styled.Span>
                  </Styled.DivisionColumn>
                  <div>
                    {_.sortBy(entries, 'number').map(entry => (
                      <Styled.DivisionReceiptTicketNumber key={entry.number} mode={modee}>
                        {entry.number}
                      </Styled.DivisionReceiptTicketNumber>
                    ))}
                  </div>
                </Styled.DivisionTableDataTicket>
              )}
              {!isPlugin && (
                <Styled.DivisionReceiptTicketNumber mode={modee}>
                  {number}
                </Styled.DivisionReceiptTicketNumber>
              )}
            </Styled.TableDataReceipt>
            <Styled.TableDataReceipt>
              {isPlugin && (
                <Styled.DivisionColumn>
                  {_.sortBy(entries, 'number').map(entry => (
                    <Styled.DivisionTableDataNormal key={entry.number} mode={modee}>
                      {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                    </Styled.DivisionTableDataNormal>
                  ))}
                </Styled.DivisionColumn>
              )}
              {!isPlugin && translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
            </Styled.TableDataReceipt>
            <Styled.TableDataReceipt mode={status === constants.canceled ? 'canceled' : ''}>
              {isPlugin && (
                <Styled.DivisionColumn>
                  {_.sortBy(entries, 'number').map(entry => (
                    <Styled.DivisionTableDataNormal key={entry.number} mode={modee}>
                      {modee === 'pending' ? (<Styled.DivisionAmount>???</Styled.DivisionAmount>) : (<Styled.DivisionAmount>
                        {entry.acceptedAmount ? toLocaleString(entry.acceptedAmount, 3) : '-'}
                      </Styled.DivisionAmount>)}
                    </Styled.DivisionTableDataNormal>
                  ))}
                </Styled.DivisionColumn>
              )}
              {!isPlugin && (
                <>
                  {modee === 'pending' ? (<Styled.DivisionAmount>???</Styled.DivisionAmount>) : (<Styled.DivisionAmount>
                    {entries[0].acceptedAmount ? toLocaleString(entries[0].acceptedAmount, 3) : '-'}
                  </Styled.DivisionAmount>)}
                </>

              )}
            </Styled.TableDataReceipt>
            <Styled.TableDataReceipt mode={status === constants.canceled ? 'canceled' : ''}>
              {isPlugin && (
                <Styled.DivisionColumn>
                  {_.sortBy(entries, 'number').map(entry => (
                    <Styled.DivisionTableDataNormal key={entry.number} mode={modee}>
                      <Styled.DivisionAmount>
                        {entry.rejectedAmount ? toLocaleString(entry.rejectedAmount, 3) : '-'}
                      </Styled.DivisionAmount>
                    </Styled.DivisionTableDataNormal>
                  ))}
                </Styled.DivisionColumn>
              )}
              {!isPlugin && (
                <Styled.DivisionAmount>
                  {entries[0].rejectedAmount ? toLocaleString(entries[0].rejectedAmount, 3) : '-'}
                </Styled.DivisionAmount>
              )}
            </Styled.TableDataReceipt>
            <Styled.TableDataReceipt>
              {((entries[0].acceptedAmount > 0 || entries[0].rejectedAmount > 0) && status !== 'Canceled' && status !== 'Processing') ? (
                <Styled.Division>
                  <ActionCancelDealById
                    key="cancel-deal-by-id"
                    objectId={objectId}
                    compositeKey={[number, rank, slot].join('#')}
                  />
                </Styled.Division>
              ) : <Styled.Division><Styled.Icon.IonicSquare color="#34495e" /></Styled.Division>}
            </Styled.TableDataReceipt>
          </Styled.TableRow>
        ));
      } else {
        rows.push((
          <Styled.TableRow key={compositeKey}>
            <Styled.TableDataReceipt>
              <Styled.DivisionReceiptTicketNumber mode={modee} style={{ opacity: modee === 'pending' ? 0.5 : 1 }}>
                {isPlugin && mode === 'couple' && (
                  <Styled.Small>
                    {translate(`label.${mode}`)}
                  </Styled.Small>
                )}
                {isPlugin && mode !== 'couple' && (
                  <Styled.SmallReceiptTicketLabel>
                    {translate(`label.${mode}`)}
                  </Styled.SmallReceiptTicketLabel>
                )}
                {number}
              </Styled.DivisionReceiptTicketNumber>
            </Styled.TableDataReceipt>
            <Styled.TableDataReceipt>
              {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
            </Styled.TableDataReceipt>
            {status === 'Processing' ? (<Styled.TableDataReceipt mode={'processing'}>
              <Styled.DivisionAmount>
                ???
              </Styled.DivisionAmount>
            </Styled.TableDataReceipt>) : (
              <Styled.TableDataReceipt mode={status === constants.canceled ? 'canceled' : ''}>
                <Styled.DivisionAmount>
                  {entries[0].acceptedAmount ? toLocaleString(entries[0].acceptedAmount, 3) : '-'}
                </Styled.DivisionAmount>
              </Styled.TableDataReceipt>
            )}
            <Styled.TableDataReceipt mode={status === constants.canceled ? 'canceled' : ''}>
              <Styled.DivisionAmount>
                {entries[0].rejectedAmount ? toLocaleString(entries[0].rejectedAmount, 3) : '-'}
              </Styled.DivisionAmount>
            </Styled.TableDataReceipt>
            <Styled.TableDataReceipt>
              {((entries[0].acceptedAmount > 0 || entries[0].rejectedAmount > 0) && status !== 'Canceled'&& status !== 'Corrupted'&&status !== 'Errored'&& status !== 'Rejected' && status !== 'Processing') ? (
                <Styled.Division>
                  <ActionCancelDealById
                    key="cancel-deal-by-id"
                    objectId={objectId}
                    compositeKey={[number, rank, slot].join('#')}
                  />
                </Styled.Division>
              ) : <Styled.Division><Styled.Icon.IonicSquare color="#34495e" /></Styled.Division>}
            </Styled.TableDataReceipt>
          </Styled.TableRow>
        ));
      }
    });
    const codes = Object.keys(systemGameCategoryCodeForWindow);
    const windows = [];
    _.each(codes, code => {
      const window = systemGameCategoryCodeForWindow[code];
      const { numberForEntry } = window;
      const entries = [];
      const numbers = Object.keys(numberForEntry);
      _.each(numbers, number => {
        entries.push({
          quantity: numberForEntry[number],
          number,
        });
      });
      window.entries = entries;
      windows.push(window);
    });
    return rows;
  }, [compositeKeyForBundle, objectId, status, translate]);
  // console.log('systemGameTypes',systemGameTypes)
  const gameTypesBanner = (
    <Styled.DivisionGameTypes>
      {systemGameTypes.map((systemGameType) => {
        return (
          <Styled.Image
            mode={systemGameTypeIdSet.has(systemGameType.objectId) ? 'default' : 'faded'}
            key={systemGameType.objectId}
            minorScaledHeight={4}
            minorScaledWidth={4}
            src={images[systemGameType.alias.replace(/\s/g, '')]}
          />
        );
      })}
    </Styled.DivisionGameTypes>
  );
  const receiptHeader = (
    <Styled.DivisionDesktopReceiptHeader>
      {isLoading && (
        <Styled.Image
          minorScaledHeight={7}
          minorScaledWidth={7}
          src={images.CardLoader}
        />
      )}
      {!isLoading && (
        <React.Fragment>
          {status === constants.approved && (<Styled.Icon.IonicCheckmarkCircle color="#2ecc71" />)}
          {status === constants.bypassed && (<Styled.Icon.IonicCheckmarkCircle color="#3498db" />)}
          {status === constants.canceled && (<Styled.Icon.IonicCloseCircle color="#e74c3c" />)}
          {status === 'Edited' && (<Styled.Icon.IonicAlertCircle color="#8e44ad" />)}
          {status === 'Errored' && (<Styled.Icon.IonicAlertCircle color="#e74c3c" />)}
          {status === 'Rejected' && (<Styled.Icon.IonicAlertCircle color="#e74c3c" />)}

          {status === 'Corrupted' && (<Styled.Icon.IonicAlertCircle color="#e74c3c" />)}
          {status === constants.warned && (<Styled.Icon.IonicAlertCircle color="#f39c12" />)}

          {status.endsWith('ing')&& (<Styled.Icon.IonicSquare color="#34495e" />)}
        </React.Fragment>
      )}
      <TempBammer>
        {gameTypesBanner}
      </TempBammer>
      <Styled.SpanLabel>
       {` #${customerIdentifier !== '-' ? Number(customerIdentifier) : '-'}`}
      </Styled.SpanLabel>
      <Styled.SpanLabel>
        <Styled.SpanTag mode="light">
          {tag}
        </Styled.SpanTag>
        {identifier}
      </Styled.SpanLabel>
      <Styled.SpanLabel>
        {format(new Date(timestamp), 'dd-MM-yy (hh:mm a)')}
      </Styled.SpanLabel>
    </Styled.DivisionDesktopReceiptHeader>
  );
  const totalAccept = [];
  if (status !== constants.canceled && totalAcceptedAmount) {
    totalAccept.push(toLocaleString(totalAcceptedAmount, 3));
    if (totalAcceptedAddonAmount > 0) {
      totalAccept.push(`(${toLocaleString(totalAcceptedAmount - totalAcceptedAddonAmount, 3)})`);
    }
  } else {
    totalAccept.push('-');
  }

  const totalReject = [];
  if (status !== constants.canceled && totalRejectedAmount) {
    totalReject.push(toLocaleString(totalRejectedAmount, 3));
    if (totalRejectedAddonAmount > 0) {
      totalReject.push(`(${toLocaleString(totalRejectedAmount - totalRejectedAddonAmount, 3)})`);
    }
  } else {
    totalReject.push('-');
  }


  return (
    <Styled.TableReceipt>
      {(status === 'Errored' || status === 'Rejected'|| status === 'Corrupted') && (
        <TempRow>
          {translate(lastActivity.note)}
        </TempRow>
      )}
      <Styled.TableHeader>
        <Styled.TableRow>
          <Styled.TableHeadingReceipt colSpan={6}>
            {receiptHeader}
          </Styled.TableHeadingReceipt>
        </Styled.TableRow>
        <Styled.TableRow>
          <Styled.TableHeadingReceipt>
            {translate('title.number')}
          </Styled.TableHeadingReceipt>
          <Styled.TableHeadingReceipt>
            {translate('title.category')}
          </Styled.TableHeadingReceipt>
          <Styled.TableHeadingReceipt>
            {translate('title.accepted')}
          </Styled.TableHeadingReceipt>
          <Styled.TableHeadingReceipt>
            {translate('title.rejected')}
          </Styled.TableHeadingReceipt>
          <Styled.TableHeadingReceipt>
            {translate('title.actions')}
          </Styled.TableHeadingReceipt>
        </Styled.TableRow>
      </Styled.TableHeader>
      <Styled.TableBodyPortal>
        {desktopReceiptConten}
      </Styled.TableBodyPortal>
      <Styled.TableFooterPortal>
        <Styled.TableRow>
          <Styled.TableDataReceipt colSpan={2}>
            <Styled.Division>
              {actions}
            </Styled.Division>
          </Styled.TableDataReceipt>
          <Styled.TableDataReceipt>
            <Styled.DivisionAmount>
              {totalAccept.join(' ')}
            </Styled.DivisionAmount>
          </Styled.TableDataReceipt>
          <Styled.TableDataReceipt>
            <Styled.DivisionAmount>
              {totalReject.join(' ')}
            </Styled.DivisionAmount>
          </Styled.TableDataReceipt>
          <Styled.TableDataReceipt>
            -
          </Styled.TableDataReceipt>
        </Styled.TableRow>
      </Styled.TableFooterPortal>
    </Styled.TableReceipt>
  );
};
BitReceipt.displayName = 'BitReceipt';
BitReceipt.defaultProps = {
  actions: [],
  isFullscreen: false,
  isLoading: false,
};
BitReceipt.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any),
  activities: PropTypes.arrayOf(PropTypes.any).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerIdentifier: PropTypes.string.isRequired,
  customerUsername: PropTypes.string.isRequired,
  drawDate: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool,
  isLoading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  totalAcceptedAddonAmount: PropTypes.number.isRequired,
  totalAcceptedAmount: PropTypes.number.isRequired,
  totalRejectedAddonAmount: PropTypes.number.isRequired,
  totalRejectedAmount: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
};
export default React.memo(BitReceipt);
