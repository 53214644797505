/**
 * Node modules
 */
import Dropdown from 'antd/lib/dropdown';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntDropdown = (props) => {
  const {
    isDisabled,
    menuOverlay,
    ...rest
  } = props;
  return (
    <Dropdown
      disabled={isDisabled}
      getPopupContainer={trigger => trigger.parentNode}
      overlay={menuOverlay}
      {...rest}
    />
  );
};
VendorAntDropdown.defaultProps = { isDisabled: false };
VendorAntDropdown.displayName = 'VendorAntDropdown';
VendorAntDropdown.propTypes = {
  isDisabled: PropTypes.bool,
  menuOverlay: PropTypes.node.isRequired,
};
export default VendorAntDropdown;
