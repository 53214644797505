/**
 * Node modules
 */
import { Location } from '@reach/router';
import React from 'react';
import _ from 'lodash';
import {
  isPlatform,
  setupConfig,
} from '@ionic/react';

/**
 * Bootstraps
 */
import BootstrapPublicEntry from '../PublicEntry';

/**
 * Components
 */
import * as Bit from '../../../components/Bit';

/**
 * Configurations
 */
import { app } from '../../../../config';

/**
 * Helpers
 */
import {
  Contexts,
  hooks,
} from '../../../../helpers';

/**
 * Private functions
 */
const getConfig = () => {
  const config = {
    mode: 'ios',
    tabsHideOnSubPages: true,
  };
  if (isPlatform('android')) {
    config.mode = 'md';
  }
  return config;
};
setupConfig(getConfig());

/**
 * Function hooks
 */
const useSystemQueriedBundle = () => hooks.useQueriedBundle({
  actionType: 'GET_SYSTEM_BY_ID',
  by: app.SITE_ID,
  hasToken: false,
  params: {
    objectId: app.SITE_ID,
  },
});

/**
 * Functional components
 */
const BootstrapEntry = () => {
  // let hidden = null;
  // let visibilityChange = null;
  // if (typeof document.hidden !== 'undefined') {
  //   hidden = 'hidden';
  //   visibilityChange = 'visibilitychange';
  // } else if (typeof document.msHidden !== 'undefined') {
  //   hidden = 'msHidden';
  //   visibilityChange = 'msvisibilitychange';
  // } else if (typeof document.webkitHidden !== 'undefined') {
  //   hidden = 'webkitHidden';
  //   visibilityChange = 'webkitvisibilitychange';
  // }
  // const [isReloading, setIsReloading] = React.useState(false);
  // const [isMetaLoading, setIsMetaLoading] = React.useState(true);
  // const [isLatestVersion, setIsLatestVersion] = React.useState(null);
  // const handleCheckVersion = React.useCallback(() => {
  //   const headers = new Headers();
  //   headers.append('pragma', 'no-cache');
  //   headers.append('cache-control', 'no-cache');
  //   const request = new Request('/meta.json');
  //   const options = {
  //     headers,
  //     method: 'GET',
  //   };
  //   fetch(request, options)
  //     .then(response => response.json())
  //     .then((meta) => {
  //       setIsMetaLoading(false);
  //       const latestVersion = meta.version;
  //       const currentVersion = app.VERSION;
  //       if (latestVersion && latestVersion !== currentVersion) {
  //         setIsLatestVersion(false);
  //       } else {
  //         setIsLatestVersion(true);
  //       }
  //     });
  // }, []);
  // const handleVisibilityChange = React.useCallback(() => {
  //   if (!document[hidden]) {
  //     handleCheckVersion();
  //   } else {
  //     setIsLatestVersion(null);
  //   }
  // }, [handleCheckVersion, hidden]);
  // React.useEffect(() => {
  //   if (isLatestVersion === false) {
  //     setIsReloading(true);
  //     setTimeout(() => {
  //       if (caches) {
  //         caches.keys().then((names) => {
  //           for (let i = 0; i < names.length; i += 1) {
  //             caches.delete(names[i]);
  //           }
  //         });
  //       }
  //       window.location.reload(true);
  //     }, 3000);
  //   } else if (isLatestVersion === null) {
  //     handleCheckVersion();
  //   }
  // }, [handleCheckVersion, isLatestVersion]);
  // React.useEffect(() => {
  //   const isSupported = !(typeof document.addEventListener === 'undefined' || hidden === undefined);
  //   if (isSupported) {
  //     document.addEventListener(visibilityChange, handleVisibilityChange, false);
  //   }
  //   return () => {
  //     if (isSupported) {
  //       document.removeEventListener(visibilityChange, handleVisibilityChange);
  //     }
  //   };
  // }, [handleVisibilityChange, hidden, visibilityChange]);
  // const systemQueriedBundle = useSystemQueriedBundle();

  const systemQueriedBundle = {
    isDocumentLoading: false,
    isFirstBoot: false,
    isPermitted: true,
    request: {
      count: 1,
      status: 'Ready',
    },
    response: {
      payload: { alias: '24abc', identity: '24abc', objectId: '11eb2f5d-fe4e-9940-b7b2-bb2805e0ecde' },
    },
  };
  const system = systemQueriedBundle.response.payload;
  const [locale, setLocale] = React.useState(localStorage.getItem(`${app.SITE_ID}#locale`) || 'th-TH');
  const [shareReceiptFormat, setShareReceiptFormat] = React.useState(localStorage.getItem(`${app.SITE_ID}#share-receipt-format`) || 'Image');
  const [invoiceFormat, setInvoiceFormat] = React.useState(localStorage.getItem(`${app.SITE_ID}#invoice-format`) || 'Identifier');
  const [terminalFontSize, setTerminalFontSize] = React.useState(localStorage.getItem(`${app.SITE_ID}#terminal-font-size`) || 'Medium');
  const [showDeepDownlines, setShowDeepDownlines] = React.useState(localStorage.getItem(`${app.SITE_ID}#show-deep-downlines`) || 'No');
  const [buttonSpacing, setButtonSpacing] = React.useState(localStorage.getItem(`${app.SITE_ID}#button-spacing`) || 'Small');
  const [canTakeNote, setCanTakeNote] = React.useState(localStorage.getItem(`${app.SITE_ID}#can-take-note`) || 'No');
  const [isUsingKeypad, setIsUsingKeypad] = React.useState(localStorage.getItem(`${app.SITE_ID}#is-using-keypad`) || 'No');
  const [keypadHeight, setKeypadHeight] = React.useState(localStorage.getItem(`${app.SITE_ID}#keypad-height`) || 'Medium');
  if (systemQueriedBundle.isFirstBoot || _.isEmpty(system)) {
    return (<Bit.SuspenseScreen />);
  }
  return (
    <Contexts.SystemBundle.Provider value={systemQueriedBundle}>
      <Contexts.SetLocale.Provider value={setLocale}>
        <Contexts.Locale.Provider value={locale}>
          <Contexts.SetTerminalFontSize.Provider value={setTerminalFontSize}>
            <Contexts.TerminalFontSize.Provider value={terminalFontSize}>
              <Contexts.SetKeypadHeight.Provider value={setKeypadHeight}>
                <Contexts.KeypadHeight.Provider value={keypadHeight}>
                  <Contexts.ShareReceiptFormat.Provider value={shareReceiptFormat}>
                    <Contexts.SetShareReceiptFormat.Provider value={setShareReceiptFormat}>
                      <Contexts.SetButtonSpacing.Provider value={setButtonSpacing}>
                        <Contexts.ButtonSpacing.Provider value={buttonSpacing}>
                          <Contexts.InvoiceFormat.Provider value={invoiceFormat}>
                            <Contexts.SetInvoiceFormat.Provider value={setInvoiceFormat}>
                              <Contexts.SetCanTakeNote.Provider value={setCanTakeNote}>
                                <Contexts.CanTakeNote.Provider value={canTakeNote}>
                                  <Contexts.SetIsUsingKeypad.Provider value={setIsUsingKeypad}>
                                    <Contexts.IsUsingKeypad.Provider value={isUsingKeypad}>
                                      <Contexts.ShowDeepDownlines.Provider value={showDeepDownlines}>

                                        <Contexts.SetShowDeepDownlines.Provider value={setShowDeepDownlines}>
                                          <Location>
                                            <BootstrapPublicEntry />
                                          </Location>
                                        </Contexts.SetShowDeepDownlines.Provider>
                                      </Contexts.ShowDeepDownlines.Provider>
                                    </Contexts.IsUsingKeypad.Provider>
                                  </Contexts.SetIsUsingKeypad.Provider>
                                </Contexts.CanTakeNote.Provider>
                              </Contexts.SetCanTakeNote.Provider>
                            </Contexts.SetInvoiceFormat.Provider>
                          </Contexts.InvoiceFormat.Provider>
                        </Contexts.ButtonSpacing.Provider>
                      </Contexts.SetButtonSpacing.Provider>
                    </Contexts.SetShareReceiptFormat.Provider>
                  </Contexts.ShareReceiptFormat.Provider>
                </Contexts.KeypadHeight.Provider>
              </Contexts.SetKeypadHeight.Provider>
            </Contexts.TerminalFontSize.Provider>
          </Contexts.SetTerminalFontSize.Provider>
        </Contexts.Locale.Provider>
      </Contexts.SetLocale.Provider>
    </Contexts.SystemBundle.Provider>
  );
};
BootstrapEntry.displayName = 'BootstrapEntry';
export default React.memo(BootstrapEntry);
