/**
 * Node modules
 */
import React from 'react';
import Tabs from 'antd/lib/tabs';

/**
 * Functional components
 */
const VendorAntTabPane = props => (<Tabs.TabPane {...props} />);
VendorAntTabPane.displayName = 'VendorAntTabPane';
export default VendorAntTabPane;
