/**
 * Betlytical modules
 */
import { requestSchemas } from '@betlytical/api-schema';

/**
 * Constant variables
 */
const validationSchemas = {};

/**
 * Functions
 */
const values = Object.values(requestSchemas);
for (let i = 0; i < values.length; i += 1) {
  const value = values[i];
  const entries = Object.entries(value);
  for (let j = 0; j < entries.length; j += 1) {
    const [key, validationSchema] = entries[j];
    validationSchemas[key] = validationSchema;
  }
}
export default validationSchemas;
