/**
 * Node modules
 */
import React from 'react';

/**
 * Betlytical modules
 */
import { permissionIdentities } from '@betlytical/api-schema';

/**
 * Lazy components
 */
const LazyPageSystemPermissions = React.lazy(() => import('../../application/containers/Page/System/Permissions'));
const LazyPageSystemTranslations = React.lazy(() => import('../../application/containers/Page/System/Translations'));
export default {
  children: [
    {
      LazyComponent: LazyPageSystemPermissions,
      name: 'permissions',
      path: 'permissions',
      permissionIdentity: permissionIdentities.GET_SYSTEM_PERMISSION,
      section: '1.0.0',
    },
    {
      LazyComponent: LazyPageSystemTranslations,
      name: 'translations',
      path: 'translations',
      permissionIdentity: permissionIdentities.GET_SYSTEM_TRANSLATION,
      section: '1.0.1',
    },
  ],
};
