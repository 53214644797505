/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import Table from 'antd/lib/table';

/**
 * Functional components
 */
const VendorAntTable = (props) => {
  const {
    hasPagination,
    hasSortTooltip,
    isBordered,
    isLoading,
    source,
    ...rest
  } = props;
  return (
    <Table
      bordered={isBordered}
      dataSource={source}
      loading={isLoading}
      pagination={hasPagination}
      scroll={{ x: '100%' }}
      showSorterTooltip={hasSortTooltip}
      {...rest}
    />
  );
};
VendorAntTable.defaultProps = {
  hasPagination: false,
  hasSortTooltip: false,
  isBordered: false,
  isLoading: false,
};
VendorAntTable.displayName = 'VendorAntTable';
VendorAntTable.propTypes = {
  hasPagination: PropTypes.bool,
  hasSortTooltip: PropTypes.bool,
  isBordered: PropTypes.bool,
  isLoading: PropTypes.bool,
  source: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default VendorAntTable;
