/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntLayout from '../../Vendor/Ant/Layout';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntLayout.Footer)`
  margin-top: auto;
  padding: ${scale.major(2)}px;
  text-align: center;
`;
