/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  align-items: center;
  color: rgba(39, 174, 96, 1);
  display: flex;
  margin-left: ${scale.minor(1)}px;
  :not(:last-child) {
    ::after {
      color: rgba(0, 0, 0, 0.65);
      content: '|';
      margin: 0 ${scale.minor(1)}px;
    }
  }
  img {
    margin-right: ${scale.minor(1)}px;
  }
`;
