/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */

const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'pending': {
      styles.push('background-color: rgba(52, 152, 219, 0.2)');
      break;
    }
    case 'corrupted': {
      styles.push('background-color: rgba(231, 76, 60, 0.2);');
      break;
    }
    case 'rejected': {
      styles.push('background-color: rgba(231, 76, 60, 0.2);');
      break;
    }
    case 'whole': {
      styles.push('background-color: rgba(46, 204, 113, 0.2);');
      break;
    }
    case 'partial': {
      styles.push('background-color: rgba(241, 196, 15, 0.2);');
      break;
    }
    case 'edited': {
      styles.push('background-color: rgba(142, 68, 173, 0.2);');
      break;
    }
    default: {
      styles.push('background-color: rgba(52, 73, 94, 1.0);');
      styles.push('color: white;');
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 12px;
  min-width: 60px;
  padding: 4px 0;
  > small {
    width: auto;
  }
`;
