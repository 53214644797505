/**
 * Node modules
 */
import styled from 'styled-components';
import { scale } from '../.private';
import { Link } from '@reach/router';


/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'hide': {
      styles.push('visibility: hidden;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
  }
`;
