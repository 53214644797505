/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import {
  format,
  isBefore,
} from 'date-fns';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitLayoutPanel = (props) => {
  const {
    code,
    drawsOpenBundle,
    title,
  } = props;
  const translate = useTranslate();
  const {
    isFirstBoot,
    response,
  } = drawsOpenBundle;
  const activeDraw = response.payload ? response.payload[0] : {};
  const systemTime = hooks.useSystemTime();
  return (
    <Styled.DivisionDesktopPanel code={code}>
      <Styled.DivisionDesktopPanelContent>
        <Styled.SpanDesktopPanelTitle>
          {title}
        </Styled.SpanDesktopPanelTitle>
        {isFirstBoot && (
          <Styled.Image
            majorScaledHeight={8}
            majorScaledWidth={8}
            src={images.DiceLoader}
          />
        )}
        {!isFirstBoot && _.isEmpty(activeDraw) && translate('label.no-active-draws')}
        {!_.isEmpty(activeDraw) && (
          <Styled.DivisionDesktopPanelBanner>
            {activeDraw.drawSystemGameTypes.map((drawSystemGameType) => {
              const {
                dateClosingTime,
                objectId,
              } = drawSystemGameType;
              const resolvedAlias = utilities.getResolvedValue(drawSystemGameType, 'alias');
              const logo = resolvedAlias.replace(/\s/g, '');
              const isOpen = isBefore(new Date(systemTime), new Date(dateClosingTime));
              return (
                <Styled.DivisionImageCard
                  key={objectId}
                  mode="banner"
                >
                  <Styled.Image
                    majorScaledHeight={4}
                    majorScaledWidth={4}
                    src={images[logo]}
                  />
                  <Styled.Small name="small">
                    {format(new Date(dateClosingTime), 'dd-MM-yy')}
                  </Styled.Small>
                  <Styled.Small name="small">
                    {format(new Date(dateClosingTime), 'hh:mm a')}
                  </Styled.Small>
                  <Styled.Small mode={isOpen ? 'open' : 'close'}>
                    {isOpen ? 'Open' : 'Closed'}
                  </Styled.Small>
                </Styled.DivisionImageCard>
              );
            })}
          </Styled.DivisionDesktopPanelBanner>
        )}
      </Styled.DivisionDesktopPanelContent>
    </Styled.DivisionDesktopPanel>
  );
};
BitLayoutPanel.defaultProps = { code: undefined };
BitLayoutPanel.displayName = 'BitLayoutPanel';
BitLayoutPanel.propTypes = {
  code: PropTypes.string,
  drawsOpenBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.node.isRequired,
};
export default React.memo(BitLayoutPanel);
