/**
 * Node modules
 */
import styled from 'styled-components';

export default styled.hr`
  background: #95a5a6;
  border:none;
  height:1px;
  position: relative;
  width: 20px;
`;
