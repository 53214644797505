/**
 * Node modules
 */
import axios from 'axios';

/**
 * Configurations
 */
import { app } from '../../../config';

export default async (input) => {
  try {
    const {
      body,
      method,
      params,
      query,
      url,
    } = input;
    const token = localStorage.getItem(`${app.SITE_ID}#token`);
    const requesting = {
      headers: {
        'Accept': 'application/json',
        'Authorization': token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json; charset=utf-8',
      },
      method,
      url,
      validateStatus: null,
      withCredentials: true,
    };

    if (params) {
      const link = new URL(url);
      const links = [];
      link.pathname.split('/').forEach((chunk) => {
        if (chunk.startsWith(':')) {
          const key = chunk.replace(/:/g, '');
          if (params[key]) {
            links.push(params[key]);
          }
        } else {
          links.push(chunk);
        }
      });
      link.pathname = links.join('/');
      requesting.url = link.href.replace(/\?/g, '');
    }
    requesting.params = query;
    requesting.data = body;
    const t0 = new Date().valueOf();
    const {
      data: payload,
      headers,
    } = await axios(requesting);
    if (url.includes('api/accounts/account/:accountId/permissions')) {
      const t3 = new Date().valueOf();
      const t1 = new Date(Number(headers['private-request-time'])).valueOf();
      const t2 = new Date(Number(headers['private-response-time'])).valueOf();
      const offset = ((t1 - t0) - (t2 - t3)) / 2;
      if (!Number.isNaN(offset)) {
        localStorage.setItem(`${app.SITE_ID}#server-date`, String(t2));
        localStorage.setItem(`${app.SITE_ID}#time-offset`, String(((t1 - t0) - (t2 - t3)) / 2));
      }
    }
    const metadata = JSON.parse(headers['private-metadata']);
    delete headers['private-metadata'];
    delete headers['private-request-time'];
    delete headers['private-response-time'];
    const {
      message,
      status,
    } = metadata;
    if (status === 'error') {
      if (message.includes('invalid-account-session')) {
        window.location.reload();
      }
      const err = new Error(message);
      err.metadata = metadata;
      throw err;
    } else if (status === 'success') {
      return {
        headers,
        metadata,
        payload,
      };
    } else {
      throw new Error('...');
    }
  } catch (e) {
    if (e.message.includes('timeout')) {
      const err = new Error('message.error.connection-problem-occurred');
      err.metadata = {
        message: err.message,
        status: 'error',
      };
      throw err;
    }
    throw e;
  }
};
