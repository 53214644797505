/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${scale.major(3)}px;
  font-weight: bold;
  justify-content: center;
  margin-top: ${scale.major(1)}px;
  word-break: break-all;
  > small {
    margin-top: -4px;
    width: auto;
    font-weight: normal;
    font-size: ${scale.minor(3)}px;
  }
`;
