/**
 * Internals
 */
import actions from './actions';

/**
 * Constant variables
 */
const handlers = {
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            metadata,
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FULFILL]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            count: state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by].count ? state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_RESET]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            headers: {},
            metadata: {},
            payload: {},
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_REQUEST]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: true,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            status: 'REQUEST',
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeAteVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeAteVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeAteVolumeByCustomerId[by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            metadata,
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FULFILL]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            count: state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by].count ? state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_RESET]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            headers: {},
            metadata: {},
            payload: {},
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_REQUEST]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: true,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            status: 'REQUEST',
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeOverallVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeOverallVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeOverallVolumeByCustomerId[by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            metadata,
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FULFILL]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            count: state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by].count ? state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_RESET]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            headers: {},
            metadata: {},
            payload: {},
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_REQUEST]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.isLoading.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: true,
        },
      },
      request: {
        ...state.request,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            status: 'REQUEST',
          },
        },
      },
    };
  },
  [actions.types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.request.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        getDrawSystemGameTypeTankedVolumeByCustomerId: {
          ...state.response.getDrawSystemGameTypeTankedVolumeByCustomerId,
          [by]: {
            ...state.response.getDrawSystemGameTypeTankedVolumeByCustomerId[by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  },
};
const initialState = {
  isLoading: {
    getDrawSystemGameTypeAteVolumeByCustomerId: {},
    getDrawSystemGameTypeOverallVolumeByCustomerId: {},
    getDrawSystemGameTypeTankedVolumeByCustomerId: {},
  },
  request: {
    getDrawSystemGameTypeAteVolumeByCustomerId: {},
    getDrawSystemGameTypeOverallVolumeByCustomerId: {},
    getDrawSystemGameTypeTankedVolumeByCustomerId: {},
  },
  response: {
    getDrawSystemGameTypeAteVolumeByCustomerId: {},
    getDrawSystemGameTypeOverallVolumeByCustomerId: {},
    getDrawSystemGameTypeTankedVolumeByCustomerId: {},
  },
};
export default {
  handlers,
  initialState,
};
