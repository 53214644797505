/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  background-color: rgba(44, 44, 44, 1);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-left: ${scale.minor(5)}px;
  padding-right: ${scale.minor(5)}px;
  > span {
    color: white;
    font-weight: bold;
    margin-top: ${scale.major(1)}px;
  }
`;
