/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'pending': {
      styles.push('background-color: rgba(52, 152, 219, 0.2)');


      break;
    }
    case 'partial': {
      styles.push('background-color: rgba(241, 196, 15, 0.2);');
      break;
    }
    case 'rejected': {
      styles.push('background-color: rgba(231, 76, 60, 0.2);');
      break;
    }
    case 'edited': {
      styles.push('background-color: rgba(142, 68, 173, 0.2);');
      break;
    }
    default: {
      styles.push('background-color: rgba(46, 204, 113, 0.2);');
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(189, 195, 199, 1);
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 8px;
  padding: 1px 4px;
  > span:last-child {
    margin-left: 4px;
  }
`;
