/**
 * Node modules
 */
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

/**
 * Constant variables
 */
const ajv = new Ajv({
  removeAdditional: 'all',
  useDefaults: 'empty',
});
addFormats(ajv);

/**
 * Functions
 */
const validate = (schema, value) => typeof value === 'string' && value.trim() !== '';
ajv.addKeyword({
  errors: false,
  keyword: 'isNotEmpty',
  type: 'string',
  validate,
});
export default ajv;
