/**
 * Node modules
 */
import React from 'react';

/**
 * Lazy components
 */
const LazyPagePortalDrawReport = React.lazy(() => import('../../application/containers/Page/Portal/DrawReport'));
const LazyPagePortalDrawReportVolume = React.lazy(() => import('../../application/containers/Page/Portal/DrawReportVolume'));
const LazyPagePortalDrawReportStrikeOverview = React.lazy(() => import('../../application/containers/Page/Portal/DrawReportStrikeOverview'));
const LazyPagePortalRunnerCreationWizard = React.lazy(() => import('../../application/containers/Page/Portal/RunnerCreationWizard'));
const LazyPagePortalRunnerGuidingWizard = React.lazy(() => import('../../application/containers/Page/Portal/RunnerGuidingWizard'));
const LazyPagePortalSystemTranslation = React.lazy(() => import('../../application/containers/Page/Portal/SystemTranslation'));
const LazyPagePortalCustomerRequestLimitPackage = React.lazy(() => import('../../application/containers/Page/Portal/CustomerRequestLimitPackage'));
export default {
  children: [
    {
      LazyComponent: LazyPagePortalCustomerRequestLimitPackage,
      path: 'customer/:customerId/system-game/:systemGameId/request-limit-package/:model',
    },
    {
      LazyComponent: LazyPagePortalDrawReport,
      children: [
        {
          LazyComponent: LazyPagePortalDrawReportVolume,
          isHidden: true,
          path: 'downline/:downlineId/volume/:volumeMode',
        },
        {
          LazyComponent: LazyPagePortalDrawReportStrikeOverview,
          isHidden: true,
          path: 'overview/:overviewMode',
        },
      ],
      path: 'report/:reportKey/system-game-type/:systemGameTypeId/customer/:customerId',
    },
    {
      LazyComponent: LazyPagePortalRunnerCreationWizard,
      path: 'runner-creation-wizard/account/:username/system-game/:systemGameId',
    },
    {
      LazyComponent: LazyPagePortalRunnerGuidingWizard,
      path: 'runner-guiding-wizard/account/:username/system-game/:systemGameId/customer/:customerId',
    },
    {
      LazyComponent: LazyPagePortalSystemTranslation,
      path: 'system-translations',
    },
  ],
};
