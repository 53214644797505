/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Functional components
 */
const BitLayoutBrand = (props) => {
  const {
    hasAlias = true,
    hasLogo = false,
    isLoginLayout,
    isSidebarLayout,
    onMenuBarClick: handleMenuBarClick,
    systemBundle,
    systemGame,
  } = props;
  const systemGameKey = _.kebabCase(systemGame.alias);
  const Component = isLoginLayout ? Styled.SpanLink : Styled.RouterLinkBrand;
  return systemGameKey && (
    <React.Fragment>
      {!isSidebarLayout && (
        <Component to={systemGameKey ? `/${systemGameKey}` : '/'}>
          {hasLogo && (<Styled.ImageBrand src={images.App} />)}
          {hasAlias && (
            <Styled.SpanBrand>
              {systemBundle.response.payload.alias}
            </Styled.SpanBrand>
          )}
        </Component>
      )}
      {isSidebarLayout && handleMenuBarClick && (
        <Styled.ButtonMenuBar onClick={handleMenuBarClick}>
          <Styled.Icon.IonicMenu />
        </Styled.ButtonMenuBar>
      )}
    </React.Fragment>
  );
};
BitLayoutBrand.defaultProps = {
  hasAlias: true,
  hasLogo: false,
  isLoginLayout: false,
  isSidebarLayout: false,
  onMenuBarClick: _.noop,
};
BitLayoutBrand.displayName = 'BitLayoutBrand';
BitLayoutBrand.propTypes = {
  hasAlias: PropTypes.bool,
  hasLogo: PropTypes.bool,
  isLoginLayout: PropTypes.bool,
  isSidebarLayout: PropTypes.bool,
  onMenuBarClick: PropTypes.func,
  systemBundle: PropTypes.objectOf(PropTypes.any).isRequired,
  systemGame: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default React.memo(BitLayoutBrand);
