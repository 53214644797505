/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'antd/lib/tag';

/**
 * Functional components
 */
const VendorAntTag = (props) => {
  const {
    isClosable,
    ...rest
  } = props;
  return (
    <Tag
      closable={isClosable}
      {...rest}
    />
  );
};
VendorAntTag.defaultProps = { isClosable: false };
VendorAntTag.displayName = 'VendorAntTag';
VendorAntTag.propTypes = { isClosable: PropTypes.bool };
export default VendorAntTag;
