/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';
import { VariableSizeGrid } from 'react-window';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitBoard from '../Board';
import BitMask from '../Mask';
import BitPacket from '../Packet';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import VendorAntTooltip from '../../Vendor/Ant/Tooltip';

/**
 * Functional components
 */
const BitPacketBoards = (props) => {
  const {
    entries,
    systemGameCategoryAlias,
    systemGameCategoryCode,
  } = props;
  const searchInputRef = React.useRef(null);
  const [currentSearchValue, setCurrentSearchValue] = React.useState('');
  const [currentEntries, setCurrentEntries] = React.useState(_.orderBy(entries, ['quantity', 'number'], ['desc', 'asc']));
  React.useEffect(() => {
    setCurrentEntries(_.orderBy(entries, ['quantity', 'number'], ['desc', 'asc']));
  }, [entries]);
  const handleSearchChange = React.useCallback((text) => {
    if (!text) {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
      setCurrentSearchValue('');
    } else {
      setCurrentSearchValue(text);
    }
  }, []);
  const translate = useTranslate();
  const debouncedSearch = _.debounce(handleSearchChange, 500);
  const debouncedHandleSearchChange = React.useCallback(e => debouncedSearch(e.target.value), [debouncedSearch]);
  const handleSearchClear = React.useCallback(() => handleSearchChange(''), [handleSearchChange]);
  const filteredEntries = React.useMemo(() => {
    const list = [];
    if (currentSearchValue) {
      for (let i = 0; i < currentEntries.length; i += 1) {
        if (currentEntries[i].number.replace(/\D/g, '').includes(currentSearchValue)) {
          list.push({
            rank: i + 1,
            ...currentEntries[i],
          });
        }
      }
      return list;
    }
    return currentEntries;
  }, [currentSearchValue, currentEntries]);
  return React.useMemo(() => {
    const windowContent = (
      <Styled.DivisionWindow>
        <Styled.DivisionWindowHeader>
          <Styled.DivisionWindowHeaderLabel mode="number">
            {translate('title.number')}
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabelBad mode="value">
            <Styled.DivisionAmount>
              {translate('title.value')}
            </Styled.DivisionAmount>
          </Styled.DivisionWindowHeaderLabelBad>
        </Styled.DivisionWindowHeader>
        <VariableSizeGrid
          columnCount={2}
          columnWidth={columnIndex => [100, 132][columnIndex]}
          height={400}
          rowCount={filteredEntries.length}
          rowHeight={() => 40}
          width={252}
        >
          {(window) => {
            const {
              columnIndex,
              rowIndex,
              style,
            } = window;
            const {
              number,
              quantity,
            } = filteredEntries[rowIndex];
            return (
              <Styled.DivisionWindowRow
                mode={(rowIndex + 1) % 2 === 0 ? 'even' : 'odd'}
                style={style}
              >
                {columnIndex === 0 && (
                  <Styled.DivisionTicketNumber>
                    {number}
                  </Styled.DivisionTicketNumber>
                )}
                {columnIndex === 1 && (
                  <Styled.Division>
                    <Styled.SpanRightAlign>
                      {utilities.toLocaleString(quantity, 2)}
                    </Styled.SpanRightAlign>
                  </Styled.Division>
                )}
              </Styled.DivisionWindowRow>
            );
          }}
        </VariableSizeGrid>
        <Styled.DivisionWindowFooterBad>
          <Styled.Span>
            {`${translate('label.entries')}: ${currentEntries.length.toLocaleString()}`}
          </Styled.Span>
          <Styled.Span>
            {utilities.toLocaleString(_.sumBy(currentEntries, 'quantity'), 2)}
          </Styled.Span>
        </Styled.DivisionWindowFooterBad>
      </Styled.DivisionWindow>
    );
    const searchClear = currentSearchValue !== undefined && (
      <Styled.DivisionSearchClear onClick={handleSearchClear}>
        <Styled.Icon.IonicClose />
      </Styled.DivisionSearchClear>
    );
    const title = (
      <Styled.DivisionWindowTitle>
        <Styled.Division>
          {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
        </Styled.Division>
        <Styled.DivisionSearch>
          <Styled.DivisionTableHandlerIcon>
            <Styled.Icon.IonicSearch />
          </Styled.DivisionTableHandlerIcon>
          <Styled.InputSearch
            onChange={debouncedHandleSearchChange}
            placeholder={`${translate('label.placeholder.search')}...`}
            ref={searchInputRef}
          />
          {searchClear}
        </Styled.DivisionSearch>
      </Styled.DivisionWindowTitle>
    );
    return (
      <BitPacket
        mode="window"
        title={title}
        windowContent={windowContent}
      />
    );
  }, [currentEntries, currentSearchValue, debouncedHandleSearchChange, filteredEntries, handleSearchClear, systemGameCategoryAlias, systemGameCategoryCode, translate]);
};
BitPacketBoards.displayName = 'BitPacketBoards';
BitPacketBoards.propTypes = {
  contentKey: PropTypes.string,
  dataType: PropTypes.string,
  handleShowRecipes: PropTypes.func,
  isInput: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  maskOptions: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  path: PropTypes.string,
  possibleLinkKeySet: PropTypes.instanceOf(Set),
  recommendedContentKey: PropTypes.string,
  referenceContentKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  symbol: PropTypes.node,
  type: PropTypes.string,
  unit: PropTypes.node,
};
export default React.memo(BitPacketBoards);
