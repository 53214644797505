/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode, type} = props;
  const styles = [];
  switch (mode) {
    case 'selected': {
      styles.push('background-color: rgba(27, 37, 41, 1);');
      break;
    }
    default: {
      break;
    }
  }
  if (type === 'def') {
    styles.push(`
    :first-child {
    height: ${scale.minor(9)}px;
    > span:nth-child(2) {
      font-weight: bold;
      font-size: 80%;
      margin-left: ${scale.major(1)}px;
    }
  }
  `)
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: ${scale.major(1)}px;
  padding-right: ${scale.major(1)}px;
  img {
    border-radius: 8px;
    box-sizing: content-box;
    height: ${scale.minor(9)}px;
    margin: ${scale.minor(1)}px;
    width: ${scale.minor(9)}px;
  }
`;
