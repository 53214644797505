/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'downline': {
      styles.push('background-color: rgba(46, 204, 113, 0.1);');
      styles.push('min-width: 100px;');
      break;
    }
    case 'self': {
      styles.push('background-color: rgba(241,196,15,0.1);');
      styles.push('min-width: 100px;');
      break;
    }
    case 'upline': {
      styles.push('background-color: rgba(155, 89, 182, 0.1);');
      styles.push('min-width: 100px;');
      break;
    }
    case 'coin': {
      styles.push('background-color: rgba(236, 240, 241, 1.0);');
      styles.push('min-width: 60px;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.td`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  border: ${scale.minor(0.25)}px solid rgba(189, 195, 199, 0.5);
  padding: ${scale.minor(1)}px;
`;
