/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  animation: blink 0.8s infinite;
  background: lime;
  height: ${scale.minor(5)}px;
  margin-left: ${scale.minor(0.25)}px;
  width: 0.2vh;
  @keyframes blink {
    0% {
      background: rgba(38, 50, 56, 1);
    }
    50% {
      background: rgba(255, 204, 0, 1);
    }
    100% {
      background: rgba(38, 50, 56, 1);
    }
  }
`;
