/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import * as Styled from '../../Styled';
import XLSX, {read } from 'xlsx';
/**
 * Internals
 */
import BitTerminalLine from '../TerminalLine';
import VendorAntInputTextArea from '../../Vendor/Ant/Input/TextArea';
import VendorAntForm from '../../Vendor/Ant/Form';
import { hooks, utilities } from '../../../../helpers';

/**
 * Functional components
 */

const isTextValid = (playCode) => {
  const {
    index,
    isNoteFocused,
    lines,
  } = playCode;
  const { text } = lines[index];
  const [number, ...amounts] = text.split('#');
  if (text.endsWith('**')) {
    return true;
  }
  if (index > 0) {
    const previousLine = lines[index - 1];
    const { text: previousText } = previousLine;
    const [previousNumber] = previousText.split('#');
    if (number.length === previousNumber.length && lines[index].schemeId === previousLine.schemeId && amounts.length === 1 && amounts[0] === '') {
      return true;
    }
  }
  // eslint-disable-next-line prefer-named-capture-group
  return text.match(/^(?:(?:\*[0-9]+)(?:#[0-9]+)+)$|^(?:(?:[0-9]+)(?:#(:?[0-9]+)?\*?[0-9]+)+)$/);
};
const shouldAsteriskDisable = (text, focusedSelectedCustomerRunnerTicketScheme) => {
  const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
  const [number, ...amounts] = text.split('#');
  const isWholeSequence = text.endsWith('**');
  const isNumberBoxed = number.startsWith('*');
  const numberOfDigits = number.replace(/\*/g, '').length;
  let targetedEntry = null;
  for (let i = 0; i < entries.length && !targetedEntry; i += 1) {
    const {
      systemGameCategoryNumberManipulator,
      systemGameCategoryNumberOfDigits,
    } = entries[i];
    if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && (numberOfDigits >= systemGameCategoryNumberOfDigits)) {
      targetedEntry = entries[i];
    }
  }
  if (amounts.length === 0) {
    if (number || isNumberBoxed) {
      return true;
    }
  }
  if (isWholeSequence) {
    return true;
  }
  if (!isNumberBoxed) {
    if (targetedEntry) {
      const { systemGameCategoryNumberManipulator } = targetedEntry;
      if (text.endsWith('#') && systemGameCategoryNumberManipulator !== null) {
        return true;
      }
      if (amounts.length > 1 && systemGameCategoryNumberManipulator !== null) {
        return true;
      }
    }
    if (amounts.length > 0 && amounts[amounts.length - 1].startsWith('*')) {
      return true;
    }
    if (amounts.length === 1 && amounts[amounts.length - 1].includes('*') && !amounts[amounts.length - 1].endsWith('*')) {
      return true;
    }
    if (amounts.length > 1 && amounts[amounts.length - 1].includes('*')) {
      return true;
    }
  }
  if (isNumberBoxed) {
    if (text.endsWith('#')) {
      return true;
    }
    if (amounts.length > 1 && amounts[amounts.length - 1]) {
      return true;
    }
  }
  return false;
};
const shouldHashtagDisable = (text, focusedSelectedCustomerRunnerTicketScheme) => {
  const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
  const [number, ...amounts] = text.split('#');
  const numberOfDigits = number.replace(/\*/g, '').length;
  const isWholeSequence = text.endsWith('**');
  const isNumberBoxed = number.startsWith('*');
  let validCount = 0;
  for (let i = 0; i < entries.length; i += 1) {
    const {
      systemGameCategoryNumberOfDigits,
      systemGameCategoryNumberManipulator,
    } = entries[i];
    if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits) {
      validCount += 1;
    }
  }
  if (!number || isWholeSequence) {
    return true;
  }
  if (validCount === 0) {
    return true;
  }
  if (validCount === amounts.length) {
    return true;
  }
  return text.endsWith('*') && !isWholeSequence;
};
const shouldNumberDisable = (text, focusedSelectedCustomerRunnerTicketScheme) => {
  const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
  const [number, ...amounts] = text.split('#');
  const numberOfDigits = number.replace(/\*/g, '').length;
  const isNumberBoxed = number.startsWith('*');
  let targetedEntry = null;
  for (let i = 0; i < entries.length && !targetedEntry; i += 1) {
    const {
      systemGameCategoryNumberManipulator,
      systemGameCategoryNumberOfDigits,
    } = entries[i];
    if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && (numberOfDigits >= systemGameCategoryNumberOfDigits)) {
      targetedEntry = entries[i];
    }
  }
  if (!number) {
    return false;
  }
  if (number) {
    if (number.replace(/\*/g, '').length >= 5 && !text.includes('#')) {
      return true;
    }
    if (number.startsWith('*') && text.endsWith('*') && number !== text) {
      return true;
    }
    if (text.endsWith('**')) {
      return true;
    }
    if (amounts.length === 1 && targetedEntry && targetedEntry.systemGameCategoryNumberManipulator !== null && text.endsWith('*')) {
      return true;
    }
  }
  return false;
};

const BitTerminal = (props) => {
  const {
    terminalFontSize,
    systemGame,
    selectDrawInput,
    isUsingKeypad,
    canTakeNote,
    focusedSelectedCustomerRunnerTicketScheme,
    customerRunnerTicketSchemesInputBundle,
    setSelectedCustomerRunnerTicketScheme,
    isPC,
    selectDrawSystemGameType,
    idForCustomerRunnerTicketScheme,
    setIsNoteFocused,
    isNoteFocused,
    playCode,
    selectedCustomer,
    selectedCustomerRunnerTicketScheme,
    note,
    setNote,
    setPlayCode,
  } = props;
  const {
    index,
    lines,
  } = playCode;
  const customerRunnerTicketSchemesInput = customerRunnerTicketSchemesInputBundle.response.payload;
  const handleNoteFocus = React.useCallback(() => setIsNoteFocused(true), [setIsNoteFocused]);
  const handleNoteBlur = React.useCallback(() => setIsNoteFocused(false), [setIsNoteFocused]);
  const handleNoteChange = React.useCallback((e) => setNote(e.target.value), []);
  const handlePress = React.useCallback((pressInput) => {
    const {
      mode,
      children,
    } = pressInput;
    setPlayCode((state) => {
      const {
        index,
        lines,
      } = state;
      const clonedLines = [...lines];
      const { text } = clonedLines[index];
      if (mode === 'backspace') {
        clonedLines[index] = {
          ...clonedLines[index],
          text: text.slice(0, -1),
        };
        return {
          ...state,
          lines: clonedLines,
        };
      }
      if (mode === 'empty') {
        clonedLines[index] = {
          ...clonedLines[index],
          text: '',
        };
        return {
          ...state,
          lines: clonedLines,
        };
      }
      if (mode === 'input') {
        if (children === '#' && text.endsWith('#')) {
          clonedLines[index] = {
            ...clonedLines[index],
            text: `${text}0${children}`,
          };
        } else {
          clonedLines[index] = {
            ...clonedLines[index],
            text: `${text}${children}`,
          };
        }
        return {
          ...state,
          lines: clonedLines,
        };
      }
      if (mode === 'down') {
        const isLastLine = index === lines.length - 1;
        if (text.endsWith('**')) {
          const { entries = [] } = focusedSelectedCustomerRunnerTicketScheme;
          const [number, ...amounts] = text.split('#');
          const numberOfDigits = number.replace(/\*/g, '').length;
          let validCount = 0;
          const isNumberBoxed = number.startsWith('*');
          for (let i = 0; i < entries.length; i += 1) {
            const {
              systemGameCategoryNumberManipulator,
              systemGameCategoryNumberOfDigits,
            } = entries[i];
            if (!(isNumberBoxed && systemGameCategoryNumberManipulator) && numberOfDigits >= systemGameCategoryNumberOfDigits) {
              validCount += 1;
            }
          }
          const amount = amounts[0].replace('**', '');
          const expandedText = [number, ...[...new Array(validCount)].map(() => amount)].join('#');
          clonedLines[index] = {
            ...clonedLines[index],
            text: expandedText,
          };
        } else if (text.endsWith('#') && index > 0) {
          const [number] = text.split('#');
          const [previousNumber, ...previousAmounts] = clonedLines[index - 1].text.split('#');
          if (number.length === previousNumber.length) {
            clonedLines[index] = {
              ...clonedLines[index],
              text: [number, ...previousAmounts].join('#'),
            };
          }
        }
        if (isLastLine) {
          if (text !== '') {
            clonedLines[index + 1] = {
              ...clonedLines[index],
              key: _.uniqueId(),
              text: '',
            };
            return {
              ...state,
              index: index + 1,
              lines: clonedLines,
            };
          }
          return {
            ...state,
            lines: clonedLines,
          };
        }
        return {
          ...state,
          index: index + 1,
          lines: clonedLines,
        };
      }
      if (mode === 'up') {
        return {
          ...state,
          index: Math.max(0, index - 1),
        };
      }
      return state;
    });
  }, [setPlayCode, focusedSelectedCustomerRunnerTicketScheme]);
  const { objectId: customerId } = selectedCustomer;

  React.useEffect(() => {
    setPlayCode((state) => {
      const {
        index: previousIndex,
        lines: previousLines,
      } = state;
      const clonedLines = [...previousLines];
      if (clonedLines[previousIndex].schemeId !== selectedCustomerRunnerTicketScheme.objectId) {
        clonedLines[previousIndex].schemeId = selectedCustomerRunnerTicketScheme.objectId;
        const { text } = clonedLines[previousIndex];
        clonedLines[previousIndex] = {
          ...clonedLines[previousIndex],
          text: text.split('#')[0],
        };
        return {
          ...state,
          lines: clonedLines,
        };
      }
      return state;
    });
  }, [selectedCustomerRunnerTicketScheme, setPlayCode]);
  React.useEffect(() => {
    setPlayCode({
      index: 0,
      lines: [{
        key: _.uniqueId(),
        schemeId: null,
        text: '',
      }],
    });
  }, [customerId, setPlayCode]);
  const keyForRef = React.useMemo(() => {
    const map = {};
    for (let i = 0; i < lines.length; i += 1) {
      map[lines[i].key] = React.createRef();
    }
    return map;
  }, [lines]);
  React.useEffect(() => {
    if (lines.length > 1 && index < lines.length) {
      keyForRef[lines[index].key].current.scrollIntoView({
        block: 'nearest',
        inline: 'start',
        behavior: 'smooth',
      });
    }
  }, [index, keyForRef, lines]);
  React.useEffect(() => {
    if (isPC) {
      window.ondragover = function (e) {
        e.preventDefault();
        return false;
      };
      window.ondragend = function (e) {
        e.preventDefault();
        return false;
      };
      window.ondrop = function (e) {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const reader = new FileReader();
          reader.onload = function (event) {
            const workbook = read(event.target.result);
            const _4D_s2 = 'เล็กเจาะจง';
            const _4d_3D = '3 ตัว';
            const _4d_2D = '2 ตัว';
            const _4d_B = 'ใหญ่รวมพิเศษ';


            const _4D_S = 'เล็กธรรมดา';
            const _4d_S_lines = XLSX.utils.sheet_to_csv(workbook.Sheets[_4D_S]);
            const lines = ['D#'];
            lines.push('*4');
            _4d_S_lines.split('\n').forEach(l => {
              const arr = l.split((','));
              const No = Number(arr[0]);
              if (No >= 1 && No <= 35) {
                for (let j = 1; j < arr.length; j += 2) {
                  const number = arr[j];
                  const cost = arr[j + 1];
                  if (number && cost) {
                    lines.push(`${number}#${cost}`);
                  }
                }
              }
            });

            const _4d_S2_lines = XLSX.utils.sheet_to_csv(workbook.Sheets[_4D_s2]);

            _4d_S2_lines.split('\n').forEach(l => {
              const arr = l.split((','));
              if (!isNaN(arr[0]) && arr[0]) {
                const No = Number(arr[0]);
                const number = arr[1];
                const s_cost = arr[2];
                const c1_cost = arr[3];
                lines.push(`${number}#${s_cost}#${c1_cost}`);
              }
            });

            lines.push('*3');
            let st= 0;
            XLSX.utils.sheet_to_csv(workbook.Sheets[_4d_3D]).split('\n').forEach(l => {
              const arr = l.split((','));
              if (!isNaN(arr[0]) && arr[0]) {
                const No = Number(arr[0]);
                const number = arr[1];
                const a_cost = arr[2];
                const b_cost = arr[3];
                const c_cost = arr[4];
                const n_cost = arr[5];
                if (number && a_cost && b_cost && c_cost && n_cost) {
                lines.push(`${number}#${a_cost}#${b_cost}#${c_cost}#${n_cost}`);
                }
                // st += Number(a_cost)
                // st += Number(b_cost)
                // st += Number(c_cost)
                // st += Number(n_cost)
              }
            });
            XLSX.utils.sheet_to_csv(workbook.Sheets[_4d_2D]).split('\n').forEach(l => {
              const arr = l.split((','));
              if (!isNaN(arr[0]) && arr[0]) {
                const No = Number(arr[0]);
                const number = arr[1];
                const a_cost = arr[2];
                const b_cost = arr[3];
                const c_cost = arr[4];
                const n_cost = arr[5];
                if (number && a_cost && b_cost && c_cost && n_cost) {
                  lines.push(`${number}#${a_cost}#${b_cost}#${c_cost}#${n_cost}`);
                }
                // st += Number(a_cost)
                // st += Number(b_cost)
                // st += Number(c_cost)
                // st += Number(n_cost)
              }
            });
            lines.push('*2');
            XLSX.utils.sheet_to_csv(workbook.Sheets[_4d_B]).split('\n').forEach(l => {
              const arr = l.split((','));
              const No = Number(arr[0]);
              if (No >= 1 && No <= 35) {
                for (let j = 1; j < arr.length; j += 2) {
                  const number = arr[j];
                  const cost = arr[j + 1];
                  if (number && cost) {
                    lines.push(`${number}#${cost}`);
                  }
                }
              }
            });
            const line22 = lines;
            const newLines = [];
            let schemeId = null;
            for (let i = 1; i < line22.length; i += 1) {
              const ll = line22[i];
              if (ll.includes('*') && ll.split('*').length === 2) {
                const aarr = ll.split('*')[1];
                const numberi = Number(aarr);
                const map = {
                  2: 1,
                  3: 2,
                  4: 3,
                  5: 4,
                  11: 5,
                  12: 6,
                  13: 7,
                  14: 8,
                };
                let resolvedIndex = numberi - 1;
                if (systemGame.code === '4D') {
                  resolvedIndex = map[numberi] - 1;
                }
                if (!isNaN(resolvedIndex) && resolvedIndex <= customerRunnerTicketSchemesInput.length) {
                  schemeId = customerRunnerTicketSchemesInput[resolvedIndex].objectId;
                }
              } else {
                if (schemeId) {
                  newLines.push({
                    key: _.uniqueId(),
                    schemeId,
                    text: ll.trim(),
                  });
                }
              }
            }
            if (newLines.length > 0) {
              setPlayCode({
                index: newLines.length - 1,
                lines: newLines,
              });
            }
          };
          reader.readAsArrayBuffer(file);
        }
        if (file.type === 'text/plain') {
          const reader = new FileReader();
          reader.onload = function (event) {
            const line22 = event.target.result.trim().split('\n');
            const newLines = [];
            let schemeId = null;
            for (let i = 1; i < line22.length; i += 1) {
              const ll = line22[i];
              if (ll.includes('*') && ll.split('*').length === 2) {
                const aarr = ll.split('*')[1];
                const numberi = Number(aarr);
                const map = {
                  2: 1,
                  3: 2,
                  4: 3,
                  5: 4,
                  11: 5,
                  12: 6,
                  13: 7,
                  14: 8,
                };
                let resolvedIndex = numberi - 1;
                if (systemGame.code === '4D') {
                  resolvedIndex = map[numberi] - 1;
                }
                if (!isNaN(resolvedIndex) && resolvedIndex <= customerRunnerTicketSchemesInput.length) {
                  schemeId = customerRunnerTicketSchemesInput[resolvedIndex].objectId;
                }
              } else {
                if (schemeId) {
                  newLines.push({
                    key: _.uniqueId(),
                    schemeId,
                    text: ll.trim(),
                  });
                }
              }
            }
            if (newLines.length > 0) {
              setPlayCode({
                index: newLines.length - 1,
                lines: newLines,
              });
            }
          };
          reader.readAsText(file);
        }

        return false;
      };
    }
  }, [customerRunnerTicketSchemesInput, isPC, setPlayCode, systemGame]);
  const listener = React.useCallback((event) => {
    const { text } = lines[index];
    if (isPC || isUsingKeypad==='Yes') {
      if (!isNoteFocused) {

        if (event.key === 'ArrowUp') {
          event.preventDefault();
          event.stopPropagation();
          if (index !== 0 && !_.isEmpty(focusedSelectedCustomerRunnerTicketScheme)) {
            handlePress({
              mode: 'up',
            });
          }
        } else if (event.key === 'Backspace') {
          event.preventDefault();
          event.stopPropagation();
          if (text && !_.isEmpty(focusedSelectedCustomerRunnerTicketScheme)) {
            handlePress({
              mode: 'backspace',
            });
          }
        } else if (event.key === 'ArrowDown' || event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          if (!_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) && isTextValid(playCode)) {
            handlePress({
              mode: 'down',
            });
          } else if (text.includes('*') && text.split('*').length === 2) {
            event.preventDefault();
            event.stopPropagation();
            const indexRows = text.split('*');
            const numberi = Number(indexRows[1]);
            if (!isNaN(numberi) && numberi - 1 <= customerRunnerTicketSchemesInput.length) {
              const {
                alias,
                entries,
                objectId,
              } = customerRunnerTicketSchemesInput[numberi - 1];
              setSelectedCustomerRunnerTicketScheme({
                alias,
                entries,
                objectId,
              });
              handlePress({
                mode: 'empty',
              });
            }
          }

        } else if (event.key === '#' || event.key === '-') {
          event.preventDefault();
          event.stopPropagation();
          if (!shouldHashtagDisable(text, focusedSelectedCustomerRunnerTicketScheme)) {
            handlePress({
              children: '#',
              mode: 'input',
            });
          }
        } else if (event.key === '*') {
          event.preventDefault();
          event.stopPropagation();
          if (!_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) && !shouldAsteriskDisable(text, focusedSelectedCustomerRunnerTicketScheme)) {
            handlePress({
              children: event.key,
              mode: 'input',
            });
          }
        } else if ((/^\d$/).test(event.key)) {

          event.preventDefault();
          event.stopPropagation();
          if (!_.isEmpty(focusedSelectedCustomerRunnerTicketScheme) && !shouldNumberDisable(text, focusedSelectedCustomerRunnerTicketScheme)) {
            handlePress({
              children: event.key,
              mode: 'input',
            });
          }
        }
      }
    }
  }, [isUsingKeypad, isNoteFocused, customerRunnerTicketSchemesInput, focusedSelectedCustomerRunnerTicketScheme, handlePress, index, isPC, lines, playCode, setSelectedCustomerRunnerTicketScheme]);
  return (
    <Styled.DivisionTerminal tabIndex="0" mode={terminalFontSize}
                             onKeyDown={listener}>

      {!isPC && (
        <Styled.DivisionTerminalMenu>
          {selectDrawInput}
        </Styled.DivisionTerminalMenu>
      )}
      {!isPC && (
        <Styled.DivisionTerminalMenu>
          {selectDrawSystemGameType}
        </Styled.DivisionTerminalMenu>
      )}
      {(canTakeNote === 'Yes' || isPC) && (
        <Styled.DivisionTerminalMenu>
          <VendorAntInputTextArea
            value={note}
            onChange={handleNoteChange}
            onFocus={handleNoteFocus}
            onBlur={handleNoteBlur}
            hasClear
            rows={1}
          />
        </Styled.DivisionTerminalMenu>
      )}
      {lines.map((line, i) => (
        <BitTerminalLine
          isPC={isPC}
          customerRunnerTicketScheme={idForCustomerRunnerTicketScheme[line.schemeId] || {}}
          index={i}
          isFocused={index === i}
          key={line.key}
          ref={keyForRef[line.key]}
          setPlayCode={setPlayCode}
          text={line.text}
        />
      ))}
    </Styled.DivisionTerminal>
  );
};
BitTerminal.displayName = 'BitTerminal';
BitTerminal.propTypes = {
  idForCustomerRunnerTicketScheme: PropTypes.objectOf(PropTypes.any).isRequired,
  playCode: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCustomer: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCustomerRunnerTicketScheme: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedDraw: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedDrawSystemGameTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  setPlayCode: PropTypes.func.isRequired,
};
export default React.memo(BitTerminal);
