/**
 * Internals
 */
import actions from './actions';

/**
 * Constant variables
 */
const handlers = {
  [actions.types.GET_DEAL_RECEIPT_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDealReceipt: {
          ...state.request.getDealReceipt,
          [by]: {
            ...state.request.getDealReceipt[by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        getDealReceipt: {
          ...state.response.getDealReceipt,
          [by]: {
            ...state.response.getDealReceipt[by],
            metadata,
          },
        },
      },
    };
  },
  [actions.types.GET_DEAL_RECEIPT_FULFILL]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDealReceipt: {
          ...state.isLoading.getDealReceipt,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDealReceipt: {
          ...state.request.getDealReceipt,
          [by]: {
            ...state.request.getDealReceipt[by],
            count: state.request.getDealReceipt[by].count ? state.request.getDealReceipt[by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  },
  [actions.types.GET_DEAL_RECEIPT_RESET]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDealReceipt: {
          ...state.isLoading.getDealReceipt,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        getDealReceipt: {
          ...state.request.getDealReceipt,
          [by]: {
            ...state.request.getDealReceipt[by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        getDealReceipt: {
          ...state.response.getDealReceipt,
          [by]: {
            ...state.response.getDealReceipt[by],
            headers: {},
            metadata: {},
            payload: {},
          },
        },
      },
    };
  },
  [actions.types.GET_DEAL_RECEIPT_REQUEST]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        getDealReceipt: {
          ...state.isLoading.getDealReceipt,
          [by]: true,
        },
      },
      request: {
        ...state.request,
        getDealReceipt: {
          ...state.request.getDealReceipt,
          [by]: {
            ...state.request.getDealReceipt[by],
            status: 'REQUEST',
          },
        },
      },
    };
  },
  [actions.types.GET_DEAL_RECEIPT_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        getDealReceipt: {
          ...state.request.getDealReceipt,
          [by]: {
            ...state.request.getDealReceipt[by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        getDealReceipt: {
          ...state.response.getDealReceipt,
          [by]: {
            ...state.response.getDealReceipt[by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  },
  [actions.types.PRINT_DEAL_RECEIPT_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        printDealReceipt: {
          ...state.request.printDealReceipt,
          [by]: {
            ...state.request.printDealReceipt[by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        printDealReceipt: {
          ...state.response.printDealReceipt,
          [by]: {
            ...state.response.printDealReceipt[by],
            metadata,
          },
        },
      },
    };
  },
  [actions.types.PRINT_DEAL_RECEIPT_FULFILL]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        printDealReceipt: {
          ...state.isLoading.printDealReceipt,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        printDealReceipt: {
          ...state.request.printDealReceipt,
          [by]: {
            ...state.request.printDealReceipt[by],
            count: state.request.printDealReceipt[by].count ? state.request.printDealReceipt[by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  },
  [actions.types.PRINT_DEAL_RECEIPT_RESET]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        printDealReceipt: {
          ...state.isLoading.printDealReceipt,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        printDealReceipt: {
          ...state.request.printDealReceipt,
          [by]: {
            ...state.request.printDealReceipt[by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        printDealReceipt: {
          ...state.response.printDealReceipt,
          [by]: {
            ...state.response.printDealReceipt[by],
            headers: {},
            metadata: {},
            payload: {},
          },
        },
      },
    };
  },
  [actions.types.PRINT_DEAL_RECEIPT_REQUEST]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        printDealReceipt: {
          ...state.isLoading.printDealReceipt,
          [by]: true,
        },
      },
      request: {
        ...state.request,
        printDealReceipt: {
          ...state.request.printDealReceipt,
          [by]: {
            ...state.request.printDealReceipt[by],
            status: 'REQUEST',
          },
        },
      },
    };
  },
  [actions.types.PRINT_DEAL_RECEIPT_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        printDealReceipt: {
          ...state.request.printDealReceipt,
          [by]: {
            ...state.request.printDealReceipt[by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        printDealReceipt: {
          ...state.response.printDealReceipt,
          [by]: {
            ...state.response.printDealReceipt[by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  },
  [actions.types.SHARE_DEAL_RECEIPT_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        shareDealReceipt: {
          ...state.request.shareDealReceipt,
          [by]: {
            ...state.request.shareDealReceipt[by],
            status: 'FAIL',
          },
        },
      },
      response: {
        ...state.response,
        shareDealReceipt: {
          ...state.response.shareDealReceipt,
          [by]: {
            ...state.response.shareDealReceipt[by],
            metadata,
          },
        },
      },
    };
  },
  [actions.types.SHARE_DEAL_RECEIPT_FULFILL]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        shareDealReceipt: {
          ...state.isLoading.shareDealReceipt,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        shareDealReceipt: {
          ...state.request.shareDealReceipt,
          [by]: {
            ...state.request.shareDealReceipt[by],
            count: state.request.shareDealReceipt[by].count ? state.request.shareDealReceipt[by].count + 1 : 1,
            status: 'READY',
          },
        },
      },
    };
  },
  [actions.types.SHARE_DEAL_RECEIPT_RESET]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        shareDealReceipt: {
          ...state.isLoading.shareDealReceipt,
          [by]: false,
        },
      },
      request: {
        ...state.request,
        shareDealReceipt: {
          ...state.request.shareDealReceipt,
          [by]: {
            ...state.request.shareDealReceipt[by],
            count: 0,
            status: 'READY',
          },
        },
      },
      response: {
        ...state.response,
        shareDealReceipt: {
          ...state.response.shareDealReceipt,
          [by]: {
            ...state.response.shareDealReceipt[by],
            headers: {},
            metadata: {},
            payload: {},
          },
        },
      },
    };
  },
  [actions.types.SHARE_DEAL_RECEIPT_REQUEST]: (state, action) => {
    const { data } = action;
    const by = data.key;
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        shareDealReceipt: {
          ...state.isLoading.shareDealReceipt,
          [by]: true,
        },
      },
      request: {
        ...state.request,
        shareDealReceipt: {
          ...state.request.shareDealReceipt,
          [by]: {
            ...state.request.shareDealReceipt[by],
            status: 'REQUEST',
          },
        },
      },
    };
  },
  [actions.types.SHARE_DEAL_RECEIPT_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    const by = data.key;
    return {
      ...state,
      request: {
        ...state.request,
        shareDealReceipt: {
          ...state.request.shareDealReceipt,
          [by]: {
            ...state.request.shareDealReceipt[by],
            status: 'SUCCESS',
          },
        },
      },
      response: {
        ...state.response,
        shareDealReceipt: {
          ...state.response.shareDealReceipt,
          [by]: {
            ...state.response.shareDealReceipt[by],
            headers,
            metadata,
            payload,
          },
        },
      },
    };
  },
};
const initialState = {
  isLoading: {
    getDealReceipt: {},
    printDealReceipt: {},
    shareDealReceipt: {},
  },
  request: {
    getDealReceipt: {},
    printDealReceipt: {},
    shareDealReceipt: {},
  },
  response: {
    getDealReceipt: {},
    printDealReceipt: {},
    shareDealReceipt: {},
  },
};
export default {
  handlers,
  initialState,
};
