/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  padding-left: ${scale.minor(4.5)}px;
  padding-right: ${scale.minor(4.5)}px;
  > svg {
    :first-child {
      margin-bottom: -0.15em;
    }
    :nth-child(2) {
      margin-top: -0.15em;
    }
  }
`;
