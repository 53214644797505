/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  && > span:first-child {
    margin-right: 0;
  }
  > img {
    margin-left: ${scale.major(1)}px;
    margin-right: ${scale.major(2)}px;
  }
`;
