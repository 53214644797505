/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.button`
  align-items: center;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  display: flex;
  height: ${scale.major(8)}px;
  justify-content: center;
  outline: none;
`;
