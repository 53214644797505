/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: ${scale.major(4)}px ${scale.major(2)}px;
  transition: ease all 0.1s;
  width: 100%;
  @media screen and (max-width: ${scale.major(300)}px) {
    padding: ${scale.major(4)}px;
  }
`;
