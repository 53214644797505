/**
 * Internals
 */
import actions from './actions';

/**
 * Constant variables
 */
const handlers = {
  [actions.types.GET_CURRENT_ACCOUNT_PERMISSIONS_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    return {
      ...state,
      request: {
        ...state.request,
        getCurrentAccountPermissions: {
          ...state.request.getCurrentAccountPermissions,
          status: 'FAIL',
        },
      },
      response: {
        ...state.response,
        getCurrentAccountPermissions: {
          ...state.response.getCurrentAccountPermissions,
          metadata,
        },
      },
    };
  },
  [actions.types.GET_CURRENT_ACCOUNT_PERMISSIONS_FULFILL]: state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getCurrentAccountPermissions: false,
    },
    request: {
      ...state.request,
      getCurrentAccountPermissions: {
        ...state.request.getCurrentAccountPermissions,
        count: state.request.getCurrentAccountPermissions.count + 1,
        status: 'READY',
      },
    },
  }),
  [actions.types.GET_CURRENT_ACCOUNT_PERMISSIONS_REQUEST]: state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      getCurrentAccountPermissions: true,
    },
    request: {
      ...state.request,
      getCurrentAccountPermissions: {
        ...state.request.getCurrentAccountPermissions,
        status: 'REQUEST',
      },
    },
  }),
  [actions.types.GET_CURRENT_ACCOUNT_PERMISSIONS_RESET]: state => ({
    ...state,
    request: {
      ...state.request,
      getCurrentAccountPermissions: {
        ...state.request.getCurrentAccountPermissions,
        count: 0,
        status: 'READY',
      },
    },
    response: {
      ...state.response,
      getCurrentAccountPermissions: {
        ...state.response.getCurrentAccountPermissions,
        headers: {},
        metadata: {},
        payload: [],
      },
    },
  }),
  [actions.types.GET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      metadata,
      payload,
    } = data;
    return {
      ...state,
      request: {
        ...state.request,
        getCurrentAccountPermissions: {
          ...state.request.getCurrentAccountPermissions,
          status: 'SUCCESS',
        },
      },
      response: {
        ...state.response,
        getCurrentAccountPermissions: {
          ...state.response.getCurrentAccountPermissions,
          metadata,
          payload,
        },
      },
    };
  },
};
const initialState = {
  isLoading: {
    getCurrentAccountPermissions: false,
  },
  request: {
    getCurrentAccountPermissions: {
      count: 0,
      status: 'READY',
    },
  },
  response: {
    getCurrentAccountPermissions: {
      metadata: {},
      payload: [],
    },
  },
};
export default {
  handlers,
  initialState,
};
