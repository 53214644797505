/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    mode,
    type,
  } = props;
  const styles = [];
  switch (mode) {
    case 'empty-left': {
      styles.push('background-color: white;');
      styles.push(`border-left: ${scale.minor(0.25)}px solid white;`);
      styles.push(`border-top: ${scale.minor(0.25)}px solid white;`);
      break;
    }
    case 'empty-right': {
      styles.push('background-color: white;');
      styles.push(`border-right: ${scale.minor(0.25)}px solid white;`);
      styles.push(`border-top: ${scale.minor(0.25)}px solid white;`);
      break;
    }
    case 'downline': {
      styles.push('background-color: rgba(46, 204, 113, 0.5);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'normal': {
      styles.push('background-color: rgba(52, 73, 94, 1);');
      styles.push('color: white;');
      styles.push('text-align: center;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'self': {
      styles.push('background-color: rgba(241, 196, 15, 0.5);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'expected': {
      styles.push('background-color: rgba(155, 89, 182, 0.5);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'upline': {
      styles.push('background-color: rgba(155, 89, 182, 0.5);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    case 'whole': {
      styles.push('background-color: rgba(52, 152, 219, 0.5);');
      styles.push('color: black;');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      break;
    }
    default: {
      styles.push('background-color: rgba(52, 73, 94, 1);');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      styles.push('color: white;');
      break;
    }
  }
  if (type === 'sort') {
    styles.push('cursor: pointer;');

    styles.push(`
    :hover {
      background-color: rgba(44, 62, 80, 0.8);
    }
    `);
  }
  return styles.join('');
};
export default styled.th`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  height: ${scale.minor(13)}px;
  padding: ${scale.major(1)}px;
  text-align: center;
  > div {
    min-width: 52px;
    > svg {
      position: absolute;
      bottom: 0;
      margin-bottom: 4px;
    }
  }
  position: relative;
`;
