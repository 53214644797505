/**
 * Node modules
 */
import Form from 'antd/lib/form';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Internals
 */
import Item from './Item';

/**
 * Functional components
 */
const VendorAntForm = (props) => {
  const {
    onSubmit: handleFinish,
    ...rest
  } = props;
  return (
    <Form
      layout="vertical"
      onFinish={handleFinish}
      {...rest}
    />
  );
};
VendorAntForm.Item = Item;
VendorAntForm.displayName = 'VendorAntForm';
VendorAntForm.propTypes = { onSubmit: PropTypes.func.isRequired };
export default VendorAntForm;
