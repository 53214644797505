/**
 * Node modules
 */
import { Link } from '@reach/router';
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { state } = props;
  const styles = [];
  switch (state) {
    case 'selected': {
      styles.push(`
        && {
          border-left: ${scale.minor(0.5)}px solid rgba(231, 76, 60, 1);
          color: rgba(53, 58, 64, 1);
          font-weight: bold;
          :hover {
            background-color: transparent;
            color: rgba(53, 58, 64, 1);
          }
        }
      `);
      break;
    }
    default: {
      styles.push(`
        :hover {
          background-color: rgba(246, 248, 250, 1);
        }
      `);
      break;
    }
  }
  return styles.join('');
};
export default styled(Link)`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  border-bottom: ${scale.minor(0.25)}px solid rgba(225, 228, 232, 1);
  color: rgba(3, 102, 214, 1);
  cursor: pointer;
  display: flex;
  height: ${scale.major(6)}px;
  padding: ${scale.minor(3)}px ${scale.major(3)}px;
  width: 100%;
`;
