/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    majorScaledOffset,
    minorScaledOffset,
  } = props;
  const styles = [];
  if (majorScaledOffset !== undefined) {
    styles.push(`margin-left: ${scale.major(-majorScaledOffset)}px;`);
    styles.push(`margin-top: ${scale.major(-majorScaledOffset)}px;`);
  } else if (minorScaledOffset !== undefined) {
    styles.push(`margin-left: ${scale.minor(-minorScaledOffset)}px;`);
    styles.push(`margin-top: ${scale.minor(-minorScaledOffset)}px;`);
  } else {
    styles.push(`margin-left: ${scale.major(-2)}px;`);
    styles.push(`margin-top: ${scale.major(-2)}px;`);
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;
