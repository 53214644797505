/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { code } = props;
  const styles = [];
  switch (code) {
    case '4D': {
      styles.push('background-color: rgba(252, 244, 225, 1);');
      break;
    }
    case 'TH': {
      styles.push('background-color: rgba(228, 228, 246, 1);');
      break;
    }
    default: {
      styles.push('background-color: rgba(149, 165, 166, 1);');
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  border-radius: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;
