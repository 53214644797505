/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntMenu from '../../Vendor/Ant/Menu';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntMenu.Item)`
  align-items: center;
  display: flex;
  min-width: ${scale.major(20)}px;
  svg {
    margin-right: ${scale.major(1)}px;
  }
`;
