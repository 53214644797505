/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntSpin from '../../Vendor/Ant/Spin';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled(VendorAntSpin)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: ${scale.minor(5)}px;
`;
