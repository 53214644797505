/**
 * Constant variables
 */
const admin = 'Admin';
const agent = 'Agent';
const approved = 'Approved';
const bypassed = 'Bypassed';
const canceled = 'Canceled';
const custom = 'Custom';
const dealer = 'Dealer';
const delegatedAdmin = 'Delegated admin';
const eat = 'Eat';
const statusError = 'error';
const statusSuccess = 'success';
const superAdmin = 'Super admin';
const tank = 'Tank';
const user = 'User';
const warned = 'Warned';
export default {
  admin,
  agent,
  approved,
  bypassed,
  canceled,
  custom,
  dealer,
  delegatedAdmin,
  eat,
  statusError,
  statusSuccess,
  superAdmin,
  tank,
  user,
  warned,
};
