/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import BitMask from '../Mask';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import { images } from '../../../../assets';
import { format } from 'date-fns';

/**
 * Functional components
 */
const BitInvoice = (props) => {
  const {
    customerNickname,
    customerUsername,
    dateFrom,
    dateTo,
    downlineNickname,
    downlineUsername,
    receivedCommissionAmount,
    receivedRewardAmount,
    actions,
    receivedValue,
    requestedAmount,
    requestedBalanceAmount,
    requestedValue,
    respondedAmount,
    respondedBalanceAmount,
    respondedCommissionAmount,
    respondedRewardAmount,
    rewards = [],
    invoiceFormat,
    systemGameTypeAlias,
  } = props;
  const flattenRewards = React.useMemo(() => {
    const list = [];
    _.each(rewards, (reward) => {
      const { deals, ...rest } = reward;
      _.each(deals, (deal) => {
        const processReward = {
          ...deal,
          ...rest,
        };
        list.push(processReward);
      });
    });
    if (invoiceFormat === 'Identifier') {
      return _.orderBy(list, ['username', 'customerIdentifier']);
    }
    return list;
  }, [invoiceFormat, rewards]);
  const translate = useTranslate();
  return (
    <Styled.DivisionInvoiceCard name="division-invoice-card">
      <Styled.DivisionInvoice name="division-invoice">
        {!downlineUsername && (
          <Styled.DivisionRow name="division-row">
            <Styled.Span name="span">
              {`${customerUsername} (${customerNickname})`}
            </Styled.Span>
            <Styled.Small name="small">
              {`${translate('label.yourself')}`}
            </Styled.Small>
          </Styled.DivisionRow>
        )}
        {downlineUsername && (
          <Styled.DivisionRow name="division-row">
            <Styled.Span name="span">
              {`${downlineUsername} (${downlineNickname})`}
            </Styled.Span>
          </Styled.DivisionRow>
        )}
        <Styled.DivisionRow name="division-row">
          <Styled.Division name="division">
            <Styled.Image src={images[systemGameTypeAlias.replace(/\s/g, '')]} />
            <Styled.Span name="span">
              {translate(`display.alias.${systemGameTypeAlias}`)}
            </Styled.Span>
          </Styled.Division>
          {dateFrom === dateTo && (
            <Styled.Span name="span">
              {format(new Date(dateFrom), 'dd-MM-yy')}
            </Styled.Span>
          )}
          {dateFrom !== dateTo && (
            <Styled.Division name="division">
              <Styled.Small name="small">
                {format(new Date(dateFrom), 'dd-MM-yy')}
              </Styled.Small>
              <Styled.Icon.IonicArrowForward />
              <Styled.Small name="small">
                {format(new Date(dateTo), 'dd-MM-yy')}
              </Styled.Small>
            </Styled.Division>
          )}
        </Styled.DivisionRow>
        <Styled.DivisionRow name="division-row">
          {!downlineUsername && (
            <Styled.DivisionInvoiceBanner name="division-invoice-banner">
              <Styled.Division name="division">
                <Styled.Small name="small">
                  {translate('label.total')}
                </Styled.Small>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount" mode={requestedBalanceAmount > 0 ? 'negative' : 'positive'}>
                  <Styled.Span>
                    {utilities.toLocaleString(Math.abs(requestedBalanceAmount), 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </Styled.Division>
            </Styled.DivisionInvoiceBanner>
          )}
          {downlineUsername && (
            <Styled.DivisionInvoiceBanner name="division-invoice-banner">
              <Styled.Division name="division">
                <Styled.Small name="small">
                  {translate('label.total')}
                </Styled.Small>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount" mode={respondedBalanceAmount < 0 ? 'negative' : 'positive'}>
                  <Styled.Span>
                    {utilities.toLocaleString(Math.abs(respondedBalanceAmount), 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </Styled.Division>
            </Styled.DivisionInvoiceBanner>
          )}
        </Styled.DivisionRow>
        <Styled.DivisionInvoiceCardSummary name="division-invoice-card-summary">
          <Styled.DivisionInvoiceRow name="division-invoice-row">
            {!downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.request')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(requestedValue, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
            {downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.received')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">

                  <Styled.Span>
                    {utilities.toLocaleString(receivedValue, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
          </Styled.DivisionInvoiceRow>
          <Styled.DivisionInvoiceRow name="division-invoice-row">
            {!downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.commissions')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(receivedCommissionAmount, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
            {downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.commissions')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(respondedCommissionAmount, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
          </Styled.DivisionInvoiceRow>
          <Styled.DivisionInvoiceRow name="division-invoice-row">
            {!downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.net')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(requestedAmount, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
            {downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.net')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(respondedAmount, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
          </Styled.DivisionInvoiceRow>
          <Styled.DivisionInvoiceRow name="division-invoice-row">
            {!downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.strike')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(receivedRewardAmount, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
            {downlineUsername && (
              <React.Fragment>
                <Styled.SpanItemTitle name="span-item-title">
                  {translate('label.strike')}
                </Styled.SpanItemTitle>
                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                  <Styled.Span>
                    {utilities.toLocaleString(respondedRewardAmount, 2, 'THB')}
                  </Styled.Span>
                </Styled.DivisionInvoiceAmount>
              </React.Fragment>
            )}
          </Styled.DivisionInvoiceRow>
        </Styled.DivisionInvoiceCardSummary>
        {flattenRewards.length > 0 && (
          <Styled.DivisionInvoiceRewards name="division-invoice-rewards">
            <Styled.Span name="span">
              {translate('title.winnings')}
            </Styled.Span>
            {flattenRewards.map((reward, i) => {
              const {
                customerIdentifier,
                identifier,
                nickname,
                note,
                objectId,
                resultNumber,
                systemGamePrizeAlias,
                systemGamePrizeId,
                tag,
                tickets,
                username,
              } = reward;
              const previousReward = flattenRewards[i - 1] || {};
              const shareHeading = previousReward.resultNumber === resultNumber && previousReward.systemGamePrizeAlias === systemGamePrizeAlias;
              return (
                <Styled.DivisionColumn name="division-column" key={systemGamePrizeId}>
                  {!shareHeading && (
                    <Styled.DivisionInvoiceRow name="division-invoice-row">
                      <Styled.Span name="span">
                        {translate(`display.alias.${systemGamePrizeAlias}`)}
                      </Styled.Span>
                      <Styled.DivisionTicketNumber name="division-ticket-number">
                        {resultNumber}
                      </Styled.DivisionTicketNumber>
                    </Styled.DivisionInvoiceRow>
                  )}
                  <Styled.DivisionColumn name="division-column">
                    <Styled.DivisionInvoiceTicket name="division-invoice-ticket" key={objectId}>
                      <Styled.DivisionInvoiceRow name="division-invoice-row">
                        <Styled.Small name="small">
                          {`${username} (${nickname}) #${Number(customerIdentifier)}`}
                        </Styled.Small>
                        <Styled.Small name="small">
                          {`${tag}${identifier}`}
                        </Styled.Small>
                      </Styled.DivisionInvoiceRow>
                      <Styled.DivisionInvoiceRow mode="note" name="division-invoice-row">
                        {note && (
                          <Styled.DivisionReceiptDealNote>
                            <Styled.Division>
                              <Styled.Small>
                                {note}
                              </Styled.Small>
                            </Styled.Division>
                          </Styled.DivisionReceiptDealNote>
                        )}
                      </Styled.DivisionInvoiceRow>
                      {tickets.map((ticket) => {
                        const {
                          amount,
                          rank,
                          rewardAmount,
                          sourceNumber,
                          systemGameCategoryAlias,
                          systemGameCategoryCode,
                          type,
                          value,
                        } = ticket;
                        return (
                          <React.Fragment key={[sourceNumber, rank, systemGameCategoryCode].join('#')}>
                            <Styled.DivisionInvoiceRow name="division-invoice-row">
                              <Styled.DivisionReceiptTicketNumber name="division-receipt-ticket-number">
                                {type === 'Box' && (
                                  <Styled.SmallReceiptTicketLabel name="small-receipt-ticket-label">
                                    {translate('label.box')}
                                  </Styled.SmallReceiptTicketLabel>
                                )}
                                <Styled.Span name="span">
                                  {sourceNumber}
                                </Styled.Span>
                              </Styled.DivisionReceiptTicketNumber>
                              <Styled.DivisionInvoicePayout name="division-invoice-payout">
                                <Styled.DivisionReceiptToken name="divisionReceiptToken">
                                  <Styled.SpanReceiptSuperscript name="span-receipt-superscript">
                                    {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                  </Styled.SpanReceiptSuperscript>
                                  <Styled.Span name="span">
                                    {value.toLocaleString()}
                                  </Styled.Span>
                                </Styled.DivisionReceiptToken>
                                <Styled.Icon.IonicClose />
                                <Styled.Span>
                                  {rewardAmount.toLocaleString()}
                                </Styled.Span>
                              </Styled.DivisionInvoicePayout>
                            </Styled.DivisionInvoiceRow>
                            <Styled.DivisionInvoiceRow name="division-invoice-row">
                              {!downlineUsername && (
                                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                                  <Styled.Span>
                                    {utilities.toLocaleString(amount, 2, 'THB')}
                                  </Styled.Span>
                                </Styled.DivisionInvoiceAmount>
                              )}
                              {downlineUsername && (
                                <Styled.DivisionInvoiceAmount name="division-invoice-amount">
                                  <Styled.Span>
                                    {utilities.toLocaleString(amount, 2, 'THB')}
                                  </Styled.Span>
                                </Styled.DivisionInvoiceAmount>
                              )}
                            </Styled.DivisionInvoiceRow>
                          </React.Fragment>
                        );
                      })}
                    </Styled.DivisionInvoiceTicket>
                  </Styled.DivisionColumn>
                </Styled.DivisionColumn>
              );
            })}
          </Styled.DivisionInvoiceRewards>
        )}
      </Styled.DivisionInvoice>
      <Styled.DivisionToolbar>
        {actions}
      </Styled.DivisionToolbar>
    </Styled.DivisionInvoiceCard>
  );
};
BitInvoice.displayName = 'BitInvoice';
BitInvoice.defaultProps = { downlineUsername: '' };
BitInvoice.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  downlineUsername: PropTypes.string,
  receivedCommissionAmount: PropTypes.number.isRequired,
  receivedRewardAmount: PropTypes.number.isRequired,
  receivedValue: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  requestedBalanceAmount: PropTypes.number.isRequired,
  requestedValue: PropTypes.number.isRequired,
  respondedAmount: PropTypes.number.isRequired,
  respondedBalanceAmount: PropTypes.number.isRequired,
  respondedCommissionAmount: PropTypes.number.isRequired,
  respondedRewardAmount: PropTypes.number.isRequired,
};
export default React.memo(BitInvoice);
