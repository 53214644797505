/**
 * Private modules
 */
import helpers from '../../helpers';

/**
 * Constant variables
 */
const types = {
  GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FAIL: 'GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FAIL',
  GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FULFILL: 'GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FULFILL',
  GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_REQUEST: 'GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_REQUEST',
  GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_RESET: 'GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_RESET',
  GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_SUCCESS: 'GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_SUCCESS',
  GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_TRIGGER: 'GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_TRIGGER',
  GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FAIL: 'GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FAIL',
  GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FULFILL: 'GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FULFILL',
  GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_REQUEST: 'GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_REQUEST',
  GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_RESET: 'GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_RESET',
  GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_SUCCESS: 'GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_SUCCESS',
  GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_TRIGGER: 'GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_TRIGGER',
  GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FAIL: 'GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FAIL',
  GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FULFILL: 'GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FULFILL',
  GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_REQUEST: 'GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_REQUEST',
  GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_RESET: 'GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_RESET',
  GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_SUCCESS: 'GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_SUCCESS',
  GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_TRIGGER: 'GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_TRIGGER',
};
const creators = {
  getDrawSystemGameTypeAteVolumeByCustomerIdFail: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FAIL),
  getDrawSystemGameTypeAteVolumeByCustomerIdFulfill: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_FULFILL),
  getDrawSystemGameTypeAteVolumeByCustomerIdRequest: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_REQUEST),
  getDrawSystemGameTypeAteVolumeByCustomerIdReset: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_RESET),
  getDrawSystemGameTypeAteVolumeByCustomerIdSuccess: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_SUCCESS),
  getDrawSystemGameTypeAteVolumeByCustomerIdTrigger: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_ATE_VOLUME_BY_CUSTOMER_ID_TRIGGER),
  getDrawSystemGameTypeOverallVolumeByCustomerIdFail: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FAIL),
  getDrawSystemGameTypeOverallVolumeByCustomerIdFulfill: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_FULFILL),
  getDrawSystemGameTypeOverallVolumeByCustomerIdRequest: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_REQUEST),
  getDrawSystemGameTypeOverallVolumeByCustomerIdReset: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_RESET),
  getDrawSystemGameTypeOverallVolumeByCustomerIdSuccess: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_SUCCESS),
  getDrawSystemGameTypeOverallVolumeByCustomerIdTrigger: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_OVERALL_VOLUME_BY_CUSTOMER_ID_TRIGGER),
  getDrawSystemGameTypeTankedVolumeByCustomerIdFail: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FAIL),
  getDrawSystemGameTypeTankedVolumeByCustomerIdFulfill: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_FULFILL),
  getDrawSystemGameTypeTankedVolumeByCustomerIdRequest: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_REQUEST),
  getDrawSystemGameTypeTankedVolumeByCustomerIdReset: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_RESET),
  getDrawSystemGameTypeTankedVolumeByCustomerIdSuccess: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_SUCCESS),
  getDrawSystemGameTypeTankedVolumeByCustomerIdTrigger: helpers.createActionCreator(types.GET_DRAW_SYSTEM_GAME_TYPE_TANKED_VOLUME_BY_CUSTOMER_ID_TRIGGER),
};
export default {
  creators,
  types,
};
