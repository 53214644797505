/* global APP_MAIN_API_HOST, APP_MAIN_API_PORT, APP_MAIN_API_PROTOCOL, APP_SITE_ID, APP_SOCKET_API_HOST, APP_SOCKET_API_PORT, APP_SOCKET_API_PROTOCOL, APP_VERSION */
export default {
  MAIN_API_HOST: APP_MAIN_API_HOST,
  MAIN_API_PORT: APP_MAIN_API_PORT,
  MAIN_API_PROTOCOL: APP_MAIN_API_PROTOCOL,
  SITE_ID: APP_SITE_ID,
  SOCKET_API_HOST: APP_SOCKET_API_HOST,
  SOCKET_API_PORT: APP_SOCKET_API_PORT,
  SOCKET_API_PROTOCOL: APP_SOCKET_API_PROTOCOL,
  VERSION: APP_VERSION,
};
