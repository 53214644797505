/**
 * Locales
 */
import enUS from '../entries/en-US.json';
import thTH from '../entries/th-TH.json';

/**
 * Constant variables
 */
export default {
  'en-US': {
    translation: {
      ...enUS,
    },
  },
  'th-TH': {
    translation: {
      ...thTH,
    },
  },
};
