/**
 * Node modules
 */
import styled from 'styled-components';


/**
 * Styled components
 */
export default styled.div`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  > div {
    width: 100%;
  }

  margin-left: 24px;
  margin-top: 8px;
  min-height: 80px;
  img {
    height: 32px;
    margin: 0;
    width: 32px;
  }
  > button {

    height: 52px;
    padding: 8px;
  }
  
`;
