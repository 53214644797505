/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  font-size: ${scale.minor(5)}px;
  font-weight: 600;
  margin-bottom: ${scale.minor(3)}px;
`;
