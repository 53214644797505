/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitMask from '../Mask';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  constants,
  masks,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import styled from 'styled-components';

/**
 * Functional components
 */
const TempNote = styled.div`
  color: #2980b9;
  align-items: center;
  display: flex;
  justify-content: center;

  > small {
    font-size: 12px;
    margin-bottom: -8px;
    font-weight: normal;
    width: auto;
    color: #bdc3c7;
  }
`;
const TempNoteRed = styled.span`
  color: #e74c3c;
`;
const BitReceipt = (props) => {
  const {
    setIsActionReady = _.noop,
    pendingBlocks = [],
    actions,
    activities,
    compiler,
    customerIdentifier,
    customerUsername,
    drawDate,
    identifier,
    isFullscreen,
    isLoading,
    note,
    rawFile,
    status,
    tag,
    timestamp,

  } = props;
  let {
    totalAcceptedAddonAmount,
    totalAcceptedAmount,
    totalRejectedAddonAmount,
    totalRejectedAmount,
    acceptedBlocks,
    rejectedBlocks,
  } = props;
  React.useEffect(() => {
    setIsActionReady(status);
  }, [status, setIsActionReady]);
  const translate = useTranslate();
  const lastActivity = activities[activities.length - 1];
  const content = React.useMemo(() => (
    <React.Fragment>
      <Styled.SectionReceiptBlock>

        {pendingBlocks.map((block) => {
          const {
            lines,
            systemGameTypeAlias,
            systemGameTypeCode,
          } = block;
          return (
            <React.Fragment key={systemGameTypeCode}>
              <Styled.DivisionReceiptChip>
                <Styled.Image src={images[systemGameTypeAlias.replace(/\s/g, '')]} />
                <Styled.Span>
                  {translate(`display.alias.${systemGameTypeAlias}`)}
                </Styled.Span>
              </Styled.DivisionReceiptChip>
              {!_.isEmpty(lines) && (
                <Styled.SectionReceipt>
                  {lines.map((line) => {
                    if (!line) {
                      return null;
                    }
                    const {
                      isPluginPartial,
                      number,
                      plugin,
                      rank,
                      straightSlots,
                    } = line;
                    const rows = [];
                    if (!_.isEmpty(straightSlots)) {
                      rows.push((
                        <Styled.DivisionReceiptRow key={`${rank}#straight`}>
                          <Styled.DivisionReceiptTicketNumber>
                            {number}
                          </Styled.DivisionReceiptTicketNumber>
                          <Styled.DivisionReceiptTokenGroup style={{ opacity: 0.5 }}>
                            {straightSlots.map((straightSlot) => {
                              if (!straightSlot) {
                                return null;
                              }
                              const {
                                amount,
                                isPartial,
                                isEdited,
                                slot,
                                systemGameCategoryAlias,
                                systemGameCategoryCode,
                              } = straightSlot;
                              let mode = 'pending';
                              return (
                                <Styled.DivisionReceiptToken
                                  key={slot}
                                  mode={mode}
                                >
                                  <Styled.SpanReceiptSuperscript>
                                    {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                  </Styled.SpanReceiptSuperscript>
                                  <Styled.Span>
                                    ???
                                  </Styled.Span>
                                </Styled.DivisionReceiptToken>
                              );
                            })}
                          </Styled.DivisionReceiptTokenGroup>
                        </Styled.DivisionReceiptRow>
                      ));
                    }
                    if (!isPluginPartial && !_.isEmpty(plugin)) {
                      const modes = Object.keys(plugin);

                      _.each(modes, mode => {
                        rows.push((
                          <Styled.DivisionReceiptRow key={`${rank}#${mode}`}>
                            <Styled.DivisionReceiptTicketNumber>
                              <Styled.SmallReceiptTicketLabel>
                                {translate(`label.${mode}`)}
                              </Styled.SmallReceiptTicketLabel>
                              <Styled.Span>
                                {number}
                              </Styled.Span>
                            </Styled.DivisionReceiptTicketNumber>
                            <Styled.DivisionReceiptTokenGroup style={{ opacity: 0.5 }}>
                              {plugin[mode]['*']?.map((pluginSlot) => {
                                if (!pluginSlot) {
                                  return null;
                                }
                                const {
                                  amount,
                                  slot,
                                  systemGameCategoryAlias,
                                  systemGameCategoryCode,
                                } = pluginSlot;
                                return (
                                  <Styled.DivisionReceiptToken key={slot} mode="pending">
                                    <Styled.SpanReceiptSuperscript>
                                      {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                    </Styled.SpanReceiptSuperscript>
                                    <Styled.Span>
                                      ???
                                    </Styled.Span>

                                  </Styled.DivisionReceiptToken>
                                );
                              })}
                            </Styled.DivisionReceiptTokenGroup>
                          </Styled.DivisionReceiptRow>
                        ));
                      });
                    }
                    return rows;
                  })}
                </Styled.SectionReceipt>
              )}
            </React.Fragment>
          );
        })}
        {acceptedBlocks.map((block) => {
          const {
            lines,
            systemGameTypeAlias,
            systemGameTypeCode,
          } = block;
          return (
            <React.Fragment key={systemGameTypeCode}>
              <Styled.DivisionReceiptChip>
                <Styled.Image src={images[systemGameTypeAlias.replace(/\s/g, '')]} />
                <Styled.Span>
                  {translate(`display.alias.${systemGameTypeAlias}`)}
                </Styled.Span>
              </Styled.DivisionReceiptChip>
              {!_.isEmpty(lines) && (
                <Styled.SectionReceipt>
                  {lines.map((line) => {
                    if (!line) {
                      return null;
                    }
                    const {
                      isPluginPartial,
                      number,
                      plugin,
                      rank,
                      straightSlots,
                    } = line;
                    const rows = [];
                    if (!_.isEmpty(straightSlots)) {
                      rows.push((
                        <Styled.DivisionReceiptRow key={`${rank}#straight`}>
                          <Styled.DivisionReceiptTicketNumber>
                            {number}
                          </Styled.DivisionReceiptTicketNumber>
                          <Styled.DivisionReceiptTokenGroup>
                            {straightSlots.map((straightSlot) => {
                              if (!straightSlot) {
                                return null;
                              }
                              const {
                                amount,
                                isPartial,
                                isEdited,
                                slot,
                                systemGameCategoryAlias,
                                systemGameCategoryCode,
                              } = straightSlot;
                              let mode = 'default';
                              if (isPartial) {
                                mode = 'partial';
                              } else if (isEdited) {
                                mode = 'edited';
                              }
                              return (
                                <Styled.DivisionReceiptToken
                                  key={slot}
                                  mode={mode}
                                >
                                  <Styled.SpanReceiptSuperscript>
                                    {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                  </Styled.SpanReceiptSuperscript>
                                  <Styled.Span>
                                    {amount.toLocaleString()}
                                  </Styled.Span>
                                </Styled.DivisionReceiptToken>
                              );
                            })}
                          </Styled.DivisionReceiptTokenGroup>
                        </Styled.DivisionReceiptRow>
                      ));
                    }
                    if (!isPluginPartial && !_.isEmpty(plugin)) {
                      const modes = Object.keys(plugin);
                      _.each(modes, mode => {
                        rows.push((
                          <Styled.DivisionReceiptRow key={`${rank}#${mode}`}>
                            <Styled.DivisionReceiptTicketNumber>
                              <Styled.SmallReceiptTicketLabel>
                                {translate(`label.${mode}`)}
                              </Styled.SmallReceiptTicketLabel>
                              <Styled.Span>
                                {number}
                              </Styled.Span>
                            </Styled.DivisionReceiptTicketNumber>
                            <Styled.DivisionReceiptTokenGroup>
                              {plugin[mode]['*'].map((pluginSlot) => {
                                if (!pluginSlot) {
                                  return null;
                                }
                                const {
                                  amount,
                                  slot,
                                  systemGameCategoryAlias,
                                  systemGameCategoryCode,
                                } = pluginSlot;
                                return (
                                  <Styled.DivisionReceiptToken key={slot}>
                                    <Styled.SpanReceiptSuperscript>
                                      {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                    </Styled.SpanReceiptSuperscript>
                                    <Styled.Span>
                                      {amount.toLocaleString()}
                                    </Styled.Span>
                                  </Styled.DivisionReceiptToken>
                                );
                              })}
                            </Styled.DivisionReceiptTokenGroup>
                          </Styled.DivisionReceiptRow>
                        ));
                      });
                    } else if (isPluginPartial && !_.isEmpty(plugin)) {
                      const modes = Object.keys(plugin);
                      _.each(modes, mode => {
                        rows.push((
                          <Styled.DivisionReceiptRow key={`${rank}#${mode}`}>
                            <Styled.DivisionReceiptTicketNumber>
                              <Styled.SmallReceiptTicketLabel>
                                {translate(`label.${mode}`)}
                              </Styled.SmallReceiptTicketLabel>
                              <Styled.Span>
                                {number}
                              </Styled.Span>
                            </Styled.DivisionReceiptTicketNumber>
                            <Styled.DivisionReceiptTicketSpecialGroup>
                              {Object.keys(plugin[mode]).map(pluginNumber => (
                                <Styled.DivisionReceiptTicketSpecial key={pluginNumber}>
                                  <Styled.HorizontalRuleReceipt />
                                  <Styled.DivisionReceiptRow>
                                    <Styled.DivisionReceiptTicketNumber>
                                      {pluginNumber}
                                    </Styled.DivisionReceiptTicketNumber>
                                    <Styled.DivisionReceiptTokenGroup>
                                      {plugin[mode][pluginNumber].map((pluginSlot) => {
                                        if (!pluginSlot) {
                                          return null;
                                        }
                                        const {
                                          amount,
                                          isPartial,
                                          isEdited,
                                          slot,
                                          systemGameCategoryAlias,
                                          systemGameCategoryCode,
                                        } = pluginSlot;
                                        let mode = 'default';
                                        if (isPartial) {
                                          mode = 'partial';
                                        } else if (isEdited) {
                                          mode = 'edited';
                                        }
                                        return (
                                          <Styled.DivisionReceiptToken
                                            key={slot}
                                            mode={mode}
                                          >
                                            <Styled.SpanReceiptSuperscript>
                                              {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                            </Styled.SpanReceiptSuperscript>
                                            <Styled.Span>
                                              {amount.toLocaleString()}
                                            </Styled.Span>
                                          </Styled.DivisionReceiptToken>
                                        );
                                      })}
                                    </Styled.DivisionReceiptTokenGroup>
                                  </Styled.DivisionReceiptRow>
                                </Styled.DivisionReceiptTicketSpecial>
                              ))}
                            </Styled.DivisionReceiptTicketSpecialGroup>
                          </Styled.DivisionReceiptRow>
                        ));
                      });
                    }
                    return rows;
                  })}
                </Styled.SectionReceipt>
              )}
            </React.Fragment>
          );
        })}
      </Styled.SectionReceiptBlock>
      {rejectedBlocks.length > 0 && (
        <Styled.SectionReceiptBlock>
          <Styled.DivisionReceiptNote>
            {translate('title.rejected-summary')}
          </Styled.DivisionReceiptNote>
          {rejectedBlocks.map((block) => {
            const {
              lines,
              systemGameTypeAlias,
              systemGameTypeCode,
            } = block;
            return (
              <React.Fragment key={systemGameTypeCode}>
                {acceptedBlocks.length > 1 && (
                  <Styled.DivisionReceiptChip>
                    <Styled.Image src={images[systemGameTypeAlias.replace(/\s/g, '')]} />
                    <Styled.Span>
                      {translate(`display.alias.${systemGameTypeAlias}`)}
                    </Styled.Span>
                  </Styled.DivisionReceiptChip>
                )}
                {!_.isEmpty(lines) && (
                  <Styled.SectionReceipt>
                    {lines.map((line) => {
                      if (!line) {
                        return null;
                      }
                      const {
                        isPluginPartial,
                        number,
                        plugin,
                        rank,
                        straightSlots,
                      } = line;
                      const rows = [];
                      if (!_.isEmpty(straightSlots)) {
                        rows.push((
                          <Styled.DivisionReceiptRow key={`${rank}#straight`}>
                            <Styled.DivisionReceiptTicketNumber>
                              {number}
                            </Styled.DivisionReceiptTicketNumber>
                            <Styled.DivisionReceiptTokenGroup>
                              {straightSlots.map((straightSlot) => {
                                if (!straightSlot) {
                                  return null;
                                }
                                const {
                                  amount,
                                  isPartial,
                                  slot,
                                  systemGameCategoryAlias,
                                  systemGameCategoryCode,
                                } = straightSlot;
                                if (!isPartial) {
                                  return null;
                                }
                                return (
                                  <Styled.DivisionReceiptToken
                                    key={slot}
                                    mode="rejected"
                                  >
                                    <Styled.SpanReceiptSuperscript>
                                      {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                    </Styled.SpanReceiptSuperscript>
                                    <Styled.Span>
                                      {amount.toLocaleString()}
                                    </Styled.Span>
                                  </Styled.DivisionReceiptToken>
                                );
                              })}
                            </Styled.DivisionReceiptTokenGroup>
                          </Styled.DivisionReceiptRow>
                        ));
                      }
                      if (isPluginPartial && !_.isEmpty(plugin)) {
                        const modes = Object.keys(plugin);
                        _.each(modes, mode => {
                          rows.push((
                            <Styled.DivisionReceiptRow key={`${rank}#${mode}`}>
                              <Styled.DivisionReceiptTicketNumber>
                                <Styled.SmallReceiptTicketLabel>
                                  {translate(`label.${mode}`)}
                                </Styled.SmallReceiptTicketLabel>
                                <Styled.Span>
                                  {number}
                                </Styled.Span>
                              </Styled.DivisionReceiptTicketNumber>
                              <Styled.DivisionReceiptTicketSpecialGroup>
                                {Object.keys(plugin[mode]).map(pluginNumber => (
                                  <Styled.DivisionReceiptTicketSpecial key={pluginNumber}>
                                    <Styled.HorizontalRuleReceipt />
                                    <Styled.DivisionReceiptRow>
                                      <Styled.DivisionReceiptTicketNumber>
                                        {pluginNumber}
                                      </Styled.DivisionReceiptTicketNumber>
                                      <Styled.DivisionReceiptTokenGroup>
                                        {plugin[mode][pluginNumber].map((pluginSlot) => {
                                          if (!pluginSlot) {
                                            return null;
                                          }
                                          const {
                                            amount,
                                            isPartial,
                                            slot,
                                            systemGameCategoryAlias,
                                            systemGameCategoryCode,
                                          } = pluginSlot;
                                          if (!isPartial) {
                                            return null;
                                          }
                                          return (
                                            <Styled.DivisionReceiptToken
                                              key={slot}
                                              mode="rejected"
                                            >
                                              <Styled.SpanReceiptSuperscript>
                                                {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                                              </Styled.SpanReceiptSuperscript>
                                              <Styled.Span>
                                                {amount.toLocaleString()}
                                              </Styled.Span>
                                            </Styled.DivisionReceiptToken>
                                          );
                                        })}
                                      </Styled.DivisionReceiptTokenGroup>
                                    </Styled.DivisionReceiptRow>
                                  </Styled.DivisionReceiptTicketSpecial>
                                ))}
                              </Styled.DivisionReceiptTicketSpecialGroup>
                            </Styled.DivisionReceiptRow>
                          ));
                        });
                      }
                      return rows;
                    })}
                  </Styled.SectionReceipt>
                )}
              </React.Fragment>
            );
          })}
        </Styled.SectionReceiptBlock>
      )}
    </React.Fragment>
  ), [acceptedBlocks, rejectedBlocks, translate]);
  const receiptHeader = (
    <Styled.DivisionReceiptHeader>
      {isLoading && (
        <Styled.Image
          minorScaledHeight={7}
          minorScaledWidth={7}
          src={images.CardLoader}
        />
      )}
      {!isLoading && (
        <React.Fragment>
          {status === constants.approved && (<Styled.Icon.IonicCheckmarkCircle color="#2ecc71" />)}
          {status === constants.bypassed && (<Styled.Icon.IonicCheckmarkCircle color="#3498db" />)}
          {status === constants.canceled && (<Styled.Icon.IonicCloseCircle color="#e74c3c" />)}
          {status === 'Edited' && (<Styled.Icon.IonicAlertCircle color="#8e44ad" />)}
          {status === 'Errored' && (<Styled.Icon.IonicAlertCircle color="#e74c3c" />)}
          {status === 'Rejected' && (<Styled.Icon.IonicAlertCircle color="#e74c3c" />)}
          {status === 'Corrupted' && (<Styled.Icon.IonicAlertCircle color="#e74c3c" />)}
          {status === constants.warned && (<Styled.Icon.IonicAlertCircle color="#f39c12" />)}
          {status.endsWith('ing')&& (<Styled.Icon.IonicSquare color="#34495e" />)}
        </React.Fragment>
      )}
      <Styled.SpanLabel>
        {`#${customerIdentifier === '-' ? '-' : Number(customerIdentifier)}`}
      </Styled.SpanLabel>
      <Styled.SpanLabel>
        {customerUsername}
      </Styled.SpanLabel>
      <Styled.SpanLabel>
        <Styled.SpanTag>
          {tag}
        </Styled.SpanTag>
        {identifier}
      </Styled.SpanLabel>
    </Styled.DivisionReceiptHeader>
  );
  const receiptDescription = (
    <Styled.DivisionReceiptDescription>
      <Styled.SpanLabel>
        {`${translate('title.brief.buy')}: ${format(new Date(timestamp), 'dd-MM-yy (hh:mm a)')}`}
      </Styled.SpanLabel>
      <Styled.SpanLabel>
        {`${translate('title.brief.date')}: ${format(new Date(drawDate), 'dd-MM-yy')}`}
      </Styled.SpanLabel>
      <Styled.DivisionReceiptNote>
        {lastActivity.note && (
          <React.Fragment>
            {(status !== 'Errored'&&status !== 'Rejected' && status !== 'Corrupted') && (
              <Styled.Span>
                {translate(lastActivity.note)}
              </Styled.Span>
            )}
            {(status === 'Errored'||status === 'Rejected'||status === 'Corrupted') && (
              <TempNoteRed>
                {translate(lastActivity.note)}
              </TempNoteRed>
            )}
            <Styled.Small>
              {`${translate('label.created-by')} ${lastActivity.username} ${translate('text.on')} ${format(new Date(lastActivity.timestamp), 'dd-MM-yy hh:mm a')}`}
            </Styled.Small>
          </React.Fragment>
        )}
        {!lastActivity.note && (
          <React.Fragment>
            <Styled.Span>
              {translate(lastActivity.title)}
            </Styled.Span>
            <Styled.Small>
              {`${translate('label.by')} ${lastActivity.username} ${translate('text.on')} ${format(new Date(lastActivity.timestamp), 'dd-MM-yy hh:mm a')}`}
            </Styled.Small>
          </React.Fragment>
        )}
      </Styled.DivisionReceiptNote>
      {note && (
        <Styled.DivisionReceiptDealNote>
          <Styled.Division>
            <Styled.Small>
              {note}
            </Styled.Small>
          </Styled.Division>
        </Styled.DivisionReceiptDealNote>
      )}
    </Styled.DivisionReceiptDescription>
  );
  const receiptAcceptedAmount = [];
  if (status === constants.canceled) {
    receiptAcceptedAmount.push((
      <Styled.DivisionReceiptAmount mode={status.toLowerCase()}>
        <Styled.Small>
          {translate('label.approved-amount')}
        </Styled.Small>
        <BitMask
          options={masks.preciseAmount}
          value={0}
        />
      </Styled.DivisionReceiptAmount>
    ));
  } else if (status === 'Processing') {
    receiptAcceptedAmount.push((
      <Styled.DivisionReceiptAmount mode={status.toLowerCase()}>

        <Styled.Image
          majorScaledHeight={12}
          majorScaledWidth={12}
          src={images.BillLoader}
        />
        <TempNote>
          กรุณารอออกบิลสักครู่
        </TempNote>
      </Styled.DivisionReceiptAmount>
    ));
  } else {
    receiptAcceptedAmount.push((
      <Styled.DivisionReceiptAmount mode={status.toLowerCase()}>
        <Styled.Small>
          {translate('label.approved-amount')}
        </Styled.Small>
        <Styled.Division>
          <BitMask
            options={masks.preciseAmount}
            value={totalAcceptedAmount}
          />
          {totalAcceptedAddonAmount > 0 && (
            <Styled.DivisionReceiptAmount
              key="total-accepted-net-amount"
              mode={status.toLowerCase()}
              type="additional"
            >
              <BitMask
                isBracketed
                options={masks.preciseAmount}
                value={totalAcceptedAmount - totalAcceptedAddonAmount}
              />
            </Styled.DivisionReceiptAmount>
          )}
        </Styled.Division>
      </Styled.DivisionReceiptAmount>
    ));
  }
  const receiptRejectedAmount = [];
  if (totalRejectedAmount > 0 && status !== constants.canceled) {
    receiptRejectedAmount.push((
      <Styled.DivisionReceiptAmount mode="rejected">
        <Styled.Small>
          {translate('label.rejected-amount')}
        </Styled.Small>
        <Styled.Division>
          <BitMask
            options={masks.preciseAmount}
            value={totalRejectedAmount}
          />
          {totalRejectedAddonAmount > 0 && (
            <Styled.DivisionReceiptAmount
              key="total-rejected-net-amount"
              mode="rejected"
              type="additional"
            >
              <BitMask
                isBracketed
                options={masks.preciseAmount}
                value={totalRejectedAmount - totalRejectedAddonAmount}
              />
            </Styled.DivisionReceiptAmount>
          )}
        </Styled.Division>
      </Styled.DivisionReceiptAmount>
    ));
  }
  const receipt = (
    <Styled.DivisionReceipt>
      <Styled.DivisionStamp>
        {status === constants.canceled && (<Styled.Image src={images.Canceled} />)}
        {receiptHeader}
        {receiptDescription}
        {!_.isEmpty(receiptAcceptedAmount) && (
          <Styled.DivisionReceiptPanel>
            {receiptAcceptedAmount}
          </Styled.DivisionReceiptPanel>
        )}
      </Styled.DivisionStamp>

      <Styled.DivisionReceiptContent>
        {content}
      </Styled.DivisionReceiptContent>
      {!_.isEmpty(receiptRejectedAmount) && (
        <Styled.DivisionReceiptPanel>
          {receiptRejectedAmount}
        </Styled.DivisionReceiptPanel>
      )}
    </Styled.DivisionReceipt>
  );
  if (isFullscreen) {
    return receipt;
  }
  return (
    <Styled.DivisionCard>
      {receipt}
      {status !== 'Processing' && (
        <Styled.DivisionToolbar>
          {actions}
        </Styled.DivisionToolbar>
      )}
    </Styled.DivisionCard>
  );
};
BitReceipt.displayName = 'BitReceipt';
BitReceipt.defaultProps = {
  actions: [],
  isFullscreen: false,
  isLoading: false,
};
BitReceipt.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any),
  activities: PropTypes.arrayOf(PropTypes.any).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerIdentifier: PropTypes.string.isRequired,
  customerUsername: PropTypes.string.isRequired,
  drawDate: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool,
  isLoading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  totalAcceptedAddonAmount: PropTypes.number.isRequired,
  totalAcceptedAmount: PropTypes.number.isRequired,
  totalRejectedAddonAmount: PropTypes.number.isRequired,
  totalRejectedAmount: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
};
export default React.memo(BitReceipt);
