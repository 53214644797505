/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    case 'sidebar': {
      styles.push('background-color: white;');
      styles.push('color: rgba(0, 0, 0, 0.65);');
      styles.push(`padding: 0 ${scale.major(2)}px 0 ${scale.major(2)}px;`);
      break;
    }
    default: {
      styles.push('background-color: rgba(53, 58, 64, 1);');
      styles.push('color: rgba(236, 240, 241, 1);');
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  align-items: center;
  display: flex;
  height: ${scale.major(8)}px;
  justify-content: center;
  padding: 0 ${scale.major(2)}px;
  position: fixed;
  width: 100%;
  z-index: 1001;
  @media screen and (max-width: ${scale.major(124)}px) {
    box-shadow: 0 0 ${scale.major(1)}px ${scale.minor(0.5)}px rgba(217, 217, 217, 1);
  }
`;
