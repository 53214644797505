/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${scale.major(2)}px;
  padding: ${scale.minor(3)}px ${scale.minor(5)}px;
`;
