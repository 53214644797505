/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  > span {
    width: auto;
    position: absolute;
    left: 4px;
  }

`;
