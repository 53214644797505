/**
 * Node modules
 */
import IMask from 'imask';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';
import { masks } from '../../../../helpers';

/**
 * Functional components
 */
const BitMask = React.forwardRef((props, ref) => {
  const {
    dataType,
    isBracketed,
    isInput,
    mode,
    name,
    nameForRef,
    onFocus,
    options,
    shouldFocusOnMount,
    selectedChainSet,
    selectedLinkKey,
    setFieldValue,
    value,
    ...rest
  } = props;
  delete rest.onChange;
  const inputRef = nameForRef[name] || ref || React.createRef();
  const [masked] = React.useState(IMask.createMask(options));
  const [mask, setMask] = React.useState(null);
  React.useEffect(() => {
    if (shouldFocusOnMount && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handleFocus = React.useCallback(() => {
    inputRef.current.select();
    onFocus(name);
  }, [inputRef, name, onFocus]);
  const handleAccept = React.useCallback(() => {
    const names = [...selectedChainSet];
    let found = false;
    for (let i = 0; i < names.length; i += 1) {
      const innerNames = names[i].replace(/\?/g, selectedLinkKey).split('#');
      for (let j = 0; j < innerNames.length; j += 1) {
        if (innerNames[j] === name) {
          found = true;
        }
      }
    }
    if (found) {
      for (let i = 0; i < names.length; i += 1) {
        const innerNames = names[i].replace(/\?/g, selectedLinkKey).split('#');
        for (let j = 0; j < innerNames.length; j += 1) {
          nameForRef[innerNames[j]].current.value = masked.value;
          setFieldValue(innerNames[j], dataType === 'number' ? Number(masked.unmaskedValue) : masked.unmaskedValue);
        }
      }
    } else {
      setFieldValue(name, dataType === 'number' ? Number(masked.unmaskedValue) : masked.unmaskedValue);
    }
  }, [dataType, masked.unmaskedValue, masked.value, name, nameForRef, selectedChainSet, selectedLinkKey, setFieldValue]);
  if (mask) {
    mask.off('accept');
    mask.on('accept', handleAccept);
  }
  React.useEffect(() => () => {
    if (mask) {
      mask.destroy();
    }
  }, [mask]);
  React.useEffect(() => {
    if (mask) {
      mask.updateOptions(options);
    }
  }, [mask, options]);
  React.useEffect(() => {
    if (mask) {
      if (dataType === 'raw') {
        mask.unmaskedValue = String(value);
      } else {
        mask.unmaskedValue = typeof value === 'number' ? value.toFixed(2) : value;
      }
    }
  }, [dataType, mask, value]);
  React.useEffect(() => {
    if (!mask && isInput) {
      const iMask = IMask(inputRef.current, masked);
      if (dataType === 'raw') {
        iMask.unmaskedValue = String(value);
      } else {
        iMask.unmaskedValue = typeof value === 'number' ? value.toFixed(2) : value;
      }
      setMask(iMask);
    }
  }, [dataType, inputRef, isInput, mask, masked, name, setFieldValue, value]);
  if (isInput) {
    return (
      <Styled.InputMask
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        dataType={dataType}
        mode={mode}
        onFocus={handleFocus}
        ref={inputRef}
        size={7}
        spellcheck="false"
        type="text"
        {...rest}
      />
    );
  }
  if (dataType === 'raw') {
    return (
      <Styled.SpanMask
        dataType={dataType}
        mode={mode}
      >
        {masked.resolve(String(value))}
      </Styled.SpanMask>
    );
  }
  const resolvedValue = masked.resolve(typeof value === 'number' ? value.toFixed(2) : value);
  return (
    <Styled.SpanMask
      dataType={dataType}
      mode={mode}
    >
      {isBracketed ? `(${resolvedValue})` : resolvedValue}
    </Styled.SpanMask>
  );
});
BitMask.defaultProps = {
  dataType: 'number',
  isInput: false,
  mode: 'default',
  name: undefined,
  nameForRef: {},
  selectedChainSet: new Set(),
  selectedLinkKey: '',
  setFieldValue: _.noop,
  onFocus: _.noop,
};
BitMask.displayName = 'BitMask';
BitMask.propTypes = {
  dataType: PropTypes.string,
  isInput: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string,
  nameForRef: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedChainSet: PropTypes.instanceOf(Set),
  selectedLinkKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
export default BitMask;
