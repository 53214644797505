/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  letter-spacing: ${scale.minor(1)}px;
  font-size: 14px;
  text-indent: 4px;
  width: 100%;
`;
