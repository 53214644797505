/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'antd/lib/skeleton';

/**
 * Functional components
 */
const VendorAntSkeleton = (props) => {
  const {
    isActive,
    isLoading,
    ...rest
  } = props;
  return (
    <Skeleton
      active={isActive}
      loading={isLoading}
      {...rest}
    />
  );
};
VendorAntSkeleton.defaultProps = {
  isActive: false,
  isLoading: false,
};
VendorAntSkeleton.displayName = 'VendorAntSkeleton';
VendorAntSkeleton.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default VendorAntSkeleton;
