/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'antd/lib/select';
import _ from 'lodash';

/**
 * Internals
 */
import Group from './Group';
import Option from './Option';

/**
 * Functional components
 */
const VendorAntSelect = (props) => {
  const {
    filterer,
    hasClear,
    isDisabled,
    isLoading,
    isVirtual,
    ...rest
  } = props;
  return (
    <Select
      allowClear={hasClear}
      disabled={isDisabled}
      filterOption={filterer}
      loading={isLoading}
      virtual={isVirtual}
      {...rest}
    />
  );
};
VendorAntSelect.Group = Group;
VendorAntSelect.Option = Option;
VendorAntSelect.defaultProps = {
  filterer: _.noop,
  hasClear: false,
  isDisabled: false,
  isLoading: false,
  isVirtual: true,
};
VendorAntSelect.displayName = 'VendorAntSelect';
VendorAntSelect.propTypes = {
  filterer: PropTypes.func,
  hasClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isVirtual: PropTypes.bool,
};
export default VendorAntSelect;
