/**
 * Functions
 */
const authorizeAccountRequestSelector = state => state.reducers.request.authorizeAccount;
const authorizeAccountResponseSelector = state => state.reducers.response.authorizeAccount;
const isAuthorizeAccountLoadingSelector = state => state.reducers.isLoading.authorizeAccount;
export default {
  authorizeAccountRequestSelector,
  authorizeAccountResponseSelector,
  isAuthorizeAccountLoadingSelector,
};
