/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { Contexts } from '../../../../helpers';

/**
 * Functional components
 */
const BitTable = (props) => {
  const {
    context,
    limiter,
    source,
    ...rest
  } = props;
  const {
    limit: contextLimit,
    params,
    search: contextSearch,
    setInput,
    sort: contextSort,
    template: contextTemplate,
  } = context;
  const [keyset, setKeyset] = React.useState({});
  const [limit, setLimit] = React.useState(contextLimit || '10');
  const [search, setSearch] = React.useState(contextSearch || {});
  const [sort, setSort] = React.useState(contextSort || {});
  const [stack, setStack] = React.useState([]);
  const [template, setTemplate] = React.useState(contextTemplate || {});
  const tableContext = {
    ...context,
    keyset,
    lastItem: source[limit - 1],
    limit,
    params,
    search,
    setInput,
    setKeyset,
    setLimit,
    setSearch,
    setSort,
    setStack,
    setTemplate,
    sort,
    stack,
    template,
  };
  return (
    <Contexts.Table.Provider value={tableContext}>
      <Styled.VendorAntTable
        source={source}
        {...rest}
      />
      {contextLimit !== 'all' && limiter}
    </Contexts.Table.Provider>
  );
};
BitTable.defaultProps = {
  context: {},
  limiter: null,
};
BitTable.displayName = 'BitTable';
BitTable.propTypes = {
  context: PropTypes.objectOf(PropTypes.any),
  limiter: PropTypes.node,
  source: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default React.memo(BitTable);
