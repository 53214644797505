/**
 * Node modules
 */
import Layout from 'antd/lib/layout';
import React from 'react';

/**
 * Functional components
 */
const VendorAntLayoutFooter = props => (<Layout.Footer {...props} />);
VendorAntLayoutFooter.displayName = 'VendorAntLayoutFooter';
export default VendorAntLayoutFooter;
