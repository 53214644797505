/**
 * Internals
 */
import actions from './actions';

/**
 * Constant variables
 */
const handlers = {
  [actions.types.AUTHORIZE_ACCOUNT_FAIL]: (state, action) => {
    const { data } = action;
    const { metadata } = data;
    return {
      ...state,
      request: {
        ...state.request,
        authorizeAccount: {
          ...state.request.authorizeAccount,
          status: 'FAIL',
        },
      },
      response: {
        ...state.response,
        authorizeAccount: {
          ...state.response.authorizeAccount,
          metadata,
          payload: {
            isAuthorized: false,
          },
        },
        createAccountSession: {
          ...state.response.createAccountSession,
          payload: {
            ...state.response.createAccountSession.payload,
            token: null,
          },
        },
      },
    };
  },
  [actions.types.AUTHORIZE_ACCOUNT_FULFILL]: state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      authorizeAccount: false,
    },
    request: {
      ...state.request,
      authorizeAccount: {
        ...state.request.authorizeAccount,
        count: state.request.authorizeAccount.count + 1,
        status: 'READY',
      },
    },
  }),
  [actions.types.AUTHORIZE_ACCOUNT_REQUEST]: state => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      authorizeAccount: true,
    },
    request: {
      ...state.request,
      authorizeAccount: {
        ...state.request.authorizeAccount,
        status: 'REQUEST',
      },
    },
  }),
  [actions.types.AUTHORIZE_ACCOUNT_RESET]: state => ({
    ...state,
    request: {
      ...state.request,
      authorizeAccount: {
        ...state.request.authorizeAccount,
        count: 0,
        status: 'READY',
      },
    },
    response: {
      ...state.response,
      authorizeAccount: {
        ...state.response.authorizeAccount,
        headers: {},
        metadata: {},
        payload: {},
      },
    },
  }),
  [actions.types.AUTHORIZE_ACCOUNT_SUCCESS]: (state, action) => {
    const { data } = action;
    const {
      headers,
      metadata,
      payload,
    } = data;
    return {
      ...state,
      request: {
        ...state.request,
        authorizeAccount: {
          ...state.request.authorizeAccount,
          status: 'SUCCESS',
        },
      },
      response: {
        ...state.response,
        authorizeAccount: {
          ...state.response.authorizeAccount,
          headers,
          metadata,
          payload: {
            ...payload,
            isAuthorized: true,
          },
        },
      },
    };
  },
};
const initialState = {
  isLoading: {
    authorizeAccount: false,
  },
  request: {
    authorizeAccount: {
      count: 0,
      status: 'READY',
    },
  },
  response: {
    authorizeAccount: {
      metadata: {},
      payload: {
        isAuthorized: false,
      },
    },
  },
};
export default {
  handlers,
  initialState,
};
