/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const { mode } = props;
  const styles = [];
  switch (mode) {
    default: {
      styles.push('background-color: rgba(236, 240, 241, 0.7);');
      styles.push(`border: ${scale.minor(0.25)}px solid rgba(178, 190, 195,1.0);`);
      styles.push('color: black;');
      styles.push('font-weight: normal;');

      break;
    }
  }
  return styles.join('');
};
export default styled.th`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  height: ${scale.minor(9)}px;
  padding: ${scale.major(1)}px;
  text-align: center;
`;
