/**
 * Node modules
 */
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { nprogress as NProgress } from '../../../../helpers';

/**
 * Functional components
 */
const BitSuspenseBar = () => {
  React.useEffect(() => {
    NProgress.start();
    return () => NProgress.done();
  }, []);
  return (<Styled.DivisionRow />);
};
BitSuspenseBar.displayName = 'BitSuspenseBar';
export default React.memo(BitSuspenseBar);
