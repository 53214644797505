/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  > img {
    display: block;
    height: ${scale.major(20)}px;
    left: 100%;
    margin-left: -${scale.major(23)}px;
    margin-top: -${scale.major(2)}px;
    position: absolute;
    transform: rotate(15deg);
    width: ${scale.major(20)}px;
  }
`;
