/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${scale.minor(18)}px;
  > button:not(:first-child) {
    border-left: ${scale.minor(0.25)}px solid white;
  }
  > div:not(:first-child) {
    display: flex;
    height: 100%;
    margin-left: auto;
    width: 50%;
    > button {
      border-left: ${scale.minor(0.25)}px solid white;
    }
  }
`;
