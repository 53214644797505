/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  width: 100%;
  > div:first-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > small {
      margin-bottom: -${scale.minor(2)}px;
      width: auto;
    }
    > div {
      font-size: ${scale.minor(8)}px;
    }
  }
  > div:nth-child(2) {
    align-items: center;
    display: flex;
    > small {
      width: auto;
    }
  }
`;
