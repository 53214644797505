/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */

export default styled.div`
  align-items: center;
  overflow: auto;
  flex-direction: column;
  padding: 24px;
  display: flex;
  width: 100%;
`;
