/**
 * Node modules
 */
import Menu from 'antd/lib/menu';
import React from 'react';

/**
 * Functional components
 */
const VendorAntMenuDivider = props => (<Menu.Divider {...props} />);
VendorAntMenuDivider.displayName = 'VendorAntMenuDivider';
export default VendorAntMenuDivider;
