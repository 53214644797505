/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    dataType,
    mode,
  } = props;
  const styles = [];
  switch (dataType) {
    case 'ticket-number': {
      styles.push('font-weight: 800;');
      styles.push(`letter-spacing: ${scale.major(1)}px;`);
      styles.push(`margin-left: ${scale.minor(1)}px;`);
      break;
    }
    default: {
      break;
    }
  }
  switch (mode) {
    case 'embedded': {
      styles.push('text-align: center;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};

/**
 * Styled components
 */
export default styled.span`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
`;
