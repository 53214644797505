/**
 * Private modules
 */
import helpers from '../../helpers';

/**
 * Constant variables
 */
const types = {
  AUTHORIZE_ACCOUNT_FAIL: 'AUTHORIZE_ACCOUNT_FAIL',
  AUTHORIZE_ACCOUNT_FULFILL: 'AUTHORIZE_ACCOUNT_FULFILL',
  AUTHORIZE_ACCOUNT_REQUEST: 'AUTHORIZE_ACCOUNT_REQUEST',
  AUTHORIZE_ACCOUNT_RESET: 'AUTHORIZE_ACCOUNT_RESET',
  AUTHORIZE_ACCOUNT_SUCCESS: 'AUTHORIZE_ACCOUNT_SUCCESS',
  AUTHORIZE_ACCOUNT_TRIGGER: 'AUTHORIZE_ACCOUNT_TRIGGER',
};
const creators = {
  authorizeAccountFail: helpers.createActionCreator(types.AUTHORIZE_ACCOUNT_FAIL),
  authorizeAccountFulfill: helpers.createActionCreator(types.AUTHORIZE_ACCOUNT_FULFILL),
  authorizeAccountRequest: helpers.createActionCreator(types.AUTHORIZE_ACCOUNT_REQUEST),
  authorizeAccountReset: helpers.createActionCreator(types.AUTHORIZE_ACCOUNT_RESET),
  authorizeAccountSuccess: helpers.createActionCreator(types.AUTHORIZE_ACCOUNT_SUCCESS),
  authorizeAccountTrigger: helpers.createActionCreator(types.AUTHORIZE_ACCOUNT_TRIGGER),
};
export default {
  creators,
  types,
};
