/**
 * Node modules
 */
import Button from 'antd/lib/button';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntButton = (props) => {
  const {
    appearance,
    intent,
    isBlock,
    isDisabled,
    isGhost,
    isLoading,
    ...rest
  } = props;
  const isDanger = intent === 'danger';
  return (
    <Button
      block={isBlock}
      danger={isDanger}
      disabled={isDisabled}
      ghost={isGhost}
      loading={isLoading}
      type={appearance}
      {...rest}
    />
  );
};
VendorAntButton.defaultProps = {
  appearance: 'default',
  intent: 'none',
  isBlock: false,
  isDisabled: false,
  isGhost: false,
  isLoading: false,
};
VendorAntButton.displayName = 'VendorAntButton';
VendorAntButton.propTypes = {
  appearance: PropTypes.string,
  intent: PropTypes.string,
  isBlock: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isGhost: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default VendorAntButton;
