/**
 * Node modules
 */
import React from 'react';

/**
 * Betlytical modules
 */
import { permissionIdentities } from '@betlytical/api-schema';

/**
 * Lazy components
 */
const LazyPageAnalyticsSystemGameTypeReports = React.lazy(() => import('../../application/containers/Page/Analytics/SystemGameTypeReports'));
export default {
  children: [
    {
      LazyComponent: LazyPageAnalyticsSystemGameTypeReports,
      name: 'reports',
      path: 'reports',
      permissionIdentity: permissionIdentities.GET_DRAW_SYSTEM_GAME_TYPE_REPORT,
      section: '1.0.0',
    },
  ],
};
