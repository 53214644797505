/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private functions
 */
const getStyles = (props) => {
  const {
    code,
    mode,
  } = props;
  const styles = [];
  switch (code) {
    case '4D': {
      styles.push(`
        button {
          background-color: rgba(252, 244, 225, 1);
          :active {
            background-color: rgba(255, 237, 166, 1);
          }
          :disabled, [disabled] {
            color: rgba(44, 62, 80, 0.2);
            img, svg {
              opacity: 0.1;
            }
          }
        }
      `);
      break;
    }
    case 'TH': {
      styles.push(`
        button {
          background-color: rgba(228, 228, 246, 1);
          :active {
            background-color: rgba(205, 205, 250, 1);
          }
          :disabled, [disabled] {
            color: rgba(44, 62, 80, 0.2);
            img, svg {
              opacity: 0.1;
            }
          }
        }
      `);
      break;
    }
    default: {
      break;
    }
  }
  switch (mode) {
    case 'fullscreen': {
      styles.push('height: 100%;');
      break;
    }
    case 'pc': {
      styles.push('height: 100%;');
      styles.push('padding: 16px;');
      break;
    }
    default: {
      break;
    }
  }
  return styles.join('');
};
export default styled.div`
  /* stylelint-disable */
  ${getStyles};
  /* stylelint-enable */
  position: relative;
  display: flex;
  flex-direction: column;
`;
