/**
 * Node modules
 */
import Drawer from 'antd/lib/drawer';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Functional components
 */
const VendorAntDrawer = (props) => {
  const {
    hasClose,
    hasOverlay,
    isClosingOnOverlayClick,
    isDestroyedOnClose,
    isShown,
    title,
    ...rest
  } = props;
  return (
    <Drawer
      closable={hasClose}
      destroyOnClose={isDestroyedOnClose}
      mask={hasOverlay}
      maskClosable={isClosingOnOverlayClick}
      title={title}
      visible={isShown}
      {...rest}
    />
  );
};
VendorAntDrawer.defaultProps = {
  hasClose: false,
  hasOverlay: true,
  isClosingOnOverlayClick: true,
  isDestroyedOnClose: false,
  isShown: false,
};
VendorAntDrawer.displayName = 'VendorAntDrawer';
VendorAntDrawer.propTypes = {
  hasClose: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  isClosingOnOverlayClick: PropTypes.bool,
  isDestroyedOnClose: PropTypes.bool,
  isShown: PropTypes.bool,
  title: PropTypes.node.isRequired,
};
export default VendorAntDrawer;
