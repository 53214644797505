/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import VendorAntRadio from '../../Vendor/Ant/Radio';

/**
 * Styled components
 */
export default styled(VendorAntRadio)`
  display: block;
`;
