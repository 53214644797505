/**
 * Node modules
 */
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

/**
 * Clients
 */
import clients from '../../../../clients';

/**
 * Internals
 */
import actions from './actions';

/**
 * Private modules
 */
import constants from '../../constants';

/**
 * Functions
 */
const requestAuthorizeAccount = function* (action) {
  const { data } = action;
  try {
    yield put(actions.creators.authorizeAccountRequest(null));
    const {
      metadata,
      payload,
    } = yield call(clients.main['request-authorize-account'], data);
    yield put(actions.creators.authorizeAccountSuccess({
      metadata,
      payload,
    }));
  } catch (error) {
    localStorage.removeItem(constants.localStorageTokenKey);
    yield put(actions.creators.authorizeAccountFail({ metadata: error.metadata }));
  } finally {
    yield put(actions.creators.authorizeAccountFulfill(null));
  }
};
const watchRequestAuthorizeAccount = function* () {
  yield takeEvery(actions.types.AUTHORIZE_ACCOUNT_TRIGGER, requestAuthorizeAccount);
};
export default { watchRequestAuthorizeAccount };
