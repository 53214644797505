/**
 * Node modules
 */
import React from 'react';
import Tabs from 'antd/lib/tabs';

/**
 * Internals
 */
import Pane from './Pane';

/**
 * Functional components
 */
const VendorAntTab = props => (<Tabs
  destroyInactiveTabPane
  {...props}
/>);
VendorAntTab.Pane = Pane;
VendorAntTab.displayName = 'VendorAntTab';
export default VendorAntTab;
