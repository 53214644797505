/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    margin-left: ${scale.minor(1)}px;
    margin-right: ${scale.minor(1)}px;
  }
  :not(:nth-child(2))::before {
    content: '|';
    margin-left: ${scale.minor(1)}px;
    margin-right: ${scale.minor(1)}px;
  }
`;
