/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-left: ${scale.major(2)}px;
  padding-right: ${scale.major(2)}px;
  z-index: 1000;
  :hover {
    background-color: rgba(242, 242, 242, 1);
    color: rgba(24, 144, 255, 1);
  }
`;
