/**
 * Node modules
 */
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import { Contexts } from '../../../../helpers';

/**
 * Functional components
 */
const BitTableLimiter = () => {
  const {
    keyset,
    lastItem,
    limit,
    params,
    search,
    setInput,
    setKeyset,
    setLimit,
    setStack,
    sort,
    stack,
  } = React.useContext(Contexts.Table);
  const handleNextPage = React.useCallback(() => {
    const sortKeys = Object.keys(sort);
    const nextKeyset = { objectId: lastItem.objectId };
    for (let i = 0; i < sortKeys.length; i += 1) {
      const key = sortKeys[i];
      nextKeyset[key] = lastItem[key];
    }
    const input = {
      query: {
        keyset: nextKeyset,
        limit,
        search,
        sort,
      },
    };
    if (params) {
      input.params = params;
    }
    setInput((previousInput) => {
      const { by } = previousInput;
      return {
        by,
        ...input,
      };
    });
    setKeyset(nextKeyset);
    setStack([...stack, keyset]);
  }, [keyset, lastItem, limit, params, search, setInput, setKeyset, setStack, sort, stack]);
  const handlePreviousPage = React.useCallback(() => {
    const previousKeyset = stack[stack.length - 1];
    const input = {
      query: {
        keyset: previousKeyset,
        limit,
        search,
        sort,
      },
    };
    if (params) {
      input.params = params;
    }
    setInput((previousInput) => {
      const { by } = previousInput;
      return {
        by,
        ...input,
      };
    });
    setKeyset(previousKeyset);
    setStack(stack.slice(0, -1));
  }, [limit, params, search, setInput, setKeyset, setStack, sort, stack]);
  const handlePageLimitChange = React.useCallback((pageLimit) => {
    const input = {
      query: {
        keyset: {},
        limit: pageLimit,
        search,
        sort,
      },
    };
    if (params) {
      input.params = params;
    }
    setInput((previousInput) => {
      const { by } = previousInput;
      return {
        by,
        ...input,
      };
    });
    setKeyset({});
    setLimit(pageLimit);
    setStack([]);
  }, [params, search, setInput, setKeyset, setLimit, setStack, sort]);
  const pageSizes = [
    1,
    2,
    20,
    30,
    40,
    50,
    100,
    200,
  ];
  return (
    <Styled.DivisionLimiter>
      <Styled.VendorAntButton
        disabled={stack.length === 0}
        icon={(<Styled.Icon.IonicCaretBack />)}
        onClick={handlePreviousPage}
      />
      <Styled.VendorAntButton
        disabled={!lastItem}
        icon={(<Styled.Icon.IonicCaretForward />)}
        onClick={handleNextPage}
      />
      <Styled.VendorAntSelect
        onChange={handlePageLimitChange}
        value={limit}
      >
        {pageSizes.map(pageSize => (
          <Styled.VendorAntSelect.Option
            key={pageSize}
            value={pageSize}
          >
            {pageSize}
          </Styled.VendorAntSelect.Option>
        ))}
      </Styled.VendorAntSelect>
    </Styled.DivisionLimiter>
  );
};
BitTableLimiter.displayName = 'BitTableLimiter';
export default React.memo(BitTableLimiter);
