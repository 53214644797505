/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';
import BitPacket from '../Packet';
import VendorAntSkeleton from '../../Vendor/Ant/Skeleton';
import { format } from 'date-fns';
import { utilities } from '../../../../helpers';
import { images } from '../../../../assets';
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitBoard = (props) => {
  const { entries, mode } = props;

  const translate = useTranslate();
  return (
    <Styled.DivisionStrikeOverview>
      {entries.map((entry, i) => {
        const {
          systemGamePrizeAlias,
          systemGamePrizeId,
          systemGamePrizeSection,
          systemGameCategories,
        } = entry;
        const components = [];
        if (i === 0 || entries[i - 1].systemGamePrizeSection[0] !== systemGamePrizeSection[0]) {
          components.push((
            <Styled.DivisionStrikeHeader key={systemGamePrizeId}>
              {translate(`display.alias.${_.head(systemGamePrizeAlias.split(' '))}`)}
            </Styled.DivisionStrikeHeader>
          ));
        }
        const headers = [];
        const amounts = [];
        const values = [];
        for (let j = 0; j < systemGameCategories.length; j += 1) {
          const {
            systemGameCategoryAlias,
            systemGameCategoryCode,
            amount,
            value,
          } = systemGameCategories[j];
          headers.push((
            <Styled.TableHeadingStrike mode={mode}>
              <Styled.Division>
                {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
              </Styled.Division>
            </Styled.TableHeadingStrike>
          ));
          amounts.push((
            <Styled.TableDataStrike mode={mode}>
              <Styled.Division>
                {amount === null ? '-' : utilities.toLocaleString(amount, 2)}
              </Styled.Division>
            </Styled.TableDataStrike>
          ));
          values.push((
            <Styled.TableDataStrike mode={mode}>
              <Styled.Division>
                {value === null ? '-' : utilities.toLocaleString(value, 2)}
              </Styled.Division>
            </Styled.TableDataStrike>
          ));
        }
        components.push((
          <Styled.DivisionStrikeOverviewBody>
            <Styled.TableStrikeOverview>
              <Styled.TableHeader>
                <Styled.TableRow>
                  <Styled.TableHeadingStrike colSpan={2}>
                    {translate(`display.alias.${systemGamePrizeAlias}`)}
                  </Styled.TableHeadingStrike>
                  {headers}
                </Styled.TableRow>
              </Styled.TableHeader>
              <Styled.TableBodyPortal>
                <Styled.TableRow>
                  <Styled.TableDataStrike
                    mode="number"
                    rowSpan={2}
                  >
                    <Styled.DivisionStrikeNumber>
                      {entry.number}
                    </Styled.DivisionStrikeNumber>
                  </Styled.TableDataStrike>
                  <Styled.TableDataStrike
                    mode="coin">
                    <Styled.DivisionCoin>
                      <Styled.Image
                        minorScaledHeight={5}
                        minorScaledWidth={5}
                        src={images.Baht}
                      />
                    </Styled.DivisionCoin>
                  </Styled.TableDataStrike>
                  {amounts}
                </Styled.TableRow>
                <Styled.TableRow>
                  <Styled.TableDataStrike
                    mode="coin">
                    <Styled.DivisionCoin>
                      <Styled.Image
                        minorScaledHeight={5}
                        minorScaledWidth={5}
                        src={images.Coin}
                      />
                    </Styled.DivisionCoin>
                  </Styled.TableDataStrike>
                  {values}
                </Styled.TableRow>
              </Styled.TableBodyPortal>
            </Styled.TableStrikeOverview>

          </Styled.DivisionStrikeOverviewBody>
        ));
        return components;
      })}
    </Styled.DivisionStrikeOverview>
  );
};
BitBoard.defaultProps = {
  actions: null,
  isFirstBoot: false,
  isLoading: false,
  mode: 'default',
};
BitBoard.displayName = 'BitBoard';
BitBoard.propTypes = {
  actions: PropTypes.node,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.node.isRequired,
  isFirstBoot: PropTypes.bool,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
};
export default React.memo(BitBoard);
