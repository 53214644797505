/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Private modules
 */
import { scale } from '../.private';

/**
 * Styled components
 */
export default styled.div`
  :not(:first-child) {
    margin-top: ${scale.major(1)}px;
  }
  background-color: rgba(236, 240, 241, 0.5);
  border-radius: ${scale.major(2)}px;
  display: flex;
  flex-direction: column;
  padding: ${scale.major(1)}px;
  width: 100%;
  > div:nth-child(2n+4) {
    justify-content: flex-end;
  }
`;
