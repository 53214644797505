/**
 * Node modules
 */
import Collapse from 'antd/lib/collapse';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Internals
 */
import Panel from './Panel';

/**
 * Functional components
 */
const VendorAntCollapse = (props) => {
  const {
    isAccordion,
    isBordered,
    layout,
    ...rest
  } = props;
  return (
    <Collapse
      accordion={isAccordion}
      bordered={isBordered}
      expandIconPosition={layout}
      {...rest}
    />
  );
};
VendorAntCollapse.Panel = Panel;
VendorAntCollapse.defaultProps = {
  isAccordion: false,
  isBordered: false,
  layout: 'right',
};
VendorAntCollapse.displayName = 'VendorAntCollapse';
VendorAntCollapse.propTypes = {
  isAccordion: PropTypes.bool,
  isBordered: PropTypes.bool,
  layout: PropTypes.string,
};
export default VendorAntCollapse;
