/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Components
 */
import * as Styled from '../../Styled';
import VendorAntTooltip from '../../Vendor/Ant/Tooltip';

/**
 * Functional components
 */
const BitTicketNumberModel = (props) => {
  const {
    model,
    rangeScheme,
    type,
  } = props;
  if (type === 'permutation') {
    return (
      <Styled.DivisionTicketNumberModel>
        {model.split('#').map((placeholder, index) => {
          const key = `${placeholder}#${index}`;
          if (placeholder === '.') {
            return (<Styled.Icon.IonicLockClosed key={key} />);
          }
          return (
            <Styled.Icon.IonicSquare
              key={key}
              placeholder={placeholder}
            />
          );
        })}
      </Styled.DivisionTicketNumberModel>
    );
  }
  if (type === 'running') {
    return (
      <Styled.DivisionTicketNumberModel>
        {model.split('#').map((placeholder, index) => {
          const key = `${placeholder}#${index}`;
          if (placeholder === '.') {
            return (<Styled.Icon.IonicLockClosed key={key} />);
          }
          if (rangeScheme && rangeScheme[placeholder]) {
            return (
              <VendorAntTooltip
                key={key}
                title={rangeScheme[placeholder].join(', ')}
              >
                <Styled.DivisionIcon>
                  <Styled.Icon.IonicSquare placeholder={placeholder} />
                </Styled.DivisionIcon>
              </VendorAntTooltip>
            );
          }
          return (
            <Styled.Icon.IonicSquare
              key={key}
              placeholder={placeholder}
            />
          );
        })}
      </Styled.DivisionTicketNumberModel>
    );
  }
  return null;
};
BitTicketNumberModel.displayName = 'BitTicketNumberModel';
BitTicketNumberModel.defaultProps = {
  model: '',
  rangeScheme: {},
  type: '',
};
BitTicketNumberModel.propTypes = {
  model: PropTypes.string,
  rangeScheme: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};
export default React.memo(BitTicketNumberModel);
